import { SVGProps } from 'react';

const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M11.46 15.705A120.781 120.781 0 0 1 22.011 5.53c.724-.641-.34-1.699-1.06-1.06A115.382 115.382 0 0 0 9.92 15.165a.77.77 0 0 0 0 1.06c3.594 3.969 6.894 8.237 11.251 11.422.78.57 1.53-.731.757-1.296-4.044-2.956-7.155-6.935-10.468-10.647Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgArrowLeft;
