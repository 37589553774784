import { FC, PropsWithChildren, RefObject, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { IProps } from 'interfaces';
import { IReduxState } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { SmallText } from 'components/Templates';
import styles from 'styles';
import { LoadMoreWrapper } from './LoadMore.styles';
import useOnScreen from 'hooks/use-onScreen';
import LoadingIcon from 'components/Icon/LoadingIcon';

export interface ILoadMoreProps extends PropsWithChildren<IProps> {
  canLoadMore: boolean;
  allLoadedText?: string;
  onLoadMore: () => void;
}

const LoadMore: FC<ILoadMoreProps> = (props: ILoadMoreProps): JSX.Element => {
  const { className, style, canLoadMore, allLoadedText, onLoadMore } = props;
  const language = useSelector((state: IReduxState) => state.language.values);
  const { elementRef, isIntersecting } = useOnScreen(false);

  useEffect(() => {
    if (isIntersecting && canLoadMore) {
      console.log('requesting to load more items');
      onLoadMore();
    }
  }, [isIntersecting, canLoadMore]);

  return (
    <LoadMoreWrapper
      className={className}
      style={style}
      ref={elementRef as RefObject<HTMLDivElement>}
    >
      <SwitchTransition>
        <CSSTransition
          classNames='load-more'
          key={canLoadMore ? 'load-more' : 'all-loaded'}
          timeout={styles.transition}
        >
          <div>
            {canLoadMore && (
              <>
                <LoadingIcon
                  style={{ marginRight: '0.5rem' }}
                  size={styles.iconSize.textSmall}
                  fill={styles.colors.brand.dark}
                />
                <SmallText as='span'>{language.seeMore}</SmallText>
              </>
            )}
            {!canLoadMore && allLoadedText && (
              <SmallText as='span'>{allLoadedText}</SmallText>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </LoadMoreWrapper>
  );
};

export default LoadMore;
