import IProps from 'interfaces/IProps';
import React, { Children, cloneElement, FC, PropsWithChildren } from 'react';
import { WhitePageSingleContainer, WhitePageWrapper } from './WhitePage.styles';
import WhitePageHeader from './WhitePageHeader';

export interface IWhitePageProps extends PropsWithChildren<IProps> {
  title: string | JSX.Element;
  onBack?: () => void;
}

const WhitePage: FC<IWhitePageProps> = (
  props: IWhitePageProps
): JSX.Element => {
  const { className, style, title, onBack, children } = props;
  const childrenList = React.Children.toArray(children);
  let LeftContent: any;
  let RightContent: any;

  console.log(children);
  childrenList.forEach((child: any) => {
    console.log(child);
    if (child.type?.displayName === 'LeftContent') LeftContent = child;
    else if (child.type?.displayName === 'RightContent') RightContent = child;
  });

  const noContentColumns = !LeftContent && !RightContent;

  return (
    <WhitePageWrapper
      className={className}
      style={style}
      direction={noContentColumns ? 'column' : 'row'}
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      {LeftContent &&
        cloneElement(
          LeftContent,
          { ...LeftContent.props },
          Children.map(
            [
              <WhitePageHeader title={title} onBack={onBack} />,
              LeftContent.props.children
            ],
            (child: any) => child
          )
        )}
      {RightContent && cloneElement(RightContent, { ...RightContent.props })}
      {noContentColumns && (
        <>
          <WhitePageHeader
            style={{ marginBottom: 0 }}
            title={title}
            onBack={onBack}
          />
          <WhitePageSingleContainer>{children}</WhitePageSingleContainer>
        </>
      )}
    </WhitePageWrapper>
  );
};

WhitePage.displayName = 'WhitePage';

export default WhitePage;
