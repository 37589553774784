import { Flex } from 'components/Templates';
import IProps from 'interfaces/IProps';
import { FC, Fragment, PropsWithChildren } from 'react';
import { IInputFormat } from 'utils/form';
import InputStep from '../../InputStep/InputStep';
import { DateTimeValuesState } from '../DateTimeInputs.logic';
import { TimeSplitter, TimePickerPopup } from './TimePicker.styles';

export interface ITimePickerProps extends PropsWithChildren<IProps> {
  show: boolean;
  inputFormat: IInputFormat[];
  values: DateTimeValuesState;
  onChange: (type: string, value: number) => void;
  onClose: () => void;
}

const TimePicker: FC<ITimePickerProps> = (props: ITimePickerProps) => {
  const { dataTestId, show, inputFormat, values, onChange, onClose } = props;

  return (
    <TimePickerPopup top='110%' show={show} onClose={onClose}>
      <Flex>
        {inputFormat.map(
          ({ type, text, min, max, step }: IInputFormat, index: number) => (
            <Fragment key={index}>
              {type === 'input' && (
                <InputStep
                  valueDataTestId={`${dataTestId}-stepUpHoursValue`}
                  upDataTestId={`${dataTestId}-stepUpHoursButton`}
                  downDataTestId={`${dataTestId}-stepDownHoursButton`}
                  value={
                    Math.round(
                      Math.min(
                        Math.max(values[text!].value || min, min),
                        max || 100
                      ) / step
                    ) * step
                  }
                  min={min}
                  max={max || 100}
                  step={step}
                  onChange={(value: number) => onChange(text!, value)}
                />
              )}
              {type === 'separator' && <TimeSplitter>{text}</TimeSplitter>}
            </Fragment>
          )
        )}
      </Flex>
    </TimePickerPopup>
  );
};

export default TimePicker;
