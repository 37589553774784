import IOption from 'interfaces/IOption';
import { FC, PropsWithChildren } from 'react';
import IProps from 'interfaces/IProps';
import useOptionList, { IUseOptionListProps } from 'hooks/use-optionList';
import { icons } from 'icons';
import Checkbox from './Checkbox';
import { Flex } from 'components/Templates';

export interface ICheckboxListProps
  extends PropsWithChildren<IProps>,
    IUseOptionListProps {
  checkboxClassName?: string;
  name: string;
  icon?: keyof typeof icons;
  options: IOption[];
  multi?: boolean;
}

const CheckboxList: FC<ICheckboxListProps> = (
  props: ICheckboxListProps
): JSX.Element => {
  const {
    dataTestId,
    className,
    style,
    checkboxClassName,
    name,
    options,
    multi
  } = props;
  const { value, optionsChangeHandler } = useOptionList(props);

  return (
    <Flex
      data-testid={dataTestId}
      className={className}
      style={style}
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      {options.map((option: IOption, index: number) => (
        <Checkbox
          dataTestId={`${dataTestId}-${index}`}
          className={checkboxClassName}
          key={option.id}
          type={multi ? 'checkbox' : 'radio'}
          identifier={name}
          option={option}
          checked={value.includes(option.id)}
          onCheck={() => optionsChangeHandler(true, option)}
          onUncheck={() => optionsChangeHandler(false, option)}
        />
      ))}
    </Flex>
  );
};

CheckboxList.displayName = 'CheckboxList';

export default CheckboxList;
