import { FC, PropsWithChildren } from 'react';
import MediaQuery from 'react-responsive';
import styles from 'styles';

const MediaScreenTablet: FC = (props: PropsWithChildren<{}>): JSX.Element => {
  const { children } = props;

  return (
    <MediaQuery minWidth={styles.smartphone} maxWidth={styles.tablet}>
      {children}
    </MediaQuery>
  );
};

export default MediaScreenTablet;
