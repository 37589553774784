import { SVGProps } from 'react';

const SvgArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M26.352 10.072c-2.956 4.044-6.935 7.155-10.647 10.468A120.78 120.78 0 0 1 5.53 9.989c-.641-.724-1.699.34-1.06 1.06A115.38 115.38 0 0 0 15.165 22.08a.77.77 0 0 0 1.06 0c3.969-3.594 8.237-6.894 11.422-11.251.57-.78-.731-1.529-1.296-.757Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgArrowDown;
