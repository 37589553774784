import { SVGProps } from 'react';

const SvgHealth = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M25.734 6.775a6.786 6.786 0 0 0-6.73-.71 7.522 7.522 0 0 0-1.595.847c-.279.2-.543.42-.817.628-.2.153-.604.564-.869.583-.313.022-.712-.522-.925-.711a7.505 7.505 0 0 0-1.63-1.081 6.494 6.494 0 0 0-6.858.297 6.253 6.253 0 0 0-1.911 6.263c.598 2.936 2.798 5.283 4.635 7.526l3.254 3.976c.892 1.089 1.877 2.845 3.453 1.738 3.244-2.379 6.829-4.503 9.39-7.67a11.172 11.172 0 0 0 2.526-5.296c.343-2.286.005-4.887-1.923-6.39Zm.473 6.013a9.477 9.477 0 0 1-2.037 4.487c-2.465 3.15-6.005 5.228-9.185 7.56-.277.194-.253.173-.473-.083-.213-.247-.414-.505-.62-.757l-1.318-1.61-2.557-3.124c-1.494-1.824-3.212-3.681-3.958-5.966-.588-1.801-.477-4.135 1.089-5.428 1.74-1.437 4.244-.93 6 .174.769.484 1.354 1.395 2.294 1.556 1.06.182 1.946-.827 2.725-1.39a5.832 5.832 0 0 1 6.003-.669c1.931 1.029 2.311 3.231 2.037 5.25Z' />
      <path d='M19.767 8.49a.75.75 0 0 0 .4 1.447 3.532 3.532 0 0 1 .423-.094l.099-.01q.1-.006.2-.007c.134-.002.267.007.4.017l.02.002a2.947 2.947 0 0 1 .275.055c.142.035.278.08.416.127a.338.338 0 0 0 .038.012c.01.005.007.005.03.015.06.029.12.058.18.089a3.499 3.499 0 0 1 .37.227l.049.035.037.03.15.135a3.583 3.583 0 0 1 .247.268l.068.088q.066.096.126.197a3.838 3.838 0 0 1 .18.347l.044.107q.034.095.062.19c.042.14.064.283.1.424l.002.018.009.081q.009.1.012.2c.004.134-.004.267-.011.4a.285.285 0 0 0 0 .04.236.236 0 0 0-.006.025 5.456 5.456 0 0 1-.054.262.75.75 0 0 0 1.446.399 4.247 4.247 0 0 0-5.312-5.126Z' />
    </g>
  </svg>
);

export default SvgHealth;
