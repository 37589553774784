import { FocusEvent, useContext } from 'react';
import {
  getUserCountry,
  IUserCountry
} from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import useClickOutside from 'hooks/use-clickOutside';
import {
  AsYouType,
  CountryCode,
  formatIncompletePhoneNumber,
  getCountryCallingCode,
  validatePhoneNumberLength
} from 'libphonenumber-js';
import { ChangeEvent, useEffect, useState } from 'react';
import useInput, { IUseInput } from '../Input.logic';
import { IPhoneNumberInputProps } from './PhoneNumberInput';
import { ActionTypes, FormContext } from 'store/form-context';
import { InputTypes } from 'utils/form';

export interface IUsePhoneNumberInputProps
  extends Omit<IUseInput, 'value' | 'showPopup' | 'changeHandler'> {
  showList: boolean;
  selectedCountryCode?: CountryCode;
  countryCallingCode?: string;
  formattedValue?: string;
  toggleShowListHandler: () => void;
  selectCountryHandler: (countryCode: string) => void;
}

const usePhoneNumberInput = (
  props: IPhoneNumberInputProps
): IUsePhoneNumberInputProps => {
  const { name } = props;
  const { dispatchInputs } = useContext(FormContext);
  const {
    input,
    value,
    isFocused,
    containerRef,
    changeHandler,
    focusHandler,
    blurHandler: inputBlurHandler
  } = useInput(props);
  const [showList, setShowList] = useState<boolean>(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState<CountryCode>();

  useClickOutside(containerRef, setShowList);

  if (input?.type !== InputTypes.PHONE)
    throw new Error('Phone Number Input requires the type to be PHONE.');

  useEffect(() => {
    getUserCountry().then((country: IUserCountry) => {
      setSelectedCountryCode(country.countryCode as CountryCode);
    });
  }, []);

  useEffect(() => {
    dispatchInputs({
      type: ActionTypes.UPDATE_VALUE,
      attribute: name,
      value: ''
    });
  }, [selectedCountryCode]);

  const toggleShowListHandler = (): void => {
    setShowList((prevState: boolean) => !prevState);
  };

  const selectCountryHandler = (countryCode: string): void => {
    setSelectedCountryCode(countryCode as CountryCode);
    setShowList(false);
  };

  const inputChangeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const value = event.target.value.replace(/\D/g, '');

    if (value.trim().length === 0) {
      changeHandler(value);
      return;
    }

    const asYouType = new AsYouType(selectedCountryCode);
    asYouType.input(value);

    const lengthValidation = validatePhoneNumberLength(
      event!.target.value,
      selectedCountryCode
    );

    switch (lengthValidation) {
      case 'TOO_SHORT':
      case undefined:
        changeHandler(value);
        break;
    }
  };

  const blurHandler = (
    event?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const asYouType = new AsYouType(selectedCountryCode);
    asYouType.input(event!.target.value);

    if (event!.target.value.trim().length > 0) {
      if (input.autoVerification && !asYouType.isValid()) {
        dispatchInputs({
          type: ActionTypes.SET_ERROR,
          attribute: name,
          value: 'Nº inválido'
        });
      } else {
        const number = asYouType.getNumber()?.number;
        if (number) changeHandler(number);
      }
    }

    inputBlurHandler(event);
  };

  const getFormattedValue = (): string | undefined => {
    if (!value) return undefined;

    const asYouType = new AsYouType(selectedCountryCode);
    asYouType.input(value as string);

    const number = asYouType.getNumber()?.nationalNumber;

    return formatIncompletePhoneNumber(`${number}`, selectedCountryCode);
  };

  return {
    input,
    showList,
    selectedCountryCode,
    countryCallingCode: selectedCountryCode
      ? getCountryCallingCode(selectedCountryCode)
      : undefined,
    formattedValue: getFormattedValue(),
    isFocused,
    containerRef,
    toggleShowListHandler,
    selectCountryHandler,
    inputChangeHandler,
    focusHandler,
    blurHandler
  };
};

export default usePhoneNumberInput;
