import { SVGProps } from 'react';

const SvgDone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M27.88 9.678a7.016 7.016 0 0 0-1.26-1.507 19.068 19.068 0 0 0-1.533-1.34.768.768 0 0 0-1.06 0L17.7 12.78l-3.006 2.828c-.512.482-1.017.974-1.543 1.441-.209.186-.422.366-.63.553-.102.093-.203.19-.302.287a1.206 1.206 0 0 0-.104-.122c-.181-.177-.376-.341-.56-.514a40.557 40.557 0 0 0-1.103-.999c-.432-.374-.862-.75-1.261-1.16a7.334 7.334 0 0 0-.953-1.037c-.94-.642-2.048.686-2.681 1.236-.663.575-1.803 1.3-1.506 2.32a2.015 2.015 0 0 0 .71.93c.355.3.675.63 1.005.957.829.825 1.654 1.655 2.496 2.466.84.808 1.73 1.563 2.61 2.327.631.547 1.279 1.344 2.074.93a.759.759 0 0 0 .275-.177c4.178-4.142 8.312-8.333 12.539-12.425a64.83 64.83 0 0 1 1.665-1.563c.43-.388.743-.78.454-1.38Zm-2.309 1.054q-.702.657-1.386 1.333l-.8.792-2.551 2.53-6.214 6.16-2.162 2.144-.172-.152q-.351-.308-.705-.612c-.412-.356-.824-.711-1.23-1.074-.868-.774-1.69-1.596-2.516-2.414a18.253 18.253 0 0 0-2.243-2.157l.148-.138a4.17 4.17 0 0 0 .7-.635q.356-.312.72-.614c.13-.108.288-.271.458-.392.235.25.46.6.666.805.354.352.73.68 1.108 1.007a18.137 18.137 0 0 1 1.927 1.788 1.06 1.06 0 0 0 .931.517c.467-.037.719-.367 1.027-.67.712-.702 1.486-1.347 2.214-2.032l9.106-8.564a7.19 7.19 0 0 1 .817.72c.195.209.386.42.577.633.082.092.22.212.34.339-.056.042-.108.082-.142.114q-.31.285-.618.572Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgDone;
