import { InputsReducerType } from 'store/form-context';
import {
  dateTimeRegex,
  ILanguage
} from '@AlticeLabsProjects/smartal-b2c-frontend-utils';

export type InputValue = string | number | Date | string[] | File;

export enum InputTypes {
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
  SEARCH = 'search',
  DATE = 'date',
  TIME = 'time',
  NUMBER = 'number',
  PHONE = 'phone',
  PHOTO = 'photo',
  TAGS = 'tags'
}

export interface IFormInput {
  value: InputValue;
  type: InputTypes;
  isRequired: boolean;
  autoVerification: boolean;
  success?: string;
  error?: string;
}

export interface IInitFormInput {
  name: string;
  value?: InputValue;
  type: InputTypes;
  isRequired?: boolean;
  autoVerification?: boolean;
  success?: string;
  error?: string;
}

export const getDefaultInputValue = (
  defaultValue: InputValue | undefined,
  type: InputTypes
): InputValue => {
  if (defaultValue) return defaultValue;

  switch (type) {
    case InputTypes.DATE:
    case InputTypes.TIME:
      return new Date();
    case InputTypes.TAGS:
      return [];
    default:
      return '';
  }
};

export const initFormValues = (
  ...initFormValues: IInitFormInput[]
): InputsReducerType => {
  let formValues = {};

  initFormValues.forEach((formValue: IInitFormInput) => {
    const formValueType = formValue.type || InputTypes.TEXT;

    formValues = {
      ...formValues,
      [formValue.name]: {
        value: getDefaultInputValue(formValue.value, formValueType),
        type: formValueType,
        isRequired:
          formValue.isRequired !== undefined ? formValue.isRequired : true,
        autoVerification:
          formValue.autoVerification || formValue.type === InputTypes.PHONE
            ? true
            : false,
        error: formValue.error || undefined
      }
    };
  });

  return formValues as InputsReducerType;
};

export interface IInputFormat {
  type: 'input' | 'separator';
  text: string;
  min: number;
  max?: number;
  step: number;
  nrDigits: number;
}

const getDateTimeInputsInfo = (
  text: string
): Omit<IInputFormat, 'type' | 'text' | 'nrDigits'> => {
  if (text.includes('d')) return { min: 1, max: 31, step: 1 };
  if (text.includes('m')) return { min: 1, max: 12, step: 1 };
  if (text.includes('y')) return { min: 1970, step: 1 };
  if (text.includes('h')) return { min: 1, max: 12, step: 1 };
  if (text.includes('H')) return { min: 1, max: 24, step: 1 };
  if (text.includes('M')) return { min: 0, max: 55, step: 5 };
  return { min: 1, step: 1 };
};

export const dateTimeFormatToInput = (format: string): IInputFormat[] => {
  // TODO: cases with values to not be replaced inside '' (e.g. 'h')

  const inputFormat: IInputFormat[] = [];
  const inputs: IInputFormat[] = [];

  format.match(dateTimeRegex)?.forEach((text: string) => {
    inputs.push({
      type: 'input',
      text,
      ...getDateTimeInputsInfo(text),
      nrDigits: text.length
    });
  });

  const separators = format
    .split(dateTimeRegex)
    .map(
      (text: string | undefined) =>
        ({ type: 'separator', text: text?.trim() } as IInputFormat)
    )
    .filter((separator: IInputFormat) => separator.text !== undefined);

  for (let i = 0; i < Math.max(inputs.length, separators.length); i++) {
    if (separators[i] && separators[i].text !== '')
      inputFormat.push(separators[i]);

    if (inputs[i]) inputFormat.push(inputs[i]);
  }

  return inputFormat;
};

export const updateDateValue = (
  text: string,
  value: number,
  date: Date
): void => {
  if (text.includes('d')) date.setDate(value);
  if (text.includes('m')) date.setMonth(value - 1);
  if (text.includes('y')) date.setFullYear(value);
  if (text.includes('h')) date.setHours(value); // TODO: check am/pm
  if (text.includes('H')) date.setHours(value);
  if (text.includes('M')) date.setMinutes(value);
};

export interface IVerification {
  success?: string;
  error?: string;
}

const regex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const verifyEmail = (
  email: string,
  language: ILanguage
): IVerification => {
  const isValid = regex.test(String(email).toLowerCase());
  return { error: isValid ? undefined : language.invalidEmailAddress };
};

export const verifyPassword = (
  password: string,
  language: ILanguage
): IVerification => {
  const passwordIsValid = password.length >= 8;
  return {
    success: passwordIsValid ? language.strongPassword : undefined,
    error: passwordIsValid ? undefined : language.passwordDoesntMeetRequirements
  };
};

export const verifyFormInput = (
  input: IFormInput,
  language: ILanguage
): IVerification => {
  switch (input.type) {
    case InputTypes.EMAIL:
      return verifyEmail(input.value as string, language);
    case InputTypes.PASSWORD:
      return verifyPassword(input.value as string, language);
    default:
      return {};
  }
};

export const getInputValue = <T>(
  input: IFormInput,
  map?: (value: InputValue) => T
): T | undefined => {
  if (map) return input?.value ? map(input?.value) : undefined;
  else {
    return input?.value !== '' && input?.value !== []
      ? (input?.value as unknown as T | undefined)
      : undefined;
  }
};
