import { SVGProps } from 'react';

const SvgNotifications = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='m27.656 22.37-1.954-.01c.037-1.77.198-3.53.142-5.307a23.154 23.154 0 0 0-.703-5.29 9.897 9.897 0 0 0-5.071-6.678 10.701 10.701 0 0 0-8.063-.366 8.557 8.557 0 0 0-5.282 6.188 22.352 22.352 0 0 0-.599 5.846c.012 1.836.073 3.68.162 5.516l-1.944-.009a.75.75 0 0 0 0 1.5l6.617.031c.362 2.403 2.915 4.055 5.25 4.089a4.641 4.641 0 0 0 4.692-4.042l6.753.032a.75.75 0 0 0 0-1.5ZM7.645 17.843a28.986 28.986 0 0 1 .183-4.83c.378-2.645 1.38-5.24 3.866-6.53a9.104 9.104 0 0 1 7.115-.313 8.053 8.053 0 0 1 4.732 5.457 20.386 20.386 0 0 1 .8 5.314c.063 1.814-.101 3.606-.139 5.413l-16.413-.078a215.67 215.67 0 0 1-.144-4.433Zm8.565 8.536a3.951 3.951 0 0 1-3.71-2.58l6.904.032a3.146 3.146 0 0 1-3.194 2.548Z' />
      <path d='M19.125 9.065a.767.767 0 0 0 0 1.06 7.977 7.977 0 0 1 1.669 5.397.75.75 0 0 0 1.5 0 9.53 9.53 0 0 0-2.108-6.457.755.755 0 0 0-1.06 0Z' />
    </g>
  </svg>
);

export default SvgNotifications;
