import { IProps } from 'interfaces';
import { FC, PropsWithChildren } from 'react';
import { StepProgressCircle, StepProgressWrapper } from './StepProgress.styles';

export interface IStepProgressProps extends PropsWithChildren<IProps> {
  step: number;
  nrSteps: number;
}

const StepProgress: FC<IStepProgressProps> = (
  props: IStepProgressProps
): JSX.Element => {
  const { dataTestId, className, style, step, nrSteps } = props;

  return (
    <StepProgressWrapper
      data-testid={dataTestId}
      className={className}
      style={style}
      childrenMargin='0.75rem'
    >
      {new Array(nrSteps).fill(0).map((_: number, index: number) => (
        <StepProgressCircle
          key={index}
          data-testid={dataTestId && `${dataTestId}-${index}`}
          viewBox='0 0 100 100'
          xmlns='http://www.w3.org/2000/svg'
          isSelected={index === step}
          hasNext={index > step}
        >
          <circle cx='50' cy='50' r='50' />
        </StepProgressCircle>
      ))}
    </StepProgressWrapper>
  );
};

export default StepProgress;
