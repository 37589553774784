import { SVGProps } from 'react';

const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M18.937 26.379c-1.536-.053-3.065.081-4.599.137-1.552.056-3.11.005-4.662-.042q-1.108-.033-2.215-.083a3.326 3.326 0 0 1-.957-.058c-.145-.06-.112-.013-.188-.136-.089-.146-.022-.585-.028-.745q-.088-2.36-.143-4.722c-.075-3.11-.115-6.22-.143-9.331a31.958 31.958 0 0 1 .023-4.288c.065-.508.232-1.45.827-1.565a12.09 12.09 0 0 1 2.258.001q5.34.042 10.678.266c.965.04.963-1.46 0-1.5q-5.336-.221-10.678-.266C7.621 4.035 5.924 3.7 5.07 5.211c-.733 1.295-.596 2.89-.584 4.32.028 3.568.066 7.135.147 10.702q.06 2.61.155 5.22a2.534 2.534 0 0 0 .505 1.94 2.954 2.954 0 0 0 1.922.487c1.968.092 3.939.154 5.908.158 1.94.003 3.874-.225 5.814-.159.966.033.964-1.467 0-1.5Z' />
      <path d='M9.294 16.914h.001a.23.23 0 0 0 .025.003l.046.001c.02-.003.005-.008-.072-.004Z' />
      <path d='M9.414 13.86a1.908 1.908 0 0 0-1.412.13 1.784 1.784 0 0 0-.805 1.03 2.93 2.93 0 0 0-.077 1.379 2.515 2.515 0 0 0 .52 1.2 2.313 2.313 0 0 0 2.849.548 2.21 2.21 0 0 0 .97-1.224 1.981 1.981 0 0 0-.129-1.489 2.837 2.837 0 0 0-.726-.903 3.205 3.205 0 0 0-1.19-.671Zm-.488 1.425c-.144-.003-.083-.018-.016-.004Zm-.151-.016h-.002Zm-.109 1.15c-.009-.027-.01-.033 0 0Zm1.343.11-.01.033c-.019.058-.028.03.01-.032Zm.026-.083c-.007.028-.016.054-.025.08-.035.053-.065.115-.096.168-.025.026-.049.054-.075.079a.662.662 0 0 1-.047.04.878.878 0 0 1-.122.07l-.022.008c-.04.012-.08.02-.12.03l-.012.002a1.177 1.177 0 0 1-.15-.005.188.188 0 0 1-.046-.001.521.521 0 0 0-.057-.002h.031a1.615 1.615 0 0 1-.163-.046l-.022-.008-.09-.045a1.625 1.625 0 0 1-.092-.055l-.028-.02a2.375 2.375 0 0 1-.15-.155 1.008 1.008 0 0 1-.037-.059.55.55 0 0 1-.03-.066 2.843 2.843 0 0 1-.093-.325l-.005-.033c-.006-.06-.011-.12-.013-.181q-.002-.087.003-.174l.005-.083c.01-.057.024-.113.04-.169a.797.797 0 0 1 .051-.118 6.827 6.827 0 0 1 0-.001c.006-.01.004-.006.002-.002l.018-.034a.384.384 0 0 1 .001-.001l.01-.02-.043.028.045-.03v-.001l.007-.01c-.014.004-.02.007-.037.011a.337.337 0 0 0 .04-.013l.026-.038-.024.037.015-.006a.263.263 0 0 1 .025-.02l.008.002-.019.014h.001a.238.238 0 0 1 .03-.013h.015l-.005.003.009-.003.133.003.089.022c.048.014.143.06.152.062a2.855 2.855 0 0 1 .312.175l.043.031c.056.048.111.095.164.146q.068.066.131.137c.022.023.042.048.062.072l.002.003c.037.051.07.104.101.158a.76.76 0 0 1 .041.094l.032.103v.002c0 .025-.003.049-.004.068.004-.011.004-.016.01-.03a.34.34 0 0 0-.014.065.175.175 0 0 1-.008.037Z' />
      <path d='m10.05 16.368.001-.01c-.027.078-.022.095-.012.072l.008-.037a.168.168 0 0 0 .003-.025ZM8.69 15.34a.341.341 0 0 1 .024-.027l-.01.006ZM8.67 15.377v.001c.002-.004 0 0 0-.002ZM8.727 15.303l-.01.007-.003.003.018-.012a.465.465 0 0 0 .052-.017l-.04.01-.002.001h.001l-.007.001-.007.003-.007.005ZM8.714 15.304l-.002.001-.003.003.003-.001.002-.003zM8.719 15.307l.003-.003-.01.003-.006.01.01-.007.003-.003zM8.69 15.34v.001l.001-.001H8.69zM8.706 15.317l-.004.002-.001.002.004-.003.001-.001zM27.507 16.035a1.085 1.085 0 0 0-.04-.379 6.775 6.775 0 0 0-1.38-1.93 35.969 35.969 0 0 0-3.554-3.784c-.708-.655-1.772.404-1.06 1.06.857.793 1.678 1.621 2.455 2.492q.565.633 1.099 1.293.241.298.474.602-5.276.094-10.547-.234c-.964-.062-.96 1.438 0 1.5q5.022.32 10.057.244c-1.178 1.296-2.37 2.58-3.636 3.79a.75.75 0 0 0 1.06 1.06c1.708-1.63 3.289-3.384 4.867-5.139a.808.808 0 0 0 .205-.575Z' />
    </g>
  </svg>
);

export default SvgLogout;
