import { SVGProps } from 'react';

const SvgPrice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    overflow='visible'
    preserveAspectRatio='none'
    viewBox='0 0 53 53'
    {...props}
  >
    <path
      className='price_svg__st0'
      d='M28 32.8c-1.3 0-2.5-.4-3.4-1.4-.5-.5-.9-1.3-1.1-2.2H30v-2.1h-6.9v-.9H30v-2h-6.5c.2-1.1.7-2.1 1.3-2.7.8-.8 1.9-1.3 3.2-1.3 1.1 0 2.1.3 2.7.7l.5-1.6c-.7-.4-1.8-.8-3.2-.8-1.9 0-3.5.7-4.7 2-.9.9-1.6 2.2-1.9 3.7H19v2h2.1v1h-1.3v.1H19v2h2.3c.3 1.3.8 2.4 1.6 3.2 1.2 1.4 2.9 2.1 4.8 2.1 1.6 0 2.8-.5 3.6-1l-.4-1.6c-.6.3-1.7.8-2.9.8zM26.5 0C11.9 0 0 11.9 0 26.5S11.9 53 26.5 53 53 41.1 53 26.5 41.1 0 26.5 0zm0 51C13 51 2 40 2 26.5S13 2 26.5 2 51 13 51 26.5 40 51 26.5 51zm0-46.1c-11.9 0-21.6 9.7-21.6 21.6s9.7 21.6 21.6 21.6c11.9 0 21.6-9.7 21.6-21.6S38.4 4.9 26.5 4.9zm0 41.2c-10.8 0-19.6-8.8-19.6-19.6S15.7 6.9 26.5 6.9s19.6 8.8 19.6 19.6-8.8 19.6-19.6 19.6z'
      vectorEffect='non-scaling-stroke'
    />
  </svg>
);

export default SvgPrice;
