import useInput from 'components/Input/Input.logic';
import IOption from 'interfaces/IOption';

export type OptionListType = string | number | (string | number)[] | undefined;

export interface IUseOptionListProps {
  name: string;
  options: IOption[];
  multi?: boolean;
}

export interface UseMultiOption {
  value: string[];
  optionsChangeHandler: (checked: boolean, option: IOption) => void;
}

const useOptionList = (props: IUseOptionListProps): UseMultiOption => {
  const { multi } = props;
  const { value, changeHandler } = useInput(props);

  const optionsChangeHandler = (checked: boolean, option: IOption): void => {
    let newValue =
      typeof value != 'object' ? [`${value}`] : [...(value as string[])];

    if (checked) newValue = multi ? [...newValue, option.id] : [option.id];
    else {
      newValue = newValue.filter(
        (value: string | number) => value !== option.id
      );
    }

    console.log(newValue);

    changeHandler(multi ? newValue : newValue[0] || '');
  };

  return { value: value as string[], optionsChangeHandler };
};

export default useOptionList;
