import IOption from 'interfaces/IOption';
import IProps from 'interfaces/IProps';
import { FC, PropsWithChildren } from 'react';
import { TagButton, TagLabel, TagWrapper } from './Tag.styles';

export interface ITagProps extends PropsWithChildren<IProps> {
  tag: IOption;
  onRemove: (tagId: string) => void;
}

const Tag: FC<ITagProps> = (props: ITagProps): JSX.Element => {
  const { dataTestId, tag, onRemove } = props;

  return (
    <TagWrapper data-testid={dataTestId}>
      <TagLabel>{tag.name}</TagLabel>
      <TagButton
        icon='close'
        type='secondary'
        state='dark'
        onClick={() => onRemove(tag.id)}
      />
    </TagWrapper>
  );
};

export default Tag;
