import { DateMasks } from 'hooks/use-date';
import ILanguage, { LanguageCode } from '../interfaces/ILanguage';
import { getHoursUntilNow, getMinutesUntilNow } from './date';

const languages = [
  { code: LanguageCode.pt_PT, name: 'Português' },
  { code: LanguageCode.en_US, name: 'English (US)' },
  { code: LanguageCode.es_ES, name: 'Español' }
];

export const getLanguages = (): LanguageCode[] => {
  return Object.values(LanguageCode);
};

export const getLanguageName = (languageCode: LanguageCode): string => {
  return languages.find((language) => language.code === languageCode)!.name;
};

export const getLanguageFlag = (languageCode: LanguageCode): string => {
  return `/flags/${languageCode}.jpg`;
};

export const toCodeAlpha2 = (languageCode: LanguageCode): string => {
  return languageCode.split('_')[0];
};

export const placeValue = (
  text: string,
  ...values: (string | number)[]
): string => {
  if (!text) return '';

  values.forEach((value: string | number) => {
    text = text.replace('${}', value.toString());
  });

  return text;
};

export const getTimeAgo = (
  language: ILanguage,
  formatDate: (date: Date, mask: DateMasks) => string,
  date: Date
): string => {
  const minutesDifference = getMinutesUntilNow(date);
  if (minutesDifference === 1) return language['1MinuteAgo'];
  if (minutesDifference < 60)
    return placeValue(language.xMinutesAgo, minutesDifference);

  const hoursDifference = getHoursUntilNow(date);
  if (hoursDifference === 1) return language['1HourAgo'];
  if (hoursDifference < 24)
    return placeValue(language.xHoursAgo, hoursDifference);

  // TODO: add custom labels 'yesterday at' and current week days (eg.: 'Tuesday at')

  return formatDate(date, DateMasks.shortDateTime);
};
