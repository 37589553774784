import styled from 'styled-components';

export interface IDateTimeSeparatorProps {}

export const DateTimeSeparator = styled.span<IDateTimeSeparatorProps>`
  ::before {
    content: ' ';
    white-space: pre;
  }

  ::after {
    content: ' ';
    white-space: pre;
  }
`;
