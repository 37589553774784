import { Flex, IFlexProps } from 'components/Templates';
import styled, { css } from 'styled-components';
import styles from 'styles';
import { transition } from 'utils/styles';

const imageSide = '7.5rem';
const labelMarginTop = '1.75rem';

export interface IPhotoInputInputWrapperProps {}

export const PhotoInputInputWrapper = styled(Flex).attrs((_: IFlexProps) => ({
  direction: 'column'
}))<IPhotoInputInputWrapperProps>`
  position: relative;
  cursor: pointer;
  ${transition('opacity')}

  :hover {
    opacity: 0.7;
  }

  > input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;

    + span {
      :last-child {
        margin-top: calc(${labelMarginTop} + ${imageSide});
      }
    }
  }
`;

export interface IPhotoInputImageProps {}

export const PhotoInputImage = styled(Flex)<IPhotoInputImageProps>`
  overflow: hidden;
  width: ${imageSide};
  height: ${imageSide};
  border-radius: 999px;

  > img {
    min-width: 100%;
    min-height: 100%;
  }
`;

export interface IPhotoInputLabelProps {
  uploaded: boolean;
}

export const PhotoInputLabel = styled.span<IPhotoInputLabelProps>`
  margin-top: ${labelMarginTop};
  text-align: center;

  ${({ uploaded }: IPhotoInputLabelProps) =>
    uploaded
      ? css`
          color: ${styles.colors.brand.dark};
        `
      : css`
          color: ${styles.colors.brand.main};
          font-weight: 600;
        `}
`;
