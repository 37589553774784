import { SVGProps } from 'react';

const SvgLoudspeakerOff = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M14.538 8.875c.667-.51 1.293-1.115 2-1.564a1.384 1.384 0 0 1 .86-.313c.366.062.545.536.624.848a6.032 6.032 0 0 1-.024 2.158c-.11.934-.177 1.87-.21 2.81-.004.134.001.268-.002.402q.74.838 1.482 1.67c-.002-.374-.007-.748-.002-1.122a34.89 34.89 0 0 1 .18-3.33 9.896 9.896 0 0 0 .09-2.638 2.702 2.702 0 0 0-1.48-2.15 2.589 2.589 0 0 0-2.418.466c-.91.63-1.762 1.344-2.637 2.028q.557.528 1.097 1.075c.148-.115.296-.23.44-.34ZM17.935 23.41a1.843 1.843 0 0 1-.66 1.423c-.798.528-1.817-.49-2.462-.917a27.403 27.403 0 0 1-2.579-2.054 14.111 14.111 0 0 0-2.254-1.75 4.46 4.46 0 0 0-2.72-.317 2.373 2.373 0 0 1-1.156-.02c-.317-.17-.26-.697-.312-1.017-.074-.45-.015-.941-.043-1.398-.028-.473-.08-.945-.111-1.418-.068-1.055.004-2.096-.006-3.149-.004-.38-.14-.653.227-.898a1.866 1.866 0 0 1 1.086-.2c.382.011.754.052 1.123.086-.505-.462-1.005-.928-1.538-1.366l-.23-.189a2.43 2.43 0 0 0-2.102 1.42 4.892 4.892 0 0 0-.084 1.515 25.99 25.99 0 0 0-.012 2.063c.022.636.085 1.267.131 1.901.05.677.002 1.36.112 2.03.153.938.343 1.754 1.36 2.065a9.016 9.016 0 0 0 2.734-.022c.949-.004 1.787.928 2.464 1.496.857.72 1.687 1.479 2.595 2.135.426.308.873.584 1.315.869a4.187 4.187 0 0 0 1.47.76 2.536 2.536 0 0 0 2.412-.921 3.374 3.374 0 0 0 .684-1.604l-1.422-1.566a8.061 8.061 0 0 1-.022 1.044ZM22.031 17.94l1.093 1.204a7.282 7.282 0 0 0-.595-7.433.773.773 0 0 0-1.026-.269.755.755 0 0 0-.269 1.026 5.86 5.86 0 0 1 .797 5.472ZM26.408 15.82a10.862 10.862 0 0 1-1.679 5.092l1.051 1.157a12.588 12.588 0 0 0 2.133-6.44 8.41 8.41 0 0 0-2.609-6.393c-.715-.645-1.779.412-1.06 1.061a6.98 6.98 0 0 1 2.164 5.523ZM18.435 17.321c-2.799-3.082-5.474-6.388-8.67-9.073A48.4 48.4 0 0 1 4.91 3.94C4.258 3.224 3.2 4.287 3.849 5A39.746 39.746 0 0 0 8.12 8.824a41.785 41.785 0 0 1 4.284 4.085c2.953 3.227 5.881 6.477 8.822 9.716l5.024 5.534c.65.716 1.708-.347 1.06-1.06Z' />
    </g>
  </svg>
);

export default SvgLoudspeakerOff;
