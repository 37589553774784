import { FC, PropsWithChildren } from 'react';
import ReactCountryFlag, { ReactCountryFlagProps } from 'react-country-flag';

export interface ICountryFlagProps
  extends PropsWithChildren<Omit<ReactCountryFlagProps, 'svg'>> {}

const CountryFlag: FC<ICountryFlagProps> = (
  props: ICountryFlagProps
): JSX.Element => {
  const {} = props;

  return (
    <ReactCountryFlag
      {...{ ...props, svg: true, style: { width: 'auto', height: 'auto' } }}
    />
  );
};

export default CountryFlag;
