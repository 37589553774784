import { Dispatch, SetStateAction, useEffect } from 'react';

export interface IUseClickOutside {}

const useClickOutside = (
  containerRef: React.RefObject<HTMLElement>,
  setState?: Dispatch<SetStateAction<boolean>>,
  onClickOutside?: () => void
): IUseClickOutside => {
  useEffect(() => {
    const outsideClickHandler = (event: MouseEvent): void => {
      const element = containerRef!.current?.parentElement;

      if (element && !element.contains(event.target as Node)) {
        if (setState) setState(false);
        else if (onClickOutside) onClickOutside();
      }
    };

    document.addEventListener('mousedown', outsideClickHandler);
    return () => document.removeEventListener('mousedown', outsideClickHandler);
  }, [containerRef, setState, onClickOutside]);

  return {};
};

export default useClickOutside;
