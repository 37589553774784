import styles from 'styles';

export const transition = (...properties: string[]): string => {
  return `transition: ${properties.map(
    (property: string) => `${property} ${styles.transition}ms ease-in-out`
  )};`;
};

export const verticalGradient = (color1: string, color2: string): string => {
  return `background: linear-gradient(0, ${color1} 0%, ${color2} 100%);`;
};
