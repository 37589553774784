import { SVGProps } from 'react';

const SvgSteps = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M26.126 13.014c-1.098-2.255-4.577-4.342-6.862-2.504a4.534 4.534 0 0 0-1.596 4.012c.199 1.757 1.025 3.379 1.008 5.167-.06 1.617-.813 3.193-.63 4.816a3.663 3.663 0 0 0 2.27 3.116 3.145 3.145 0 0 0 3.679-1.036c.973-1.27 1.03-2.952 1.32-4.466.58-3.028 2.292-6.062.81-9.105ZM23.479 23.96c-.212 1.038-.673 2.355-1.93 2.39a2.14 2.14 0 0 1-2.036-2.307 9.62 9.62 0 0 1 .355-2.094c.026-.123.043-.248.067-.371.055.004.11.007.164.013.035.006.162.022.158.022l.262.04q.565.087 1.126.2c.714.144 1.42.323 2.12.525-.09.528-.179 1.056-.286 1.582Zm.734-3.802c-.064.245-.116.492-.168.74a26.175 26.175 0 0 0-2.174-.525q-.605-.118-1.215-.206a11.47 11.47 0 0 0-.519-.066c.011-.137.033-.273.038-.411a13.127 13.127 0 0 0-.659-3.66 4.38 4.38 0 0 1-.05-3.395 2.03 2.03 0 0 1 2.228-1.26 4.325 4.325 0 0 1 2.8 1.814c1.52 2.212.312 4.678-.281 6.97ZM6.688 6.592a6.065 6.065 0 0 0-1.05 3.828 28.028 28.028 0 0 0 1.19 4.88c.574 2.45.61 7.117 4.264 6.84a3.547 3.547 0 0 0 3.171-2.861c.364-1.788-.493-3.511-.56-5.29-.015-1.626.68-3.089.938-4.67a4.732 4.732 0 0 0-.937-3.896c-1.913-2.467-5.49-.955-7.016 1.17Zm.861 1.48c.747-1.58 3.53-3.564 4.976-1.738a3.651 3.651 0 0 1 .484 3.451 16.427 16.427 0 0 0-.805 4.204c.005.139.029.274.042.41-.172.018-.345.04-.523.067q-.61.088-1.215.206c-.73.141-1.453.32-2.17.523-.529-2.309-1.897-4.783-.789-7.124Zm4.033 12.436c-2.173.87-2.704-1.86-2.996-3.824a26.222 26.222 0 0 1 2.148-.532q.561-.112 1.127-.2l.26-.04c-.004 0 .132-.017.16-.022.065-.007.13-.014.196-.018a20.094 20.094 0 0 1 .37 2.014 2.416 2.416 0 0 1-1.265 2.622Z' />
    </g>
  </svg>
);

export default SvgSteps;
