import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { DatePickerHeaderButton, Header } from './DatePickerHeader.styles';
import { FC } from 'react';
import { useDate } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';

const DatePickerHeader: FC<ReactDatePickerCustomHeaderProps> = (
  props: ReactDatePickerCustomHeaderProps
): JSX.Element => {
  const {
    date,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    decreaseMonth,
    increaseMonth
  } = props;
  const { formatDate, masks } = useDate();

  return (
    <Header justifyContent='space-between'>
      <DatePickerHeaderButton
        dataTestId='datePicker-previousButton'
        type='text'
        icon='arrowLeft'
        disabled={prevMonthButtonDisabled}
        onClick={decreaseMonth}
      />
      <h6>{formatDate(date, masks.monthYear)}</h6>
      <DatePickerHeaderButton
        dataTestId='datePicker-nextButton'
        type='text'
        icon='arrowRight'
        disabled={nextMonthButtonDisabled}
        onClick={increaseMonth}
      />
    </Header>
  );
};

export default DatePickerHeader;
