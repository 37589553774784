import { SVGProps } from 'react';

const SvgDetails = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M24.53 13.198a.75.75 0 0 0 0-1.5q-10.002-.248-20.007-.095a.75.75 0 0 0 0 1.5q10.003-.153 20.007.095ZM19.485 6.966a.75.75 0 0 0 0-1.5q-7.595-.17-15.192-.064a.75.75 0 0 0 0 1.5q7.596-.106 15.192.064ZM22.121 19.62a.75.75 0 0 0 0-1.5L5.038 18.09a.75.75 0 0 0 0 1.5ZM27.599 25.16l-11.358.051-5.723.026c-1.736.008-3.45.116-5.181.02-.965-.053-.962 1.447 0 1.5 1.732.096 3.446-.012 5.181-.02l5.723-.026 11.358-.051a.75.75 0 0 0 0-1.5Z' />
    </g>
  </svg>
);

export default SvgDetails;
