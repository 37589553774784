import { PropsWithChildren, FC } from 'react';
import { RightContentWrapper } from './RightContent.styles';

export interface IRightContentProps extends PropsWithChildren<{}> {}

const RightContent: FC<IRightContentProps> = (
  props: IRightContentProps
): JSX.Element => {
  const { children } = props;

  return <RightContentWrapper>{children}</RightContentWrapper>;
};

RightContent.displayName = 'RightContent';

export default RightContent;
