import Icon, { IIconProps } from 'components/Icon/Icon';
import LoadingIcon from 'components/Icon/LoadingIcon';
import { Flex } from 'components/Templates';
import styled, {
  css,
  FlattenSimpleInterpolation,
  ThemeProvider
} from 'styled-components';
import styles from 'styles';
import { rgba } from 'utils/colors';
import { transition } from 'utils/styles';
import { ButtonType, ButtonState } from './Button';

interface ButtonTheme {
  isFullButton: boolean;
  type?: ButtonType;
  state?: ButtonState;
  active?: boolean;
  disabled?: boolean;
  reverse?: boolean;
}

export interface IButtonThemeProviderProps {
  theme?: ButtonTheme;
}

export const ButtonThemeProvider = styled(
  ThemeProvider
)<IButtonThemeProviderProps>``;

const getStateColor = (state?: ButtonState): string => {
  switch (state) {
    case 'green':
      return styles.colors.feedback.success;
    case 'red':
      return styles.colors.feedback.alert;
    case 'dark':
      return styles.colors.brand.dark;
    case 'main':
    default:
      return styles.colors.brand.main;
  }
};

const primaryStyles = ({ state }: ButtonTheme): FlattenSimpleInterpolation => {
  const color = getStateColor(state);

  return css`
    background-color: ${color};

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: ${rgba(color, 0.9)};
      outline: none;
    }

    &:active {
      filter: brightness(75%);
    }
  `;
};

const secondaryStyles = ({
  active,
  state
}: ButtonTheme): FlattenSimpleInterpolation => {
  const color = getStateColor(state);

  return css`
    background-color: ${active ? rgba(color, 0.6) : rgba(color, 0.1)};

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: ${active ? rgba(color, 0.5) : rgba(color, 0.2)};
      outline: none;
    }

    &:active {
      background-color: ${rgba(color, 0.35)};
    }
  `;
};

const textStyles = ({
  isFullButton,
  active
}: ButtonTheme): FlattenSimpleInterpolation => {
  const color = styles.colors.brand.main;

  return css`
    background-color: ${active && !isFullButton
      ? rgba(color, 0.6)
      : 'transparent'};

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: ${active && !isFullButton
        ? rgba(color, 0.5)
        : rgba(color, 0.1)};
      outline: none;
    }

    &:active {
      background-color: ${rgba(color, 0.1)};
    }
  `;
};

export interface IWrapperProps {
  theme: ButtonTheme;
  to?: string;
  loading?: boolean;
}

export const Wrapper = styled.button<IWrapperProps>`
  position: relative;
  display: block;
  border-radius: 999px;
  box-sizing: border-box;
  font-size: ${styles.textSize.normal};
  font-weight: 600;
  opacity: ${({ theme: { disabled } }: IWrapperProps) => (disabled ? 0.4 : 1)};
  cursor: pointer;
  pointer-events: ${({ theme: { disabled }, loading }: IWrapperProps) =>
    disabled || loading ? 'none' : 'auto'};
  ${transition('background-color', 'filter')}

  ${({ theme: { isFullButton, type } }: IWrapperProps) =>
    isFullButton
      ? css`
          min-width: ${type === 'text' ? 'auto' : '12.5rem'};
          width: auto !important;
          padding: 0.5rem 1rem;
        `
      : css`
          width: 2.5rem !important;
          padding: 0.5rem;
        `};

  ${({ theme }: IWrapperProps) => {
    switch (theme.type) {
      case 'primary':
        return primaryStyles(theme);
      case 'secondary':
        return secondaryStyles(theme);
      case 'text':
        return textStyles(theme);
    }
  }};
`;

export interface IButtonContentProps {
  theme: ButtonTheme;
}

export const ButtonContent = styled(Flex)<IButtonContentProps>`
  font-size: inherit;
  font-weight: inherit;

  ${({ theme: { reverse } }: IButtonContentProps) =>
    reverse
      ? css`
          flex-direction: row-reverse;
        `
      : css``}

  &.loading-enter {
    opacity: 0;
    transform: translateY(50%);
  }

  &.loading-enter-active,
  &.loading-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.loading-exit-active {
    opacity: 0;
    transform: translateY(-50%);
  }

  &.loading-enter-active,
  &.loading-exit-active {
    ${transition('opacity', 'transform')}
  }

  > * {
    ${({ theme: { reverse } }: IButtonContentProps) =>
      reverse
        ? css`
            &:not(:first-child) {
              margin-right: 0.25rem;
            }
          `
        : css`
            &:not(:last-child) {
              margin-right: 0.25rem;
            }
          `}
  }
`;

const buttonIconStyles = ({
  theme: { isFullButton, type, state, active }
}: IIconProps & IButtonIconProps): Omit<IIconProps, 'src'> => {
  let fill;

  switch (type) {
    case 'primary':
      fill = styles.colors.brand.lightest;
      break;
    case 'secondary':
      fill = active ? styles.colors.brand.lightest : getStateColor(state);
      break;
    case 'text':
      fill =
        active && !isFullButton
          ? styles.colors.brand.lightest
          : getStateColor(state);
      break;
    default:
      fill = undefined;
  }

  return { size: styles.iconSize.text, fill };
};

export interface IButtonLoadingIconProps {
  theme: ButtonTheme;
}

export const ButtonLoadingIcon = styled(LoadingIcon).attrs(
  buttonIconStyles
)<IButtonIconProps>``;

export interface IButtonIconProps {
  theme: ButtonTheme;
}

export const ButtonIcon = styled(Icon).attrs(
  buttonIconStyles
)<IButtonIconProps>``;

export interface IButtonLabelProps {
  theme: ButtonTheme;
}

export const ButtonLabel = styled.span<IButtonLabelProps>`
  font-family: 'Open Sans';
  font-weight: inherit;
  font-size: inherit;
  white-space: nowrap;
  ${transition('color')}

  color: ${({ theme: { type, state, active } }: IButtonLabelProps) => {
    switch (type) {
      case 'primary':
        return styles.colors.brand.lightest;
      case 'secondary':
        return active ? styles.colors.brand.lightest : getStateColor(state);
      case 'text':
        switch (state) {
          case 'red':
            return styles.colors.feedback.alert;
          case 'dark':
            return styles.colors.brand.dark;
          case 'main':
          default:
            return styles.colors.brand.main;
        }
    }
  }};
`;
