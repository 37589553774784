import { useDate } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { useEffect } from 'react';
import { dateTimeFormatToInput, IInputFormat, InputTypes } from 'utils/form';
import useDateTimeInputs, { IUseDateTimeInputs } from '../DateTimeInputs.logic';
import { IDateInputProps } from './DateInput';

export interface IUseDateInput
  extends Omit<IUseDateTimeInputs, 'dispatchValues' | 'changeHandler'> {
  inputFormat: IInputFormat[];
  datePickerChangeHandler: (date: Date) => void;
}

const useDateInput = (props: IDateInputProps): IUseDateInput => {
  const { min, max } = props;
  const { masks } = useDate();
  const inputFormat = dateTimeFormatToInput(masks.inputDate);
  const {
    input,
    value,
    isFocused,
    showPopup,
    containerRef,
    values,
    containerClickHandler,
    inputClickHandler,
    inputChangeHandler,
    changeHandler,
    focusHandler,
    blurHandler
  } = useDateTimeInputs(props, inputFormat);
  const valueTime = (value as Date).getTime();

  if (input?.type !== InputTypes.DATE)
    throw new Error('Date Input requires the type to be DATE.');

  useEffect(() => {
    console.log(value);
    if (min && valueTime < min.getTime()) changeHandler(min);
    else if (max && valueTime > max.getTime()) changeHandler(max);
  }, [valueTime]);

  const datePickerChangeHandler = (date: Date): void => {
    changeHandler(date);
  };

  return {
    inputFormat,
    input,
    value,
    values,
    isFocused,
    showPopup,
    containerRef,
    containerClickHandler,
    inputClickHandler,
    inputChangeHandler,
    focusHandler,
    blurHandler,
    datePickerChangeHandler
  };
};

export default useDateInput;
