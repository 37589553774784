import { SVGProps } from 'react';

const SvgAddMeasure = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M27.3 16.727a7.4 7.4 0 0 0-.505-.87c1.446-3.154 1.593-7.765-1.879-9.614a6.8 6.8 0 0 0-4.98-.5 7.998 7.998 0 0 0-2.706 1.293c-.362.272-.706.566-1.081.819-.413.279-.482.335-.859.02a11.128 11.128 0 0 0-2.038-1.496 7.78 7.78 0 0 0-2.698-.847 5.742 5.742 0 0 0-4.807 1.585c-2.655 2.8-1.427 7.003.514 9.794 1.359 1.953 2.961 3.749 4.467 5.589l2.402 2.934a2.721 2.721 0 0 0 1.163 1.022 1.47 1.47 0 0 0 1.172-.165 7.287 7.287 0 0 0 2.198 1.457 7.915 7.915 0 0 0 8.632-1.756A6.673 6.673 0 0 0 28 21.959a9.818 9.818 0 0 0-.7-5.232Zm-14.04 6.51-1.86-2.272c-1.139-1.391-2.322-2.757-3.4-4.197-1.682-2.247-3.426-5.716-1.414-8.335a4.211 4.211 0 0 1 3.872-1.413 6.128 6.128 0 0 1 2.13.703 11.725 11.725 0 0 1 1.83 1.367 1.872 1.872 0 0 0 1.727.467 7.533 7.533 0 0 0 2.01-1.35 6.234 6.234 0 0 1 4.446-1.177A4.06 4.06 0 0 1 26 9.688a7.639 7.639 0 0 1-.28 4.884 6.663 6.663 0 0 0-.825-.656.656.656 0 0 0 .172-.3 4.246 4.246 0 0 0-5.312-5.125.75.75 0 1 0 .399 1.446 3.53 3.53 0 0 1 .424-.094l.099-.01q.1-.006.2-.007c.134-.002.266.007.4.017l.02.002.079.014q.098.018.196.041c.142.035.278.08.416.127a.338.338 0 0 0 .038.012c.01.005.007.005.03.015.06.029.12.058.18.089a3.478 3.478 0 0 1 .37.227l.049.035.037.03c.05.046.1.089.149.135a3.583 3.583 0 0 1 .247.268c.02.025.058.073.068.088q.067.096.127.197a3.837 3.837 0 0 1 .18.347l.044.107q.034.095.062.19c.042.14.064.283.099.424l.004.019.008.08q.009.1.012.2c.004.134-.004.267-.011.4a.303.303 0 0 0 0 .04.236.236 0 0 0-.006.025 5.336 5.336 0 0 1-.055.262c-.002.012.001.025-.001.037-.026-.01-.05-.023-.077-.032a9.013 9.013 0 0 0-2.853-.265 8.666 8.666 0 0 0-3.132.438 7.41 7.41 0 0 0-4.62 7.22 8.565 8.565 0 0 0 .728 3.116Zm12.455 1.083a6.22 6.22 0 0 1-11.15-2.637 6 6 0 0 1 3.393-6.84 6.558 6.558 0 0 1 2.182-.384 15.931 15.931 0 0 1 2.347.045 4.609 4.609 0 0 1 2.961 2.032 6.639 6.639 0 0 1 1.12 3.892 6.86 6.86 0 0 1-.853 3.892Z' />
      <path d='M21.805 19.83c.007-1.235.005-2.47-.032-3.704-.029-.964-1.529-.968-1.5 0 .036 1.225.038 2.45.031 3.676a141.71 141.71 0 0 0-3.873 0 .75.75 0 0 0 0 1.5c1.285-.02 2.57-.014 3.855 0A105.37 105.37 0 0 1 20.161 25c-.053.965 1.448.962 1.5 0 .067-1.223.1-2.447.124-3.67 1.202.026 2.405.056 3.606.113.965.045.963-1.455 0-1.5a140.275 140.275 0 0 0-3.586-.113Z' />
    </g>
  </svg>
);

export default SvgAddMeasure;
