import { SVGProps } from 'react';

const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    overflow='visible'
    preserveAspectRatio='none'
    viewBox='0 0 26 26'
    {...props}
  >
    <path
      d='M20.4 8.93a10 10 0 0 0-3.17-4.28A8.57 8.57 0 0 0 12 3.12 8.86 8.86 0 0 0 3.53 9.3a8.76 8.76 0 0 0 3.4 9.84 9.77 9.77 0 0 0 5.58 1.74A8.3 8.3 0 0 0 17 19.3a9.33 9.33 0 0 0 3.4-10.37Zm-2.72 9.15a7.93 7.93 0 0 1-9.22 1.36 8.46 8.46 0 0 1-3.7-3.2 8.69 8.69 0 0 1-1-5.27 8.25 8.25 0 0 1 6.6-7.2 8.83 8.83 0 0 1 6 .84 9 9 0 0 1 3.63 4.45 8.8 8.8 0 0 1-2.31 9.02Z'
      vectorEffect='non-scaling-stroke'
      style={{
        fill: '#00445e'
      }}
      transform='translate(1 1)'
    />
    <path
      d='M11.5 15.17a1.3 1.3 0 0 0-1.26 1 1.46 1.46 0 0 0 2.74 1 1.32 1.32 0 0 0-.22-1.43 1.72 1.72 0 0 0-1.26-.57Zm1.1 1.56v.2a1.07 1.07 0 0 1-.25.31 1 1 0 0 1-.7.23 1 1 0 0 1-.85-1.23v-.08s0-.08 0 0a1.09 1.09 0 0 1 .08-.15v-.06l.11-.1s.05-.05 0 0H11.92l.11.07h.06a1.7 1.7 0 0 1 .19.19v.05l.06.12v.06c0 .06 0 .09 0 0v.2c0 .02.27.13.26.19ZM13 6.16a4.91 4.91 0 0 0-3.75.93c-.26.18-.51.38-.75.58a2.51 2.51 0 0 0-.67.63.86.86 0 0 0 .26 1.08 4.6 4.6 0 0 0 .92.74c.41.22.63.06 1-.21a3.62 3.62 0 0 1 1.58-1C12.16 8.8 13 9 13 9.7s-.7.89-1.15 1.16a3 3 0 0 0-1.06 1 2.74 2.74 0 0 0-.42 1.21c0 .34-.18 1 .23 1.15a2.52 2.52 0 0 0 .78.06 7.8 7.8 0 0 0 1.08 0c.55-.07.63-.46.92-.86a6.88 6.88 0 0 1 1.4-1 3.9 3.9 0 0 0 1-1.21 2.91 2.91 0 0 0 0-2.87A4.4 4.4 0 0 0 13 6.16Zm2.38 4.7a3.62 3.62 0 0 1-.85 1c-.4.33-.9.53-1.29.89a1.85 1.85 0 0 0-.34.4c-.12.18-.18.46-.41.54a3 3 0 0 1-.9.06h-.43c-.21 0-.3.05-.33-.14a2.39 2.39 0 0 1 1.05-2.19c.67-.46 1.66-.73 1.61-1.72A1.49 1.49 0 0 0 12 8.38a2.48 2.48 0 0 0-1.44.45 4.59 4.59 0 0 0-.6.49l-.28.23c-.15.13-.17.2-.34.14A2.88 2.88 0 0 1 8.45 9c-.08-.08-.2-.21-.19-.33s.28-.35.41-.46.57-.46.87-.68a4.66 4.66 0 0 1 3.09-.93 3.94 3.94 0 0 1 2.54 1.5 2.47 2.47 0 0 1 .25 2.76Z'
      vectorEffect='non-scaling-stroke'
      style={{
        fill: '#00445e'
      }}
      transform='translate(1 1)'
    />
  </svg>
);

export default SvgHelp;
