import { LanguageCode } from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const dateTimeRegex =
  /d{1,4}|m{1,4}|yy(?:yy)?|h{1,2}|H{1,2}|M{1,2}|T{1,2}?|[LloSZ]|"[^"]*"|'[^']*'/g;

export enum DateMasks {
  shortWeekDay = 'shortWeekDay',
  shortWeekDayNumber = 'shortWeekDayNumber',
  shortDayMonth = 'shortDayMonth',
  shortDayMonthYear = 'shortDayMonthYear',
  mediumDate = 'mediumDate',
  monthYear = 'monthYear',
  shortTime = 'shortTime',
  shortDayNumberMonth = 'shortDayNumberMonth',
  shortMonthDayNumber = 'shortMonthDayNumber',
  dateTime = 'dateTime',
  shortDateTime = 'shortDateTime',
  fullDateTime = 'fullDateTime',
  shortFullDate = 'shortFullDate',
  fullDate = 'fullDate',
  inputDate = 'inputDate',

  urlDateTime = 'urlDateTime',
  urlDate = 'urlDate',
  urlTime = 'urlTime'
}

interface IFlags {
  d: number;
  dd: string;
  ddd: string;
  dddd: string;
  mm: string;
  mmm: string;
  mmmm: string;
  yy: string;
  yyyy: number;
  hh: string;
  HH: string;
  MM: string;
  ss: string;
  TT: string;
}

export interface IUseDate {
  days: string[];
  months: string[];
  masks: { [key: string]: string };
  formatDate: (date: Date, mask: string | DateMasks) => string;
}

const useDate = (): IUseDate => {
  const languageCode = useSelector((state: IReduxState) => state.language.code);
  const language = useSelector((state: IReduxState) => state.language.values);

  const days = useCallback(() => {
    return [
      language.sunday,
      language.monday,
      language.tuesday,
      language.wednesday,
      language.thursday,
      language.friday,
      language.saturday
    ];
  }, [language])();

  const months = useCallback(() => {
    return [
      language.january,
      language.february,
      language.march,
      language.april,
      language.may,
      language.june,
      language.july,
      language.august,
      language.september,
      language.october,
      language.november,
      language.december
    ];
  }, [language])();

  const convert = (mask: string | DateMasks, flags: IFlags): string => {
    let maskToApply = mask as string;
    const maskIsForUrl = Object.keys(urlDateMasks).includes(mask.toString());

    if (maskIsForUrl) {
      maskToApply = urlDateMasks[mask as keyof typeof urlDateMasks];
    } else if (Object.keys(DateMasks).includes(mask)) {
      const languageMasks = dateMasks[languageCode!];
      maskToApply = languageMasks[mask as keyof typeof languageMasks];
    }

    return maskToApply.replace(dateTimeRegex, (substring: string) => {
      return substring in flags
        ? `${flags[substring as keyof IFlags]}`
        : substring.slice(1, substring.length - 1);
    });
  };

  const formatDate = (date: Date, mask: string | DateMasks): string => {
    const weekDay = date.getDay();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const twelveHourFormat = hours % 12;
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const flags = {
      d: day,
      dd: day?.toLocaleString('en-US', { minimumIntegerDigits: 2 }),
      ddd: days[weekDay]?.substring(0, 3),
      dddd: days[weekDay],
      mm: (month + 1)?.toLocaleString('en-US', { minimumIntegerDigits: 2 }),
      mmm: months[month]?.substring(0, 3),
      mmmm: months[month],
      yy: year.toString().substring(2),
      yyyy: year,
      hh: (twelveHourFormat || 12).toLocaleString('en-US', {
        minimumIntegerDigits: 2
      }),
      HH: hours.toLocaleString('en-US', { minimumIntegerDigits: 2 }),
      MM: minutes.toLocaleString('en-US', { minimumIntegerDigits: 2 }),
      ss: seconds.toLocaleString('en-US', { minimumIntegerDigits: 2 }),
      TT: hours < 12 ? 'AM' : 'PM'
    } as IFlags;

    return convert(mask, flags);
  };

  return { days, months, masks: dateMasks[languageCode!], formatDate };
};

const dateMasks = {
  [LanguageCode.en_US]: {
    [DateMasks.shortWeekDay]: 'ddd',
    [DateMasks.shortWeekDayNumber]: 'd ddd',
    [DateMasks.shortDayMonth]: 'd mmm',
    [DateMasks.shortDayMonthYear]: 'd mmm yy',
    [DateMasks.mediumDate]: 'mmmm, dd yyyy',
    [DateMasks.monthYear]: 'mmmm yyyy',
    [DateMasks.shortTime]: 'hh:MM TT',
    [DateMasks.shortDayNumberMonth]: 'd mmm',
    [DateMasks.shortMonthDayNumber]: 'mmm d',
    [DateMasks.dateTime]: 'mmmm, dd yyyy, hh:MM TT',
    [DateMasks.shortDateTime]: "dd/mm 'at' HH:MM",
    [DateMasks.fullDateTime]: 'mmm, dd yyyy, hh:MM TT',
    [DateMasks.shortFullDate]: 'd ddd\nmmm yyyy',
    [DateMasks.fullDate]: 'dddd, dd mmmm yyyy',
    [DateMasks.inputDate]: 'mm/dd/yyyy'
  },
  [LanguageCode.pt_PT]: {
    [DateMasks.shortWeekDay]: 'ddd',
    [DateMasks.shortWeekDayNumber]: 'd ddd',
    [DateMasks.shortDayMonth]: 'd mmm',
    [DateMasks.shortDayMonthYear]: 'd mmm yy',
    [DateMasks.mediumDate]: "dd 'de' mmmm yyyy",
    [DateMasks.monthYear]: 'mmmm yyyy',
    [DateMasks.shortTime]: 'HH:MM',
    [DateMasks.shortDayNumberMonth]: 'd mmm',
    [DateMasks.shortMonthDayNumber]: 'mmm d',
    [DateMasks.dateTime]: 'dd mmmm yyyy, HH:MM',
    [DateMasks.shortDateTime]: "dd/mm 'às' HH:MM",
    [DateMasks.fullDateTime]: "dd 'de' mmm yyyy, HH:MM",
    [DateMasks.shortFullDate]: 'd ddd\nmmm yyyy',
    [DateMasks.fullDate]: "dddd, dd 'de' mmmm yyyy",
    [DateMasks.inputDate]: 'dd/mm/yyyy'
  },
  [LanguageCode.es_ES]: {
    [DateMasks.shortWeekDay]: 'ddd',
    [DateMasks.shortWeekDayNumber]: 'd ddd',
    [DateMasks.shortDayMonth]: 'd mmm',
    [DateMasks.shortDayMonthYear]: 'd mmm yy',
    [DateMasks.mediumDate]: 'dd mmmm yyyy',
    [DateMasks.monthYear]: 'mmmm yyyy',
    [DateMasks.shortTime]: 'HH:MM',
    [DateMasks.shortDayNumberMonth]: 'd mmm',
    [DateMasks.shortMonthDayNumber]: 'mmm d',
    [DateMasks.dateTime]: 'dd mmmm yyyy, HH:MM',
    [DateMasks.shortDateTime]: "dd/mm 'a las' HH:MM",
    [DateMasks.fullDateTime]: 'dd mmm yyyy, HH:MM',
    [DateMasks.shortFullDate]: 'd ddd\nmmm yyyy',
    [DateMasks.fullDate]: 'dddd, dd mmmm yyyy',
    [DateMasks.inputDate]: 'dd/mm/yyyy'
  }
};

const urlDateMasks = {
  [DateMasks.urlDateTime]: 'yyyy-mm-dd HH:MM:ss',
  [DateMasks.urlDate]: 'yyyy-mm-dd',
  [DateMasks.urlTime]: 'HH:MM'
};

export default useDate;
