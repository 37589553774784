import { SVGProps } from 'react';

const SvgAddPatient = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M27.3 16.727a6.837 6.837 0 0 0-3.757-3.503 4.417 4.417 0 0 0-.883-.192c1.244-4.407-2.137-9.507-7.002-9.196a7.37 7.37 0 0 0-5.95 3.553 6.755 6.755 0 0 0-.21 5.685 7.822 7.822 0 0 0 3.258 3.85 9.532 9.532 0 0 1-1.279.697c-.736.356-1.506.646-2.215 1.056a5.77 5.77 0 0 0-2.72 3.715c-.352 1.399-.713 3.428.2 4.678a2.544 2.544 0 0 0 2.174.972q1.284.01 2.568.016 3.302.013 6.605-.007a.757.757 0 0 0 .304-.063 7.888 7.888 0 0 0 7.903-1.996A6.673 6.673 0 0 0 28 21.959a9.818 9.818 0 0 0-.7-5.232Zm-6.127-7.449a16.056 16.056 0 0 1-2.474-.096 9.998 9.998 0 0 0 1.58-1.62 6.058 6.058 0 0 1 .894 1.716Zm-6.58-3.759a5.22 5.22 0 0 1 4.661.98 1.002 1.002 0 0 0-.088.089 8.833 8.833 0 0 1-3.702 2.83 12.86 12.86 0 0 1-2.458.742 5.355 5.355 0 0 1-2.53.075 5.028 5.028 0 0 1 .245-1.44 5.414 5.414 0 0 1 3.871-3.276Zm-3.94 6.285a7.477 7.477 0 0 0 2.403-.13 14.1 14.1 0 0 0 2.987-.883c.276-.117.535-.262.8-.399a17.436 17.436 0 0 0 4.617.376 5.146 5.146 0 0 1-.337 2.191h-.433a8.666 8.666 0 0 0-3.131.437 7.457 7.457 0 0 0-3.262 2.61 6.184 6.184 0 0 1-3.644-4.201Zm2.366 6.742.147-.085c-.017.068-.024.14-.04.21ZM8.647 26.54c-1.044-.11-.977-1.27-.916-2.074.124-1.664.555-3.398 2.046-4.348a12.934 12.934 0 0 1 1.842-.908l.01.016 1.324 1.531a8.241 8.241 0 0 0 2.777 5.796c-1.379.004-2.757.01-4.136.005-.966-.004-1.987.082-2.947-.018Zm17.068-2.22a6.22 6.22 0 0 1-11.15-2.637 6 6 0 0 1 3.393-6.84 6.559 6.559 0 0 1 2.182-.384 15.931 15.931 0 0 1 2.347.045 4.609 4.609 0 0 1 2.961 2.032 6.638 6.638 0 0 1 1.12 3.892 6.86 6.86 0 0 1-.853 3.892Z' />
      <path d='M21.805 19.83c.007-1.235.005-2.47-.032-3.704-.029-.964-1.529-.968-1.5 0 .036 1.225.038 2.45.031 3.676a141.71 141.71 0 0 0-3.873 0 .75.75 0 0 0 0 1.5c1.285-.02 2.57-.014 3.855 0A105.37 105.37 0 0 1 20.161 25c-.053.965 1.448.962 1.5 0 .067-1.223.1-2.447.124-3.67 1.202.026 2.405.056 3.606.113.965.045.963-1.455 0-1.5a140.275 140.275 0 0 0-3.586-.113Z' />
    </g>
  </svg>
);

export default SvgAddPatient;
