import { SVGProps } from 'react';

const SvgWaterRatio = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M25.103 16.302a14.28 14.28 0 0 0-1.268-2.553 40.646 40.646 0 0 0-3.406-4.495Q19.35 7.94 18.29 6.612c-.699-.876-1.24-1.84-1.887-2.752a.76.76 0 0 0-1.295 0 56.318 56.318 0 0 1-4.17 6.16c-.852 1.144-1.673 2.308-2.518 3.456a12.096 12.096 0 0 0-1.545 2.98 8.957 8.957 0 0 0 .36 7.387 9.477 9.477 0 0 0 5.369 4.177 11.358 11.358 0 0 0 7.945-.55 8.598 8.598 0 0 0 3.942-3.37 9.696 9.696 0 0 0 1.257-5.301 8.53 8.53 0 0 0-.645-2.497ZM12.16 10.874l.002-.003a.871.871 0 0 0 .212.05 19.706 19.706 0 0 0 2.065.027.786.786 0 0 0 .53-.22.75.75 0 0 0-.53-1.28c-.395.016-.789-.007-1.183-.015.458-.6.911-1.202 1.339-1.821.43-.625.825-1.284 1.209-1.948a20.001 20.001 0 0 0 1.14 1.652q1.087 1.366 2.194 2.717c.718.877 1.532 1.712 2.2 2.626A23.607 23.607 0 0 1 23.1 15.48a9.549 9.549 0 0 1 .663 1.525c.026.082.057.17.085.254-.523.207-1.013.485-1.542.693a2.895 2.895 0 0 1-2.206-.136 5.432 5.432 0 0 0-2.285-.462 11.115 11.115 0 0 0-2.347.633 3.347 3.347 0 0 1-2.38-.137 7.169 7.169 0 0 0-2.288-.583 5.187 5.187 0 0 0-2.817.73 9.395 9.395 0 0 1 .463-1.459 12.181 12.181 0 0 1 .604-1.231 14.64 14.64 0 0 0 2.58-.115.827.827 0 0 0 .53-.22.75.75 0 0 0 0-1.06.717.717 0 0 0-.53-.22 14.612 14.612 0 0 1-1.647.116c.692-1.005 1.458-1.967 2.177-2.934Zm11.737 10.874a7.05 7.05 0 0 1-4.105 4.426c-3.512 1.504-8.135.935-10.597-2.125l2.091-.015a.75.75 0 0 0 0-1.5l-2.866.02a.706.706 0 0 0-.127.028 6.561 6.561 0 0 1-.535-2.517.692.692 0 0 0 .108-.076 3.745 3.745 0 0 1 3.844-1.056 13.753 13.753 0 0 0 2.27.716 5.056 5.056 0 0 0 2.276-.344 4.771 4.771 0 0 1 2.144-.444c.77.106 1.468.527 2.227.688a3.622 3.622 0 0 0 2.078-.15c.527-.2 1.017-.48 1.536-.69a7.567 7.567 0 0 1-.344 3.039Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgWaterRatio;
