import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { GridColumn, GridWrapper } from './Grid.styles';

export interface IGridProps extends PropsWithChildren<{}> {}

const Grid: FC<IGridProps> = (props: IGridProps): JSX.Element => {
  const {} = props;
  const [showGrid, setShowGrid] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('keypress', (event) => {
      if (event.ctrlKey && event.shiftKey && event.code === 'KeyQ') {
        setShowGrid((prevState) => !prevState);
      }
    });
  }, []);

  if (!showGrid) return <></>;

  return (
    <GridWrapper>
      {new Array(12).fill(0).map((_, i) => (
        <GridColumn key={i} className='col-1' />
      ))}
    </GridWrapper>
  );
};

export default Grid;
