export type DeviceType = 'dekstop' | 'smallDesktop' | 'tablet' | 'smartphone';

export const getNrColumns = (device: DeviceType = 'dekstop'): number => {
  switch (device) {
    case 'dekstop':
    case 'smallDesktop':
      return 12;
    case 'tablet':
      return 8;
    case 'smartphone':
      return 4;
  }
};

export const getMargin = (device: DeviceType = 'dekstop'): string => {
  switch (device) {
    case 'dekstop':
    case 'smallDesktop':
      return 'calc((100vw * 30) / 1440)';
    case 'tablet':
      return 'calc((100vw * 20) / 600)';
    case 'smartphone':
      return 'calc((100vw * 32) / 360)';
  }
};

export const getGutter = (device: DeviceType = 'dekstop'): string => {
  switch (device) {
    case 'dekstop':
    case 'smallDesktop':
      return 'calc((100vw * 16) / 1440)';
    case 'tablet':
      return 'calc((100vw * 16) / 600)';
    case 'smartphone':
      return 'calc((100vw * 16) / 360)';
  }
};

export const getColumnsWidth = (
  {
    device,
    nrColumns
  }: {
    device?: DeviceType;
    nrColumns?: number;
  } = { device: 'dekstop', nrColumns: 1 }
): string => {
  const deviceNrColumns = getNrColumns(device);
  const margin = getMargin(device);
  const gutter = getGutter(device);
  const width = `calc((100vw - ((${deviceNrColumns} - 1) * ${gutter}) - (2 * ${margin})) / ${deviceNrColumns})`;

  return `calc((${width} * ${nrColumns || 1}) + (${gutter}) * ${
    (nrColumns || 1) - 1
  })`;
};
