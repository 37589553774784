import { SVGProps } from 'react';

const SvgPills = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M16.26 13.665a7.766 7.766 0 0 0-9.644 1.255 7.974 7.974 0 0 0-2.357 5.228 7.634 7.634 0 0 0 1.834 5.262 8.424 8.424 0 0 0 7.469 2.364 8.082 8.082 0 0 0 6.146-5.54 7.42 7.42 0 0 0-3.448-8.57ZM7.153 24.35a6.088 6.088 0 0 1-.267-7.37.705.705 0 0 0 .075.11q4.293 4.127 8.903 7.903a.955.955 0 0 0 .131.078 6.2 6.2 0 0 1-2.24 1.128 6.872 6.872 0 0 1-6.602-1.849Zm11.28-3.13a6.86 6.86 0 0 1-1.413 2.845.535.535 0 0 0-.095-.133q-4.606-3.774-8.902-7.903a.76.76 0 0 0-.242-.154 6.065 6.065 0 0 1 2.755-1.65 6.365 6.365 0 0 1 4.403.426 6.03 6.03 0 0 1 3.494 6.568ZM25.515 5.1a5.734 5.734 0 0 0-7.108.925 5.828 5.828 0 0 0-1.74 4.039 5.659 5.659 0 0 0 1.368 3.715 6.21 6.21 0 0 0 5.516 1.733 5.955 5.955 0 0 0 4.518-4.104A5.488 5.488 0 0 0 25.515 5.1Zm-6.424 2.416a4.252 4.252 0 0 1 5.411-1.266 4.551 4.551 0 0 1 1.617 1.444L18.2 10.523a4.44 4.44 0 0 1 .891-3.007Zm7.604 3.244a4.476 4.476 0 0 1-3.186 3.23 4.656 4.656 0 0 1-4.413-1.272 3.35 3.35 0 0 1-.502-.747l8.123-2.901a3.549 3.549 0 0 1-.022 1.69Z' />
    </g>
  </svg>
);

export default SvgPills;
