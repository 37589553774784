import { SVGProps } from 'react';

const SvgStopRepeat = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M28.062 12.318a11.651 11.651 0 0 0-3.87-5.741 13.353 13.353 0 0 0-12.987-2.12C6.192 6.45 3.241 11.879 3.601 17.19c.37 5.456 4.7 9.569 9.864 10.787 5.141 1.213 10.873-.507 13.457-5.367a13.114 13.114 0 0 0 .859-2.037 14.207 14.207 0 0 0 .281-8.255Zm-6.166 13.325a11.197 11.197 0 0 1-6.552 1.15c-4.531-.492-8.945-3.538-10.02-8.126a11.521 11.521 0 0 1 5.24-12.263 11.66 11.66 0 0 1 11.492.49A10.648 10.648 0 0 1 26.854 18.1a17.258 17.258 0 0 1-.52 2.075 9.501 9.501 0 0 1-4.438 5.468Z' />
      <path d='M22.44 14.424a8.382 8.382 0 0 0-.247-3.111 2.285 2.285 0 0 0-2.21-1.515c-1.275-.047-2.552-.03-3.83-.021a32.811 32.811 0 0 0-3.574.03 3.639 3.639 0 0 0-2.756 1.372 4.345 4.345 0 0 0-.368 2.666c.062.894-.016 1.793-.031 2.689a20.487 20.487 0 0 0 .126 3.661 2.351 2.351 0 0 0 1.05 1.752 4.58 4.58 0 0 0 2.524.271c1.497-.09 2.986-.168 4.486-.12 1.22.043 2.754.178 3.817-.555 1.131-.78 1.21-2.272 1.133-3.521a79.899 79.899 0 0 1-.12-3.598Zm-10.775 6.311c-.048-.005-.058-.01 0 0Zm9.3-1.015a1.507 1.507 0 0 1-.192.411 1.438 1.438 0 0 1-.97.433 11.794 11.794 0 0 1-2.193.034 39.117 39.117 0 0 0-3.669.068 17.33 17.33 0 0 1-2.232.074l-.033-.003c-.057-.011-.113-.021-.17-.035-.037-.01-.074-.022-.11-.034a1.97 1.97 0 0 0-.057-.026l-.029-.014a.361.361 0 0 1-.078-.074l-.005-.008a1.404 1.404 0 0 1-.087-.154l-.003-.007-.007-.025c-.02-.063-.04-.125-.057-.188a3.357 3.357 0 0 1-.047-.21c-.006-.028-.03-.179-.035-.208a11.98 11.98 0 0 1-.075-1.271c-.026-1.383.115-2.775.068-4.152a5.323 5.323 0 0 1 .04-2.217c.335-.769 1.613-.845 2.326-.856 2.166-.034 4.357-.037 6.522.037.406.014.644.035.854.401a2.237 2.237 0 0 1 .257.957c.024.51-.05 1.037-.045 1.55.005.592.03 1.184.05 1.776.035.96.12 1.938.09 2.898a3.41 3.41 0 0 1-.114.843Z' />
    </g>
  </svg>
);

export default SvgStopRepeat;
