import { SVGProps } from 'react';

const SvgBloodPressure = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M27.739 7.76a6.007 6.007 0 0 0-4.702-2.338 7.561 7.561 0 0 0-3.101.623 15.498 15.498 0 0 0-2.723 1.736c-.4.27-.48.328-.846.02-.351-.294-.664-.623-1.039-.89a8.207 8.207 0 0 0-2.81-1.293 5.8 5.8 0 0 0-5.983 1.848 6.651 6.651 0 0 0-.921 5.627 7.4 7.4 0 0 0-2.358 5.75 7.987 7.987 0 0 0 4.726 7.13 8.05 8.05 0 0 0 6.619-.272 1.619 1.619 0 0 0 2.376.11c.636-.46 1.279-.913 1.922-1.364 1.404-.986 2.803-1.982 4.127-3.074 2.519-2.08 4.985-4.734 5.572-8.067a7.49 7.49 0 0 0-.86-5.545ZM4.883 19.91c-.014-.07-.014-.141-.026-.212.323.011.646.036.97.03a.78.78 0 0 0 .53-.22.75.75 0 0 0-.53-1.28c-.349.007-.697-.019-1.045-.033a5.813 5.813 0 0 1 3.495-5.126 6.364 6.364 0 0 1 1.91-.37v.412a.774.774 0 0 0 .22.53.835.835 0 0 0 .53.22.758.758 0 0 0 .53-.22.78.78 0 0 0 .22-.53v-.444a9.03 9.03 0 0 1 1.119.063 4.607 4.607 0 0 1 2.961 2.032 6.42 6.42 0 0 1 1.095 3.49c-.366-.014-.732-.036-1.099-.02a.787.787 0 0 0-.53.22.75.75 0 0 0 .53 1.28c.366-.016.73.006 1.095.02a5.75 5.75 0 0 1-.824 2.794 5.991 5.991 0 0 1-4.536 2.503v-1.576a.773.773 0 0 0-.22-.53.75.75 0 0 0-1.28.53v1.515c-.128-.02-.26-.029-.385-.058a6.29 6.29 0 0 1-4.73-5.02Zm22.356-7.829c-.142 2.868-1.992 5.25-4.014 7.13a38.383 38.383 0 0 1-3.83 3.055c-.65.465-1.304.921-1.957 1.38q-.447.315-.89.633c.02-.022.046-.038.067-.06a6.672 6.672 0 0 0 1.704-4.034 9.818 9.818 0 0 0-.7-5.23 6.838 6.838 0 0 0-3.757-3.505 9.015 9.015 0 0 0-2.853-.266 8.667 8.667 0 0 0-3.132.439 7.718 7.718 0 0 0-.937.472 5.054 5.054 0 0 1 .636-3.517 4.172 4.172 0 0 1 4.356-1.555 6.785 6.785 0 0 1 2.552 1.125c.597.422 1.06 1.1 1.786 1.323a2.364 2.364 0 0 0 2.116-.697 8.972 8.972 0 0 1 2.462-1.48 5.305 5.305 0 0 1 4.69.399c1.49.962 1.784 2.73 1.701 4.388Z' />
      <path d='M20.796 8.412a.75.75 0 0 0 .399 1.446 3.536 3.536 0 0 1 .42-.093l.097-.009a3.38 3.38 0 0 1 .199-.008c.133-.002.265.008.398.017l.02.002.076.014q.098.018.195.041c.14.034.276.079.413.126a.332.332 0 0 0 .037.011c.01.005.007.005.029.015.06.029.12.057.178.088a3.448 3.448 0 0 1 .368.225l.048.035.036.03c.05.045.1.087.148.134a3.553 3.553 0 0 1 .246.266c.02.025.057.071.067.086q.066.095.126.195a3.846 3.846 0 0 1 .178.344l.043.106q.034.094.063.19c.04.138.063.28.098.42l.003.017.008.08c.006.065.01.132.012.198.004.133-.003.265-.01.398a.311.311 0 0 0 0 .04.246.246 0 0 0-.006.024 5.474 5.474 0 0 1-.054.26.75.75 0 1 0 1.446.399 4.222 4.222 0 0 0-5.28-5.097ZM11.569 18.974c-.008-.005-.017-.008-.025-.014.008.007.03.02.048.031a.28.28 0 0 0-.023-.017Z' />
      <path d='M11.84 20.888a2.072 2.072 0 0 0 1.046-.786 1.71 1.71 0 0 0 .284-1.095 1.752 1.752 0 0 0-.752-1.263 2.592 2.592 0 0 0-.61-.32 16.906 16.906 0 0 1-.023-1.569.756.756 0 0 0-.75-.75.766.766 0 0 0-.75.75c-.018.504-.007 1.007.02 1.51a2.19 2.19 0 0 0-1.043.877 1.67 1.67 0 0 0 .286 2.317 2.85 2.85 0 0 0 2.293.33Zm-1.076-2.185a.815.815 0 0 0 .098.03l-.053-.007Zm.867.314-.003-.002Zm-1.046.38-.043-.017c-.032-.014-.062-.031-.093-.046 0-.023-.002-.045 0-.068l.01-.054.02-.063c.026-.04.05-.106.068-.134l.039-.06c.035-.037.07-.075.108-.11l-.044.026c.025-.014.048-.033.072-.05a.139.139 0 0 1 .026-.016l.059-.035.027-.013.015-.006h.005l.017-.005h.031a.834.834 0 0 0 .278.036l.015.005.066.025a1.533 1.533 0 0 1 .282.148c-.034-.023-.006-.01.026.014l.054.037-.032-.02.002.001c.013.012.04.044.063.068l.003.005.01.017c0 .034 0 .069-.002.102-.004.019-.007.038-.013.056l-.003.005c-.012.023-.026.045-.04.068-.026.026-.043.044-.064.063l-.041.027-.091.05-.014.008-.079.018c-.022.006-.044.008-.067.013a1.927 1.927 0 0 1-.305 0c-.063-.013-.126-.022-.188-.039a2.65 2.65 0 0 1-.177-.057Z' />
      <path d='m10.749 18.805-.022.013-.005.003a.182.182 0 0 0-.02.015l-.008.008c.05-.031.065-.043.055-.04Z' />
    </g>
  </svg>
);

export default SvgBloodPressure;
