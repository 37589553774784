import { SVGProps } from 'react';

const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M24.583 8.874c.662-.702-.397-1.765-1.06-1.06q-3.54 3.76-7.232 7.371-3.778-3.89-7.673-7.664c-.694-.672-1.756.388-1.06 1.06q3.89 3.767 7.66 7.652-3.732 3.619-7.602 7.092c-.72.645.343 1.703 1.06 1.06q3.863-3.457 7.58-7.071 3.508 3.64 6.914 7.377c.651.715 1.71-.348 1.061-1.061q-3.395-3.734-6.9-7.363 3.696-3.626 7.252-7.393Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgClose;
