import { SVGProps } from 'react';

const SvgAccess = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M28.045 13.144a11.32 11.32 0 0 0-1.653-1.734c-.349-.348-.702-.692-1.055-1.037.576-.592 1.168-1.17 1.749-1.755a.762.762 0 0 0 0-1.06l-2.458-2.47c-.567-.569-1.208-1.234-1.942-.47-.566.59-1.11 1.204-1.68 1.79a429.218 429.218 0 0 1-5.125 5.162 15.62 15.62 0 0 1-1.284 1.267 7.955 7.955 0 0 0-.922-.49 4.453 4.453 0 0 0-2.221-.231 7.79 7.79 0 0 0-2.226.578 10.524 10.524 0 0 0-1.93.95 9.239 9.239 0 0 0-2.636 3.12 6.433 6.433 0 0 0-.704 4.161 8.876 8.876 0 0 0 1.973 3.87 7.728 7.728 0 0 0 3.3 2.577 7.596 7.596 0 0 0 7.49-1.698 7.526 7.526 0 0 0 2.294-3.789 7.733 7.733 0 0 0 .168-2.624 8.412 8.412 0 0 0-.235-1.289c-.054-.2-.131-.399-.203-.599a20.16 20.16 0 0 0 1.644-1.56l1.06-1.04.056-.055.627.642 1.669 1.707a.767.767 0 0 0 1.06 0c.627-.542 1.185-1.14 1.772-1.722a7.395 7.395 0 0 0 1.41-1.444.757.757 0 0 0 .002-.757Zm-6.998 1.12c-.016-.01-.012-.008 0 0Zm4.372.183c-.343.36-.71.693-1.071 1.032l-1.261-1.29a7.137 7.137 0 0 0-1.19-1.163.756.756 0 0 0-.758 0 9.147 9.147 0 0 0-1.628 1.548q-.538.526-1.078 1.051c-.127.123-.253.247-.382.37-.043.04-.21.185-.203.188a1.335 1.335 0 0 0-.701.966 6.226 6.226 0 0 0 .41 1.436 6.266 6.266 0 0 1-.176 3.54 6.44 6.44 0 0 1-4.04 3.825 5.774 5.774 0 0 1-3.083.164 5.368 5.368 0 0 1-2.742-1.763c-1.485-1.637-2.64-3.724-1.921-5.969a7.309 7.309 0 0 1 1.605-2.61 5.168 5.168 0 0 1 1.11-1.001 12.681 12.681 0 0 1 1.587-.741 4.427 4.427 0 0 1 3.361-.236c.202.096.388.218.579.331.072.043.138.082.209.12.031.09.607.232.59.231a1.142 1.142 0 0 0 .67-.303 14.222 14.222 0 0 0 1.636-1.543q1.43-1.423 2.853-2.853c1.052-1.06 2.105-2.119 3.132-3.202.135-.142.354-.325.54-.515.033.03.071.06.1.09l.79.793 1.136 1.14c-.59.583-1.185 1.162-1.743 1.772a.763.763 0 0 0 0 1.06q.742.718 1.473 1.448c.269.268.538.537.802.81.102.106.246.228.382.353-.129.114-.263.222-.359.312-.213.203-.425.395-.63.61ZM13.125 20.474v-.003.003z' />
      <path d='m10.047 20.549.002.053c0-.052-.002-.061-.002-.053Z' />
      <path d='M14.24 18.73a3.257 3.257 0 0 0-1.58-1.512 3.05 3.05 0 0 0-3.51 1.237 3.256 3.256 0 0 0-.257 3.335 2.873 2.873 0 0 0 1.18 1.178 2.721 2.721 0 0 0 1.847.227 3.507 3.507 0 0 0 1.663-.826 3.707 3.707 0 0 0 .972-1.453 2.069 2.069 0 0 0 .085-.547 2.163 2.163 0 0 0-.007-.488 3.072 3.072 0 0 0-.393-1.151Zm-3.141-.057c-.021.007-.033.01 0 0Zm.037 3.094c-.042-.005-.093-.025 0 0Zm1.989-1.285c0 .063-.004.028 0-.008a.231.231 0 0 0-.022.026c-.045.082-.09.163-.137.244a3.61 3.61 0 0 1-.198.303l-.074.088c-.063.069-.13.132-.198.197l-.03.029c-.031.023-.063.045-.095.066a2.195 2.195 0 0 1-.225.126c-.02.008-.044.02-.053.022-.08.03-.16.059-.242.085a1.787 1.787 0 0 1-.552.112c-.05 0-.101-.002-.152-.004h-.009c-.092-.026-.184-.05-.276-.077l-.002-.003c-.028-.017-.06-.03-.088-.046a1.908 1.908 0 0 1-.193-.124c-.012-.016-.107-.099-.13-.123-.032-.032-.062-.065-.092-.098a.254.254 0 0 0-.028-.027.239.239 0 0 0-.02-.035c-.048-.076-.092-.153-.137-.232l-.025-.049-.03-.081a2.38 2.38 0 0 1-.067-.26l-.001-.01v.003-.005a.313.313 0 0 1-.002-.052l-.006-.119c0-.047.001-.095.003-.142l.005-.073.012-.086q.014-.071.031-.142a2.898 2.898 0 0 1 .084-.28l.032-.082.052-.106a3.306 3.306 0 0 1 .186-.307l.023-.03c.036-.042.072-.082.11-.121a1.509 1.509 0 0 1 .539-.395l.04-.014c.063-.02.124-.04.187-.056q.075-.02.15-.035c.027-.006.078-.013.095-.015a1.328 1.328 0 0 1 .671.119c-.136-.055.173.118.057.032a1.59 1.59 0 0 1 .22.209c.029.031.056.064.084.097.014.018.04.048.043.054.084.115.164.233.237.356a1.989 1.989 0 0 1 .227.507 2.414 2.414 0 0 1-.004.55c.003-.02.007-.038.018-.043.04-.017.009.014-.018.046Z' />
    </g>
  </svg>
);

export default SvgAccess;
