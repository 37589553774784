import countries from 'country-list-js';
import IProps from 'interfaces/IProps';
import { FC, Fragment, PropsWithChildren } from 'react';
import styles from 'styles';
import {
  InputHelpMessage,
  InputIcon,
  InputStateMessage,
  InputWrapper
} from '../Input.styles';
import { IInputProps } from '../Input.types';
import {
  PhoneNumberInputContainer,
  PhoneNumberInputCountryFlag,
  PhoneNumberInputDropdown,
  PhoneNumberInputDropdownIcon,
  PhoneNumberInputSelectedCountry
} from './PhoneNumberInput.styles';
import usePhoneNumberInput from './PhoneNumberInput.logic';
import { CSSTransition } from 'react-transition-group';
import CountryFlag from 'components/CountryFlag';
import { Flex } from 'components/Templates';

export interface IPhoneNumberInputProps
  extends PropsWithChildren<
    Omit<IInputProps, 'icon' | 'prefix' | 'placeholder' | 'suffix'> & IProps
  > {
  includeIcon?: boolean;
}

const PhoneNumberInput: FC<IPhoneNumberInputProps> = (
  props: IPhoneNumberInputProps
): JSX.Element => {
  const { dataTestId, className, style, includeIcon, disabled, help } = props;
  const {
    input,
    showList,
    selectedCountryCode,
    countryCallingCode,
    formattedValue,
    isFocused,
    containerRef,
    toggleShowListHandler,
    selectCountryHandler,
    inputChangeHandler,
    focusHandler,
    blurHandler
  } = usePhoneNumberInput(props);
  const { success, error } = input;

  return (
    <InputWrapper className={className} style={style}>
      {includeIcon && <InputIcon src='phoneNumber' />}
      <div>
        {success && (
          <InputStateMessage
            data-testid={`${dataTestId}_success`}
            type='success'
          >
            {success}
          </InputStateMessage>
        )}
        {error && (
          <InputStateMessage data-testid={`${dataTestId}_error`} type='alert'>
            {error}
          </InputStateMessage>
        )}
        <PhoneNumberInputContainer
          isFocused={isFocused}
          state={success ? 'success' : error ? 'alert' : undefined}
          ref={containerRef}
        >
          <PhoneNumberInputSelectedCountry onClick={toggleShowListHandler}>
            <Flex>
              <PhoneNumberInputCountryFlag>
                {selectedCountryCode && (
                  <CountryFlag countryCode={selectedCountryCode} />
                )}
              </PhoneNumberInputCountryFlag>
              <PhoneNumberInputDropdownIcon
                src='arrowDown'
                size={styles.iconSize.text}
                fill={styles.colors.brand.dark}
                isOpen={showList}
              />
            </Flex>
          </PhoneNumberInputSelectedCountry>
          <CSSTransition
            classNames='countries-list'
            in={showList}
            timeout={styles.transition}
          >
            <PhoneNumberInputDropdown>
              <ul>
                {countries
                  .ls('iso2' as any)
                  .map((countryCode: string | undefined) => (
                    <Fragment key={countryCode}>
                      {countryCode && countryCode !== selectedCountryCode && (
                        <li onClick={() => selectCountryHandler(countryCode)}>
                          <PhoneNumberInputCountryFlag>
                            <CountryFlag countryCode={countryCode} />
                          </PhoneNumberInputCountryFlag>
                        </li>
                      )}
                    </Fragment>
                  ))}
              </ul>
            </PhoneNumberInputDropdown>
          </CSSTransition>
          <input
            data-testid={dataTestId}
            type='text'
            placeholder={
              countryCallingCode ? `(+${countryCallingCode}) Number` : ''
            }
            value={formattedValue}
            disabled={disabled}
            onChange={inputChangeHandler}
            onFocus={focusHandler}
            onBlur={blurHandler}
          />
        </PhoneNumberInputContainer>
        {help && (
          <InputHelpMessage data-testid={`${dataTestId}_help`}>
            {help}
          </InputHelpMessage>
        )}
      </div>
    </InputWrapper>
  );
};

PhoneNumberInput.displayName = 'PhoneNumberInput';

export default PhoneNumberInput;
