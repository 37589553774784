import styled, { keyframes } from 'styled-components';
import Icon from './Icon';

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export interface ILoadingIconWrapperProps {}

export const LoadingIconWrapper = styled(Icon)<ILoadingIconWrapperProps>`
  animation: ${loading} 1s ease-in-out 1s infinite;
`;
