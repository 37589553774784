import Icon from 'components/Icon/Icon';
import styled from 'styled-components';
import { InputContainer } from '../Input.styles';

export interface ISearchInputContainerProps {}

export const SearchInputContainer = styled(
  InputContainer
)<ISearchInputContainerProps>`
  padding-top: 0.5rem;
  padding-left: 0.75rem;
  padding-bottom: 0.5rem;
  margin-top: 0;
`;

export interface ISearchIconProps {}

export const SearchIcon = styled(Icon)<ISearchIconProps>`
  margin-right: 0.375rem;
`;
