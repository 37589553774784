import { SVGProps } from 'react';

const SvgLoudspeaker = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M19.536 7.796a2.702 2.702 0 0 0-1.48-2.15 2.589 2.589 0 0 0-2.418.466c-1.79 1.239-3.353 2.811-5.31 3.816a3.47 3.47 0 0 1-1.66.424c-.64-.01-1.272-.168-1.915-.16a2.617 2.617 0 0 0-2.555 1.455 4.892 4.892 0 0 0-.085 1.515 25.99 25.99 0 0 0-.011 2.063c.022.635.085 1.267.13 1.9.05.678.003 1.362.113 2.032.153.936.343 1.752 1.36 2.064a9.016 9.016 0 0 0 2.734-.022c.948-.004 1.787.927 2.464 1.496.857.72 1.687 1.479 2.595 2.135.426.308.873.584 1.315.868a4.187 4.187 0 0 0 1.47.761 2.536 2.536 0 0 0 2.412-.922c1.014-1.246.747-3.009.719-4.498-.046-2.425-.18-4.849-.147-7.274a34.89 34.89 0 0 1 .18-3.331 9.896 9.896 0 0 0 .09-2.638Zm-1.538 2.208c-.11.934-.177 1.87-.21 2.81-.065 1.927 0 3.856.06 5.782.03.941.046 1.882.072 2.824a15.898 15.898 0 0 1 .015 1.99 1.843 1.843 0 0 1-.66 1.423c-.798.528-1.817-.49-2.462-.917a27.403 27.403 0 0 1-2.579-2.054 14.111 14.111 0 0 0-2.254-1.75 4.46 4.46 0 0 0-2.72-.317 2.373 2.373 0 0 1-1.156-.02c-.317-.17-.26-.697-.312-1.017-.074-.45-.015-.941-.043-1.398-.028-.473-.08-.945-.111-1.418-.068-1.055.004-2.096-.006-3.149-.004-.38-.14-.653.227-.898a1.866 1.866 0 0 1 1.086-.2 10.098 10.098 0 0 0 2.894.022c1.739-.436 3.305-1.777 4.7-2.842.666-.51 1.292-1.115 2-1.564a1.384 1.384 0 0 1 .86-.313c.366.062.545.535.624.847a6.032 6.032 0 0 1-.025 2.159ZM22.53 11.71a.773.773 0 0 0-1.027-.268.755.755 0 0 0-.269 1.026 5.819 5.819 0 0 1 .433 6.262.75.75 0 0 0 1.296.757 7.296 7.296 0 0 0-.434-7.776Z' />
      <path d='M25.304 9.236c-.715-.645-1.779.412-1.06 1.061a6.98 6.98 0 0 1 2.164 5.523 11.958 11.958 0 0 1-2.488 6.332c-.562.787.738 1.537 1.295.757a13.168 13.168 0 0 0 2.699-7.28 8.41 8.41 0 0 0-2.61-6.393Z' />
    </g>
  </svg>
);

export default SvgLoudspeaker;
