import Button from 'components/Button/Button';
import styled from 'styled-components';

export interface IPasswordInputVisibilityButtonProps {}

export const PasswordInputVisibilityButton = styled(
  Button
)<IPasswordInputVisibilityButtonProps>`
  position: absolute;
  right: 0.25rem;
`;
