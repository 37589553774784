import { PropsWithChildren, FC } from 'react';
import { LeftContentWrapper } from './LeftContent.styles';

export interface ILeftContentProps extends PropsWithChildren<{}> {}

const LeftContent: FC<ILeftContentProps> = (
  props: ILeftContentProps
): JSX.Element => {
  const { children } = props;

  return <LeftContentWrapper>{children}</LeftContentWrapper>;
};

LeftContent.displayName = 'LeftContent';

export default LeftContent;
