import { SVGProps } from 'react';

const SvgTime = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M15.463 13.35q.038 1.468.021 2.94c-.004.353-.01.706-.024 1.06q-.009.233-.021.466l-.011.176a1.224 1.224 0 0 1-.134.075q-.204.093-.41.184-.352.158-.706.311-.74.321-1.486.624a.774.774 0 0 0-.524.922.755.755 0 0 0 .923.524q.867-.352 1.726-.723a10.778 10.778 0 0 0 1.576-.716c.489-.315.516-.852.545-1.377.02-.364.032-.73.04-1.095a73.618 73.618 0 0 0-.015-3.372.75.75 0 0 0-1.5 0ZM12.679 6.018l6.824.028a.75.75 0 0 0 0-1.5l-6.824-.028a.75.75 0 0 0 0 1.5Z' />
      <path d='M26.382 9.387c-.997-1.198-2.243-2.369-3.898-2.41a2.757 2.757 0 0 0-1.993.748 1.866 1.866 0 0 0-.499.86A9.978 9.978 0 0 0 15 7.89a9.636 9.636 0 0 0-2.935.848 1.451 1.451 0 0 0-.205-.618 2.51 2.51 0 0 0-1.812-1.128 4.754 4.754 0 0 0-4.068 1.97c-.982 1.116-2.077 2.483-1.974 4.067a3.413 3.413 0 0 0 .339 1.268 2.079 2.079 0 0 0 .736.954c.47.25.929-.02 1.324-.28.069-.045.128-.102.194-.151a8.97 8.97 0 0 0-.529 2.743 9.705 9.705 0 0 0 2.33 6.712 10.757 10.757 0 0 0 9.472 3.04 10.316 10.316 0 0 0 7.863-6.89 8.72 8.72 0 0 0 .18-5.25 1.28 1.28 0 0 0 .555.177c.8.029 1.295-1.099 1.444-1.754.366-1.604-.55-3.033-1.531-4.212Zm-4.674-.723a2.142 2.142 0 0 1 2.308.435 7.362 7.362 0 0 1 1.073 1.073 8.918 8.918 0 0 1 .938 1.245 2.945 2.945 0 0 1 .47 1.35 1.722 1.722 0 0 1-.105.695 1.635 1.635 0 0 1-.09.179c-.029-.02-.06-.037-.087-.059a6.177 6.177 0 0 1-.59-.563l-2.522-2.58-1.236-1.266-.333-.34-.006-.008a1.996 1.996 0 0 1 .18-.16ZM5.786 13.58c-.03.023-.061.04-.092.061-.017-.034-.04-.065-.055-.1a1.68 1.68 0 0 1-.134-.51 2.418 2.418 0 0 1 .277-1.26 8.657 8.657 0 0 1 1.926-2.44 2.535 2.535 0 0 1 2.38-.798.994.994 0 0 1 .382.229l.03.035c-.417.43-.837.856-1.256 1.284L6.38 13.014a6.037 6.037 0 0 1-.593.566Zm18.768 5.546a8.899 8.899 0 0 1-6.198 6.552 9.206 9.206 0 0 1-8.897-2.462 8.077 8.077 0 0 1-1.884-5.01 8.163 8.163 0 0 1 1.892-5.48A8.617 8.617 0 0 1 14.2 9.547a8.546 8.546 0 0 1 5.823.693c3.25 1.647 5.408 5.217 4.532 8.885Z' />
    </g>
  </svg>
);

export default SvgTime;
