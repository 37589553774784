import useInput from 'components/Input/Input.logic';
import useClickOutside from 'hooks/use-clickOutside';
import IOption from 'interfaces/IOption';
import { useRef, useState } from 'react';
import { IFormInput, InputValue } from 'utils/form';
import { ISelectProps } from './Select';

export interface IUseSelect {
  isOpen: boolean;
  input?: IFormInput;
  value: InputValue;
  width: string;
  containerRef: React.RefObject<HTMLDivElement>;
  selectRef: React.RefObject<HTMLSelectElement>;
  toggleOpenOptionsHandler: () => void;
  selectOptionHandler: (option: IOption) => void;
}

const useSelect = (props: ISelectProps): IUseSelect => {
  const { placeholder, options, disabled } = props;
  const { input, value, changeHandler } = useInput(props);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);
  useClickOutside(containerRef, setIsOpen);

  const toggleOpenOptionsHandler = (): void => {
    if (!disabled || options.length > 0) setIsOpen((prevState) => !prevState);
  };

  const selectOptionHandler = (option: IOption): void => {
    selectRef.current!.value = option.id + '';
    changeHandler(option.id, true);
    setIsOpen((prevState) => !prevState);
  };

  return {
    isOpen,
    input,
    value,
    width: `${0.6 * getGreatestOptionNrChars(options, placeholder)}rem`,
    containerRef,
    selectRef,
    toggleOpenOptionsHandler,
    selectOptionHandler
  };
};

const getGreatestOptionNrChars = (
  options: IOption[],
  placeholder?: string
): number => {
  let nrChars = placeholder?.length || 0;

  options.forEach((option: IOption) => {
    const optionNrChars = option.name.length;
    if (optionNrChars > nrChars) nrChars = optionNrChars;
  });

  return nrChars;
};

export default useSelect;
