import { SVGProps } from 'react';

const SvgAge = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M25.722 17.993a5.534 5.534 0 0 0-.648-2.692 3.732 3.732 0 0 0-2.434-1.359 11.035 11.035 0 0 0-3.013-.103l-1.692.005c-.014-.078-.03-.155-.042-.233-.01-.06-.022-.122-.027-.183l-.007-.05q-.043-.36-.063-.722a11.226 11.226 0 0 1 .02-1.48.552.552 0 0 0-.022-.121 2.791 2.791 0 0 0 .898-2.118 5.627 5.627 0 0 0-.993-2.357 7.79 7.79 0 0 1-.922-2.28.753.753 0 0 0-1.473.2c.052 1.118-.914 1.988-1.446 2.883a3.036 3.036 0 0 0-.088 3.497 2.683 2.683 0 0 0 .65.586v2.383h-1.374a24.648 24.648 0 0 0-3.114.07 4.042 4.042 0 0 0-3.684 3.398 19.979 19.979 0 0 0 .022 2.818c.03 1.11.054 2.221.085 3.331.041 1.495-.268 4.417 1.786 4.75a9.913 9.913 0 0 0 1.546.018h1.693l3.447-.001 6.704-.002a7.02 7.02 0 0 0 3.003-.213c.937-.473 1.01-1.499 1.091-2.427.224-2.537.106-5.059.097-7.598ZM15.768 7.234c.113-.16.22-.326.324-.493.05.098.086.204.14.3a5.512 5.512 0 0 1 .96 1.896 1.245 1.245 0 0 1-.305.917 1.675 1.675 0 0 1-.85.555 1.18 1.18 0 0 1-1.285-.862c-.16-.841.568-1.68 1.016-2.313Zm.151 4.671a2.4 2.4 0 0 0 .368-.036 12.024 12.024 0 0 0 .14 1.98h-.507Zm-8.17 5.428A2.11 2.11 0 0 1 9.11 15.73a4.922 4.922 0 0 1 2.272-.386c.952.012 1.905.005 2.858.005 2.076 0 4.152-.01 6.228-.013.439-.001.906-.053 1.343-.025a5.952 5.952 0 0 1 1.526.451c.824.293.843 1.476.885 2.231.003.9.025 1.799.039 2.697-1.672 1.018-3.297-.944-4.241-2.122-.337-.42-.918-.186-1.178.152a4.721 4.721 0 0 1-3.042 2.083c-1.34.138-2.138-.852-2.842-1.838a.761.761 0 0 0-1.296 0c-.786 1.228-2.316 2.876-3.855 1.741-.008-.006-.016-.005-.024-.01-.01-.386-.018-.77-.03-1.155a11.82 11.82 0 0 1-.004-2.208Zm16.318 8.859c-.051.305-.084.48-.409.545a5.031 5.031 0 0 1-1.039-.007c-3.744-.057-7.494.003-11.239.004-.882 0-1.765-.01-2.648 0-.479.006-.62-.15-.742-.624a5.16 5.16 0 0 1-.094-1.15c-.022-.852-.046-1.703-.067-2.555a3.56 3.56 0 0 0 2.545-.164 5.23 5.23 0 0 0 1.967-1.65 4.03 4.03 0 0 0 3.459 1.712 5.595 5.595 0 0 0 3.68-2.045 6.495 6.495 0 0 0 2.247 1.842 3.773 3.773 0 0 0 2.543.222c-.002.404-.007.809-.022 1.213a20.963 20.963 0 0 1-.182 2.657Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgAge;
