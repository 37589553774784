import { SVGProps } from 'react';

const SvgMedicFemale = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    overflow='visible'
    viewBox='0 0 128 128'
    xmlSpace='preserve'
    y={0}
    x={0}
    id='medicFemale_svg__Layer_1_1632235826038'
    {...props}
  >
    <g>
      <style>{'path{fill:#009ebb}'}</style>
      <path
        d='M64 128C28.7 128 0 99.3 0 64S28.7 0 64 0s64 28.7 64 64-28.7 64-64 64zm0-2c34.2 0 62-27.8 62-62S98.2 2 64 2 2 29.8 2 64s27.8 62 62 62z'
        style={{
          strokeWidth: 0
        }}
        vectorEffect='non-scaling-stroke'
      />
      <path
        d='M.2 96C4.7 75.8 7 54.8 7 33 7 14.8 21.8 0 40 0s33 14.8 33 33c0 21.8 2.3 42.8 6.8 63h-2C73.2 75.7 71 54.7 71 33 71 15.9 57.1 2 40 2S9 15.9 9 33c0 21.7-2.2 42.7-6.7 63H.2z'
        style={{
          strokeWidth: 0
        }}
        vectorEffect='non-scaling-stroke'
        transform='translate(24 18)'
      />
      <path
        d='m42.8 82.6 1.8.8-1.7 4c-.1.4-.5.6-.9.6h-4c-.4 0-.8-.2-.9-.6l-1.7-4 1.8-.8 1.5 3.4h2.7l1.4-3.4z'
        style={{
          strokeWidth: 0
        }}
        vectorEffect='non-scaling-stroke'
        transform='translate(24 18)'
      />
      <path
        d='m38.9 88-1.8 13.1-2-.3 1.9-14c.1-.5.5-.9 1-.9h4c.5 0 .9.4 1 .9l1.9 14-2 .3L41.1 88h-2.2zM41.6 80l6 4.8-1.2 1.6-7-5.6c-.5-.4-.5-1.2 0-1.6l10-8 1.2 1.6-9 7.2z'
        style={{
          strokeWidth: 0
        }}
        vectorEffect='non-scaling-stroke'
        transform='translate(24 18)'
      />
      <path
        d='m50.6 73.8-7.5 35.4-2-.4 7.9-37c.2-.7 1-1 1.6-.6l6 5c.2.2.4.5.4.8v9c0 .6-.4 1-1 1h-3.1l3.9 5.4c.2.3.2.8 0 1.1l-9.8 16-1.7-1L54.8 93l-4.6-6.5a1 1 0 0 1 .8-1.6h4v-7.5l-4.4-3.6zM38.4 80l-6 4.8 1.2 1.6 7-5.6c.5-.4.5-1.2 0-1.6l-10-8-1.2 1.6 9 7.2z'
        style={{
          strokeWidth: 0
        }}
        vectorEffect='non-scaling-stroke'
        transform='translate(24 18)'
      />
      <path
        d='m29.4 73.8 7.5 35.4 2-.4-7.9-37c-.2-.7-1-1-1.6-.6l-6 5c-.3.2-.4.5-.4.8v9c0 .6.4 1 1 1h3.1l-3.9 5.4c-.2.3-.2.8 0 1.1l9.8 16 1.7-1L25.2 93l4.6-6.5a1 1 0 0 0-.8-1.6h-4v-7.5l4.4-3.6z'
        style={{
          strokeWidth: 0
        }}
        vectorEffect='non-scaling-stroke'
        transform='translate(24 18)'
      />
      <path
        d='M72.3 101h-2l-.8-10c-.3-3.3-2.3-6.2-5.4-7.5l-8.5-3.7.8-1.8 8.5 3.7c3.7 1.6 6.2 5.1 6.6 9.2l.8 10.1zm-62.6 0h-2l.8-10.1c.3-4 2.9-7.6 6.6-9.2l8.5-3.7.8 1.8-8.5 3.7c-3 1.3-5.1 4.2-5.4 7.5l-.8 10zm38.6-27.9-1.9.7c-.3-.7-.4-1.4-.4-2.1V66h2v5.7c0 .5.1 1 .3 1.4zm-16.6 0c.2-.5.3-.9.3-1.4V66h2v5.7c0 .7-.1 1.5-.4 2.1l-1.9-.7zM63.4 40.4c-.5.3-1.1.2-1.4-.3-.3-.5-.2-1.1.3-1.4.7-.4 1.4-.7 2.2-.7 2.8 0 4.5 2.5 4.5 6.5 0 4.2-3.4 9.5-6.5 9.5-1 0-1.8-.4-2.3-1.2-.3-.4-.2-1.1.2-1.4.4-.3 1.1-.2 1.4.2.2.3.4.4.7.4 1.8 0 4.5-4.2 4.5-7.5 0-3.1-1-4.5-2.5-4.5-.3 0-.7.1-1.1.4zM18.2 51.6c.3-.4 1-.5 1.4-.2.4.3.5 1 .2 1.4-.6.7-1.4 1.2-2.3 1.2-3.1 0-6.5-5.3-6.5-9.5 0-4 1.7-6.5 4.5-6.5.7 0 1.5.2 2.2.7.5.3.6.9.3 1.4s-.9.6-1.4.3c-.4-.2-.8-.4-1.1-.4-1.5 0-2.5 1.4-2.5 4.5 0 3.3 2.7 7.5 4.5 7.5.3 0 .5-.1.7-.4z'
        style={{
          strokeWidth: 0
        }}
        vectorEffect='non-scaling-stroke'
        transform='translate(24 18)'
      />
      <path
        d='M17 38.5c16.6-2.3 26.6-7.6 30.1-15.9.3-.8 1.5-.8 1.8 0 3.2 7.5 8 12.5 14.3 15.1.3.1.6.4.6.8.1 1.1.2 2.3.2 3.5 0 14.3-10.7 26-24 26S16 56.3 16 42c0-.9 0-1.7.1-2.6.1-.5.4-.8.9-.9zm1 3.5c0 13.3 9.9 24 22 24s22-10.7 22-24c0-.9 0-1.8-.1-2.6-6-2.6-10.6-7.3-13.9-14-4.5 7.7-14.5 12.7-30 15V42z'
        style={{
          strokeWidth: 0
        }}
        vectorEffect='non-scaling-stroke'
        transform='translate(24 18)'
      />
    </g>
  </svg>
);

export default SvgMedicFemale;
