import { SVGProps } from 'react';

const SvgQuestionnaireFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M25.62 24.715a31.972 31.972 0 0 1-.07-3.792c.164-3.019-.017-6.071-.073-9.092-.013-.744-.035-1.49-.029-2.235a1.798 1.798 0 0 0-.436-1.361 2.37 2.37 0 0 0-1.733-.63l.001-.365.005-1.693a5.716 5.716 0 0 0-.024-1.355c-.258-1.038-1.462-.987-2.317-.924-.886.065-1.622.453-1.656 1.435a7.928 7.928 0 0 0 .132 1.095c.052.495.015 1.011.018 1.508l-.002.323c-2.617.164-5.239.09-7.859.033-.662-.015-1.323-.028-1.985-.034a6.173 6.173 0 0 0-1.627.06 1.93 1.93 0 0 0-1.352 1.405 6.118 6.118 0 0 0-.082 2.027c.06.926.069 1.86.075 2.789.017 2.711-.191 5.417-.251 8.126a37.947 37.947 0 0 0 .057 3.887 1.89 1.89 0 0 0 2.033 1.819c1.312.09 2.657.002 3.972-.002l4.121-.012 4.12-.012 1.999-.005a6.341 6.341 0 0 0 1.91-.075c1.182-.404 1.117-1.923 1.053-2.92ZM10.297 11.56q2.987.106 5.975.046a.75.75 0 0 1 0 1.5q-2.987.06-5.975-.046a.75.75 0 0 1 0-1.5Zm-.197 3.26a77.952 77.952 0 0 0 8.138.075c.967-.041.962 1.46 0 1.5a77.952 77.952 0 0 1-8.138-.075c-.96-.059-.964-1.559 0-1.5Zm.277 3.353q2.85.052 5.699-.109a.757.757 0 0 1 .75.75.767.767 0 0 1-.75.75q-2.847.161-5.7.11a.75.75 0 0 1 .001-1.5Zm10.14 5.527q-5.051.125-10.101-.098c-.963-.042-.965-1.542 0-1.5q5.047.223 10.1.098a.75.75 0 0 1 0 1.5Zm1.257-5.796a3.251 3.251 0 0 1-.273 1.463c-.065.145-.138.287-.206.431a5.11 5.11 0 0 1-.52-1.363 13.212 13.212 0 0 1 .006-2.789c.065-1.974.155-3.948.162-5.923.003-.832.04-1.69.036-2.542.266-.021.532-.033.8-.038l.001.098-.023 7.123c-.004 1.18.022 2.36.017 3.54Zm-.021-12.261q-.43.009-.859.045c-.028-.27-.051-.54-.102-.804a.309.309 0 0 1 0-.082c.076-.005.196-.036.265-.04.17-.01.54.067.682-.007.036.01.038-.01.06-.011a7.284 7.284 0 0 0-.046.899Z'
      fill='#009ebb'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgQuestionnaireFilled;
