import { SVGProps } from 'react';

const SvgPatients = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M25.905 24.846c0-.44.018-.877-.014-1.317a6.16 6.16 0 0 0-2.318-4.475 11.56 11.56 0 0 0-2.102-1.122c-.373-.2-.739-.408-1.093-.639a4.651 4.651 0 0 1-.71-.488c.114-.064.23-.126.342-.194a6.822 6.822 0 0 0 2.435-8.204 6.97 6.97 0 0 0-7.188-4.532C12.409 4.22 9.642 6.17 9.09 9.106a7.644 7.644 0 0 0 2.33 6.832 7.198 7.198 0 0 0 1.345.976 9.747 9.747 0 0 1-1.033.58c-.807.415-1.669.718-2.455 1.173a5.661 5.661 0 0 0-2.592 3.22 7.628 7.628 0 0 0-.28 4.55c.588 1.61 2.096 1.603 3.553 1.613q2.745.017 5.49.012 1.277-.003 2.554-.01c.771-.005 1.534.073 2.299.057.817-.017 1.635-.04 2.453-.057a3.876 3.876 0 0 0 2.257-.415 2.328 2.328 0 0 0 .962-1.692c.029-.369-.068-.729-.069-1.099ZM21.143 9.28a16.041 16.041 0 0 1-2.444-.097A9.998 9.998 0 0 0 20.27 7.57c.105.142.217.275.31.43a5.993 5.993 0 0 1 .563 1.279ZM14.18 5.646a5.316 5.316 0 0 1 5.073.854.987.987 0 0 0-.087.088 8.833 8.833 0 0 1-3.702 2.83 12.86 12.86 0 0 1-2.458.742 5.352 5.352 0 0 1-2.532.075 5.076 5.076 0 0 1 3.706-4.59Zm-3.521 6.16a7.489 7.489 0 0 0 2.398-.132 14.1 14.1 0 0 0 2.986-.883c.276-.117.536-.262.8-.399a17.445 17.445 0 0 0 4.581.377 5.173 5.173 0 0 1-2.171 4.547 5.467 5.467 0 0 1-6.202.032 6.306 6.306 0 0 1-2.393-3.542Zm4.603 5.973a6.73 6.73 0 0 0 3.036-.353 4.419 4.419 0 0 0 1.495 1.288 22.442 22.442 0 0 0-3.437 3.252 2.096 2.096 0 0 0-.169.187c-.111-.122-.315-.306-.347-.343q-.507-.585-1.012-1.172l-1.812-2.095a5.14 5.14 0 0 0 1.29-.965 6.485 6.485 0 0 0 .956.2Zm8.11 8.763c-.705.023-1.411.028-2.117.045-1.374.031-2.742-.037-4.124-.03q-2.332.01-4.665.005-1.133-.003-2.267-.01c-.576-.004-1.535.174-2.044-.156s-.468-1.107-.443-1.63a10.08 10.08 0 0 1 .264-1.926 4.531 4.531 0 0 1 2.659-3.192c.315-.154.647-.297.983-.442.005.006.006.013.011.02l2.363 2.733c.617.715 1.433 2.09 2.57 1.728a2.89 2.89 0 0 0 1.16-1.017q.533-.609 1.111-1.176a18.519 18.519 0 0 1 2.418-2.008 8.757 8.757 0 0 1 1.11.532 5.117 5.117 0 0 1 1.398 1.41c.813 1.258.52 2.637.675 4.029.092.83-.283 1.059-1.062 1.084Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgPatients;
