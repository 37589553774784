import { SVGProps } from 'react';

const SvgWeight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M26.026 7.146c-1.856-1.723-4.471-2.313-6.912-2.625a21.14 21.14 0 0 0-8.55.63C8.36 5.8 5.7 6.967 4.75 9.208c-1.026 2.42-.133 5.124.27 7.584a51.096 51.096 0 0 0 1.638 7.878 3.865 3.865 0 0 0 2.033 2.527 9.567 9.567 0 0 0 4.153.384c1.613-.074 3.22.056 4.834.01a42.264 42.264 0 0 0 4.836-.049c2.626-.515 3.373-3.449 3.784-5.713.49-2.7.868-5.407 1.222-8.127.308-2.37.404-4.793-1.494-6.556ZM16.47 5.852a19.877 19.877 0 0 1 4.578.493 6.775 6.775 0 0 1-1.667 1.554 5.945 5.945 0 0 1-2.656.765c.005-.389.022-.777.054-1.164a.565.565 0 0 0-.065-.289.56.56 0 0 0-.155-.241.75.75 0 0 0-1.06 0l-.118.152a.746.746 0 0 0-.102.378c-.032.386-.049.773-.053 1.16a4.528 4.528 0 0 1-2.05-.524 6.4 6.4 0 0 1-1.707-1.691 19.639 19.639 0 0 1 5-.593Zm9.736 6.143a73.263 73.263 0 0 1-1.089 7.894 27.66 27.66 0 0 1-.771 3.694c-.37 1.16-.945 2.26-2.23 2.513a24.9 24.9 0 0 1-3.714-.011c-1.326-.011-2.647-.013-3.974-.038-1.126-.02-2.25.108-3.376.075a2.936 2.936 0 0 1-2.528-.947 7.635 7.635 0 0 1-.836-2.464 56.988 56.988 0 0 1-.785-3.73c-.22-1.232-.4-2.472-.623-3.704a23.118 23.118 0 0 1-.5-3.294 4.447 4.447 0 0 1 .864-3.067A7.696 7.696 0 0 1 9.2 7.27c.254-.124.514-.236.778-.34a9.133 9.133 0 0 0 2.358 2.45 7.259 7.259 0 0 0 3.782.81 8.005 8.005 0 0 0 3.672-.806 9.43 9.43 0 0 0 2.808-2.566c.244.094.486.193.72.304a5.34 5.34 0 0 1 2.412 2.01 5.055 5.055 0 0 1 .475 2.863Z' />
      <path d='M18.337 11.283a3.717 3.717 0 0 0-1.339 3.21c.134 1.418.818 2.725.805 4.16-.055 1.323-.662 2.6-.48 3.93a3.02 3.02 0 0 0 1.952 2.504 2.634 2.634 0 0 0 3-.89 7.874 7.874 0 0 0 1.067-3.529c.467-2.466 1.844-4.913.62-7.384-.917-1.85-3.755-3.49-5.625-2Zm3.9 7.472c-.269.964-.414 1.954-.587 2.938-.13.746-.352 1.878-1.252 2.044-1.023.189-1.625-.84-1.617-1.722.011-1.125.475-2.225.522-3.361a9.346 9.346 0 0 0-.443-2.635 3.956 3.956 0 0 1-.206-2.733 1.513 1.513 0 0 1 1.56-1.048 3.224 3.224 0 0 1 2.145 1.29c1.207 1.615.352 3.523-.122 5.226ZM8.449 12.632a4.907 4.907 0 0 0-.935 3.102 19.996 19.996 0 0 0 .94 3.955c.477 2.024.505 5.698 3.47 5.557a2.947 2.947 0 0 0 2.666-2.196c.404-1.478-.332-2.923-.393-4.396A21.322 21.322 0 0 1 14.934 15a3.878 3.878 0 0 0-.788-3.224c-1.558-1.96-4.412-.844-5.696.856Zm3.795 11.04c-1.98.695-2.028-2.94-2.27-4.056-.386-1.777-1.48-3.68-.678-5.499.519-1.175 2.628-2.765 3.706-1.385a3.007 3.007 0 0 1 .253 2.877 11.107 11.107 0 0 0-.557 3.045c.042 1.027.356 1.982.494 2.992a1.765 1.765 0 0 1-.949 2.025Z' />
    </g>
  </svg>
);

export default SvgWeight;
