import { SVGProps } from 'react';

const SvgVisibilityOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    overflow='visible'
    preserveAspectRatio='none'
    viewBox='0 0 26 26'
    {...props}
  >
    <path
      d='M13 7a9.77 9.77 0 0 1 8.82 5.5 9.647 9.647 0 0 1-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C22.27 8.11 18 5 13 5c-1.27 0-2.49.2-3.64.57l1.65 1.65C11.66 7.09 12.32 7 13 7zm-1.07 1.14L14 10.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C17.5 10.01 15.48 8 13 8c-.37 0-.72.05-1.07.14zM3.01 4.87l2.68 2.68A11.738 11.738 0 0 0 2 12.5C3.73 16.89 8 20 13 20c1.52 0 2.98-.29 4.32-.82l3.42 3.42 1.41-1.41L4.42 3.45 3.01 4.87zm7.5 7.5 2.61 2.61c-.04.01-.08.02-.12.02a2.5 2.5 0 0 1-2.5-2.5c0-.05.01-.08.01-.13zm-3.4-3.4 1.75 1.75a4.6 4.6 0 0 0-.36 1.78 4.507 4.507 0 0 0 6.27 4.14l.98.98c-.88.24-1.8.38-2.75.38a9.77 9.77 0 0 1-8.82-5.5c.7-1.43 1.72-2.61 2.93-3.53z'
      vectorEffect='non-scaling-stroke'
    />
  </svg>
);

export default SvgVisibilityOff;
