import { SVGProps } from 'react';

const SvgSynchronize = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M27.656 12.962a11.198 11.198 0 0 0-6.217-6.9 10.32 10.32 0 0 0-9.163.69 10.136 10.136 0 0 0-5.174 8.489c-.235-.015-.471-.025-.706-.046-.725-.065-1.85-.419-2.307.374a1.369 1.369 0 0 0-.128.957 2.257 2.257 0 0 0 .385.896c.165.253.404.496.558.723a3.434 3.434 0 0 0 .29.43.12.12 0 0 0 .015.014l.008.012c.04.061.085.12.126.182.658.98 1.477 2.582 2.927 2.152.023-.007.036-.023.058-.031a.828.828 0 0 0 .099-.053.752.752 0 0 0 .146-.092c.009-.007.02-.01.028-.017a12.332 12.332 0 0 0 2.773-3.367 4.937 4.937 0 0 0 .405-1.01.987.987 0 0 0-.692-1.183 5.06 5.06 0 0 0-2.084.075c-.134.01-.27.002-.404.007.051-5.529 6.026-9.645 11.268-8.174a9.58 9.58 0 0 1 5.98 5.3 9.656 9.656 0 0 1-.176 7.745 8.09 8.09 0 0 1-2.637 3.386 10.76 10.76 0 0 1-5.455 1.672c-.96.06-.966 1.56 0 1.5a13.162 13.162 0 0 0 5.642-1.516 8.938 8.938 0 0 0 3.196-3.224 11.514 11.514 0 0 0 1.24-8.991ZM9.788 16.667c.118-.017.204-.032.288-.044a9.97 9.97 0 0 1-2.32 2.858c-.03-.02-.088-.064-.102-.072a2.89 2.89 0 0 1-.254-.256l-.079-.097a6.328 6.328 0 0 1-.133-.187c-.178-.258-.34-.526-.513-.788a6.31 6.31 0 0 0-.216-.308c-.06-.08-.139-.158-.192-.244a1.848 1.848 0 0 0-.094-.178c-.177-.253-.406-.5-.553-.732l.068.007c.275.028.55.056.826.08.494.043.989.074 1.485.08a11.649 11.649 0 0 0 1.789-.12Z' />
      <path d='m16.01 15.187-.002.004a.268.268 0 0 0 .04-.079.951.951 0 0 1-.038.075Z' />
      <path d='M18.58 19.21a3.026 3.026 0 0 0 1.65-.975 3.144 3.144 0 0 0 .688-2.224 3.019 3.019 0 0 0-2.504-3.242 3.316 3.316 0 0 0-3.821 1.946 2.917 2.917 0 0 0-.03.086l-.065.152a4.256 4.256 0 0 0-.089.234 3.144 3.144 0 0 0-.125.488 3.32 3.32 0 0 0-.007 1.154 2.742 2.742 0 0 0 .865 1.536 3.912 3.912 0 0 0 3.439.845Zm-4.014-4.418.01-.024.018-.042Zm2.272 2.872-.125-.046a.262.262 0 0 1-.03-.014 3.344 3.344 0 0 1-.296-.163c-.045-.028-.087-.06-.13-.088l-.002-.003a2.854 2.854 0 0 1-.247-.243l-.004-.004c-.02-.04-.067-.093-.084-.122a2.094 2.094 0 0 1-.095-.172c-.025-.09-.06-.177-.08-.27l-.007-.033-.003.012c.003-.003 0-.017 0-.024l-.009-.045c-.015-.069 0-.055.01-.022-.005-.04-.012-.082-.013-.102-.001-.06 0-.12.004-.18l.008-.102c.004-.067-.009-.03-.013.015a1.71 1.71 0 0 1 .104-.381q.029-.093.063-.184l.036-.094c-.085.227.018-.04.023-.053.022-.052.042-.103.06-.155l-.006.01.008-.014.01-.026a.104.104 0 0 1 .028-.049c.02-.042.041-.085.065-.125.016-.027.053-.096.089-.145l.007-.008c.021-.021.04-.046.06-.069q.076-.083.16-.159c.019-.017.04-.033.058-.051.113-.068.22-.139.338-.2.008-.004.02-.008.032-.015.074-.024.146-.052.22-.073q.096-.027.194-.047l.067-.012c.132-.008.261-.021.394-.018.07.002.142.01.213.014l.092.016a3.506 3.506 0 0 1 .443.125l.048.019.013.008c.07.036.14.072.208.113.054.033.104.07.157.105.028.025.057.048.084.073.052.05.099.102.148.153l.033.036.007.015a2.357 2.357 0 0 1 .156.269l.005.017c.024.072.047.144.065.218.007.03.012.06.02.09.003.014.005.01.008.02a1.5 1.5 0 0 1-.013.53 3.343 3.343 0 0 1 .001.42l-.007.082c-.012.059-.022.118-.036.177q-.02.077-.044.153l-.016.045.01-.01-.01.012-.015.044c-.028.087-.029.032-.01-.014-.046.075-.112.228-.133.262l-.01.018c-.032.035-.061.072-.094.106q-.054.057-.113.11l-.03.025c-.081.053-.16.108-.246.154-.038.021-.08.039-.118.059l-.056.02a2.762 2.762 0 0 1-.309.089l-.14.028-.01.003a.048.048 0 0 0-.006 0 4.678 4.678 0 0 1-.683-.004l-.01-.002a4.706 4.706 0 0 1-.161-.032 3.23 3.23 0 0 1-.335-.092Z' />
      <path d='M19.316 16.891v-.003a.08.08 0 0 0-.024.033.127.127 0 0 1 .024-.03ZM15.736 16.427a.226.226 0 0 1 0 .068l.001.011a.126.126 0 0 0 0-.079Z' />
    </g>
  </svg>
);

export default SvgSynchronize;
