import { SVGProps } from 'react';

const SvgRemove = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M28.194 13.74c-.254-.996-1.632-.903-2.424-.905H13.618c-1.983 0-3.964-.043-5.946-.05a10.79 10.79 0 0 0-2.711.066c-.905.245-1.117 1.205-1.176 2.024a21.113 21.113 0 0 0 .105 2.83c.058.913.43 1.445 1.394 1.51 2.019.133 4.091-.001 6.114-.004l12.388-.015a24.937 24.937 0 0 0 3.233-.063c1.207-.161 1.13-1.173 1.15-2.15l.033-1.698a6.09 6.09 0 0 0-.008-1.545Zm-2.054 3.937c-1.609.046-3.221.02-4.83.022l-10.159.012-4.955.006c-.207 0-.413.002-.62.001h-.123a.431.431 0 0 0-.085-.01c-.023-.34-.032-.927-.034-.971a14.888 14.888 0 0 1 .028-2.309c.028-.298-.044-.083.165-.14a2.342 2.342 0 0 1 .476-.005c.398-.002.795-.001 1.192 0 .872.004 1.742.009 2.612.031 1.588.042 3.178.021 4.766.021H25.77a5.337 5.337 0 0 1 .975.018c.002.235-.034.5-.038.69l-.024 1.212-.024 1.212-.003.186c-.02.004-.03.002-.052.008q-.232.01-.464.016Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgRemove;
