import { Flex } from 'components/Templates';
import styled, { css } from 'styled-components';
import styles from 'styles';

export interface IMultiOptionContainerProps {
  join?: boolean;
}

export const MultiOptionContainer = styled(Flex)<IMultiOptionContainerProps>`
  ${({ join }: IMultiOptionContainerProps) =>
    join
      ? css`
          > * {
            &:first-child {
              > div {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }

            &:not(:first-child) {
              &:not(:last-child) {
                > div {
                  border-radius: 0;
                }
              }
            }

            &:last-child {
              > div {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }

            &:not(:last-child) {
              border-right: 2px solid ${styles.colors.brand.lightest};
            }
          }
        `
      : css`
          > * {
            width: 100%;

            span {
              margin-left: 0;
            }

            &:not(:last-child) {
              margin-right: 0.25rem;
            }
          }
        `}

  > * {
    > div {
      > span {
        font-weight: 600;
      }
    }
  }
`;
