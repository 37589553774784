import { SVGProps } from 'react';

const SvgTasksFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M26.406 6.521A7.593 7.593 0 0 0 24.87 8a17.787 17.787 0 0 1-2.08 1.856c-.328.272-.643.56-.957.848.027-.544.044-1.089.065-1.63.037-.907.128-1.806.175-2.71a2.244 2.244 0 0 0-.263-1.385 1.622 1.622 0 0 0-1.53-.533c-.521.03-1.032.143-1.55.202-.808.09-1.635.1-2.447.145-1.786.098-3.574.177-5.364.204q-1.222.018-2.445 0c-.97-.014-1.962-.134-2.931-.11a1.042 1.042 0 0 0-1.076 1.157c-.031.941.02 1.894.05 2.835.06 1.808.164 3.615.27 5.42A105.037 105.037 0 0 1 5.02 26.61a.764.764 0 0 0 .75.75 76.584 76.584 0 0 0 14.578-.334c.823-.098 2.296.029 2.625-.951a4.67 4.67 0 0 0-.016-1.665q-.049-.769-.107-1.536c-.16-2.09-.375-4.177-.58-6.264.68-.584 1.37-1.155 2.095-1.682a22.134 22.134 0 0 0 2.343-1.72 3.326 3.326 0 0 0 .724-2.423c.082-1.204.134-2.409.102-3.616a.756.756 0 0 0-1.128-.648Zm-.428 3.543c-.023.401-.051.803-.08 1.204s0 .657-.302.927a20.877 20.877 0 0 1-1.825 1.322 34.924 34.924 0 0 0-2.046 1.589c-.118.099-.23.205-.349.305-1.214 1.035-2.382 2.123-3.57 3.188-.609.545-1.246 1.058-1.845 1.613-.057.053-.456.36-.642.549a4.761 4.761 0 0 0-.385-.31 43.27 43.27 0 0 1-1.966-1.525 8.956 8.956 0 0 0-.796-.603c-.378-.251-.854-.34-.956-.8a12.498 12.498 0 0 1-.1-2.572 42.957 42.957 0 0 0-.016-1.1c.182.202.367.401.548.606.594.67 1.112 1.406 1.712 2.071a3.893 3.893 0 0 0 1.6 1.228c.737.199 1.42-.324 1.984-.725a43.977 43.977 0 0 0 4.544-3.948c.75-.707 1.49-1.435 2.28-2.098A19.052 19.052 0 0 0 25.93 9.06l.093-.108c-.011.37-.024.74-.045 1.11Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgTasksFilled;
