import IOption from 'interfaces/IOption';
import IProps from 'interfaces/IProps';
import { FC, FormEvent, PropsWithChildren } from 'react';
import styles from 'styles';
import { rgba } from 'utils/colors';
import { OptionTag, OptionWrapper } from './Option.styles';

export enum OptionType {
  TAG = 'tag',
  RADIO_BUTTON = 'radio-button'
}

export interface OptionProps extends PropsWithChildren<IProps> {
  type: 'radio' | 'checkbox';
  identifier?: string;
  option: IOption;
  checked: boolean;
  isBig?: boolean;
  isJoined?: boolean;
  onCheck: () => void;
  onUncheck: () => void;
}

const Option: FC<OptionProps> = (props: OptionProps): JSX.Element => {
  const {
    dataTestId,
    className,
    type,
    identifier,
    option,
    checked,
    isBig,
    isJoined,
    onCheck,
    onUncheck
  } = props;
  const { id, icon, name } = option;

  const changeHandler = (event: FormEvent<HTMLInputElement>): void => {
    if (event.currentTarget.checked) onCheck();
    else onUncheck();
  };

  return (
    <OptionWrapper className={className}>
      <input
        data-testid={dataTestId}
        type={type}
        name={type === 'radio' ? identifier : id}
        checked={checked}
        value={id}
        onChange={changeHandler}
      />
      <OptionTag
        iconClassName='option-icon'
        icon={icon}
        label={name}
        color={
          checked ? styles.colors.brand.lightest : styles.colors.brand.main
        }
        background={
          checked
            ? styles.colors.brand.main
            : rgba(styles.colors.brand.main, 0.1)
        }
        active={checked}
        isBig={isBig}
        isJoined={isJoined}
      />
    </OptionWrapper>
  );
};

export default Option;
