import IOption, { IOptionGroup } from 'interfaces/IOption';
import IProps from 'interfaces/IProps';
import { FC, PropsWithChildren } from 'react';
import {
  InputIcon,
  InputPlaceholder,
  InputStateMessage,
  InputWrapper
} from '../Input.styles';
import { IInputProps } from '../Input.types';
import Tag from './Tag';
import useTagsWithOptionsInput from './TagsWithOptionsInput.logic';
import {
  Tags,
  TagsWithOptionsInputContainer
} from './TagsWithOptionsInput.styles';
import TagsInputOptionsPopup from './TagsInputOptionsPopup';
import Button from 'components/Button/Button';

export interface ITagsWithOptionsInputProps
  extends PropsWithChildren<
    Omit<IInputProps, 'prefix' | 'suffix' | 'help'> & IProps
  > {
  tags: IOptionGroup[] | IOption[];
}

const TagsWithOptionsInput: FC<ITagsWithOptionsInputProps> = (
  props: ITagsWithOptionsInputProps
): JSX.Element => {
  const { dataTestId, className, style, name, icon, placeholder, tags } = props;
  const {
    input,
    value,
    selectedTags,
    showOptions,
    isFocused,
    toggleShowOptions,
    addTagHandler,
    removeTagHandler,
    closeOptionsPopupHandler
  } = useTagsWithOptionsInput(props);
  const { success, error } = input;
  const inputValue = value as string[] | undefined;
  const hasValue = selectedTags && selectedTags.length > 0;

  return (
    <InputWrapper className={className} style={style}>
      {icon && <InputIcon src={icon} />}
      <div>
        {success && (
          <InputStateMessage
            data-testid={`${dataTestId}_success`}
            type='success'
          >
            {success}
          </InputStateMessage>
        )}
        {error && (
          <InputStateMessage data-testid={`${dataTestId}_error`} type='alert'>
            {error}
          </InputStateMessage>
        )}
        <TagsWithOptionsInputContainer
          isFocused={isFocused}
          hasValue={hasValue}
          state={success ? 'success' : error ? 'alert' : undefined}
        >
          <Tags>
            {!hasValue && placeholder && (
              <InputPlaceholder>{placeholder}</InputPlaceholder>
            )}
            {selectedTags.map((tag: IOption) => (
              <Tag key={tag.id} tag={tag} onRemove={removeTagHandler} />
            ))}
          </Tags>
          <Button
            style={{ marginLeft: 'auto' }}
            type='secondary'
            active={showOptions}
            icon='plus'
            onClick={toggleShowOptions}
          />
          <TagsInputOptionsPopup
            dataTestId={`${dataTestId}_popup`}
            show={showOptions}
            identifier={name}
            tags={tags}
            selectedTags={inputValue || []}
            onAddTag={addTagHandler}
            onRemoveTag={removeTagHandler}
            onClose={closeOptionsPopupHandler}
          />
        </TagsWithOptionsInputContainer>
      </div>
    </InputWrapper>
  );
};

TagsWithOptionsInput.displayName = 'TagsWithOptionsInput';

export default TagsWithOptionsInput;
