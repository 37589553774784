import IProps from 'interfaces/IProps';
import { FC, PropsWithChildren } from 'react';
import { InputWrapper, InputIcon, InputStateMessage } from '../Input.styles';
import { IInputProps } from '../Input.types';
import Tag from './Tag';
import useTagsInput from './TagsInput.logic';
import { TagsInputContainer } from './TagsInput.styles';

export interface ITagsInputProps
  extends PropsWithChildren<
    Omit<IInputProps, 'prefix' | 'suffix' | 'help'> & IProps
  > {}

const TagsInput: FC<ITagsInputProps> = (
  props: ITagsInputProps
): JSX.Element => {
  const { dataTestId, className, style, icon, placeholder } = props;
  const {
    input,
    value,
    isFocused,
    inputRef,
    focusInputHandler,
    keyUpHandler,
    focusHandler,
    blurHandler,
    removeTagHandler
  } = useTagsInput(props);
  const { success, error } = input;
  const inputValue = value as string[] | undefined;

  return (
    <InputWrapper className={className} style={style}>
      {icon && <InputIcon src={icon} />}
      <div>
        {success && (
          <InputStateMessage
            data-testid={`${dataTestId}_success`}
            type='success'
          >
            {success}
          </InputStateMessage>
        )}
        {error && (
          <InputStateMessage data-testid={`${dataTestId}_error`} type='alert'>
            {error}
          </InputStateMessage>
        )}
        <TagsInputContainer
          hasValues={!!inputValue && inputValue.length > 0}
          isFocused={isFocused}
          state={success ? 'success' : error ? 'alert' : undefined}
          onClick={focusInputHandler}
        >
          {inputValue?.map((tag: string, index: number) => (
            <Tag
              key={index}
              tag={{ id: tag, name: tag }}
              onRemove={removeTagHandler}
            />
          ))}
          <input
            type='text'
            placeholder={
              placeholder && (!inputValue || inputValue.length === 0)
                ? placeholder
                : undefined
            }
            onKeyUp={keyUpHandler}
            onFocus={focusHandler}
            onBlur={blurHandler}
            ref={inputRef}
          />
        </TagsInputContainer>
      </div>
    </InputWrapper>
  );
};

TagsInput.displayName = 'TagsInput';

export default TagsInput;
