import { SVGProps } from 'react';

const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M25.373 8.57c-3.935 4.179-8.043 8.182-12.136 12.204l-1.033 1.014a3.855 3.855 0 0 0-.312.306c-.166.206-.174.02.105.04l.53.22a55.136 55.136 0 0 1-5.9-5.649c-.669-.699-1.728.363-1.06 1.06 1.053 1.103 2.1 2.213 3.202 3.267a20.083 20.083 0 0 0 2.773 2.441c.923.598 1.977-.874 2.564-1.45l5.811-5.713q3.321-3.277 6.516-6.679c.661-.702-.397-1.765-1.06-1.06Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgCheck;
