import IOption, { IOptionGroup } from 'interfaces/IOption';
import { useContext, useState } from 'react';
import { ActionTypes, FormContext } from 'store/form-context';
import { InputTypes } from 'utils/form';
import useTagsInput, { IUseTagsInput } from './TagsInput.logic';
import { ITagsWithOptionsInputProps } from './TagsWithOptionsInput';

export interface IUseTagsWithOptionsInput
  extends Omit<
    IUseTagsInput,
    | 'focusHandler'
    | 'blurHandler'
    | 'addTagHandler'
    | 'inputRef'
    | 'focusInputHandler'
    | 'keyUpHandler'
  > {
  selectedTags: IOption[];
  showOptions: boolean;
  toggleShowOptions: () => void;
  addTagHandler: (tagId: string, groupIndex?: number) => void;
  removeTagHandler: (tagId: string) => void;
  closeOptionsPopupHandler: () => void;
}

const useTagsWithOptionsInput = (
  props: ITagsWithOptionsInputProps
): IUseTagsWithOptionsInput => {
  const { name, tags } = props;
  const { dispatchInputs } = useContext(FormContext);
  const { input, value, isFocused, focusInputHandler, removeTagHandler } =
    useTagsInput(props);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  if (input?.type !== InputTypes.TAGS)
    throw new Error('Tags Input requires the type to be TAGS.');

  const getSelectedTags = (): IOption[] => {
    if (tags.length === 0) return [];

    const value = input?.value as string[] | undefined;

    if ('options' in tags[0]) {
      return (tags as IOptionGroup[])
        .map((tagsGroup: IOptionGroup) =>
          tagsGroup.options.filter((tag: IOption) => value?.includes(tag.id))
        )
        .reduce((prev: IOption[], next: IOption[]) => prev.concat(next));
    } else {
      return (tags as IOption[]).filter((tag: IOption) =>
        value?.includes(tag.id)
      );
    }
  };

  const toggleShowOptions = (): void => {
    setShowOptions((prevState: boolean) => {
      const newState = !prevState;

      if (newState) focusInputHandler();

      return newState;
    });
  };

  const addTagHandler = (tagId: string, groupIndex?: number): void => {
    const value = input.value as string[];

    if (groupIndex !== undefined) {
      (tags as IOptionGroup[])[groupIndex].options.forEach((tag: IOption) => {
        if (value.includes(tag.id)) value.splice(value.indexOf(tag.id), 1);
      });
    }

    value.push(tagId);

    dispatchInputs({
      type: ActionTypes.UPDATE_VALUE,
      attribute: name,
      value
    });
  };

  const closeOptionsPopupHandler = (): void => {
    setShowOptions(false);
  };

  return {
    input,
    value,
    selectedTags: getSelectedTags(),
    showOptions,
    isFocused,
    toggleShowOptions,
    addTagHandler,
    removeTagHandler,
    closeOptionsPopupHandler
  };
};

export default useTagsWithOptionsInput;
