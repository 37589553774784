import styled from 'styled-components';
import styles from 'styles';
import { getColumnsWidth, getGutter } from 'utils/grid';
import { rgba } from 'utils/colors';

export interface ILeftContentWrapperProps {}

export const LeftContentWrapper = styled.div<ILeftContentWrapperProps>`
  position: relative;
  bottom: ${getGutter()};
  width: ${getColumnsWidth({ nrColumns: 4 })};
  min-height: 100%;
  padding: ${getGutter()};
  padding-left: 0;
  border-right: 1px solid ${rgba(styles.colors.brand.dark, 0.15)};
  margin-right: ${getGutter()};
`;
