import { SVGProps } from 'react';

const SvgVeryLow = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M25.628 16.59c-.422-.408-.869-.79-1.292-1.197-.64-.615-1.434-1.247-2.31-.625a6.388 6.388 0 0 0-.87.902l-1.307 1.395c-.776.83-1.588 1.638-2.343 2.485a7.803 7.803 0 0 1-.691.769c-.155.133-.323.24-.466.388a.641.641 0 0 1-.067.057.768.768 0 0 1-.096-.054 25.944 25.944 0 0 1-2.258-2.2l-2.521-2.463c-.647-.632-1.487-1.853-2.514-1.257a21.114 21.114 0 0 0-2.556 2.032c-.364.297-.768.58-.83 1.084a1.555 1.555 0 0 0 .596 1.242q1.196 1.275 2.405 2.539c1.546 1.608 3.21 3.066 4.867 4.556.492.443.983.888 1.479 1.327.313.278.74.798 1.165.902a.71.71 0 0 0 .429-.03c.018-.005.035-.016.052-.022a.686.686 0 0 0 .145-.082.725.725 0 0 0 .104-.059 65.168 65.168 0 0 0 4.946-4.813c.683-.73 1.34-1.488 2.03-2.211q.398-.417.81-.822l.324-.314.107-.1a.626.626 0 0 0 .048-.05c.62-.517 1.682-1.344 1.45-2.265a2.55 2.55 0 0 0-.835-1.114Zm-.645 1.293c-.002-.018 0-.015 0 0Zm-15.471-1.71c.098-.096.058-.049 0 0Zm14.49 2.697c-1.216 1.08-2.26 2.35-3.368 3.535a67.346 67.346 0 0 1-4.442 4.348l-.278-.243q-.6-.533-1.195-1.072c-1.14-1.028-2.313-2.02-3.423-3.08a62.657 62.657 0 0 1-2.13-2.154q-.482-.507-.96-1.017l-.48-.509a5.125 5.125 0 0 0-.553-.613c.163-.135.328-.267.491-.401.415-.341.828-.685 1.248-1.02.173-.138.377-.266.54-.418a2.716 2.716 0 0 0 .211.214c.395.392.797.778 1.195 1.167 1.403 1.371 2.755 2.847 4.231 4.138.626.548 1.395.92 2.078.279.504-.473.995-.948 1.458-1.46 1.345-1.486 2.773-2.897 4.1-4.399.034-.038.073-.078.107-.118a1.783 1.783 0 0 1 .287.25c.388.38.797.737 1.193 1.11a4.808 4.808 0 0 0 .507.489c-.003-.002.044.042.094.084a.227.227 0 0 1-.023.024c-.321.25-.582.595-.887.866Z' />
      <path d='M8.508 10.687c1.546 1.608 3.21 3.066 4.867 4.556.492.443.983.887 1.479 1.327.312.278.74.798 1.165.902a.71.71 0 0 0 .428-.03c.018-.006.035-.016.053-.022a.686.686 0 0 0 .145-.082.725.725 0 0 0 .104-.059 65.168 65.168 0 0 0 4.946-4.813c.683-.73 1.339-1.488 2.03-2.211q.398-.418.81-.822l.324-.314.107-.1a.626.626 0 0 0 .048-.05c.62-.517 1.681-1.344 1.45-2.265a2.55 2.55 0 0 0-.835-1.114c-.423-.408-.87-.79-1.293-1.197-.64-.615-1.434-1.247-2.31-.625a6.388 6.388 0 0 0-.87.902l-1.307 1.394c-.777.83-1.589 1.639-2.343 2.486a7.803 7.803 0 0 1-.692.768c-.155.134-.322.24-.466.389a.641.641 0 0 1-.066.057.768.768 0 0 1-.096-.054 25.944 25.944 0 0 1-2.258-2.2l-2.521-2.463C10.76 4.415 9.92 3.194 8.893 3.79a21.114 21.114 0 0 0-2.556 2.032c-.364.297-.768.58-.83 1.084a1.555 1.555 0 0 0 .596 1.242Q7.3 9.423 8.508 10.687Zm16.475-3.805c-.002-.018 0-.015 0 0ZM9.512 5.172c.098-.095.058-.048 0 0Zm-.6.472c.173-.138.377-.266.539-.418a2.716 2.716 0 0 0 .212.214c.395.393.796.779 1.195 1.168 1.403 1.37 2.755 2.847 4.231 4.138.626.547 1.395.92 2.078.278.504-.473.994-.947 1.458-1.46 1.345-1.486 2.773-2.897 4.1-4.398.034-.038.073-.079.107-.12a1.783 1.783 0 0 1 .286.25c.39.38.798.739 1.194 1.11a4.808 4.808 0 0 0 .507.49c-.003 0 .044.042.094.084a.227.227 0 0 1-.024.025c-.32.25-.582.594-.887.865-1.216 1.08-2.26 2.35-3.369 3.535a67.346 67.346 0 0 1-4.442 4.349l-.277-.244q-.6-.533-1.195-1.072c-1.14-1.028-2.313-2.02-3.424-3.08a62.657 62.657 0 0 1-2.13-2.154q-.482-.507-.96-1.017l-.48-.509a5.125 5.125 0 0 0-.552-.613c.163-.135.328-.267.491-.4.415-.342.828-.685 1.248-1.02Z' />
    </g>
  </svg>
);

export default SvgVeryLow;
