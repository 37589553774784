import { SVGProps } from 'react';

const SvgHomeFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M13.1 4.337c-1.34.008-2.677.149-4.017.184l-1.952.05a7.6 7.6 0 0 0-1.824.085 1.235 1.235 0 0 0-.819 1.222c-.063.692-.066 1.397-.065 2.09.002 1.29.078 2.578.208 3.86.11 1.08.01 2.722 1.32 3.065a9.188 9.188 0 0 0 1.698.174c.677.045 1.356.071 2.035.073a21.846 21.846 0 0 0 4.068-.319c1.434-.27 1.18-1.737 1.201-2.861l.087-4.582a4.138 4.138 0 0 0-.276-2.151 1.776 1.776 0 0 0-1.664-.89ZM18.473 14.894a9.187 9.187 0 0 0 1.697.174c.678.045 1.357.071 2.036.073a21.848 21.848 0 0 0 4.068-.319c1.434-.27 1.18-1.737 1.201-2.861l.087-4.582a4.138 4.138 0 0 0-.276-2.151 1.776 1.776 0 0 0-1.664-.89c-1.34.007-2.677.148-4.017.183l-1.952.05a7.6 7.6 0 0 0-1.824.085 1.235 1.235 0 0 0-.819 1.223c-.063.692-.067 1.396-.065 2.09.002 1.289.078 2.578.208 3.86.11 1.08.01 2.722 1.32 3.065ZM13.1 16.86c-1.34.007-2.677.148-4.017.183l-1.952.05a7.595 7.595 0 0 0-1.824.085 1.235 1.235 0 0 0-.819 1.223c-.063.692-.066 1.396-.065 2.09.002 1.289.078 2.578.208 3.86.11 1.079.01 2.721 1.32 3.065a9.18 9.18 0 0 0 1.698.174c.677.045 1.356.07 2.035.072a21.837 21.837 0 0 0 4.068-.318c1.434-.27 1.18-1.738 1.201-2.862l.087-4.582a4.138 4.138 0 0 0-.276-2.151 1.776 1.776 0 0 0-1.664-.89ZM25.622 16.86c-1.34.007-2.677.148-4.017.183l-1.952.05a7.595 7.595 0 0 0-1.824.085 1.235 1.235 0 0 0-.819 1.223c-.063.692-.067 1.396-.065 2.09.002 1.289.078 2.578.208 3.86.11 1.079.01 2.721 1.32 3.065a9.178 9.178 0 0 0 1.697.174c.678.045 1.357.07 2.036.072a21.84 21.84 0 0 0 4.068-.318c1.434-.27 1.18-1.738 1.201-2.862l.087-4.582a4.138 4.138 0 0 0-.276-2.151 1.776 1.776 0 0 0-1.664-.89Z' />
    </g>
  </svg>
);

export default SvgHomeFilled;
