import { SVGProps } from 'react';

const SvgLow = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M26.464 11.953a2.55 2.55 0 0 0-.836-1.115c-.422-.408-.869-.79-1.292-1.197-.64-.615-1.434-1.246-2.31-.625a6.394 6.394 0 0 0-.87.902l-1.307 1.395c-.776.83-1.588 1.638-2.343 2.486a7.813 7.813 0 0 1-.691.768c-.155.133-.323.24-.466.388a.628.628 0 0 1-.067.057.77.77 0 0 1-.096-.054 25.915 25.915 0 0 1-2.258-2.2l-2.52-2.463c-.648-.631-1.488-1.852-2.515-1.257a21.115 21.115 0 0 0-2.556 2.033c-.364.297-.768.58-.83 1.084a1.555 1.555 0 0 0 .596 1.241q1.196 1.276 2.405 2.54c1.546 1.608 3.21 3.066 4.867 4.556.492.443.983.887 1.479 1.327.313.278.74.798 1.165.901a.71.71 0 0 0 .429-.03c.018-.005.035-.015.052-.022a.682.682 0 0 0 .145-.081.714.714 0 0 0 .104-.06 65.168 65.168 0 0 0 4.946-4.812c.683-.73 1.34-1.488 2.03-2.211q.398-.418.81-.822c.107-.106.217-.209.324-.314.035-.035.071-.067.107-.101a.751.751 0 0 0 .048-.049c.62-.518 1.682-1.345 1.45-2.265Zm-1.481.178c-.002-.018 0-.015 0 0ZM9.512 10.42c.098-.096.058-.048 0 0Zm14.49 2.698c-1.216 1.08-2.26 2.35-3.368 3.535a67.32 67.32 0 0 1-4.443 4.348l-.277-.244q-.6-.533-1.195-1.072c-1.14-1.027-2.313-2.02-3.424-3.08a62.748 62.748 0 0 1-2.129-2.153q-.482-.507-.96-1.017l-.48-.51a5.125 5.125 0 0 0-.553-.612c.163-.135.328-.267.491-.401.415-.341.828-.685 1.248-1.02.173-.138.377-.266.54-.418a2.733 2.733 0 0 0 .211.214c.395.392.797.778 1.195 1.167 1.403 1.371 2.755 2.847 4.231 4.138.626.548 1.395.92 2.078.279.504-.473.995-.948 1.458-1.46 1.345-1.486 2.773-2.897 4.1-4.399.034-.038.073-.078.107-.118a1.787 1.787 0 0 1 .287.25c.388.38.797.738 1.193 1.11a4.808 4.808 0 0 0 .507.49l.094.083a.237.237 0 0 1-.023.024c-.321.25-.582.595-.887.866Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgLow;
