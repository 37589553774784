import { SVGProps } from 'react';

const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M27.446 26.749c-1.84-2.265-4.156-4.073-6.108-6.232a10.983 10.983 0 0 0 2.506-5.884 9.291 9.291 0 0 0-5.669-9.804 10.708 10.708 0 0 0-1.716-.522 10.125 10.125 0 0 0-6.41.432A9.23 9.23 0 0 0 5.945 8.39a10.764 10.764 0 0 0-.361 10.691c2.129 3.757 7.033 5.37 11.125 4.356a8.692 8.692 0 0 0 3.57-1.858c1.952 2.158 4.267 3.966 6.106 6.23a.755.755 0 0 0 1.061 0 .767.767 0 0 0 0-1.06Zm-9.97-5.144A8.886 8.886 0 0 1 7.44 19.18a8.966 8.966 0 0 1-.792-8.912 8.185 8.185 0 0 1 3.46-3.893 8.32 8.32 0 0 1 5.953-.623 8.036 8.036 0 0 1 4.77 2.836 8.046 8.046 0 0 1 1.575 4.746 8.811 8.811 0 0 1-4.929 8.27Z' />
      <path d='M13.654 7.498a.762.762 0 0 0-1.06 0l-3 3.14a.75.75 0 0 0 1.06 1.06l3-3.14a.76.76 0 0 0 0-1.06ZM17.436 7.998a.757.757 0 0 0-1.06 0q-2.456 2.727-5.16 5.213a.75.75 0 0 0 1.06 1.06q2.702-2.483 5.16-5.213a.767.767 0 0 0 0-1.06ZM18.367 10.022q-1.269 1.435-2.617 2.795a.75.75 0 0 0 1.06 1.06q1.349-1.36 2.618-2.794a.75.75 0 0 0-1.06-1.06Z' />
    </g>
  </svg>
);

export default SvgSearch;
