import Icon from 'components/Icon/Icon';
import { Flex, IFlexProps } from 'components/Templates';
import styled, { css } from 'styled-components';
import styles from 'styles';
import { transition } from 'utils/styles';

export interface ITagWrapperProps {
  isOnlyLabel: boolean;
  background?: string;
}

export const TagWrapper = styled(Flex).attrs((_: IFlexProps) => ({
  flex: 'inline-flex'
}))<ITagWrapperProps>`
  ${transition('background-color')}

  ${({ isOnlyLabel, background }: ITagWrapperProps) =>
    background
      ? css`
          padding: 0.1rem ${isOnlyLabel ? '0.5rem' : '1rem'};
          border-radius: 999px;
          background-color: ${background};
        `
      : css``}

  > * {
    :last-child {
      :not(:first-child) {
        margin-left: 0.5rem;
      }
    }
  }
`;

export interface ITagIconProps {}

export const TagIcon = styled(Icon)<ITagIconProps>`
  > svg {
    padding: 5%;
  }
`;

export interface ITagLabelProps {
  color: string;
}

export const TagLabel = styled.span<ITagLabelProps>`
  color: ${({ color }: ITagLabelProps) => color};
  font-size: ${styles.textSize.small};
  white-space: nowrap;
  ${transition('color')}
`;
