import { SVGProps } from 'react';

const SvgHelpContentFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M13.682 11.32a2.06 2.06 0 0 0 .128-.04l.009-.004-.01.008.013-.01c.079-.029.048-.026.016-.012.062-.04.235-.107.27-.126.173-.095.343-.2.51-.307.078-.05.153-.103.23-.154l.022-.02c.033-.028.066-.057.097-.087.037-.036.07-.074.105-.112h.001c.042-.068.086-.133.123-.203.004-.008.034-.08.052-.108l.006-.018a3.824 3.824 0 0 0 .138-.516l.003-.017a.712.712 0 0 1-.078 0 1.429 1.429 0 0 0-1.57.775 1.472 1.472 0 0 0-.083.956ZM17.484 12.748a3.395 3.395 0 0 0-3.664.36.719.719 0 0 1-.867.124.657.657 0 0 1-.612-.068c-.643-.401-1.261.166-1.452.78a4.053 4.053 0 0 0 .3 2.49c.17.475.442 1.104.962 1.28.648.217 1.437-.194 2.112-.135a2.958 2.958 0 0 1 1.08.356.859.859 0 0 0 .913-.097 4.558 4.558 0 0 0 1.607-1.334 4.321 4.321 0 0 0 .397-2.107 1.61 1.61 0 0 0-.776-1.65Zm-.443 2.168-.076.18a.75.75 0 0 1-1.026.269.838.838 0 0 1-.345-.449 2.3 2.3 0 0 0-.124-.442 2.327 2.327 0 0 0-.214-.369c-.023-.025-.043-.052-.067-.077a.798.798 0 0 1-.22-.53.755.755 0 0 1 .22-.53.75.75 0 0 1 1.06 0 2.985 2.985 0 0 1 .792 1.55.756.756 0 0 1 0 .398Z' />
      <path d='M26.74 25.462c-.01-.702-.03-1.404-.035-2.107l-.063-9.136c-.01-1.358-.018-2.71.024-4.066a2.804 2.804 0 0 0-.255-1.364 6.371 6.371 0 0 0-1.264-1.426 8.507 8.507 0 0 1-1.163-1.44c-.335-.542-.525-1.14-1.18-1.367a7.057 7.057 0 0 0-1.942-.242 26.616 26.616 0 0 0-2.341.015c-3.105.131-6.203.154-9.31.17-.73.004-1.465-.014-2.195.011a1.519 1.519 0 0 0-1.471.788 4.703 4.703 0 0 0-.211 1.813q-.043 1.16-.064 2.32c-.055 3.053.013 6.105.183 9.154a37.478 37.478 0 0 1 .016 4.481 3.97 3.97 0 0 0 .956 3.311 5.43 5.43 0 0 0 3.951 1.109c1.728-.021 3.454-.089 5.182-.122 1.73-.034 3.462-.038 5.19.057a31.047 31.047 0 0 0 5.232.215c.026-.003.044-.017.069-.022a.669.669 0 0 0 .579-.35 3.902 3.902 0 0 0 .113-1.802Zm-16.53-13.23a2.437 2.437 0 0 1 1.968-.673 2.741 2.741 0 0 1-.069-.55 2.956 2.956 0 0 1 .498-1.656 2.987 2.987 0 0 1 3.109-1.205.705.705 0 0 1 .227.095.822.822 0 0 1 .282-.063.753.753 0 0 1 .75.75 5.518 5.518 0 0 1-.465 2.03 3.937 3.937 0 0 1 2.477 1.054 3.722 3.722 0 0 1 .732 3.014 4.348 4.348 0 0 1-1.153 2.958 5.03 5.03 0 0 1-2.787 1.555c-.595.04-.96-.31-1.49-.45a3.98 3.98 0 0 0-1.401.128c-2.36.29-3.39-2.25-3.572-4.21a3.437 3.437 0 0 1 .894-2.776Zm11.647 13.763a52.813 52.813 0 0 0-4.197-.153c-2.713-.003-5.451.26-8.16.136a2.463 2.463 0 0 1-2.435-1.353l16.032-.231a.766.766 0 0 0 .75-.75q.426-7.68.123-15.373c.115.121.216.255.334.373.334.334.797.641.86 1.14a9.877 9.877 0 0 1-.043 1.313c-.008.645.008 1.292.012 1.937l.065 9.333q.01 1.293.033 2.585c.008.375.014.75.017 1.125v.102a29.194 29.194 0 0 1-3.39-.184Z' />
    </g>
  </svg>
);

export default SvgHelpContentFilled;
