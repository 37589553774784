import { FC, PropsWithChildren } from 'react';
import MediaQuery from 'react-responsive';
import styles from 'styles';

const MediaScreenSmartphone: FC = (
  props: PropsWithChildren<{}>
): JSX.Element => {
  const { children } = props;

  return <MediaQuery maxWidth={styles.smartphone}>{children}</MediaQuery>;
};

export default MediaScreenSmartphone;
