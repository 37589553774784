import { SVGProps } from 'react';

const SvgSafePlace = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id='safePlace_svg__Camada_1'
    data-name='Camada 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 18.67 26.47'
    {...props}
  >
    <defs>
      <style>{'.safePlace_svg__cls-2{fill:#32687d}'}</style>
    </defs>
    <path
      d='M9.1 8.09A.78.78 0 0 1 8.67 8a.65.65 0 0 1-.27-.34 5.4 5.4 0 0 1-.23-.9 5.77 5.77 0 0 1-.08-.94 5.7 5.7 0 0 1 1.73-4.13 6 6 0 0 1 8.36 0 5.7 5.7 0 0 1 1.73 4.09 5.77 5.77 0 0 1-.08.94 5.4 5.4 0 0 1-.23.9.65.65 0 0 1-.27.34.78.78 0 0 1-.43.13.89.89 0 0 1-.74-.44 1.18 1.18 0 0 1-.14-.9 3.41 3.41 0 0 0 .09-.48 3.91 3.91 0 0 0 0-.49 4.62 4.62 0 0 0-1.21-3 4 4 0 0 0-2.9-1.4 4 4 0 0 0-2.93 1.36 4.62 4.62 0 0 0-1.21 3 3.91 3.91 0 0 0 0 .49 3.41 3.41 0 0 0 .09.48 1.18 1.18 0 0 1-.14.9.89.89 0 0 1-.71.48Z'
      transform='translate(-4.67)'
      style={{
        fill: '#00445e',
        fillOpacity: 0.8,
        fillRule: 'evenodd'
      }}
    />
    <path
      className='safePlace_svg__cls-2'
      d='M20.6 8.5a9.68 9.68 0 0 0-13.2 0 8.67 8.67 0 0 0-2.73 6.29v.37a8.4 8.4 0 0 0 .45 2.73 8.93 8.93 0 0 0 1.3 2.46c.12.15.24.3.37.44s.26.29.39.43 1.93 1.54 3.58 2.78L14 26.47A37.85 37.85 0 0 0 17.46 24c1.49-1.15 3-2.34 3.34-2.73a8.137 8.137 0 0 0 .76-.88 8.93 8.93 0 0 0 1.3-2.46 8.4 8.4 0 0 0 .45-2.73v-.37A8.67 8.67 0 0 0 20.6 8.5Zm-2.4 10.88a6.26 6.26 0 0 1-8.58 0 5.58 5.58 0 0 1 0-8.17 6.29 6.29 0 0 1 8.58 0 5.6 5.6 0 0 1 0 8.17Z'
      transform='translate(-4.67)'
    />
    <path
      className='safePlace_svg__cls-2'
      d='M15.05 13.05a1.65 1.65 0 0 0-1.14-.45 1.67 1.67 0 0 0-1.15.45 1.5 1.5 0 0 0 0 2.18 1.67 1.67 0 0 0 1.15.45 1.65 1.65 0 0 0 1.14-.45 1.5 1.5 0 0 0 0-2.18Zm-.57 1.64a.86.86 0 0 1-1.15 0 .79.79 0 0 1-.23-.55.76.76 0 0 1 .23-.54.83.83 0 0 1 1.15 0 .73.73 0 0 1 .24.54.76.76 0 0 1-.24.55Z'
      transform='translate(-4.67)'
    />
    <path
      className='safePlace_svg__cls-2'
      d='M13.91 19a1 1 0 0 0 .7-.29 1 1 0 0 0 .3-.71v-2.7a1 1 0 0 0-.3-.71 1 1 0 0 0-1.7.71V18a1 1 0 0 0 1 1Z'
      transform='translate(-4.67)'
    />
  </svg>
);

export default SvgSafePlace;
