import { SVGProps } from 'react';

const SvgWristwatch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 48 48'
    xmlSpace='preserve'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      clipRule='evenodd'
      d='M38 26c-.048 0-.093-.011-.141-.014A14.985 14.985 0 0 1 32 35.979V43a4 4 0 0 1-4 4H18a4 4 0 0 1-4-4v-7.021C10.363 33.242 8 28.902 8 24c0-4.903 2.363-9.243 6-11.979V5a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v7.021a14.981 14.981 0 0 1 5.859 9.993c.048-.003.093-.014.141-.014a2 2 0 0 1 0 4zM16 43a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-5.738C27.909 38.367 25.529 39 23 39s-4.909-.633-7-1.738V43zM30 5a2 2 0 0 0-2-2H18a2 2 0 0 0-2 2v5.738C18.091 9.632 20.471 9 23 9s4.909.632 7 1.738V5zm-7 6c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zm1 15.816V33a1 1 0 1 1-2 0v-6.184A2.992 2.992 0 0 1 20 24c0-.471.118-.91.312-1.308l-3.026-3.026a.977.977 0 0 1 1.381-1.381l3.026 3.026A2.974 2.974 0 0 1 23 21a3 3 0 0 1 3 3 2.99 2.99 0 0 1-2 2.816zM23 23a1 1 0 1 0 0 2 1 1 0 1 0 0-2z'
      fillRule='evenodd'
    />
  </svg>
);

export default SvgWristwatch;
