import { SVGProps } from 'react';

const SvgGender = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M27.317 4.824c-.35-1.046-1.666-.718-2.503-.709A25.668 25.668 0 0 1 22.11 4a.755.755 0 0 0-.75.75.766.766 0 0 0 .75.75 25.74 25.74 0 0 0 2.34.117c.21 0 .42-.009.63-.013-.27.284-.539.571-.818.846-.52.512-1.083.978-1.624 1.466-1.05.948-2.087 1.912-3.119 2.88a5.493 5.493 0 0 0-4.73-1.326 5.297 5.297 0 0 0-2.335 1.3c-.456-.427-.919-.847-1.378-1.272a59.725 59.725 0 0 1 1.587-1.443.754.754 0 0 0 0-1.061.768.768 0 0 0-1.06 0c-.555.483-1.096.981-1.632 1.484-.204-.186-.403-.376-.608-.561-.526-.475-1.073-.928-1.58-1.424-.295-.287-.576-.59-.86-.888.192.004.384.012.576.012A25.968 25.968 0 0 0 9.89 5.5a.77.77 0 0 0 .75-.75.755.755 0 0 0-.75-.75 25.687 25.687 0 0 1-2.652.116c-.434-.004-.867-.029-1.3-.046a1.18 1.18 0 0 0-1.255.754 3.8 3.8 0 0 0-.065 1.242l.02 1.316.044 2.735a.75.75 0 0 0 1.5 0C6.165 9.06 6.142 8 6.125 6.94c.184.19.364.385.553.57.52.513 1.083.978 1.624 1.467.199.18.393.365.591.546-.35.346-.707.686-1.049 1.04a.75.75 0 0 0 1.06 1.061c.357-.368.727-.723 1.092-1.083.5.462 1.003.919 1.498 1.384a6.102 6.102 0 0 0-.974 2.636 5.277 5.277 0 0 0 2.708 5.378 6.385 6.385 0 0 0 1.78.556l.069 2.716-1.87-.001a.75.75 0 0 0 0 1.5h1.908l.065 2.54a.75.75 0 0 0 1.5 0l-.065-2.538h1.763a.75.75 0 0 0 0-1.5h-1.801l-.067-2.643a4.782 4.782 0 0 0 2.646-.942 6.135 6.135 0 0 0 1.65-7.217 4.648 4.648 0 0 0-.298-.487c1.056-.99 2.116-1.976 3.19-2.946.526-.475 1.073-.927 1.581-1.423.204-.2.397-.41.595-.615-.017 1.06-.04 2.12-.056 3.179a.75.75 0 0 0 1.5 0l.042-2.683.02-1.315a3.972 3.972 0 0 0-.063-1.296Zm-8.68 13.253a4.054 4.054 0 0 1-4.21.748 3.601 3.601 0 0 1-2.432-3.407 4.452 4.452 0 0 1 2.483-4.206c1.814-.937 4.184.207 5.032 1.955a4.652 4.652 0 0 1-.872 4.91Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgGender;
