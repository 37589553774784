import { SVGProps } from 'react';

const SvgChronicDisease = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M27.81 10.138a6.526 6.526 0 0 0-4.78-4.475 7.556 7.556 0 0 0-6.916 2.235 7.73 7.73 0 0 0-5.011-2.486 6.686 6.686 0 0 0-5.108 1.913 7.196 7.196 0 0 0-2.033 5.51 11.636 11.636 0 0 0 3.442 7.01c2.135 2.35 4.758 4.183 7.14 6.263a.719.719 0 0 0 .097.056c.021.02.031.043.055.062 1.142.865 2.111-.122 3.016-.822q1.532-1.185 2.99-2.46a33.321 33.321 0 0 0 5.477-5.675 8.582 8.582 0 0 0 1.63-7.13Zm-6.946 10.643q-1.338 1.238-2.747 2.395-.65.534-1.314 1.05c-.236.183-.475.363-.713.542a3.306 3.306 0 0 1-.264.2c-.141.085-.173.112-.21.094-.005-.004-.006-.01-.01-.014-1.89-1.649-3.914-3.138-5.745-4.854-1.722-1.613-3.596-3.637-4.183-5.99a6.26 6.26 0 0 1 .779-5.115 5.16 5.16 0 0 1 3.832-2.179 6.34 6.34 0 0 1 5.282 2.634.751.751 0 0 0 1.06 0 6.728 6.728 0 0 1 2.33-1.87 5.63 5.63 0 0 1 3.016-.693 5.161 5.161 0 0 1 4.252 3.143c1.62 4.253-2.594 8.092-5.365 10.657Z' />
      <path d='M17.532 16.09a5.213 5.213 0 0 0-2.157 1.39q-.902-.927-1.77-1.887a8.178 8.178 0 0 1 .656-.647c.078-.067.158-.13.236-.195l.01-.008.027-.02.11-.08a8.85 8.85 0 0 1 .565-.376.75.75 0 1 0-.757-1.295 9.233 9.233 0 0 0-1.838 1.507q-.917-1.05-1.794-2.13l.116-.093.052-.04.082-.064q.105-.08.21-.157.475-.353.967-.681a.75.75 0 1 0-.757-1.295c-.547.366-1.07.767-1.587 1.175-.14-.178-.285-.35-.422-.53a.775.775 0 0 0-1.026-.269.755.755 0 0 0-.269 1.026c.19.25.391.49.584.738-.346.309-.703.605-1.031.933a.75.75 0 0 0 1.06 1.06c.287-.287.6-.547.901-.82q.841 1.038 1.719 2.044a6.4 6.4 0 0 0-.922.807.75.75 0 1 0 1.061 1.061 5.492 5.492 0 0 1 .441-.431c.064-.056.13-.11.196-.162l.055-.04.105-.075c.018-.013.038-.024.057-.036q.956 1.059 1.95 2.083a8.42 8.42 0 0 1-1.055 1.02.751.751 0 0 0 0 1.061.773.773 0 0 0 1.061 0 8.418 8.418 0 0 0 1.052-1.018c.215.212.422.432.64.642.695.671 1.757-.388 1.06-1.06-.232-.225-.455-.46-.685-.687.06-.061.118-.124.18-.183q.104-.098.212-.191c.04-.035.082-.068.122-.102l.017-.016a.04.04 0 0 0 .006-.003 5.163 5.163 0 0 1 .502-.323q.069-.037.14-.07c.012-.007.043-.02.065-.03.083-.03.166-.061.252-.086a.75.75 0 1 0-.399-1.447Z' />
    </g>
  </svg>
);

export default SvgChronicDisease;
