export enum LanguageCode {
  en_US = 'en-US',
  pt_PT = 'pt-PT',
  es_ES = 'es-ES'
}

export default interface ILanguage {
  // 1-9
  '1HourAgo': string;
  '1MinuteAgo': string;
  // A
  addPhoto: string;
  april: string;
  august: string;
  // B
  // C
  // D
  december: string;
  // E
  // F
  february: string;
  friday: string;
  // G
  // H
  // I
  invalidEmailAddress: string;
  // J
  january: string;
  june: string;
  july: string;
  // K
  // L
  // M
  march: string;
  may: string;
  minimumXCharacters: string;
  monday: string;
  // N
  november: string;
  // O
  october: string;
  // P
  password: string;
  passwordDoesntMeetRequirements: string;
  // Q
  // R
  // S
  saturday: string;
  seeMore: string;
  selectPhotoToEditIfYouDontAgree: string;
  september: string;
  strongPassword: string;
  sunday: string;
  // T
  thursday: string;
  tuesday: string;
  // U
  // V
  // W
  wednesday: string;
  // X
  xHoursAgo: string;
  xMinutesAgo: string;
  // Y
  // Z
}
