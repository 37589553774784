import { SVGProps } from 'react';

const SvgMicroOff = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M12.527 7.688c.01-.028.014-.057.026-.085a5.103 5.103 0 0 1 1.015-1.579 3.556 3.556 0 0 1 5.478.05 3.487 3.487 0 0 1 .495.823 2.566 2.566 0 0 1 .092 1.081c-.014 2.424-.015 4.848-.039 7.272.144.16.287.321.432.48l1.072 1.182c.069-.954-.004-1.942 0-2.836l.035-6.098a3.81 3.81 0 0 0-.447-2.134 5.13 5.13 0 0 0-1.64-1.764 5.45 5.45 0 0 0-1.843-.761 3.29 3.29 0 0 0-1.142-.014 7.316 7.316 0 0 0-1.357.193 6.057 6.057 0 0 0-3.268 2.963c-.032.064-.057.127-.087.19l.008.006q.597.502 1.17 1.03ZM12.58 20.605a5.087 5.087 0 0 0 4.619.928l-1.18-1.299a3.427 3.427 0 0 1-1.354-.135c-1.734-.55-2.145-2.151-2.228-3.81-.498-.547-.992-1.098-1.492-1.643.011.627.02 1.254.045 1.88a5.325 5.325 0 0 0 1.59 4.08ZM24.283 16.51c-.05-.96-1.55-.967-1.5 0a7.453 7.453 0 0 1-.172 2.068l1.162 1.28a8.227 8.227 0 0 0 .51-3.349Z' />
      <path d='M20.441 26.432a298.548 298.548 0 0 0-3.701.006 13.255 13.255 0 0 0 .063-1.71 9.546 9.546 0 0 0 2.706-.65l-1.099-1.21c-.081.025-.16.062-.241.085a7.591 7.591 0 0 1-7.565-1.815 6.01 6.01 0 0 1-1.338-4.705c.089-.96-1.412-.954-1.5 0a7.491 7.491 0 0 0 1.288 5.162 7.765 7.765 0 0 0 4.035 2.763 9.618 9.618 0 0 0 2.216.38 12.87 12.87 0 0 1-.06 1.705c-1.148.008-2.295.017-3.442.017a.75.75 0 0 0 0 1.5c2.879 0 5.76-.047 8.639-.028a.75.75 0 0 0 0-1.5ZM27.31 27.098l-8.876-9.777c-2.798-3.082-5.473-6.388-8.669-9.073A48.4 48.4 0 0 1 4.91 3.94C4.258 3.224 3.2 4.287 3.849 5A39.746 39.746 0 0 0 8.12 8.824a41.785 41.785 0 0 1 4.284 4.085c2.953 3.227 5.881 6.477 8.822 9.716l5.024 5.534c.65.716 1.708-.347 1.06-1.06Z' />
    </g>
  </svg>
);

export default SvgMicroOff;
