import { SVGProps } from 'react';

const SvgAssign = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M25.953 22.824a6.527 6.527 0 0 0-2.644-4.287 5.345 5.345 0 0 0-1.137-.592c-.337-.13-.701-.151-1.022-.313a6.65 6.65 0 0 1-1.43-.874 5.733 5.733 0 0 0 2.503-2.887 7.158 7.158 0 0 0-1.241-7.335 7.069 7.069 0 0 0-6.516-2.403 6.66 6.66 0 0 0-5.174 5.771 7.395 7.395 0 0 0 3.433 7.051c-.02.017-.037.036-.058.052a3.518 3.518 0 0 1-.982.44 10.675 10.675 0 0 0-2.212.931 7.247 7.247 0 0 0-2.87 3.331 7.404 7.404 0 0 0-.568 2.41c-.023.35.015.69.021 1.038a3.762 3.762 0 0 0 .032 1.02 2.446 2.446 0 0 0 1.317 1.487 5.68 5.68 0 0 0 2.47.194l10.742.106a25.145 25.145 0 0 0 2.532-.006 3.86 3.86 0 0 0 2.09-.512 2.038 2.038 0 0 0 .78-1.898 12.537 12.537 0 0 0-.066-2.724Zm-6.891-4.666a15.232 15.232 0 0 0-4.072 2.385 9.329 9.329 0 0 0-.78-1.36c-.202-.299-.416-.658-.666-.983.22-.174.39-.38.615-.548a.735.735 0 0 0 .068-.075 7.31 7.31 0 0 0 4.064-.164 4.724 4.724 0 0 0 .77.745ZM14.453 5.672a5.406 5.406 0 0 1 5.025 1.458 5.577 5.577 0 0 1 1.65 4.996c-1.833-.435-2.693-2.316-3.408-3.905a.754.754 0 0 0-1.295 0c-1.028 2.057-3.422 2.511-5.475 1.885a.702.702 0 0 0-.154-.015 5.16 5.16 0 0 1 3.658-4.419Zm-3.609 5.947a6.146 6.146 0 0 0 6.11-1.549c.824 1.598 1.95 3.104 3.775 3.504-.973 2.362-4.47 3.304-6.7 2.38a5.609 5.609 0 0 1-3.185-4.335Zm-.611 14.742c-.355-.004-.72-.03-1.074-.01a2.279 2.279 0 0 1-1.083-.03c-.644-.272-.494-.703-.529-1.268a6.137 6.137 0 0 1 .226-2.195 5.714 5.714 0 0 1 2.622-3.283 10.11 10.11 0 0 1 1.76-.707 4.696 4.696 0 0 1 .417.584c.204.282.403.568.588.863a5.744 5.744 0 0 1 .767 1.813 22.584 22.584 0 0 1 .564 4.275l-1.99-.02Zm14.227-.219a.976.976 0 0 1-.753.278c-.714.047-1.418.06-2.134.053l-5.58-.055a23.876 23.876 0 0 0-.542-4.337 13.557 13.557 0 0 1 5.437-2.94 6.45 6.45 0 0 1 1.491.575 4.47 4.47 0 0 1 1.373 1.376 7.145 7.145 0 0 1 .796 4.215c-.02.26.06.62-.088.835Z' />
      <path d='m21.519 22.087-.817-.01c-.018-.192-.024-.385-.048-.577a.822.822 0 0 0-.22-.53.75.75 0 0 0-1.06 0 .72.72 0 0 0-.22.53c.023.186.028.373.046.559l-.72-.01a.75.75 0 0 0 0 1.5l.793.01c.001.279.011.556-.001.835a.755.755 0 0 0 .75.75.766.766 0 0 0 .75-.75c.012-.272.002-.544.001-.816l.745.01a.75.75 0 0 0 0-1.5Z' />
    </g>
  </svg>
);

export default SvgAssign;
