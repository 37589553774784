import { CSSTransition } from 'react-transition-group';
import {
  Dispatch,
  FC,
  MouseEvent,
  PropsWithChildren,
  SetStateAction,
  useRef
} from 'react';
import useMediaScreen from 'hooks/use-mediaScreen';
import useClickOutside from 'hooks/use-clickOutside';
import styles from 'styles';
import { PopupBackground, PopupContainer } from './Popup.styles';
import IProps from 'interfaces/IProps';

export interface IPopupProps extends PropsWithChildren<IProps> {
  top: string;
  show: boolean;
  close?: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
}

const Popup: FC<IPopupProps> = (props: IPopupProps) => {
  const { dataTestId, className, style, top, show, close, onClose, children } =
    props;
  const { isMobileScreen } = useMediaScreen();
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, close, onClose);

  const mouseDownHandler = (event: MouseEvent): void => {
    event.preventDefault();
  };

  return (
    <CSSTransition
      classNames='popup'
      in={show}
      timeout={styles.transition}
      mountOnEnter
      unmountOnExit
    >
      <>
        {isMobileScreen && <PopupBackground />}
        <PopupContainer
          data-testid={dataTestId}
          className={className}
          style={style}
          top={top}
          onMouseDown={mouseDownHandler}
          ref={containerRef}
        >
          {children}
        </PopupContainer>
      </>
    </CSSTransition>
  );
};

export default Popup;
