import { Link } from 'react-router-dom';
import { icons } from 'icons';
import IProps from 'interfaces/IProps';
import { FC, PropsWithChildren, MouseEvent, useState } from 'react';
import {
  ButtonContent,
  ButtonIcon,
  ButtonLabel,
  ButtonLoadingIcon,
  ButtonThemeProvider,
  Wrapper
} from './Button.styles';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styles from 'styles';

export type ButtonType = 'primary' | 'secondary' | 'text';
export type ButtonState = 'main' | 'green' | 'red' | 'dark' | 'white';

export interface IButtonProps extends PropsWithChildren<IProps> {
  type?: ButtonType;
  state?: ButtonState;
  buttonType?: 'button' | 'submit' | 'reset';
  active?: boolean;
  disabled?: boolean;
  reverse?: boolean;
  loading?: boolean;
  to?: string;
  icon?: keyof typeof icons;
  label?: string;
  showLabelOnMouseOuver?: boolean;
  onClick?: () => void;
}

const Button: FC<IButtonProps> = (props: IButtonProps): JSX.Element => {
  const {
    dataTestId,
    className,
    style,
    type,
    state,
    buttonType,
    active,
    disabled,
    reverse,
    loading,
    to,
    icon,
    label,
    showLabelOnMouseOuver,
    onClick
  } = props;
  const [mouseIsOver, setMouseIsOver] = useState<boolean>(false);

  const clickHandler = (event: MouseEvent): void => {
    event.stopPropagation();

    if (!to && onClick) event.preventDefault();
    if (!disabled && onClick) onClick();
  };

  return (
    <ButtonThemeProvider
      theme={{
        isFullButton: label && !showLabelOnMouseOuver,
        type,
        state,
        active,
        disabled,
        reverse
      }}
    >
      <Wrapper
        data-testid={dataTestId}
        className={className}
        style={style}
        as={to ? Link : 'button'}
        to={to}
        type={!to ? buttonType : undefined}
        disabled={disabled}
        loading={loading}
        onClick={clickHandler}
        onMouseEnter={() => setMouseIsOver(true)}
        onMouseLeave={() => setMouseIsOver(false)}
      >
        <SwitchTransition>
          <CSSTransition
            classNames='loading'
            key={`${loading}`}
            timeout={styles.transition}
          >
            <ButtonContent>
              {loading && <ButtonLoadingIcon />}
              {!loading && (
                <>
                  {icon && <ButtonIcon src={icon} />}
                  {(showLabelOnMouseOuver ? mouseIsOver && label : label) && (
                    <ButtonLabel>{label}</ButtonLabel>
                  )}
                </>
              )}
            </ButtonContent>
          </CSSTransition>
        </SwitchTransition>
      </Wrapper>
    </ButtonThemeProvider>
  );
};

Button.defaultProps = {
  type: 'primary',
  state: 'main',
  buttonType: 'button'
};

Button.displayName = 'Button';

export default Button;
