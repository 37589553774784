import { RefObject, useRef } from 'react';
import { IInputStepProps } from './InputStep';

export interface IUseInputStep {
  inputRef: RefObject<HTMLInputElement>;
  increaseHandler: () => void;
  decreaseHandler: () => void;
}

const useInputStep = (props: IInputStepProps): IUseInputStep => {
  const { min, max, onChange } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const increaseHandler = (): void => {
    // circles to minimum value when it reaches the maximum one
    if (Number(inputRef.current?.value) === max)
      inputRef.current!.value = min + '';
    else inputRef.current?.stepUp();

    inputRef.current!.value = Number(inputRef.current!.value).toLocaleString(
      'en-US',
      {
        minimumIntegerDigits: 2
      }
    );

    onChange(Number(inputRef.current?.value));
  };

  const decreaseHandler = (): void => {
    // circles to maximum value when it reaches the minimum one
    if (Number(inputRef.current?.value) === min)
      inputRef.current!.value = max + '';
    else inputRef.current?.stepDown();

    inputRef.current!.value = Number(inputRef.current!.value).toLocaleString(
      'en-US',
      {
        minimumIntegerDigits: 2
      }
    );

    onChange(Number(inputRef.current?.value));
  };

  return { inputRef, increaseHandler, decreaseHandler };
};

export default useInputStep;
