import { SVGProps } from 'react';

const SvgArrowTop = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M27.53 20.95A115.382 115.382 0 0 0 16.834 9.92a.77.77 0 0 0-1.06 0c-3.969 3.594-8.237 6.894-11.422 11.251-.57.78.731 1.53 1.296.757 2.956-4.044 6.935-7.155 10.647-10.468A120.781 120.781 0 0 1 26.47 22.01c.641.724 1.699-.34 1.06-1.061Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgArrowTop;
