import styled, { css } from 'styled-components';
import { InputContainer, IInputContainerProps } from '../Input.styles';

export interface ITagsInputContainerProps {
  hasValues: boolean;
}

export const TagsInputContainer = styled(InputContainer).attrs(
  (_: IInputContainerProps) => ({ alignItems: 'flex-start' })
)<ITagsInputContainerProps>`
  flex-wrap: wrap;
  padding: 0.25rem;
  padding-bottom: 0;
  border-radius: 1.5rem;
  cursor: text;

  > input {
    min-width: 50%;
    height: 2.5rem;

    ${({ hasValues }: ITagsInputContainerProps) =>
      hasValues
        ? css``
        : css`
            padding-left: 0.5rem;
          `}
  }
`;
