import { Flex } from 'components/Templates';
import styled from 'styled-components';
import styles from 'styles';
import { rgba } from 'utils/colors';
import { transition } from 'utils/styles';

export interface ICheckboxWrapperProps {
  checked: boolean;
}

export const CheckboxWrapper = styled(Flex)<ICheckboxWrapperProps>`
  position: relative;
  cursor: pointer;

  :not(:last-child) {
    margin-bottom: 2rem;
  }

  :hover {
    circle {
      :last-of-type {
        fill: ${({ checked }: ICheckboxWrapperProps) =>
          rgba(styles.colors.brand.main, checked ? 0.85 : 0.15)};
      }
    }

    rect {
      fill: ${({ checked }: ICheckboxWrapperProps) =>
        rgba(styles.colors.brand.main, checked ? 0.85 : 0.15)};
    }
  }

  > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  > svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 1rem;

    > circle,
    > rect {
      ${transition('fill')}
    }
  }
`;
