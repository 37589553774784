import { SVGProps } from 'react';

const SvgMeasures = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M26.573 5.578a2.807 2.807 0 0 0-2.508-1.318c-1.058-.065-2.107.162-3.163.187-1.643.037-3.288.03-4.93.022-1.594-.009-3.186.034-4.779.028a14.591 14.591 0 0 0-3.947.067 3.023 3.023 0 0 0-1.582.944 3.813 3.813 0 0 0-.626 2.19 19.477 19.477 0 0 0 .088 2.042c.05.852.032 1.708.036 2.56.014 3.176.01 6.35.002 9.525-.001.625.001 1.247-.032 1.871a5.065 5.065 0 0 0 .156 1.999 3.624 3.624 0 0 0 2.904 2.1 14.623 14.623 0 0 0 2.198-.1c.827-.044 1.663-.01 2.49-.01l5.523.003c1.432.001 2.871.088 4.302.066a4.008 4.008 0 0 0 3.39-1.444c.766-1.234.59-2.771.574-4.158q-.029-2.494-.028-4.99c.003-2.86.03-5.705.252-8.556a5.806 5.806 0 0 0-.32-3.028ZM7.03 6.275c.932-.673 2.415-.353 3.472-.296 1.34.071 2.693 0 4.034-.01 2.591-.017 5.21.126 7.791-.132a4.598 4.598 0 0 1 2.64.143c.61.37.49 1.451.464 2.053-.045 1.05-.204 2.09-.226 3.143q-.052 2.494-.06 4.99c-.005 1.2.003 2.401.01 3.602l-9.65.042-4.82.021a23.258 23.258 0 0 0-4.02.199c.004-1.947.01-3.893.004-5.84-.003-1.369.027-2.745-.025-4.113-.025-.643-.102-1.284-.11-1.927-.009-.589-.032-1.494.496-1.875ZM22.188 26.25c-1.142-.029-2.278-.06-3.421-.061l-4.239-.003c-1.374 0-2.766-.067-4.138.01-.57.032-1.135.12-1.707.12a2.15 2.15 0 0 1-1.58-.632c-.801-.715-.447-1.953-.441-2.917.002-.405-.001-.81 0-1.214a19.774 19.774 0 0 1 4.27-.223l4.82-.02 9.408-.042c.007.745.02 1.49.026 2.235a3.682 3.682 0 0 1-.387 2.05c-.564.76-1.772.718-2.611.697Z' />
      <path d='M12.097 23.391H9.714a.75.75 0 0 0 0 1.5h2.383a.75.75 0 1 0 0-1.5ZM17.442 23.345h-2.19a.75.75 0 1 0 0 1.5h2.19a.75.75 0 0 0 0-1.5ZM12.456 15.113q.598.732 1.198 1.463a9.826 9.826 0 0 0 1.155 1.364c.887.743 1.55-.279 2.127-.829a17.896 17.896 0 0 1 1.593-1.169 17.058 17.058 0 0 0 1.53-1.297 4.012 4.012 0 0 0 1.243-3.123 2.794 2.794 0 0 0-1.543-2.603 3.476 3.476 0 0 0-3.106.166 2.607 2.607 0 0 0-.74.525l-.016.011-.063-.057a3.98 3.98 0 0 0-1.396-.79 2.942 2.942 0 0 0-2.97.634 3.147 3.147 0 0 0-.68 2.872 7.09 7.09 0 0 0 1.668 2.833Zm-.237-3.983a1.166 1.166 0 0 1 1.085-1.005 2.22 2.22 0 0 1 1.646.663 1.381 1.381 0 0 0 .99.417 2.197 2.197 0 0 0 1.122-.6 2.155 2.155 0 0 1 1.768-.437 1.274 1.274 0 0 1 .973 1.354 2.752 2.752 0 0 1-.35 1.582 7.412 7.412 0 0 1-1.572 1.458c-.596.47-1.247.868-1.837 1.347a3.968 3.968 0 0 0-.466.473l-.089.098c-.154-.189-.3-.385-.447-.565l-.872-1.065a19.52 19.52 0 0 1-1.434-1.838 2.677 2.677 0 0 1-.517-1.882Z' />
    </g>
  </svg>
);

export default SvgMeasures;
