import * as Add from 'components/SVGs/add';
import * as Configurations from 'components/SVGs/configurations';
import * as General from 'components/SVGs/general';
import * as Health from 'components/SVGs/health';
import * as Measurements from 'components/SVGs/measurements';
import * as Menu from 'components/SVGs/menu';
import * as PersonalInfo from 'components/SVGs/perosnalInfo';
import * as Temp from 'components/SVGs/temp';

export const icons = {
  // A
  addAllergy: <Add.AddAllergy />,
  addclinicalNote: <Add.AddClinicalNote />,
  addDevice: <Add.AddDevice />,
  addDisease: <Add.AddDisease />,
  addHelpContent: <Add.AddHelpContent />,
  addHouse: <Temp.AddHouse />,
  addMeasure: <Add.AddMeasure />,
  addPatient: <Add.AddPatient />,
  addQuestionnaire: <Add.AddQuestionnaire />,
  addReminder: <Add.AddReminder />,
  addShare: <Add.AddShare />,
  addTask: <Add.AddTask />,
  address: <PersonalInfo.Address />,
  age: <PersonalInfo.Age />,
  allergy: <Health.Allergy />,
  appointment: <Health.MeetingDoctor />,
  arrowDown: <General.ArrowDown />,
  arrowLeft: <General.ArrowLeft />,
  arrowRight: <General.ArrowRight />,
  arrowTop: <General.ArrowTop />,
  assign: <Health.Assign />,

  // B
  belt: <Temp.Belt />,
  bloodPressure: <Measurements.BloodPressure />,
  bmi: <Measurements.Bmi />,

  // C
  calendar: <General.Calendar />,
  calories: <Measurements.Calories />,
  call: <General.Call />,
  camera: <General.Camera />,
  category: <General.Category />,
  chat: <Temp.Chat />,
  check: <General.Check />,
  chronicDisease: <Health.ChronicDisease />,
  clinicalData: <Menu.ClinicalData />,
  clinicalDataFilled: <Menu.ClinicalDataFilled />,
  clock: <General.Time />,
  close: <General.Close />,
  conditions: <Configurations.Conditions />,
  contacts: <Menu.Contacts />,

  // D
  delete: <General.Delete />,
  devices: <Menu.Devices />,
  devicesFilled: <Menu.DevicesFilled />,
  details: <General.Details />,
  disease: <Health.Disease />,
  done: <General.Done />,

  // E
  ecg: <Measurements.Ecg />,
  edit: <General.Edit />,
  education: <PersonalInfo.Education />,
  email: <PersonalInfo.Email />,
  endCall: <General.EndCall />,

  // F
  fateRatio: <Measurements.FateRatio />,
  female: <Temp.Female />,
  fence: <Temp.Fence />,
  file: <General.File />,
  filters: <General.Filters />,
  flipCamera: <General.FlipCamera />,
  foodAllergy: <Health.FoodAllergy />,

  // G
  gender: <PersonalInfo.Gender />,
  glucose: <Measurements.Glucose />,
  group: <General.Group />,

  // H
  health: <Menu.Health />,
  healthFilled: <Menu.HealthFilled />,
  heartBeatRate: <Measurements.HeartBeatRate />,
  height: <PersonalInfo.Height />,
  help: <Temp.Help />,
  helpContent: <Menu.HelpContent />,
  helpContentFilled: <Menu.HelpContentFilled />,
  high: <Health.High />,
  historic: <Menu.Historic />,
  home: <Menu.Home />,
  homeFilled: <Menu.HomeFilled />,

  // I
  image: <General.Image />,
  institution: <Health.Institution />,

  // J

  // K

  // L
  lifestyle: <Menu.Lifestyle />,
  lifestyleFilled: <Menu.LifestyleFilled />,
  list: <General.List />,
  loading: <General.Loading />,
  logout: <Menu.Logout />,
  loudspeaker: <General.Loudspeaker />,
  loudspeakerOff: <General.LoudspeakerOff />,
  low: <Health.Low />,

  // M
  male: <Temp.Male />,
  mapMarker: <Temp.MapMarker />,
  mapMarkerFilled: <Temp.MapMarkerFilled />,
  marritalStatus: <PersonalInfo.Status />,
  measure: <Health.Measure />,
  measures: <Health.Measures />,
  medicFemale: <Temp.MedicFemale />,
  menu: <General.Menu />,
  micro: <General.Micro />,
  microOff: <General.MicroOff />,
  missing: <General.Missing />,
  muscleRatio: <Measurements.MuscleRatio />,

  // N
  notifications: <Menu.Notifications />,
  notificationsFilled: <Menu.NotificationsFilled />,
  ntProbnp: <Measurements.NtProbnp />,

  // O
  options: <General.Options />,

  // P
  patientFemale: <Temp.PatientFemale />,
  patients: <Menu.Patients />,
  patientsFilled: <Menu.PatientsFilled />,
  phoneNumber: <PersonalInfo.PhoneNumber />,
  pills: <Health.Pills />,
  plus: <General.Add />,
  price: <Temp.Price />,
  priceFilled: <Temp.PriceFilled />,
  privacyPolicy: <Configurations.PrivacyPolicy />,
  pushNotification: <Configurations.PushNotification />,

  // Q
  questionnaire: <Menu.Questionnaire />,
  questionnaireFilled: <Menu.QuestionnaireFilled />,

  // R
  remove: <General.Remove />,
  repeat: <General.Repeat />,
  report: <Configurations.Report />,
  respiratoryRate: <Measurements.RespiratoryRate />,

  // S
  safePlace: <Temp.SafePlace />,
  search: <General.Search />,
  security: <Configurations.Access />,
  settings: <Menu.Settings />,
  share: <General.Share />,
  sleep: <Measurements.Sleep />,
  sos: <Temp.Sos />,
  spo2: <Measurements.Spo2 />,
  stopRepeat: <General.StopRepeat />,
  steps: <Measurements.Steps />,
  synchronize: <Configurations.Synchronize />,

  // T
  tasks: <Menu.Tasks />,
  tasksFilled: <Menu.TasksFilled />,
  temperature: <Measurements.Temperature />,
  text: <General.Text />,
  time: <General.Hour />,
  tips: <General.Tips />,

  // U
  userData: <Menu.UserData />,
  userDataFilled: <Menu.UserDataFilled />,
  userRights: <Configurations.UserRights />,

  // V
  verified: <Temp.Verified />,
  veryHigh: <Health.VeryHigh />,
  veryLow: <Health.VeryLow />,
  video: <General.Video />,
  videoCall: <General.VideoCall />,
  videoCallOff: <General.VideoCallOff />,
  visibility: <General.Visibility />,
  visibilityOff: <General.VisibilityOff />,

  // W
  waterRatio: <Measurements.WaterRatio />,
  weight: <Measurements.Weight />,
  wristwatch: <Temp.Wristwatch />

  // X

  // Y

  // Z
};
