import { SVGProps } from 'react';

const SvgHeartBeatRate = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M20.155 9.937a3.554 3.554 0 0 1 .424-.094l.099-.01q.1-.006.2-.007a4.78 4.78 0 0 1 .4.017l.021.002a2.885 2.885 0 0 1 .274.055c.142.035.279.08.416.127a.338.338 0 0 0 .039.012c.01.005.007.005.029.015.06.029.12.058.18.089a3.499 3.499 0 0 1 .37.227l.049.035.037.03c.05.046.101.089.15.135a3.57 3.57 0 0 1 .247.268c.02.025.058.073.068.088q.067.096.127.197a3.848 3.848 0 0 1 .18.347l.043.107q.034.095.063.19c.041.14.064.283.099.424l.003.019.009.08q.008.1.011.2c.004.134-.003.267-.01.4a.303.303 0 0 0 0 .04.236.236 0 0 0-.006.025 5.456 5.456 0 0 1-.055.262.75.75 0 0 0 1.446.399 4.246 4.246 0 0 0-5.311-5.126.75.75 0 0 0 .399 1.446Z' />
      <path d='m28 17.526-2.184-.003a10.432 10.432 0 0 0 1.83-4.357c.343-2.287.006-4.888-1.922-6.39a6.786 6.786 0 0 0-6.731-.71 7.52 7.52 0 0 0-1.594.846c-.28.2-.544.42-.817.628-.2.153-.605.565-.87.583-.312.022-.712-.523-.925-.712a7.505 7.505 0 0 0-1.629-1.08 6.494 6.494 0 0 0-6.859.297 6.253 6.253 0 0 0-1.91 6.263 9.893 9.893 0 0 0 1.24 3.073 30.71 30.71 0 0 1-1.43-.213c-.943-.17-1.348 1.276-.398 1.447.82.148 1.883.35 2.97.44.726.963 1.516 1.88 2.252 2.779l3.255 3.976c.891 1.089 1.876 2.845 3.453 1.738 3.034-2.225 6.354-4.238 8.867-7.079 1.136-.009 2.27-.027 3.402-.026a.75.75 0 0 0 0-1.5Zm-21.952-4.23c-.588-1.802-.477-4.136 1.089-5.43 1.74-1.436 4.245-.928 6 .175.769.484 1.354 1.395 2.294 1.556 1.06.183 1.946-.827 2.725-1.39a5.832 5.832 0 0 1 6.004-.669c1.93 1.029 2.311 3.231 2.037 5.25a9.478 9.478 0 0 1-2.037 4.487c-.067.085-.143.162-.211.245l-2.3-.003a2.505 2.505 0 0 1-1.302-.145 1.392 1.392 0 0 1-.412-.715 4.022 4.022 0 0 0-.96-1.815.757.757 0 0 0-1.177.152 15.687 15.687 0 0 1-.859 1.64l-1.551-3.694a.758.758 0 0 0-1.447 0l-1.658 4.804c-.162-.959-.256-1.93-.337-2.905a.75.75 0 0 0-1.5 0c-.14 1.186-1.54 1.355-2.478 1.329-.146-.004-.291-.025-.437-.032a11.001 11.001 0 0 1-1.483-2.84Zm8.453 11.456c-.213-.246-.414-.505-.62-.757l-1.318-1.61-2.557-3.124c-.436-.532-.89-1.07-1.335-1.618a4.47 4.47 0 0 0 1.995-.627 15.665 15.665 0 0 0 .785 3.536.757.757 0 0 0 1.447 0l1.837-5.322 1.34 3.192a.763.763 0 0 0 1.372.18 15.298 15.298 0 0 0 1.033-1.646 5.07 5.07 0 0 0 .322.858 2.4 2.4 0 0 0 2.183 1.197c.518.022 1.038.031 1.558.038a68.061 68.061 0 0 1-7.57 5.786c-.276.194-.251.173-.472-.083Z' />
    </g>
  </svg>
);

export default SvgHeartBeatRate;
