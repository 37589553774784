import { SVGProps } from 'react';

const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M4.888 16.776q11.115-.192 22.224.372c.965.049.962-1.452 0-1.5q-11.102-.564-22.224-.371a.75.75 0 0 0 0 1.5ZM4.692 9.107a33.497 33.497 0 0 0 5.505.258l5.575.12 11.272.244a.75.75 0 0 0 0-1.5L16.02 7.99l-5.45-.118-2.725-.06A16.628 16.628 0 0 1 5.09 7.66c-.944-.18-1.348 1.266-.4 1.447ZM26.995 22.962c-7.327.369-14.663.119-21.989-.138-.965-.034-.964 1.466 0 1.5 7.326.257 14.662.507 21.989.138.96-.048.966-1.548 0-1.5Z' />
    </g>
  </svg>
);

export default SvgMenu;
