import { SVGProps } from 'react';

const SvgGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M27.956 13.791q-.027-1.143-.084-2.286a6.903 6.903 0 0 0-.191-1.945c-.519-1.433-2.675-1.06-3.846-1.06h-9.077a5.128 5.128 0 0 0-1.172.024l-.126.011a4.06 4.06 0 0 1-.244-.415 7.368 7.368 0 0 1-.598-1.037 2.314 2.314 0 0 0-.7-1.186.618.618 0 0 0-.368-.19.688.688 0 0 0-.352-.032 35.815 35.815 0 0 0-3.753-.023c-.712 0-1.433-.038-2.143-.013a1.236 1.236 0 0 0-1.21.908 7.014 7.014 0 0 0-.052 1.776l-.005 2.367q-.006 2.361-.008 4.722 0 4.236.07 8.461a2.088 2.088 0 0 0 1.437 2.184 8.063 8.063 0 0 0 2.812.296l9.43.006 4.715.004 2.358.001a4.176 4.176 0 0 0 1.86-.249 2.098 2.098 0 0 0 1.125-1.392 10.225 10.225 0 0 0 0-2.105c-.003-1.445.094-2.879.119-4.32.025-1.503.039-3.004.003-4.506ZM11.059 7.098l.003.011c-.02-.012-.027-.02-.027-.025Zm15.28 16.688c.003.275.095.589-.073.817-.31.421-1.227.261-1.666.261l-4.095-.003-8.437-.006-4.094-.003a4.586 4.586 0 0 1-2.095-.271c-.326-.173-.276-.349-.283-.708a17.41 17.41 0 0 0-.033-1.886 29.304 29.304 0 0 1-.036-3.62q0-4.586.01-9.172a11.97 11.97 0 0 0-.003-2.044l.035-.005.163.007c.407.01.816 0 1.223 0l1.942-.001a17.725 17.725 0 0 1 2.172-.005c0 .009.005.012.004.024a1.093 1.093 0 0 0 .089.294 5.103 5.103 0 0 0 .361.759 6.906 6.906 0 0 0 .924 1.448 1.583 1.583 0 0 0 1.372.335c.662-.033 1.33-.007 1.992-.007h8.258c.569 0 1.148-.032 1.716.002.142.008.43.015.503.124.135.205.054.799.067 1.046q.106 1.988.123 3.98c.022 2.573-.155 5.132-.143 7.705.001.31.001.62.004.929Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgGroup;
