import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IReduxState from '../interfaces/IReduxState';
import { languageActions } from '../store/redux/language-slice';
import { LanguageCode } from '../interfaces/ILanguage';
import languages from '../assets/languages';
import useStorage from '../services/storage.service';

export interface IUseLanguage {
  changeLanguage: (code: LanguageCode, save?: boolean) => void;
}

const useLanguage = (
  checkForChange: boolean = true,
  languagesFolder?: string
): IUseLanguage => {
  const dispatch = useDispatch();
  const { saveToStorage, getFromStorage } = useStorage('localStorage');
  const languageCode = useSelector((state: IReduxState) => state.language.code);

  if (checkForChange) {
    useEffect(() => {
      const savedLanguage = getFromStorage('language') as LanguageCode;
      const supportedLanguages = Object.values(LanguageCode);
      const savedLanguageIsValid = supportedLanguages.includes(savedLanguage);

      if (savedLanguage && savedLanguageIsValid) changeLanguage(savedLanguage);
      else {
        if (!savedLanguageIsValid)
          console.log('Saved language is not available!');

        changeLanguage(
          (process.env.REACT_APP_DEFAULT_LANGUAGE as LanguageCode) ||
            LanguageCode.pt_PT,
          false
        );
      }
    }, []);

    useEffect(() => {
      getLanguageValues();
    }, [languageCode]);
  }

  const getLanguageValues = async (): Promise<void> => {
    if (!languageCode) return;

    let values = [];

    if (languagesFolder) {
      const response = await fetch(`${languagesFolder}/${languageCode}.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      if (!response.ok) return;
      values = await response.json();
    }

    dispatch(
      languageActions.setValues({ ...languages[languageCode], ...values })
    );
  };

  const changeLanguage = (code: LanguageCode, save: boolean = true): void => {
    dispatch(languageActions.changeLanguage(code));
    if (save) saveToStorage('language', code);
  };

  return { changeLanguage };
};

export default useLanguage;
