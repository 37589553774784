import styled, { css } from 'styled-components';
import { transition } from 'utils/styles';

export interface IIconWrapperProps {
  size: string;
  backgroundColor?: string;
  fill: string;
}

export const IconWrapper = styled.span<IIconWrapperProps>`
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }: IIconWrapperProps) => size};
  height: ${({ size }: IIconWrapperProps) => size};
  border-radius: 999px;
  background-color: ${({ backgroundColor }: IIconWrapperProps) =>
    !backgroundColor || backgroundColor === 'transparent'
      ? 'transparent'
      : 'white'};
  ${transition('background-color')}

  > svg {
    width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    background-color: ${({ backgroundColor }: IIconWrapperProps) =>
      backgroundColor};
    ${transition('background-color')}

    ${({ backgroundColor }: IIconWrapperProps) =>
      backgroundColor || backgroundColor === 'transparent'
        ? css`
            padding: 15%;
          `
        : ''};

    path {
      fill: ${({ fill }: IIconWrapperProps) => fill};
      ${transition('fill')}
    }
  }
`;
