import IProps from 'interfaces/IProps';
import { FC, PropsWithChildren } from 'react';
import {
  InputContainer,
  InputHelpMessage,
  InputIcon,
  InputPrefix,
  InputStateMessage,
  InputSuffix,
  InputWithDigits,
  InputWrapper
} from '../Input.styles';
import { IInputProps } from '../Input.types';
import InputStep from '../InputStep/InputStep';
import useNumberInput from './NumberInput.logic';
import { NumberInputPopup } from './NumberInput.styles';

export interface INumberInputProps
  extends PropsWithChildren<IInputProps & IProps> {
  nrDigits?: number;
  min?: number;
  max?: number;
  step?: number;
}

const NumberInput: FC<INumberInputProps> = (
  props: INumberInputProps
): JSX.Element => {
  const {
    dataTestId,
    className,
    style,
    icon,
    disabled,
    prefix,
    placeholder,
    nrDigits,
    min,
    max,
    step,
    suffix,
    help
  } = props;
  const {
    input,
    value,
    isFocused,
    showPopup,
    containerRef,
    inputChangeHandler,
    focusHandler,
    blurHandler,
    keyDownHandler,
    changeStepHandler
  } = useNumberInput(props);
  const { success, error } = input;

  return (
    <InputWrapper className={className} style={style}>
      {icon && <InputIcon src={icon} />}
      <div>
        {success && (
          <InputStateMessage
            data-testid={`${dataTestId}_success`}
            type='success'
          >
            {success}
          </InputStateMessage>
        )}
        {error && (
          <InputStateMessage data-testid={`${dataTestId}_error`} type='alert'>
            {error}
          </InputStateMessage>
        )}
        <InputContainer
          style={{ width: 'fit-content' }}
          isFocused={isFocused}
          state={success ? 'success' : error ? 'alert' : undefined}
          ref={containerRef}
        >
          {prefix && <InputPrefix>{prefix}</InputPrefix>}
          <InputWithDigits
            data-testid={dataTestId}
            nrDigits={nrDigits ? nrDigits : max?.toString().length}
            type='number'
            placeholder={placeholder}
            value={value as number}
            step={step || 'any'}
            min={min}
            max={max}
            disabled={disabled}
            onChange={inputChangeHandler}
            onFocus={focusHandler}
            onBlur={blurHandler}
            onKeyDown={keyDownHandler}
            style={{ textAlign: suffix ? 'right' : 'left' }}
          />
          {step && (
            <NumberInputPopup show={showPopup} top='110%' onClose={blurHandler}>
              <InputStep
                valueDataTestId={`${dataTestId}-stepValue`}
                upDataTestId={`${dataTestId}-stepButton`}
                downDataTestId={`${dataTestId}-stepDownButton`}
                value={
                  Math.round(
                    Math.min(
                      Math.max((value as number) || min || 0, min || 0),
                      max || 100
                    ) / step
                  ) * step
                }
                min={min ? min : 0}
                max={max ? max : 100}
                step={step}
                onChange={changeStepHandler}
              />
            </NumberInputPopup>
          )}
          {suffix && <InputSuffix>{suffix}</InputSuffix>}
        </InputContainer>
        {help && (
          <InputHelpMessage data-testid={`${dataTestId}_help`}>
            {help}
          </InputHelpMessage>
        )}
      </div>
    </InputWrapper>
  );
};

NumberInput.displayName = 'NumberInput';

export default NumberInput;
