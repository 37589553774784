import Button from 'components/Button/Button';
import { Flex, IFlexProps } from 'components/Templates';
import styled from 'styled-components';
import styles from 'styles';

export interface ITagWrapperProps {}

export const TagWrapper = styled(Flex).attrs((_: IFlexProps) => ({
  flex: 'inline-flex'
}))<ITagWrapperProps>`
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  border-radius: 999px;
  margin-right: 0.625rem;
  margin-bottom: 0.25rem;
  background-color: ${styles.colors.brand.lightest};
  pointer-events: none;
`;

export interface ITagLabelProps {}

export const TagLabel = styled.div<ITagLabelProps>`
  margin-right: 1rem;
`;

export interface ITagButtonProps {}

export const TagButton = styled(Button)<ITagButtonProps>`
  width: 2rem !important;
  height: 2rem;
  padding: 0.25rem;
`;
