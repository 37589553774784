import { SVGProps } from 'react';

const SvgGlucose = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M24.704 7.975a4.266 4.266 0 0 0-1.407-2.097c-1.51-1.39-3.713-1.614-5.672-1.77a15.159 15.159 0 0 0-6.25.545c-1.626.585-3.569 1.912-3.872 3.737a16.636 16.636 0 0 0-.08 3.197c.032 1.103.01 2.2.004 3.302a13.075 13.075 0 0 0 .33 3.316 13.042 13.042 0 0 0 1.458 3.268 24.757 24.757 0 0 0 1.972 2.873 1.646 1.646 0 0 0 1.157.722 10.58 10.58 0 0 0 1.311.035c.017.608.035 1.216.049 1.823a.97.97 0 0 0 .68.924 4.279 4.279 0 0 0 1.184.07q.54.006 1.08.009a3.508 3.508 0 0 0 1.07-.073c.906-.286.71-1.343.71-2.086v-.723a3.597 3.597 0 0 0 .835-.092 2.508 2.508 0 0 0 1.33-.508 15.11 15.11 0 0 0 1.92-2.455 19.444 19.444 0 0 0 1.66-2.62 6.818 6.818 0 0 0 .534-2.714q.072-3.086.12-6.173a10.153 10.153 0 0 0-.123-2.51Zm-8.596 18.45-.72-.007-.196-.003-.06-2.572-.035-1.46c-.005-.214-.004-.429-.007-.644.327.006.655.02.974.021.29.002.595.02.896.01-.01.21-.03.416-.032.624-.003.512 0 1.023 0 1.535v2.5h-.041q-.39-.001-.78-.005Zm7.239-16.728c-.024 1.673-.096 3.342-.138 5.015-.022.912.059 1.838-.05 2.746a5.598 5.598 0 0 1-1.07 2.446c-.54.835-1.072 1.681-1.684 2.466-.21.27-.562.883-.872 1.017a2.644 2.644 0 0 1-1.105.152v-.101c0-.368-.006-.737 0-1.105a8.19 8.19 0 0 0 .02-1.038 1.19 1.19 0 0 0-.44-.918c-.38-.268-.716-.112-1.095-.111-.779.002-1.557-.021-2.336-.015a.94.94 0 0 0-.998.619 1.324 1.324 0 0 0-.004.53c.01.1.014.226.015.402.003.597.018 1.194.032 1.79-.259 0-.517.002-.775-.001-.283-.004-.279.037-.461-.13a3.326 3.326 0 0 1-.362-.472 24.345 24.345 0 0 1-1.514-2.274 10.184 10.184 0 0 1-1.568-4.845c-.075-1.826-.035-3.678-.046-5.506a4.658 4.658 0 0 1 .28-2.318 5.123 5.123 0 0 1 1.886-1.628 10.352 10.352 0 0 1 5.028-.889 14.098 14.098 0 0 1 5.207.798 4.221 4.221 0 0 1 1.69 1.406 3.28 3.28 0 0 1 .36 1.964Z' />
      <path d='M17.107 11.486a7.767 7.767 0 0 1-.18-.34 1.69 1.69 0 0 1-.106-.244 4.41 4.41 0 0 1-.09-.315l-.013-.052a1.9 1.9 0 0 1-.008-.235.758.758 0 0 0-.75-.75c-.025.001-.047.013-.072.017a.783.783 0 0 0-.71.534 8.994 8.994 0 0 0-.345 1.256 4.987 4.987 0 0 1-.471 1.133 2.54 2.54 0 0 0 .097 2.88 2.191 2.191 0 0 0 2.932.224 2.421 2.421 0 0 0 .443-2.696 11.422 11.422 0 0 0-.727-1.412Zm-.558 2.647a.629.629 0 0 1-.011.056c-.01.037-.02.075-.033.112l-.005.016a.123.123 0 0 0-.013.021 2.053 2.053 0 0 1-.059.1c-.022.024-.046.049-.07.072l.002-.002-.008.009a.62.62 0 0 1-.15.04 1.003 1.003 0 0 1-.34-.038.57.57 0 0 1-.268-.128.318.318 0 0 1-.03-.028l-.05-.081c-.012-.019-.009-.011-.01-.013a1.436 1.436 0 0 1-.052-.178l-.005-.045a2.28 2.28 0 0 0 0-.156l.008-.062.031-.13a2.495 2.495 0 0 1 .083-.255c.047-.122.154-.327.234-.48.071-.133.129-.27.189-.405.089.154.18.307.262.464a2.3 2.3 0 0 1 .176.392 3.374 3.374 0 0 1 .12.454c-.003-.028.004.133.004.176l-.005.09Z' />
      <path d='M20.986 8.025a3.462 3.462 0 0 0-2.746-.72c-1.224.051-2.448.03-3.673.046-1.26.016-3.073-.198-3.732 1.174a3.853 3.853 0 0 0-.223 1.58c-.03.76-.114 1.5-.083 2.261.063 1.507.092 3.02.176 4.525a1.438 1.438 0 0 0 .906 1.464 7.418 7.418 0 0 0 2.23.143c1.686-.02 3.354-.142 5.04-.038.696.043 1.644.219 2.212-.301a1.934 1.934 0 0 0 .443-1.6c-.053-1.536.003-3.067.035-4.602a21.458 21.458 0 0 0-.045-2.214 2.682 2.682 0 0 0-.54-1.718Zm-2.828 8.893c-1.262-.052-2.526.014-3.787.065a17.7 17.7 0 0 1-1.606-.006 2.437 2.437 0 0 0-.528-.035l-.026-.002c-.012-.227-.024-.665-.026-.71-.039-1.225-.096-2.45-.148-3.675a14.262 14.262 0 0 1 .026-1.768c.037-.463-.013-.976.067-1.432.075-.423.507-.454.868-.472.65-.032 1.303-.03 1.954-.037 1.005-.01 2.012.024 3.015-.028.5-.025 1.487-.188 1.899.201.242.229.18.954.195 1.277.03.676.02 1.354.001 2.03-.037 1.411-.074 2.82-.026 4.232 0 .024-.01.282-.01.444h-.063c-.601.015-1.205-.06-1.805-.084Zm1.874.182.004.034c-.02-.011-.016-.023-.004-.034Z' />
    </g>
  </svg>
);

export default SvgGlucose;
