import {
  ChangeEvent,
  FC,
  FocusEvent,
  Fragment,
  PropsWithChildren
} from 'react';
import { DateTimeSeparator } from '../DateTimeInputs.styles';
import {
  InputContainer,
  InputHelpMessage,
  InputIcon,
  InputStateMessage,
  InputWithDigits,
  InputWrapper
} from '../../Input.styles';
import { IInputProps } from '../../Input.types';
import useTimeInput from './TimeInput.logic';
import TimePicker from './TimePicker';
import { IInputFormat } from 'utils/form';
import IProps from 'interfaces/IProps';

export interface ITimeInputProps
  extends PropsWithChildren<
    Omit<IInputProps, 'suffix' | 'placeholder' | 'prefix'> & IProps
  > {}

const TimeInput: FC<ITimeInputProps> = (
  props: ITimeInputProps
): JSX.Element => {
  const { dataTestId, className, style, icon, disabled, help } = props;
  const {
    inputFormat,
    input,
    values,
    isFocused,
    showPopup,
    containerRef,
    containerClickHandler,
    inputClickHandler,
    inputChangeHandler,
    focusHandler,
    blurHandler,
    timePickerChangeHandler
  } = useTimeInput(props);
  const { success, error } = input;

  return (
    <InputWrapper className={className} style={style}>
      {icon && <InputIcon src={icon} />}
      <div>
        {success && (
          <InputStateMessage
            data-testid={`${dataTestId}_success`}
            type='success'
          >
            {success}
          </InputStateMessage>
        )}
        {error && (
          <InputStateMessage data-testid={`${dataTestId}_error`} type='alert'>
            {error}
          </InputStateMessage>
        )}
        <InputContainer
          style={{ width: 'fit-content', cursor: 'text' }}
          isFocused={isFocused}
          state={success ? 'success' : error ? 'alert' : undefined}
          onClick={containerClickHandler}
          ref={containerRef}
        >
          {inputFormat.map((format: IInputFormat, index: number) => (
            <Fragment key={index}>
              {format.type === 'input' && (
                <InputWithDigits
                  data-testid={`${dataTestId}_${format.text}`}
                  nrDigits={format.text?.length}
                  type='number'
                  value={values[format.text!].value?.toLocaleString('en-US', {
                    minimumIntegerDigits: values[format.text!].verified
                      ? format.nrDigits
                      : undefined
                  })}
                  disabled={disabled}
                  min={format.min}
                  max={format.max}
                  step={format.step}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    inputChangeHandler(event, format)
                  }
                  onClick={inputClickHandler}
                  onFocus={focusHandler}
                  onBlur={(event: FocusEvent<HTMLInputElement>) =>
                    blurHandler(format, event)
                  }
                />
              )}
              {format.type === 'separator' && (
                <DateTimeSeparator>{format.text}</DateTimeSeparator>
              )}
            </Fragment>
          ))}
          <TimePicker
            show={showPopup}
            inputFormat={inputFormat}
            values={values}
            onChange={timePickerChangeHandler}
            onClose={blurHandler}
          />
        </InputContainer>
        {help && (
          <InputHelpMessage data-testid={`${dataTestId}_help`}>
            {help}
          </InputHelpMessage>
        )}
      </div>
    </InputWrapper>
  );
};

TimeInput.displayName = 'TimeInput';

export default TimeInput;
