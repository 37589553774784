import { useDate } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import {
  dateTimeFormatToInput,
  IInputFormat,
  InputTypes,
  updateDateValue
} from 'utils/form';
import useDateTimeInputs, { IUseDateTimeInputs } from '../DateTimeInputs.logic';
import { ITimeInputProps } from './TimeInput';

export interface IUseTimeInput
  extends Omit<
    IUseDateTimeInputs,
    'value' | 'dispatchValues' | 'changeHandler'
  > {
  inputFormat: IInputFormat[];
  timePickerChangeHandler: (type: string, value: number) => void;
}

const useTimeInput = (props: ITimeInputProps): IUseTimeInput => {
  const { masks } = useDate();
  const inputFormat = dateTimeFormatToInput(masks.shortTime);
  const {
    input,
    value,
    isFocused,
    showPopup,
    containerRef,
    values,
    dispatchValues,
    containerClickHandler,
    inputClickHandler,
    inputChangeHandler,
    changeHandler,
    focusHandler,
    blurHandler
  } = useDateTimeInputs(props, inputFormat);

  if (input.type !== InputTypes.TIME)
    throw new Error('Time Input requires the formInput to be a Time.');

  const timePickerChangeHandler = (type: string, inputValue: number): void => {
    dispatchValues({ type, value: inputValue });

    const newTime = value as Date;
    updateDateValue(type, inputValue, newTime);
    changeHandler(newTime);
  };

  return {
    inputFormat,
    input,
    values,
    isFocused,
    showPopup,
    containerRef,
    containerClickHandler,
    inputClickHandler,
    inputChangeHandler,
    focusHandler,
    blurHandler,
    timePickerChangeHandler
  };
};

export default useTimeInput;
