import IUserCountry from '../interfaces/IUserCountry';

export const getUserCountry = (): Promise<IUserCountry> => {
  return new Promise((resolve, reject) => {
    fetch('http://ip-api.com/json', { method: 'GET' })
      .then((response: Response) => {
        response
          .json()
          .then((json: IUserCountry) => {
            resolve(json);
          })
          .catch(() => reject());
      })
      .catch(() => reject());
  });
};

export const getTimezone = (): string => {
  return new Date()
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ')[2]
    .toUpperCase();
};
