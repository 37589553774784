import { Flex } from 'components/Templates';
import { FC, PropsWithChildren } from 'react';
import useInputStep from './InputStep.logic';
import { InputStepArrowButton, InputStepNumberInput } from './InputStep.styles';

export interface IInputStepProps extends PropsWithChildren<{}> {
  valueDataTestId?: string;
  upDataTestId?: string;
  downDataTestId?: string;
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;
}

const InputStep: FC<IInputStepProps> = (
  props: IInputStepProps
): JSX.Element => {
  const {
    valueDataTestId,
    upDataTestId,
    downDataTestId,
    value,
    min,
    max,
    step
  } = props;
  const { inputRef, increaseHandler, decreaseHandler } = useInputStep(props);

  return (
    <Flex direction='column'>
      <InputStepArrowButton
        dataTestId={upDataTestId}
        type='text'
        theme='dark'
        icon='arrowTop'
        onClick={increaseHandler}
      />
      <InputStepNumberInput
        data-testid={valueDataTestId}
        type='number'
        value={
          value
            ? value.toLocaleString('en-US', {
                minimumIntegerDigits: 2
              })
            : min
        }
        min={min}
        max={max}
        step={step}
        readOnly
        ref={inputRef}
      />
      <InputStepArrowButton
        dataTestId={downDataTestId}
        type='text'
        theme='dark'
        icon='arrowDown'
        onClick={decreaseHandler}
      />
    </Flex>
  );
};

export default InputStep;
