import { SVGProps } from 'react';

const SvgQuestionnaire = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M25.62 24.715a31.972 31.972 0 0 1-.07-3.792c.164-3.019-.017-6.071-.073-9.092-.013-.744-.035-1.49-.029-2.235a1.798 1.798 0 0 0-.436-1.361 2.37 2.37 0 0 0-1.733-.63l.001-.365.005-1.693a5.716 5.716 0 0 0-.024-1.355c-.258-1.038-1.462-.987-2.317-.924-.886.065-1.622.453-1.656 1.435a7.928 7.928 0 0 0 .132 1.095c.052.495.015 1.011.018 1.508l-.002.323c-2.617.164-5.239.09-7.859.033-.662-.015-1.323-.028-1.985-.034a6.173 6.173 0 0 0-1.627.06 1.93 1.93 0 0 0-1.352 1.405 6.118 6.118 0 0 0-.082 2.027c.06.926.069 1.86.075 2.789.017 2.711-.191 5.417-.251 8.126a37.947 37.947 0 0 0 .057 3.887 1.89 1.89 0 0 0 2.033 1.819c1.312.09 2.657.002 3.972-.002l4.121-.012 4.12-.012 1.999-.005a6.341 6.341 0 0 0 1.91-.075c1.182-.404 1.117-1.923 1.053-2.92ZM21.778 7.142l.002.098-.023 7.124c-.004 1.18.022 2.36.017 3.54a3.251 3.251 0 0 1-.273 1.463c-.065.145-.138.287-.206.431a5.11 5.11 0 0 1-.52-1.363 13.212 13.212 0 0 1 .006-2.789c.065-1.974.155-3.948.162-5.924.003-.831.04-1.688.036-2.542.266-.02.532-.033.8-.038Zm-.72-2.38c.17-.01.54.068.682-.006.035.009.038-.01.06-.012a7.265 7.265 0 0 0-.048.899q-.43.009-.858.045c-.029-.27-.052-.54-.102-.804a.309.309 0 0 1 0-.082c.076-.005.196-.036.266-.04Zm2.973 21.444c-.104.08-.61.001-.75.002l-.873.002-1.874.006-3.496.01-7.118.02c-.489.002-.988.037-1.475-.005-.635-.055-.539-.34-.565-.927a49.929 49.929 0 0 1-.022-3.4c.054-2.305.213-4.606.244-6.912.02-1.583-.1-3.157-.112-4.737-.003-.38-.046-.977.374-1.131A4.93 4.93 0 0 1 9.84 9.13c.662.008 1.324.022 1.986.037 2.534.058 5.069.12 7.6-.038q-.02 2.836-.116 5.671a24.563 24.563 0 0 0-.043 3.622 11.002 11.002 0 0 0 1.418 3.41.754.754 0 0 0 1.296 0 9.613 9.613 0 0 0 1.283-3.3c.082-1.216-.011-2.455-.008-3.673q.007-2.88.018-5.76a1.54 1.54 0 0 1 .348.037c.293.076.305.09.326.335.1 1.185.03 2.412.054 3.602.048 2.265.117 4.534.083 6.8-.017 1.142-.124 2.27-.061 3.413.029.516.072 1.032.103 1.548.018.285.179 1.157-.096 1.371Z' />
      <path d='M10.297 13.06q2.987.106 5.975.046a.75.75 0 0 0 0-1.5q-2.987.06-5.975-.046a.75.75 0 0 0 0 1.5ZM18.238 16.396c.962-.041.967-1.542 0-1.5a77.952 77.952 0 0 1-8.138-.076c-.964-.06-.96 1.44 0 1.5a77.952 77.952 0 0 0 8.138.076ZM10.377 19.674q2.85.052 5.699-.11a.767.767 0 0 0 .75-.75.757.757 0 0 0-.75-.75q-2.847.162-5.699.11a.75.75 0 0 0 0 1.5ZM20.517 22.2q-5.051.125-10.101-.098c-.965-.042-.963 1.458 0 1.5q5.047.223 10.1.098a.75.75 0 0 0 0-1.5Z' />
    </g>
  </svg>
);

export default SvgQuestionnaire;
