import { ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import { InputTypes } from 'utils/form';
import useInput, { IUseInput } from '../Input.logic';
import { INumberInputProps } from './NumberInput';

export interface IUseNumberInput extends Omit<IUseInput, 'changeHandler'> {
  changeStepHandler: (value: number) => void;
  keyDownHandler: (event: KeyboardEvent<HTMLInputElement>) => void;
}

const useNumberInput = (props: INumberInputProps): IUseNumberInput => {
  const { nrDigits, step, min, max } = props;
  const {
    input,
    value,
    isFocused,
    showPopup,
    containerRef,
    changeHandler,
    focusHandler,
    blurHandler: inputBlurHandler
  } = useInput(props, true);

  if (input?.type !== InputTypes.NUMBER)
    throw new Error('Number Input requires the type to be NUMBER.');

  const checkAndUpdateValue = (currentValue?: string): void => {
    if (currentValue === '') {
      changeHandler('');
      return;
    }

    let newValue = currentValue !== undefined ? +currentValue : +value;

    if (min && newValue < min) newValue = min;
    else if (max && newValue > max) newValue = max;
    else if (step) {
      const modulus = newValue % step;
      newValue =
        modulus < step / 2 ? newValue - modulus : newValue + (step - modulus);
    } else if (nrDigits && newValue.toString().length > nrDigits) {
      changeHandler(+newValue.toString().slice(0, -1));
      return;
    }

    changeHandler(newValue);
  };

  const inputChangeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    changeHandler(event.target.value, false);
  };

  const blurHandler = (
    event?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    checkAndUpdateValue();
    inputBlurHandler(event);
  };

  const keyDownHandler = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') checkAndUpdateValue();
  };

  const changeStepHandler = (value: number): void => {
    changeHandler(value);
  };

  return {
    input,
    value,
    isFocused,
    showPopup,
    containerRef,
    inputChangeHandler,
    focusHandler,
    blurHandler,
    keyDownHandler,
    changeStepHandler
  };
};

export default useNumberInput;
