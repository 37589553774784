import Icon, { IIconProps } from 'components/Icon/Icon';
import { Flex, IFlexProps } from 'components/Templates';
import styled, { css } from 'styled-components';
import styles from 'styles';
import { rgba } from 'utils/colors';
import { transition } from 'utils/styles';

export interface ITagOptionWrapperProps {
  selected: boolean;
}

export const TagOptionWrapper = styled(Flex).attrs((_: IFlexProps) => ({
  justifyContent: 'flex-start'
}))<ITagOptionWrapperProps>`
  position: relative;
  padding: 0.5625rem 0;
  cursor: pointer;
  ${transition('color')}

  &:hover {
    color: ${rgba(styles.colors.brand.dark, 0.7)};
  }

  ${({ selected }: ITagOptionWrapperProps) =>
    selected
      ? css`
          > span {
            font-weight: 600;
          }
        `
      : ''}

  > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;

export interface ITagOptionCheckIconProps {}

export const TagOptionCheckIcon = styled(Icon).attrs((_: IIconProps) => ({
  src: 'check',
  size: styles.iconSize.text,
  fill: styles.colors.brand.dark
}))<ITagOptionCheckIconProps>`
  margin-left: auto;
`;
