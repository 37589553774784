import Icon from 'components/Icon/Icon';
import IProps from 'interfaces/IProps';
import { FC, PropsWithChildren } from 'react';
import styles from 'styles';
import { rgba } from 'utils/colors';
import { IInputProps } from '../Input.types';
import usePhotoInput from './PhotoInput.logic';
import {
  PhotoInputImage,
  PhotoInputLabel,
  PhotoInputInputWrapper
} from './PhotoInput.styles';

export interface IPhotoInputProps
  extends PropsWithChildren<
    Omit<
      IInputProps,
      'icon' | 'success' | 'error' | 'prefix' | 'suffix' | 'help'
    > &
      IProps
  > {
  uploadedLabel?: string;
}

const PhotoInput: FC<IPhotoInputProps> = (
  props: IPhotoInputProps
): JSX.Element => {
  const { dataTestId, className, style, disabled, uploadedLabel } = props;
  const { language, value, imageSrc, fileUploadChangeHandler } =
    usePhotoInput(props);

  return (
    <PhotoInputInputWrapper as='label' className={className} style={style}>
      <input
        data-testid={dataTestId}
        type='file'
        accept='image/png,image/jpeg'
        disabled={disabled}
        onChange={fileUploadChangeHandler}
      />
      {value && imageSrc && (
        <PhotoInputImage>
          <img src={imageSrc} alt='Uploaded image' />
        </PhotoInputImage>
      )}
      {!value && (
        <Icon
          src='camera'
          size='7.5rem'
          backgroundColor={rgba(styles.colors.brand.main, 0.1)}
          fill={rgba(styles.colors.brand.main, 0.1)}
        />
      )}
      <PhotoInputLabel uploaded={!!value}>
        {value ? (
          <>
            {uploadedLabel && (
              <>
                {uploadedLabel}
                <br />
              </>
            )}
            {language.selectPhotoToEditIfYouDontAgree}
          </>
        ) : (
          language.addPhoto
        )}
      </PhotoInputLabel>
    </PhotoInputInputWrapper>
  );
};

PhotoInput.displayName = 'PhotoInput';

export default PhotoInput;
