import IProps from 'interfaces/IProps';
import { FC, PropsWithChildren } from 'react';
import useInput from '../Input.logic';
import { InputTypes } from 'utils/form';
import { InputIcon, InputStateMessage, InputWrapper } from '../Input.styles';
import { IInputProps } from '../Input.types';
import { TextAreaInputContainer } from './TextArea.styles';

export interface ITextAreaProps
  extends PropsWithChildren<
    Omit<IInputProps, 'prefix' | 'suffix' | 'help'> & IProps
  > {}

const TextArea: FC<ITextAreaProps> = (props: ITextAreaProps): JSX.Element => {
  const { dataTestId, className, style, icon, disabled, placeholder } = props;
  const {
    input,
    value,
    isFocused,
    inputChangeHandler,
    focusHandler,
    blurHandler
  } = useInput(props);
  const { success, error } = input;

  if (input?.type !== InputTypes.TEXT)
    throw new Error('TextArea requires the type to be TEXT.');

  return (
    <InputWrapper className={className} style={style}>
      {icon && <InputIcon src={icon} />}
      <div>
        {success && (
          <InputStateMessage
            data-testid={`${dataTestId}_success`}
            type='success'
          >
            {success}
          </InputStateMessage>
        )}
        {error && (
          <InputStateMessage data-testid={`${dataTestId}_error`} type='alert'>
            {error}
          </InputStateMessage>
        )}
        <TextAreaInputContainer
          isFocused={isFocused}
          state={success ? 'success' : error ? 'alert' : undefined}
        >
          <textarea
            data-testid={dataTestId}
            rows={3}
            placeholder={placeholder}
            value={value as string}
            disabled={disabled}
            onChange={inputChangeHandler}
            onFocus={focusHandler}
            onBlur={blurHandler}
          />
        </TextAreaInputContainer>
      </div>
    </InputWrapper>
  );
};

TextArea.displayName = 'TextArea';

export default TextArea;
