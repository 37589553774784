import IReduxState from 'interfaces/IReduxState';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

const enum METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export interface IUseHttp {
  isLoading: boolean;
  get: (url: string, parameters?: any) => Promise<any>;
  post: (url: string, parameters?: any, body?: any) => Promise<any>;
  put: (url: string, parameters?: any, body?: any) => Promise<any>;
  del: (url: string, parameters?: any, body?: any) => Promise<any>;
}

const useHttp = (): IUseHttp => {
  // const navigate = useNavigate();
  const token = useSelector((state: IReduxState) => state.auth.token);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [controller] = useState<AbortController>(new AbortController());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let isSubscribed = true;

  useEffect(() => {
    return () => {
      controller.abort();
      isSubscribed = false;
      setIsMounted(false);
    };
  }, []);

  const getHeaders = (
    method: METHOD,
    bodyIsFormData: boolean = false
  ): RequestInit => {
    let headers = new Headers();
    if (token) headers.append('Authorization', `Bearer ${token}`);
    if (!bodyIsFormData) headers.append('Content-Type', 'application/json');

    return {
      method,
      mode: 'cors',
      credentials: 'same-origin',
      headers: headers,
      redirect: 'follow',
      referrerPolicy: 'no-referrer'
    };
  };

  const getParameters = (params: any): string => {
    if (params === undefined) return '';

    const parameters = new URLSearchParams(params);
    parameters.forEach((value: string, key: string) => {
      if (value === 'undefined') parameters.delete(key);
    });

    const parametersString = parameters.toString();
    return parametersString === '' ? '' : `?${parametersString}`;
  };

  const request = (
    method: METHOD,
    url: string,
    parameters?: any,
    body?: any
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);

      const bodyIsFormData = body instanceof FormData;

      fetch(`${url}${getParameters(parameters)}`, {
        signal: controller.signal,
        ...getHeaders(method, bodyIsFormData),
        body: body ? (bodyIsFormData ? body : JSON.stringify(body)) : undefined
      })
        .then((response) => {
          if (isSubscribed) setIsLoading(false);

          const contentType = response.headers.get('Content-Type');

          if (response.ok) {
            if (contentType?.includes('application/json')) {
              return response.json().then((json: any) => {
                if (!isMounted) return;

                resolve(json);
              });
            } else if (contentType?.includes('text/plain')) {
              return response.text().then(() => {
                // if (isMounted) resolve();
              });
            } else if (
              contentType?.includes('image/jpeg') ||
              contentType?.includes('image/png') ||
              contentType?.includes('application/pdf')
            ) {
              return response.blob().then((blob: Blob) => {
                if (isMounted) resolve(URL.createObjectURL(blob));
              });
            }

            // if (isMounted) resolve();
          } else if (response.status === 401) {
            // navigate(routes.login.path, {
            //   unauthorized: true
            // } as ILoginRouteState);
          } else {
            if (contentType?.includes('application/json')) {
              return response.json().then((json: any) => {
                reject({
                  status: response.status,
                  message: json.message,
                  errors: json.errors
                });
              });
            }

            reject({ status: response.status, message: response.statusText });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.name !== 'AbortError') reject(error);
        });
    });
  };

  const get = (url: string, parameters?: any): Promise<any> => {
    return request(METHOD.GET, url, parameters, undefined);
  };

  const post = (url: string, parameters?: any, body?: any): Promise<any> => {
    return request(METHOD.POST, url, parameters, body);
  };

  const put = (url: string, parameters?: any, body?: any): Promise<any> => {
    return request(METHOD.PUT, url, parameters, body);
  };

  const del = (url: string, parameters?: any, body?: any): Promise<any> => {
    return request(METHOD.DELETE, url, parameters, body);
  };

  return { isLoading, get, post, put, del };
};

export default useHttp;
