import { SVGProps } from 'react';

const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M27.15 7.26a8.923 8.923 0 0 0-2.588-2.557 4.577 4.577 0 0 0-1.416-.606 4.856 4.856 0 0 0-1.815-.27 10.498 10.498 0 0 0-3.308.814 7.557 7.557 0 0 0-1.386.87c-.49.381-.857.856-1.312 1.267-1.977 1.784-3.85 3.682-5.741 5.564l-2.967 2.953c-.437.435-.867.878-1.313 1.304a5.351 5.351 0 0 0-1.116 1.233 3.806 3.806 0 0 0-.235 1.852l-.001 2.105-.005 4.257a4.889 4.889 0 0 0 .02 1.344 1.392 1.392 0 0 0 1.263.897 34.175 34.175 0 0 0 4.038-.08c1.458-.035 2.957.03 4.41-.087.6-.049.951-.511 1.379-.882q.825-.715 1.63-1.454c2.288-2.098 4.461-4.318 6.606-6.562.795-.815 1.485-1.71 2.306-2.492a8.969 8.969 0 0 0 2.13-2.902 6.94 6.94 0 0 0-.578-6.567ZM8.534 26.727l-1.492.036a.836.836 0 0 0-.24-.247c-.048-.032-.095-.067-.142-.1a4.317 4.317 0 0 1-.698-.697c-.051-.071-.104-.142-.151-.216a.8.8 0 0 0-.362-.3l.002-1.556.003-3.59c0-.296-.04-.66-.021-1l1.462.049.904.031a4.509 4.509 0 0 0 .816.047c.002.102.006.218.006.192.002.292 0 .585-.004.878q-.011.877-.05 1.754c-.024.536-.034 1.107.48 1.433a1.996 1.996 0 0 0 1.416.115l2.692-.376q0 1.724-.126 3.445c-.325.003-.65.009-.976.017Zm8.692-3.503q-1.256 1.206-2.557 2.365c-.026.023-.054.051-.08.076.059-1.113.093-2.228.074-3.343a.764.764 0 0 0-.95-.723l-2.136.299-1.067.149c-.149.02-.297.043-.445.062l-.029.004c.01-.217.046-.45.052-.632q.021-.614.029-1.228c.009-.69.156-1.62-.354-2.178s-1.467-.42-2.145-.444l-1.196-.041a6.07 6.07 0 0 0 .257-.239l2.416-2.404 5.127-5.103c.394-.391.793-.778 1.19-1.167.02-.02.042-.036.063-.055a89.62 89.62 0 0 0 8.032 8.144c-.418.47-.831.943-1.274 1.396-1.639 1.716-3.294 3.418-5.006 5.062Zm9.278-10.347a7.258 7.258 0 0 1-1.964 2.794l-.005.005a89.63 89.63 0 0 1-7.942-8.05 7.189 7.189 0 0 1 2.491-1.821 5.513 5.513 0 0 1 3.001-.417 6.63 6.63 0 0 1 1.315.372 6.08 6.08 0 0 1 1.292.908 5.74 5.74 0 0 1 1.812 6.21Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgEdit;
