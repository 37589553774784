import Icon, { IIconProps } from 'components/Icon/Icon';
import {
  Flex,
  IFlexProps,
  ISmallTextProps,
  SmallText
} from 'components/Templates';
import styled, { css } from 'styled-components';
import styles from 'styles';
import { rgba } from 'utils/colors';
import { transition } from 'utils/styles';

export type StateType = 'success' | 'alert';

export interface IInputWrapperProps {}

export const InputWrapper = styled(Flex).attrs(
  ({ justifyContent, alignItems }: IFlexProps) => ({
    justifyContent: justifyContent || 'flex-start',
    alignItems: alignItems || 'flex-start'
  })
)<IInputWrapperProps>`
  box-sizing: border-box;

  > div {
    flex: 1;
    max-width: 100%;
  }
`;

export interface IInputIconProps {}

export const InputIcon = styled(Icon).attrs((_: IIconProps) => ({
  size: styles.iconSize.text,
  fill: styles.colors.brand.dark
}))<IInputIconProps>`
  min-width: ${styles.iconSize.text};
  margin-top: 2rem;
  margin-right: 1rem;
`;

export interface IInputContainerProps {
  isFocused: boolean;
  state?: StateType;
}

export const InputContainer = styled(Flex).attrs((_: IFlexProps) => ({
  justifyContent: 'flex-start'
}))<IInputContainerProps>`
  position: relative;
  width: 100%;
  padding: 0.44rem 1.25rem 0.44rem 1rem;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-color: ${({ isFocused, state }: IInputContainerProps) =>
    (isFocused && styles.colors.brand.main) ||
    (state && styles.colors.feedback[state])};
  border-radius: 999px;
  margin-top: ${({ state }: IInputContainerProps) =>
    !state ? styles.inputMargin : '0'};
  background-color: ${rgba(styles.colors.brand.main, 0.1)};
  ${transition('border-color', 'color', 'background-color')}

  > * {
    display: inline-flex;
  }

  > input {
    flex: 1;
    min-width: 0;
    height: 100%;
    background-color: transparent;

    :focus {
      outline: none !important;
    }

    ::placeholder {
      color: ${rgba(styles.colors.brand.dark, 0.4)};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button,
    &[type='date']::-webkit-inner-spin-button,
    &[type='date']::-webkit-calendar-picker-indicator,
    &[type='time']::-webkit-inner-spin-button,
    &[type='time']::-webkit-calendar-picker-indicator,
    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-results-button,
    &[type='search']::-webkit-search-results-decoration {
      display: none;
      -webkit-appearance: none;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

export interface IInputPlaceholderProps {}

export const InputPlaceholder = styled.span<IInputPlaceholderProps>`
  display: flex;
  align-items: center;
  height: 2.5rem;
  margin-left: 1rem;
  color: ${rgba(styles.colors.brand.dark, 0.4)};
  pointer-events: none;
`;

export interface IInputPrefixProps {}

export const InputPrefix = styled.span<IInputPrefixProps>`
  margin-right: 1.5rem;
  color: ${rgba(styles.colors.brand.dark, 0.4)};
`;

export interface IInputWithDigitsProps {
  placeholder?: string;
  nrDigits?: number;
}

export const InputWithDigits = styled.input<IInputWithDigitsProps>`
  ${({ nrDigits }: IInputWithDigitsProps) =>
    nrDigits
      ? css`
          width: ${({ placeholder }: IInputWithDigitsProps) =>
            `${0.6 * Math.max(placeholder?.length || 0, nrDigits)}rem`};
          min-width: auto !important;
        `
      : ''}
`;

export interface IInputSuffixProps {}

export const InputSuffix = styled.span<IInputSuffixProps>`
  margin-left: 0.375rem;
`;

const stateMessageMargin = '0.125rem';

export interface IInputStateMessageProps {
  type: StateType;
}

export const InputStateMessage = styled(SmallText).attrs(
  (_: ISmallTextProps) => ({
    as: 'span'
  })
)<IInputStateMessageProps>`
  display: block;
  margin-bottom: ${stateMessageMargin};
  margin-left: 1rem;
  color: ${({ type }: IInputStateMessageProps) => styles.colors.feedback[type]};
`;

export interface IInputHelpMessageProps {}

export const InputHelpMessage = styled(SmallText).attrs(
  (_: ISmallTextProps) => ({
    as: 'span'
  })
)<IInputHelpMessageProps>`
  display: block;
  margin-top: ${stateMessageMargin};
  margin-left: 1rem;
  color: ${rgba(styles.colors.brand.dark, 0.6)};
  font-size: ${styles.textSize.small};
`;
