import IProps from 'interfaces/IProps';
import IOption, { IOptionGroup } from 'interfaces/IOption';
import { FC, PropsWithChildren } from 'react';
import TagOption from './TagOption';
import {
  OptionsGroup,
  OptionsGroupIcon,
  OptionsGroupTitle,
  OptionsList,
  TagsInputPopup
} from './TagsInputOptionsPopup.styles';

export interface ITagsInputOptionsPopupProps extends PropsWithChildren<IProps> {
  show: boolean;
  identifier: string;
  tags: IOptionGroup[] | IOption[];
  selectedTags: string[];
  onAddTag: (tagId: string, groupIndex?: number) => void;
  onRemoveTag: (tagId: string) => void;
  onClose: () => void;
}

const TagsInputOptionsPopup: FC<ITagsInputOptionsPopupProps> = (
  props: ITagsInputOptionsPopupProps
): JSX.Element => {
  const {
    dataTestId,
    show,
    identifier,
    tags,
    selectedTags,
    onAddTag,
    onRemoveTag,
    onClose
  } = props;

  const tagIsSelected = (tag: IOption): boolean => {
    return !!selectedTags.find((selectedTag: string) => selectedTag === tag.id);
  };

  return (
    <TagsInputPopup top='100%' show={show} onClose={onClose}>
      {tags.length > 0 && (
        <OptionsList>
          {'options' in tags[0] ? (
            <>
              {(tags as IOptionGroup[]).map(
                (tagGroup: IOptionGroup, index: number) => (
                  <OptionsGroup key={index}>
                    <OptionsGroupTitle>
                      {tagGroup.icon && (
                        <OptionsGroupIcon src={tagGroup.icon} />
                      )}
                      <b>{tagGroup.name}</b>
                    </OptionsGroupTitle>
                    <ul>
                      {tagGroup.options.map(
                        (tag: IOption, tagIndex: number) => (
                          <li key={tag.id}>
                            <TagOption
                              dataTestId={`${dataTestId}-${index}-${tagIndex}`}
                              identifier={`${identifier}-${index}`}
                              tag={tag}
                              isSelected={tagIsSelected(tag)}
                              onAddTag={(tagId: string) =>
                                onAddTag(tagId, index)
                              }
                              onRemoveTag={onRemoveTag}
                            />
                          </li>
                        )
                      )}
                    </ul>
                  </OptionsGroup>
                )
              )}
            </>
          ) : (
            <>
              {(tags as IOption[]).map((tag: IOption, index: number) => (
                <TagOption
                  key={tag.id}
                  dataTestId={`${dataTestId}-${index}`}
                  identifier={identifier}
                  tag={tag}
                  isSelected={tagIsSelected(tag)}
                  onAddTag={onAddTag}
                  onRemoveTag={onRemoveTag}
                />
              ))}
            </>
          )}
        </OptionsList>
      )}
    </TagsInputPopup>
  );
};

export default TagsInputOptionsPopup;
