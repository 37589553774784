import { useState } from 'react';
// import { LOG_COMPONENT, LOG_ERROR } from 'utils/logger';
// import { useWinstonLogger } from 'winston-react';

export interface IUseStep {
  step: number;
  isFistStep: boolean;
  isFinalStep: boolean;
  previousStepHandler: () => void;
  nextStepHandler: () => void;
  goToStep: (step: number) => void;
}

const useStep = (nrSteps: number, initialStep?: number): IUseStep => {
  // const logger = useWinstonLogger();
  const [step, setStep] = useState<number>(
    initialStep != undefined ? initialStep : 0
  );

  const incrementStep = (increment: number): void => {
    setStep((prevState: number) => {
      const step = prevState + increment;
      if (step < 0) {
        // logger.log(LOG_ERROR, 'cannot go to previous step (min 0)');
        return prevState;
      }

      if (step > nrSteps - 1) {
        // logger.log(LOG_ERROR, `cannot go beyond max number of steps (max ${nrSteps})`);
        return nrSteps - 1;
      }

      // logger.log(LOG_COMPONENT, `going to step ${step}`);
      return step;
    });
  };

  const previousStepHandler = (): void => incrementStep(-1);

  const nextStepHandler = (): void => incrementStep(1);

  const goToStep = (step: number): void => {
    // logger.log(LOG_COMPONENT, `going to step ${step}`);
    setStep(step);
  };

  return {
    step,
    isFistStep: step === 0,
    isFinalStep: step === nrSteps - 1,
    previousStepHandler,
    nextStepHandler,
    goToStep
  };
};

export default useStep;
