import { SVGProps } from 'react';

const SvgLoading = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    overflow='visible'
    preserveAspectRatio='none'
    viewBox='0 0 34 34'
    {...props}
  >
    <g fill='#009eba'>
      <path
        d='M25.85 22.191a11.285 11.285 0 0 1-6.934 5.104 9.28 9.28 0 0 1-7.823-1.68c-4.706-3.535-5.875-10.683-1.887-15.19a10.781 10.781 0 0 1 8.861-3.956c.963.073.958-1.427 0-1.5a11.951 11.951 0 0 0-9.204 3.643 11.578 11.578 0 0 0-3.594 9.041c.385 6.433 6.169 12.19 12.802 11.318a12.715 12.715 0 0 0 9.075-6.023.75.75 0 0 0-1.295-.757ZM25 10.284l.113.141a10.944 10.944 0 0 1 .818 1.231.75.75 0 0 0 1.026.27.758.758 0 0 0 .345-.449.79.79 0 0 0-.076-.578 12.01 12.01 0 0 0-1.165-1.676.752.752 0 0 0-.53-.22.763.763 0 0 0-.53.22.755.755 0 0 0-.22.53l.026.2a.749.749 0 0 0 .193.33ZM28.672 15.8a.816.816 0 0 0-.22-.53.75.75 0 0 0-1.06 0 .725.725 0 0 0-.22.53 12.264 12.264 0 0 1 .028 2.455.753.753 0 0 0 .22.53.75.75 0 0 0 1.061 0 .843.843 0 0 0 .22-.53 12.264 12.264 0 0 0-.03-2.455Z'
        vectorEffect='non-scaling-stroke'
      />
    </g>
  </svg>
);

export default SvgLoading;
