import { SVGProps } from 'react';

const SvgCall = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M23.055 19.074c-.74-.736-1.576-1.443-2.694-1.119a5.399 5.399 0 0 0-1.564.892c-.229.167-.454.34-.674.52q-.128.103-.253.21l-.06.053a10.554 10.554 0 0 1-1.606-1.343 28.078 28.078 0 0 1-1.616-1.64 8.88 8.88 0 0 1-1.999-3.43c1.423-.885 3.557-2.524 2.312-4.267-.253-.356-.596-.63-.861-.975-.328-.425-.558-.919-.87-1.355A3.168 3.168 0 0 0 10.9 5.116a3.698 3.698 0 0 0-2.589 1.08 7.266 7.266 0 0 0-1.888 2.247c-.78 1.654-.249 4.012.268 5.658a10.807 10.807 0 0 0 1.311 2.984c.621.89 1.218 1.803 1.898 2.65a27.108 27.108 0 0 0 4.554 4.573 12.172 12.172 0 0 0 5.427 2.485 4.822 4.822 0 0 0 3-.368 8.617 8.617 0 0 0 2.286-1.79c1.956-2.087-.668-4.127-2.111-5.561Zm.695 4.843a9.028 9.028 0 0 1-1.193.964c-1.68 1.077-3.925.214-5.52-.663a18.364 18.364 0 0 1-4.6-3.846 24.688 24.688 0 0 1-2.038-2.46 17.304 17.304 0 0 1-1.637-2.505 11.146 11.146 0 0 1-1.156-5.663 4.214 4.214 0 0 1 1.687-2.42 2.391 2.391 0 0 1 1.412-.716 1.56 1.56 0 0 1 1.168.767c.342.461.594.982.937 1.444a9.396 9.396 0 0 1 .894 1.026c.24.46-.388.92-.696 1.195a9.276 9.276 0 0 1-1.468 1.06.712.712 0 0 0-.128.115.75.75 0 0 0-.43.837 9.936 9.936 0 0 0 2.545 4.655 29.994 29.994 0 0 0 1.977 1.981 12.289 12.289 0 0 0 1.085.89c.355.261.791.68 1.26.687.455.006.768-.357 1.082-.627a10.355 10.355 0 0 1 1.26-.925c.353-.22.666-.448 1.065-.237a6.26 6.26 0 0 1 1.263 1.183c.363.363.728.725 1.083 1.096a2.55 2.55 0 0 1 .796 1.167c.063.415-.37.74-.648.995Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgCall;
