import { Flex } from 'components/Templates';
import styled from 'styled-components';
import { transition } from 'utils/styles';

export interface ILoadMoreWrapperProps {}

export const LoadMoreWrapper = styled(Flex)<ILoadMoreWrapperProps>`
  margin-top: 1rem;
  ${transition('opacity', 'transform')}

  &.load-more-enter-active {
    opacity: 1;
    transform: translateY(0);
  }

  &.load-more-enter,
  &.load-more-exit,
  &.load-more-exit-active {
    opacity: 0;

    &:first-child {
      transform: translateY(-1rem);
    }

    &:last-child {
      transform: translateY(1rem);
    }
  }
`;
