import { SVGProps } from 'react';

const SvgStatus = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M27.646 14.73a8.071 8.071 0 0 0-6.965-5.58 8.19 8.19 0 0 0-4.631 1.063 7.554 7.554 0 0 0-3.37-1.063 8.228 8.228 0 0 0-3.444.498q-.075-.517-.152-1.032c-.057-.382-.088-.783-.18-1.157A.987.987 0 0 0 7.69 6.7a1.633 1.633 0 0 0-.604.358c-.175.14-.35.279-.527.418L4.636 8.999c-.378.3-.77.588-.797 1.117a.982.982 0 0 0 .31.744 1.68 1.68 0 0 0 .663.337q.15.051.295.11l.026.012c.054.025.109.05.162.076a6.048 6.048 0 0 1 .535.293l.255.16a6.758 6.758 0 0 0-.932 1.389 8.504 8.504 0 0 0 1.952 10.161 7.135 7.135 0 0 0 5.295 1.918 8.856 8.856 0 0 0 3.728-1.151 6.8 6.8 0 0 0 4.272 1.151 8.815 8.815 0 0 0 4.776-1.861 7.757 7.757 0 0 0 2.47-8.725ZM7.14 10.75h-.005Zm.008.002.06.013c-.022-.002-.039-.011-.06-.013ZM5.09 11.3c-.026-.011-.043-.02 0 0Zm.703-1.317.798-.631.98-.776.06.4q.096.63.187 1.26a.487.487 0 0 0 .042.094 7.58 7.58 0 0 0-.652.434l-.058-.014-.008-.002a3.222 3.222 0 0 1-.208-.127c-.156-.103-.31-.208-.47-.302a6.665 6.665 0 0 0-.671-.336Zm2.373 12.355a6.96 6.96 0 0 1-2.135-7.276 6.126 6.126 0 0 1 5.398-4.4 6.37 6.37 0 0 1 3.26.546 6.86 6.86 0 0 0-1.537 2.029 8.492 8.492 0 0 0 1.671 9.885 8.198 8.198 0 0 1-1.523.553 5.614 5.614 0 0 1-5.134-1.337Zm9.55-8.27a6.22 6.22 0 0 1-1.566 8.252 6.956 6.956 0 0 1-2.119-7.258 6.23 6.23 0 0 1 1.998-3.028 6.81 6.81 0 0 1 1.688 2.034Zm7.49 7.251a7.112 7.112 0 0 1-3.906 2.356 5.424 5.424 0 0 1-3.857-.47 7.739 7.739 0 0 0 2.203-8.475 8.953 8.953 0 0 0-2.294-3.506 6.407 6.407 0 0 1 8.365 2.844 6.34 6.34 0 0 1-.512 7.251Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgStatus;
