import { SVGProps } from 'react';

const SvgAddTask = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M27.3 16.727a6.837 6.837 0 0 0-3.757-3.503 4.32 4.32 0 0 0-.801-.184c0-.015.008-.025.008-.04 0-1.367-.02-2.735-.022-4.102-.001-.943.093-1.857.131-2.795.05-1.225-.545-1.915-1.797-1.845-.53.03-1.048.147-1.575.203-.802.086-1.617.1-2.422.144a135.4 135.4 0 0 1-5.364.204q-1.345.02-2.69-.004c-.893-.017-1.796-.12-2.687-.107-.816.013-1.068.558-1.076 1.282-.009.903.022 1.808.051 2.711.06 1.808.164 3.615.27 5.421a105.058 105.058 0 0 1 .234 12.31.764.764 0 0 0 .75.75 77.774 77.774 0 0 0 9.967.076.72.72 0 0 0 .117-.03 7.266 7.266 0 0 0 1.026.53 7.915 7.915 0 0 0 8.632-1.756A6.673 6.673 0 0 0 28 21.959a9.818 9.818 0 0 0-.7-5.232Zm-5.865-10.96c.014-.004.04-.005.047-.011a.039.039 0 0 1-.047.011ZM7.329 25.715c.291-6.34-.499-12.66-.575-18.996l-.004-.44c.306.007.676-.023.795-.018q1.222.051 2.444.056c1.63.01 3.26-.034 4.889-.104 1.532-.066 3.083-.102 4.61-.252.45-.044.893-.14 1.343-.184.078-.007.366.02.534.007l-.001.175c-.006.924-.136 1.842-.135 2.772 0 1.41.02 2.819.021 4.228h-.56a8.666 8.666 0 0 0-3.13.437 7.41 7.41 0 0 0-4.622 7.22 8.374 8.374 0 0 0 2.044 5.194q-3.827.14-7.653-.095Zm18.386-1.395a6.22 6.22 0 0 1-11.15-2.637 6 6 0 0 1 3.393-6.84 6.559 6.559 0 0 1 2.182-.384 15.931 15.931 0 0 1 2.347.045 4.609 4.609 0 0 1 2.961 2.032 6.638 6.638 0 0 1 1.12 3.892 6.86 6.86 0 0 1-.853 3.892Z' />
      <path d='M21.805 19.83c.007-1.235.005-2.47-.032-3.704-.029-.964-1.529-.968-1.5 0 .036 1.225.038 2.45.031 3.676a141.71 141.71 0 0 0-3.873 0 .75.75 0 0 0 0 1.5c1.285-.02 2.57-.014 3.855 0A105.37 105.37 0 0 1 20.161 25c-.053.965 1.448.962 1.5 0 .067-1.223.1-2.447.124-3.67 1.202.026 2.405.056 3.606.113.965.045.963-1.455 0-1.5a140.275 140.275 0 0 0-3.586-.113Z' />
    </g>
  </svg>
);

export default SvgAddTask;
