import { SVGProps } from 'react';

const SvgCategory = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M10.313 14.382c.729.036 1.467.009 2.196.011q2.311.007 4.622-.002c.812.005 1.626.077 2.437.058.791-.017 1.584-.057 2.38-.064a.762.762 0 0 0 .722-.95 3.167 3.167 0 0 0-.677-1.253c-.384-.554-.777-1.103-1.171-1.65-.768-1.065-1.533-2.134-2.303-3.198-.352-.486-.7-.978-1.065-1.454a1.154 1.154 0 0 0-1.384-.538c-.657.266-1.208 1.137-1.661 1.664a178.224 178.224 0 0 0-3.184 3.836l-1.086 1.34a2.88 2.88 0 0 0-.684 1.15.829.829 0 0 0 .858 1.05Zm4.738-5.826c.432-.508.866-1.014 1.313-1.508l.058-.055.138.187q.467.636.928 1.277.988 1.37 1.973 2.74c.35.487.7.974 1.045 1.466.057.082.125.168.194.256-.377.011-.755.017-1.131.03-.81.028-1.628-.054-2.438-.059-1.88.008-3.76.002-5.64-.007l.795-.981c.912-1.123 1.83-2.242 2.765-3.346ZM11.956 15.947q-2.127-.143-4.261-.116c-.7.009-1.392.08-2.09.103-.509.017-1.039-.061-1.438.341a2.888 2.888 0 0 0-.428 2.01l.002 2.37c.001 1.585.041 3.165.134 4.747.054.598.276 1.175.927 1.316a11.24 11.24 0 0 0 2.313.02l4.325-.024c1.073-.006 2.92.312 3.196-1.126a16.396 16.396 0 0 0 .14-2.095l.13-2.234.118-2.039a6.23 6.23 0 0 0-.013-1.934c-.367-1.371-1.917-1.263-3.055-1.34Zm1.557 3.467-.203 3.495-.096 1.651-.023.389a1.868 1.868 0 0 0-.018.252.102.102 0 0 1-.028 0 2.29 2.29 0 0 0-.427.005l-3.932.023-1.966.011-.983.006a2.343 2.343 0 0 0-.467 0c-.123-2.193-.13-4.385-.13-6.582 0-.253-.005-.506 0-.759.003-.121.003-.247.017-.369l.017-.105.068.001a7.217 7.217 0 0 0 .73-.037 33.106 33.106 0 0 1 3.464-.056q1.696.037 3.387.182c.37.031.636-.057.665.343a12.066 12.066 0 0 1-.075 1.55ZM28.172 19.996a5.695 5.695 0 0 0-1.647-3.04 5.362 5.362 0 0 0-3.37-1.2 6.653 6.653 0 0 0-3.457.936 5.251 5.251 0 0 0-2.228 2.554 5.492 5.492 0 0 0 1.32 6.055 5.68 5.68 0 0 0 6.058.954 5.911 5.911 0 0 0 3.324-6.26Zm-3.49 4.651a4.13 4.13 0 0 1-4.398-.08 3.912 3.912 0 0 1-1.622-4.132c.414-1.833 1.947-2.75 3.682-3.115a3.781 3.781 0 0 1 1.631.068 3.277 3.277 0 0 1 1.34.496 4.163 4.163 0 0 1 1.41 2.51 4.379 4.379 0 0 1-2.043 4.253Z' />
    </g>
  </svg>
);

export default SvgCategory;
