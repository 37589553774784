import { FC, PropsWithChildren } from 'react';
import { icons } from '../../icons';
import IProps from 'interfaces/IProps';
import { IconWrapper } from './Icon.styles';
import styles from 'styles';

export interface IIconProps extends PropsWithChildren<IProps> {
  src: keyof typeof icons;
  size?: string;
  backgroundColor?: string;
  fill?: string;
}

const Icon: FC<IIconProps> = (props: IIconProps): JSX.Element => {
  const { dataTestId, className, style, src, size, backgroundColor, fill } =
    props;

  return (
    <IconWrapper
      data-testid={dataTestId}
      className={className}
      style={style}
      size={size!}
      backgroundColor={backgroundColor}
      fill={fill!}
    >
      {icons[src]}
    </IconWrapper>
  );
};

Icon.defaultProps = {
  size: styles.iconSize.normal,
  fill: styles.colors.brand.main
};

Icon.displayName = 'Icon';

export default Icon;
