import { SVGProps } from 'react';

const SvgEducation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M28.095 12.763c-.27-.7-1.051-.962-1.663-1.285q-2.953-1.561-5.954-3.03-1.465-.716-2.94-1.41a7.54 7.54 0 0 0-1.41-.615 2.212 2.212 0 0 0-1.502.279L9.207 9.514c-.882.458-1.77.908-2.647 1.375-.731.389-1.5.623-2.204 1.079a1.062 1.062 0 0 0-.15 1.73 8.454 8.454 0 0 0 2.237 1.29l.835.43a16.962 16.962 0 0 1 .034 2.83c-.062.8-.473 1.842.24 2.447a7.903 7.903 0 0 0 1.253.73l1.383.777 2.765 1.556a12.445 12.445 0 0 0 2.497 1.329c.933.261 1.917-.405 2.727-.784 1.882-.88 3.76-1.78 5.606-2.735a2.052 2.052 0 0 0 1.08-2.149c-.004-1.15 0-2.3 0-3.451l-.002-.005c.145-.07.294-.133.439-.205.098-.05.198-.098.298-.147v4.854a.75.75 0 0 0 1.5 0v-5.678c.119-.08.24-.158.35-.247a1.556 1.556 0 0 0 .647-1.777Zm-4.96 7.454a10.868 10.868 0 0 1-1.312.661q-1.532.765-3.077 1.503-.772.368-1.548.73c-.399.186-.992.616-1.432.494a6.733 6.733 0 0 1-1.312-.724l-1.489-.838-2.87-1.615c-.46-.26-.929-.509-1.383-.778q-.398-.213-.057-.047l.059-.43c.03-.231.057-.462.078-.694a16.494 16.494 0 0 0 .03-2.266l3.076 1.584c.897.462 1.786.955 2.7 1.385a2.982 2.982 0 0 0 2.478.12c1.869-.692 3.716-1.482 5.538-2.287.252-.111.498-.236.749-.35-.002.868-.005 1.736 0 2.604 0 .367.092.727-.229.948Zm-1.279-4.497a137.874 137.874 0 0 1-4.537 1.88c-.726.29-1.3.597-2.056.243-.771-.362-1.52-.782-2.277-1.172l-4.528-2.33-2.051-1.056c-.189-.097-.438-.275-.667-.385.314-.172.649-.26.976-.407.38-.17.744-.385 1.113-.576l2.456-1.274 5.098-2.646c.606-.293 1.567.415 2.135.684q1.157.55 2.308 1.114 2.179 1.068 4.333 2.184c.663.344 1.318.701 1.983 1.041.084.043.554.318.554.22a3.337 3.337 0 0 1-.575.393 42.56 42.56 0 0 1-4.265 2.087ZM26.347 22.638a.758.758 0 0 0-.53.22.78.78 0 0 0-.22.53v1.49a.773.773 0 0 0 .22.53.75.75 0 0 0 1.061 0 .779.779 0 0 0 .22-.53v-1.49a.774.774 0 0 0-.22-.53.762.762 0 0 0-.53-.22Z' />
    </g>
  </svg>
);

export default SvgEducation;
