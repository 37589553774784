import { SVGProps } from 'react';

const SvgVisibility = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    overflow='visible'
    preserveAspectRatio='none'
    viewBox='0 0 26 26'
    {...props}
  >
    <path
      d='M13 7a9.77 9.77 0 0 1 8.82 5.5C20.17 15.87 16.79 18 13 18s-7.17-2.13-8.82-5.5A9.77 9.77 0 0 1 13 7m0-2C8 5 3.73 8.11 2 12.5 3.73 16.89 8 20 13 20s9.27-3.11 11-7.5C22.27 8.11 18 5 13 5zm0 5a2.5 2.5 0 0 1 0 5 2.5 2.5 0 0 1 0-5m0-2c-2.48 0-4.5 2.02-4.5 4.5S10.52 17 13 17s4.5-2.02 4.5-4.5S15.48 8 13 8z'
      vectorEffect='non-scaling-stroke'
    />
  </svg>
);

export default SvgVisibility;
