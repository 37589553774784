import sass from './exports.module.scss';
import IStyles from 'interfaces/IStyles';

const styles = Object.freeze({
  desktop: +sass.desktop,
  smallDesktop: +sass.desktop,
  tablet: +sass.tablet,
  smartphone: +sass.smartphone,
  transition: +sass.transition,
  disabledOpacity: +sass.disabledOpacity,
  inputMargin: sass.inputMargin,
  scrollGradientHeight: +sass.scrollGradientHeight,

  textSize: {
    normal: sass.textSize,
    small: sass.textSize_small,
    tiny: sass.textSize_tiny
  },

  iconSize: {
    normal: sass.iconSize,
    small: sass.iconSize_small,
    text: sass.iconSize_text,
    textSmall: sass.iconSize_text_small,
    big: sass.iconSize_big
  },

  colors: {
    brand: {
      main: sass['colors-brand-main'],
      lightest: sass['colors-brand-lightest'],
      dark: sass['colors-brand-dark']
    },
    feedback: {
      success: sass['colors-feedback-success'],
      warning1: sass['colors-feedback-warning1'],
      warning2: sass['colors-feedback-warning2'],
      alert: sass['colors-feedback-alert'],
      online: sass['colors-feedback-online'],
      busy: sass['colors-feedback-busy'],
      offline: sass['colors-feedback-offline']
    },
    auxiliary: {
      auxiliary1: sass['colors-auxiliary-auxiliary1'],
      auxiliary1Dark: sass['colors-auxiliary-auxiliary1_dark'],
      auxiliary2: sass['colors-auxiliary-auxiliary2'],
      auxiliary2Dark: sass['colors-auxiliary-auxiliary2_dark'],
      auxiliary3: sass['colors-auxiliary-auxiliary3'],
      auxiliary3Dark: sass['colors-auxiliary-auxiliary3_dark'],
      auxiliary4: sass['colors-auxiliary-auxiliary4'],
      auxiliary4Dark: sass['colors-auxiliary-auxiliary4_dark'],
      auxiliary5: sass['colors-auxiliary-auxiliary5'],
      auxiliary5Dark: sass['colors-auxiliary-auxiliary5_dark'],
      auxiliary6: sass['colors-auxiliary-auxiliary6'],
      auxiliary6Dark: sass['colors-auxiliary-auxiliary6_dark'],
      auxiliary7: sass['colors-auxiliary-auxiliary7'],
      auxiliary7Dark: sass['colors-auxiliary-auxiliary7_dark'],
      auxiliary8: sass['colors-auxiliary-auxiliary8'],
      auxiliary8Dark: sass['colors-auxiliary-auxiliary8_dark']
    }
  }
} as IStyles);

export default styles;
