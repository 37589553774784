import { SVGProps } from 'react';

const SvgVideoCall = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M25.848 9.611a7.44 7.44 0 0 0-2.334 1.863c-.421.4-.81.832-1.213 1.25.026-.456.04-.913.072-1.369a3.262 3.262 0 0 0-.4-2.484 1.437 1.437 0 0 0-1.006-.468c-.61-.041-1.221-.069-1.831-.112-.992-.051-1.957.085-2.947.07q-1.66-.027-3.32-.05-1.727-.022-3.453-.04a18.948 18.948 0 0 0-3.285.044 2.092 2.092 0 0 0-1.846 1.69c-.112.954-.09 1.956-.117 2.919a131.581 131.581 0 0 0-.034 6.862c.021.929-.163 2.227.455 3.005a2.704 2.704 0 0 0 2.137.817c2.277.086 4.562.085 6.84.116 2.248.03 4.562.17 6.803-.017a1.679 1.679 0 0 0 1.735-1.396 27.253 27.253 0 0 0-.006-3.246l.002-.14 1.034 1.145.946 1.047a6.288 6.288 0 0 0 .982 1.01 1.482 1.482 0 0 0 2.088-.417 4.254 4.254 0 0 0 .335-2.592 44.58 44.58 0 0 1 .326-5.879c.144-1.257.174-4.343-1.963-3.628Zm-4.922 1.011c-.025.57-.082 1.14-.117 1.71q-.17 2.813-.204 5.63-.016 1.348 0 2.695.004.368.01.735a2.059 2.059 0 0 1 .012.655c-.105.21.098.06-.204.155a2.57 2.57 0 0 1-.675.025c-1.937.035-3.874.026-5.81.002-1.888-.024-3.775-.06-5.663-.093a6.724 6.724 0 0 1-2.251-.12c-.396-.162-.338-.495-.352-.883-.019-.53-.032-1.061-.043-1.592q-.059-2.839.012-5.678c.02-.832.027-1.668.078-2.498.014-.22.049-.442.058-.663a2.49 2.49 0 0 1-.008-.525c.128-.383.667-.405.993-.423 2.101-.114 4.25.033 6.353.061q1.537.02 3.075.046c.99.016 1.955-.121 2.946-.07.44.032.88.082 1.32.1.403.017.368-.108.458.168a1.691 1.691 0 0 1 .012.563Zm5.409 2.405c-.095.901-.206 1.798-.267 2.703q-.091 1.364-.09 2.732c0 .266.197 2.44-.125 2.455.07-.004-.31-.415-.276-.377q-.22-.241-.437-.484l-1.019-1.128-1.891-2.095a.676.676 0 0 0-.088-.065c.013-.607.02-1.215.043-1.822a.71.71 0 0 0 .177-.13q.891-.992 1.843-1.925a14.662 14.662 0 0 1 1.928-1.75.783.783 0 0 1 .15-.083 1.892 1.892 0 0 1 .092.488 6.452 6.452 0 0 1-.04 1.481Z' />
      <path d='M8.853 12.393a.75.75 0 0 0-1.28.53l.065 1.867a.785.785 0 0 0 .22.53.75.75 0 0 0 1.28-.53l-.066-1.866a.784.784 0 0 0-.22-.531Z' />
    </g>
  </svg>
);

export default SvgVideoCall;
