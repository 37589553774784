import { Flex, IFlexProps } from 'components/Templates';
import styled from 'styled-components';
import styles from 'styles';
import { rgba } from 'utils/colors';
import { transition } from 'utils/styles';

export interface IPopupBackgroundProps {}

export const PopupBackground = styled.div<IPopupBackgroundProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  ${transition('background-color', 'max-height')}
  z-index: 40;

  &.popup-enter,
  &.popup-exit,
  &.popup-exit-done {
    background-color: transparent;
  }

  &.popup-enter-done {
    background-color: ${rgba(styles.colors.brand.dark, 0.4)};
  }
`;

export interface IPopupContainerProps {
  top: string;
}

export const PopupContainer = styled(Flex).attrs((_: IFlexProps) => ({
  direction: 'column',
  justifyContent: 'flex-start'
}))<IPopupContainerProps>`
  position: absolute;
  top: ${({ top }: IPopupContainerProps) => top};
  min-width: 10rem;
  overflow-y: hidden;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: ${styles.colors.brand.lightest};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  z-index: 40;
  ${transition('max-height', 'padding')}

  &.popup-enter,
  &.popup-exit,
  &.popup-exit-done {
    max-height: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
  }

  &.popup-enter-done {
    max-height: 12.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  > * {
    width: 100%;
    box-sizing: border-box;
  }
`;
