import { FC, PropsWithChildren } from 'react';
import MediaQuery from 'react-responsive';
import styles from 'styles';

const MediaScreenDesktop: FC = (props: PropsWithChildren<{}>): JSX.Element => {
  const { children } = props;

  return <MediaQuery minWidth={styles.tablet + 1}>{children}</MediaQuery>;
};

export default MediaScreenDesktop;
