import { SVGProps } from 'react';

const SvgUserDataFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009ebb'>
      <path d='M18.21 16.582a4.384 4.384 0 0 1-4.776.046 5.887 5.887 0 0 0-1.31 4.01c.01.204-.008.793.144.95.148.154.785.051 1.01.052q1.36.006 2.72.008t2.718.003h.726c.107-.001.312.036.409-.023.247-.152.3-.835.319-1.086.118-1.579-.771-2.984-1.62-4.254a4.33 4.33 0 0 1-.34.294ZM14.235 15.388a2.918 2.918 0 0 0 4.083-3.887 3.91 3.91 0 0 0-.291-.32 2.508 2.508 0 0 0-.267-.23 3.528 3.528 0 0 0-.762-.422 4.031 4.031 0 0 0-.506-.149 3.82 3.82 0 0 0-.258-.049l-.013-.002-.047-.004a4.384 4.384 0 0 0-.522-.013 4.131 4.131 0 0 0-.222.016l-.043.004-.052.01a2.68 2.68 0 0 0-2.23 1.979 2.943 2.943 0 0 0 .098 1.75 2.608 2.608 0 0 0 1.032 1.317Z' />
      <path d='M24.229 6.457a11.904 11.904 0 0 0-6.45-2.809 13.406 13.406 0 0 0-4.24.187c-.558.114-1.134.217-1.686.35a6.678 6.678 0 0 0-1.726.823 13.74 13.74 0 0 0-5.313 5.41 11.429 11.429 0 0 0-1.176 3.663 16.198 16.198 0 0 0 .133 4.13 7.237 7.237 0 0 0 .345 1.878 19.823 19.823 0 0 0 .867 1.901 12.392 12.392 0 0 0 2.15 2.978 13.103 13.103 0 0 0 3.067 2.143 11.893 11.893 0 0 0 3.885 1.338 13.41 13.41 0 0 0 8.33-1.538 10.033 10.033 0 0 0 3.268-2.511 10.593 10.593 0 0 0 1.96-3.747c1.241-4.79.499-10.792-3.414-14.195Zm-3.372 16.279c-.668.613-1.761.415-2.593.415-1.874 0-3.747.003-5.62-.013a1.698 1.698 0 0 1-1.834-1.197 5.946 5.946 0 0 1-.169-2.176 7.54 7.54 0 0 1 1.703-4.16 4.437 4.437 0 0 1-.595-3.974 4.284 4.284 0 0 1 3.681-2.803 4.694 4.694 0 0 1 4.03 1.702c.02.026.032.057.05.084.021.024.048.042.067.068a3.987 3.987 0 0 1-.055 4.426.738.738 0 0 1 .126.124c1.054 1.577 2.15 3.33 2.023 5.31a3.286 3.286 0 0 1-.814 2.194Z' />
    </g>
  </svg>
);

export default SvgUserDataFilled;
