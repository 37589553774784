import styled from 'styled-components';
import styles from 'styles';
import { getGutter } from 'utils/grid';

export interface ISmallTextProps {}

export const SmallText = styled.div<ISmallTextProps>`
  font-size: 0.875rem;
  font-weight: inherit;
`;

export interface ITinyTextProps {}

export const TinyText = styled.div<ITinyTextProps>`
  font-size: 0.75rem;
  font-weight: inherit;
`;

export interface ICaptionProps {}

export const Caption = styled.div<ICaptionProps>`
  font-size: ${styles.textSize.tiny};
  font-weight: inherit;
  line-height: 1rem;
`;

export interface IOverlineProps {}

export const Overline = styled.div<IOverlineProps>`
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0.09375em;
`;

export interface IFlexProps {
  flex?: string;
  direction?: string;
  justifyContent?: string;
  alignItems?: string;
}

export const Flex = styled.div<IFlexProps>`
  display: ${({ flex }: IFlexProps) => flex || 'flex'};
  flex-direction: ${({ direction }: IFlexProps) => direction || 'row'};
  justify-content: ${({ justifyContent }: IFlexProps) =>
    justifyContent || 'center'};
  align-items: ${({ alignItems }: IFlexProps) => alignItems || 'center'};
`;

export interface IContainerProps {
  flex?: string;
  direction?: string;
  justifyContent?: string;
  alignItems?: string;
}

export const Container = styled(Flex).attrs((_: IFlexProps) => ({
  flex: 'inline-flex',
  direction: 'column',
  justifyContent: 'flex-start'
}))<IContainerProps>`
  height: 100%;
  padding: ${getGutter()};
  border-radius: 2rem;
  box-sizing: border-box;
  background-color: ${styles.colors.brand.lightest};

  > * {
    width: 100%;
  }
`;
