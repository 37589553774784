import { SVGProps } from 'react';

const SvgText = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M27.659 11.596a71.869 71.869 0 0 1-9.659.015c-.962-.082-.956 1.419 0 1.5a71.869 71.869 0 0 0 9.659-.015c.961-.047.966-1.547 0-1.5ZM18.229 6.46q4.699.417 9.416.563c.965.03.964-1.47 0-1.5q-4.715-.146-9.416-.563a.756.756 0 0 0-.75.75.766.766 0 0 0 .75.75ZM27.425 18.195c-7.56.368-15.128.118-22.687-.139-.965-.033-.964 1.468 0 1.5 7.559.257 15.127.507 22.687.139.96-.047.966-1.547 0-1.5ZM27 25.257c-1.732.096-3.445-.012-5.181-.02l-5.723-.026-11.358-.051a.75.75 0 0 0 0 1.5l11.358.051 5.723.026c1.735.008 3.45.116 5.181.02.96-.053.966-1.553 0-1.5ZM4.207 7.506a.75.75 0 0 0 1.061 0 .78.78 0 0 0 .22-.53v-.428H8.71v6.766c-.09.005-.179 0-.268.008a.8.8 0 0 0-.53.22.75.75 0 0 0 0 1.06.739.739 0 0 0 .53.22 10.453 10.453 0 0 1 2.032.042.748.748 0 0 0 .53-.22.75.75 0 0 0 0-1.06l-.151-.118a.746.746 0 0 0-.38-.102c-.088-.01-.177-.01-.266-.019l.003-.01V6.547h3.102v.369a.774.774 0 0 0 .22.53.75.75 0 0 0 1.06 0 .78.78 0 0 0 .22-.53v-.911a.727.727 0 0 0-.69-.958H5.027a.668.668 0 0 0-.288-.059.758.758 0 0 0-.53.22c-.01.012-.015.026-.025.038a.795.795 0 0 0-.195.76v.968a.774.774 0 0 0 .22.531Z' />
    </g>
  </svg>
);

export default SvgText;
