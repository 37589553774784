import Icon from 'components/Icon/Icon';
import { Flex } from 'components/Templates';
import styled, { css } from 'styled-components';
import styles from 'styles';
import { rgba } from 'utils/colors';
import { transition } from 'utils/styles';
import { IInputContainerProps, InputContainer } from '../Input.styles';

export interface IPhoneNumberInputContainerProps {}

export const PhoneNumberInputContainer = styled(
  InputContainer
)<IPhoneNumberInputContainerProps>`
  > input {
    margin-left: 4.5rem;
  }

  > * {
    &:first-child {
      > * {
        border-color: ${({ isFocused, state }: IInputContainerProps) =>
          (isFocused && styles.colors.brand.main) ||
          (state && styles.colors.feedback[state])};
        border-right: none;
      }
    }
  }
`;

export interface IPhoneNumberInputSelectedCountryProps {}

export const PhoneNumberInputSelectedCountry = styled.div<IPhoneNumberInputSelectedCountryProps>`
  position: absolute;
  top: -2px;
  left: -2px;
  overflow: hidden;
  height: calc(100% + 4px);
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
  background-color: white;
  cursor: pointer;
  z-index: 5;

  > div {
    width: 100%;
    height: 100%;
    padding-right: 0.5rem;
    padding-left: 1rem;
    border: 2px solid transparent;
    border-radius: inherit;
    box-sizing: border-box;

    background-color: ${rgba(styles.colors.brand.main, 0.2)};
    ${transition('border', 'background-color')}

    &:hover {
      background-color: ${rgba(styles.colors.brand.main, 0.3)};
    }
  }
`;

export interface IPhoneNumberInputDropdownIconProps {
  isOpen: boolean;
}

export const PhoneNumberInputDropdownIcon = styled(
  Icon
)<IPhoneNumberInputDropdownIconProps>`
  ${transition('transform')}

  ${({ isOpen }: IPhoneNumberInputDropdownIconProps) =>
    isOpen
      ? css`
          transform: rotate(180deg);
        `
      : css``}
`;

export interface IPhoneNumberInputDropdownProps {}

export const PhoneNumberInputDropdown = styled.div<IPhoneNumberInputDropdownProps>`
  position: absolute;
  top: 50%;
  left: -2px;
  overflow-y: hidden;
  width: 4.5rem;
  height: 0;
  border: 1px solid ${rgba(styles.colors.brand.main, 0.15)};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0;
  box-sizing: border-box;
  background-color: white;
  z-index: 4;
  ${transition('height', 'padding')}

  &.countries-list-enter,
  &.countries-list-exit,
  &.countries-list-exit-done {
    height: 0;
    padding: 0;
  }

  &.countries-list-enter-done {
    height: 15rem;
    padding: 1.3rem 0 0.5rem 0;
  }

  > ul {
    overflow-y: auto;
    overflow-y: overlay;
    width: 100%;
    height: 100%;

    > li {
      padding: 0.5rem 0 0.5rem 1rem;
      cursor: pointer;
      ${transition('background-color')}

      &:hover {
        background-color: ${rgba(styles.colors.brand.main, 0.15)};
      }
    }
  }
`;

export interface IPhoneNumberInputCountryFlagProps {}

export const PhoneNumberInputCountryFlag = styled(
  Flex
)<IPhoneNumberInputCountryFlagProps>`
  overflow: hidden;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 999px;
  margin-right: 0.25rem;
  background-color: lightgrey;

  > img {
    min-height: 100%;
  }
`;
