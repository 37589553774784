import { SVGProps } from 'react';

const SvgLifestyleFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M26.361 7.985a6.273 6.273 0 0 0-1.258-.034H18.51c.356-.502.711-1.005 1.088-1.489a1.996 1.996 0 0 1 1.704-.833c1.77-.078 3.545-.087 5.316-.13a.75.75 0 0 0 0-1.5l-3.046.074a28.296 28.296 0 0 0-2.869.107 3.167 3.167 0 0 0-2.151 1.203c-.638.793-1.21 1.646-1.806 2.47a.518.518 0 0 0-.038.098h-3.607a4.448 4.448 0 0 0-1.14-1.79 5.309 5.309 0 0 0-2.395-1.462 4.733 4.733 0 0 0-5.839 4.425 5.547 5.547 0 0 0 .69 2.747 3.815 3.815 0 0 0 2.202 1.872 3.816 3.816 0 0 0 2.96-.126c.035-.02.06-.049.094-.07l.859 1.201c.843 1.18 1.678 2.356 2.494 3.556l1.268 1.867a2.792 2.792 0 0 1 .469.734 5.665 5.665 0 0 1 .001 1.186 22.558 22.558 0 0 0-.013 2.315c.032.295.082.149-.056.276a4.19 4.19 0 0 1-.4.312c-.743.533-1.535.994-2.273 1.533-.532.389-.442 1.472.379 1.398a38.755 38.755 0 0 1 3.909.05c1.692.02 3.393.07 5.085.005a.756.756 0 0 0 .379-1.397 16.636 16.636 0 0 1-1.969-1.499 4.834 4.834 0 0 1-.612-.479c-.25-.276-.174-.708-.172-1.059.003-.503-.007-1.007 0-1.51.02-1.693 1.466-2.88 2.442-4.125a104.512 104.512 0 0 0 3.36-4.58c.474-.678.932-1.338 1.46-1.982a3.68 3.68 0 0 0 1.075-2 1.266 1.266 0 0 0-.998-1.364Zm-3.709 5.792a14.135 14.135 0 0 1-5.487-.26c-1.895-.442-4.017-1.092-5.877-.281l-.888-1.243c1.89-.949 4.042-.666 6.044-.192a20.312 20.312 0 0 0 3.427.648 11.32 11.32 0 0 0 3.713-.4.752.752 0 0 1 .281-.018c-.415.572-.814 1.159-1.213 1.746Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgLifestyleFilled;
