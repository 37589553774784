import {
  IReduxState,
  placeValue
} from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { useSelector } from 'react-redux';
import { FC, PropsWithChildren, useState } from 'react';
import useInput from '../Input.logic';
import {
  InputContainer,
  InputHelpMessage,
  InputStateMessage,
  InputWrapper
} from '../Input.styles';
import { InputTypes } from 'utils/form';
import { IInputProps } from '../Input.types';
import { PasswordInputVisibilityButton } from './PasswordInput.styles';
import IProps from 'interfaces/IProps';

export interface IPasswordInputProps
  extends PropsWithChildren<
    Omit<IInputProps, 'icon' | 'prefix' | 'placeholder' | 'suffix' | 'help'> &
      IProps
  > {
  placeholder?: string;
  includeHelp?: boolean;
}

const PasswordInput: FC<IPasswordInputProps> = (
  props: IPasswordInputProps
): JSX.Element => {
  const { dataTestId, className, style, placeholder, disabled, includeHelp } =
    props;
  const language = useSelector((state: IReduxState) => state.language.values);
  const {
    input,
    value,
    isFocused,
    inputChangeHandler,
    focusHandler,
    blurHandler
  } = useInput(props);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { success, error } = input;

  if (input?.type !== InputTypes.PASSWORD)
    throw new Error('Password Input requires the type to be PASSWORD.');

  const toggleShowPassword = (): void => {
    setShowPassword((prevState: boolean) => !prevState);
  };

  return (
    <InputWrapper className={className} style={style} direction='column'>
      {success && (
        <InputStateMessage data-testid={`${dataTestId}_success`} type='success'>
          {success}
        </InputStateMessage>
      )}
      {error && (
        <InputStateMessage data-testid={`${dataTestId}_error`} type='alert'>
          {error}
        </InputStateMessage>
      )}
      <InputContainer
        isFocused={isFocused}
        state={success ? 'success' : error ? 'alert' : undefined}
      >
        <input
          data-testid={dataTestId}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder || language.password}
          value={value as string}
          disabled={disabled}
          onChange={inputChangeHandler}
          onFocus={focusHandler}
          onBlur={blurHandler}
        />
        <PasswordInputVisibilityButton
          type='text'
          state='dark'
          icon={showPassword ? 'visibilityOff' : 'visibility'}
          onClick={toggleShowPassword}
        />
      </InputContainer>
      {includeHelp && (
        <InputHelpMessage data-testid={`${dataTestId}_help`}>
          {placeValue(language.minimumXCharacters, 8)}
        </InputHelpMessage>
      )}
    </InputWrapper>
  );
};

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
