import styles from 'styles';

const colorExists = (colorToSearch: string | number | boolean): boolean => {
  const { brand, auxiliary, feedback } = styles.colors;
  const allColors = [
    ...Object.values(brand),
    ...Object.values(auxiliary),
    ...Object.values(feedback)
  ];

  const foundColor = allColors.find((color: string) => color === colorToSearch);

  return !!foundColor;
};

export const rgba = (color: string, alpha: number): string => {
  if (!colorExists(color)) {
    throw new Error(
      `Color '${color}' is not present in styles defined colors.`
    );
  }

  const opacity = Math.round(Math.min(Math.max(alpha || 1, 0), 1) * 255);
  const opacityDecimal = opacity.toString(16).toUpperCase();
  return color + (opacityDecimal.length === 1 ? '0' : '') + opacityDecimal;
};
