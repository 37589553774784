import { SVGProps } from 'react';

const SvgAddHouse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id='add_house_svg__Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    x={0}
    y={0}
    viewBox='0 0 32 32'
    xmlSpace='preserve'
    {...props}
  >
    <style>{'.add_house_svg__st0{fill:#009eba}'}</style>
    <path
      className='add_house_svg__st0'
      d='M29.1 19.2c-.4-.9-1-1.7-1.8-2.3.2-.1.4-.2.5-.4.3-.4.4-.9.3-1.4-.3-.5-.6-.9-1-1.3-.9-.9-1.9-1.7-2.9-2.5-1.1-.9-2.1-1.8-3.1-2.7-1-.9-1.8-1.9-2.8-2.7-.8-.8-1.9-1.1-3-.8-1.4.4-2.5 1.7-3.5 2.6-1.2 1-2.4 2.1-3.5 3.2-1.1 1-2.3 2.1-3.4 3.2-.4.4-1 .7-1.1 1.3-.1.5 0 .9.4 1.2l.3.3c.1 0 .2.1.3.1.5 0 .9.1 1.3 0h.8v5c-.1 1.2 0 2.5.3 3.7.5.9 1.6 1.5 2.6 1.3 2 0 4-.1 6-.2.3.5.6 1 1 1.5 0 0 .7.7 1.1 1 .4.3.9.6 1.4.8 3 1.2 6.4.5 8.6-1.8 1-1.1 1.6-2.5 1.7-4 .4-1.7.2-3.5-.5-5.1zm-14.5 6.2c0-1.3 0-2.5.1-3.8 0-.5.1-.9.4-1.3-.2.4-.3.9-.3 1.4 0 0-.2.9 0 1.5 0 .3 0 .5.1.8 0 0 .1.7.3 1.4h-.6zm.2-6.6c-1 .5-1.7 1.5-1.7 2.7-.1 1.3-.1 2.7-.1 4-.9 0-1.7.1-2.6.1-.5.1-1 .1-1.5-.1s-.5-.8-.5-1.3v-5.9c0-.6 0-1.2-.1-1.8-.1-.5-.6-.9-1.1-.9h-.9c-.3 0-.6.1-.9 0 .1-.1.2-.2.3-.2.2-.2.3-.4.5-.6.4-.4.8-.8 1.3-1.2.9-.8 1.8-1.7 2.7-2.5l2.7-2.4c.5-.4.9-.8 1.4-1.2.4-.4.9-.7 1.4-1 1-.4 1.8.5 2.4 1.2l2.5 2.5c.8.8 1.8 1.6 2.7 2.3.8.7 1.8 1.3 2.5 2.1l.5.5c.1.1.5.5.5.6 0-.2-1.1 0-1.3 0h-.1c-.2-.1-.4-.1-.7-.2-.7-.1-1.4-.1-2.1-.1H20.9c-.1 0-.2 0-.3.1-.2 0-.4.1-.6.1-.2.1-.4.1-.6.2-1 .4-1.8 1-2.6 1.8l-.5.5c-.1.1-.1.2-.2.3-.5.1-.9.2-1.3.4zm12.7 8c-1.5 2-4 2.9-6.4 2.4-1.4-.3-2.6-1.1-3.5-2.3-.4-.5-.6-1-.9-1.5 0-.1-.1-.2-.1-.3V25c-.1-.3-.2-.6-.2-.9-.3-1.5-.1-3 .6-4.2.2-.4.5-.8.9-1.2.5-.6 1.2-1 1.9-1.4.7-.2 1.4-.4 2.2-.4h2.3l.9.3c.9.4 1.6 1 2.1 1.8.8 1.2 1.2 2.5 1.1 3.9.1 1.3-.2 2.7-.9 3.9z'
    />
    <path
      className='add_house_svg__st0'
      d='M27.2 23.9c-.7 0-1.4 0-2-.1h-1.5V25c0 .6 0 1.2-.1 1.7v.7c-.1 1-1.6 1-1.5 0v-.5c0-.5.1-1 .1-1.5v-1.7h-4c-.3-.1-.5-.4-.5-.7 0-.3.2-.5.4-.6.1-.1.2-.1.4-.1H22.3v-3.7c0-.9 1.2-1 1.5-.2v3.8h1.5c.7 0 1.4 0 2.1.1.8.1.8 1.6-.2 1.6z'
    />
  </svg>
);

export default SvgAddHouse;
