import Button from 'components/Button/Button';
import { Flex, IFlexProps } from 'components/Templates';
import styled from 'styled-components';
import styles from 'styles';
import { transition, verticalGradient } from 'utils/styles';

export interface IScrollViewWrapperProps {}

export const ScrollViewWrapper = styled(Flex).attrs((_: IFlexProps) => ({
  direction: 'column'
}))<IScrollViewWrapperProps>`
  position: relative;
  width: 100%;
  overflow-y: hidden;
  height: 100%;

  > * {
    &:nth-child(2) {
      position: relative;
      overflow-x: hidden;
      overflow-y: overlay;
      width: 100%;
      height: 100%;
      padding-bottom: 0.375rem;
      box-sizing: border-box;
    }
  }
`;

export interface IScrollViewGradientProps {
  hide: boolean;
}

export const ScrollViewGradient = styled.div<IScrollViewGradientProps>`
  position: absolute;
  left: 0;
  width: 100%;
  height: ${({ hide }: IScrollViewGradientProps) =>
    hide ? 0 : styles.scrollGradientHeight}px;
  z-index: 10;
  ${transition('height')}

  &:first-child {
    ${verticalGradient('rgba(255, 255, 255, 0)', 'white')}
    top: 0;

    > div {
      &:first-child {
        ${verticalGradient('rgba(255, 255, 255, 0)', 'white')}
      }
    }
  }

  &:last-child {
    ${verticalGradient('white', 'rgba(255, 255, 255, 0)')}
    bottom: 0;

    > div {
      &:first-child {
        ${verticalGradient('white', 'rgba(255, 255, 255, 0)')}
      }
    }
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: inherit;

    &:last-child {
      background: inherit;
    }
  }
`;

export interface IScrollViewBackToTopButtonProps {}

export const ScrollViewBackToTopButton = styled(
  Button
)<IScrollViewBackToTopButtonProps>`
  position: absolute;
  right: 1.75rem;
  bottom: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 999px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 68, 94, 0.3);
  ${transition('opacity')}
  z-index: 11;

  &.back-to-top_button-enter,
  &.back-to-top_button-exit,
  &.back-to-top_button-exit-done {
    opacity: 0;
  }

  &.back-to-top_button-enter-done {
    opacity: 1;
  }
`;
