import { SVGProps } from 'react';

const SvgMapMarkerFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name='Camada 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32.5 37.19'
    {...props}
  >
    <path
      d='M37.5 20.62a15 15 0 0 0-30 0v.62A15 15 0 0 0 10.32 30a14.31 14.31 0 0 0 1.22 1.48c.66.7 10.92 8.79 10.92 8.79 1.5-.76 9.7-7.49 10.92-8.79A14.62 14.62 0 0 0 34.66 30a15 15 0 0 0 2.82-8.76Zm-15 6.1a6.1 6.1 0 1 1 6.09-6.1 6.09 6.09 0 0 1-6.09 6.1Z'
      transform='translate(-6.25 -4.37)'
      style={{
        fill: '#009eba',
        stroke: '#009eba',
        strokeLinejoin: 'round',
        strokeWidth: '2.5px'
      }}
    />
  </svg>
);

export default SvgMapMarkerFilled;
