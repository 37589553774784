export type StorageTypes = 'sessionStorage' | 'localStorage';

export interface IUseStorage {
  getFromStorage: (key: string) => string | null;
  saveToStorage: (key: string, value: string | object | JSON) => void;
  removeFromStorage: (key: string) => void;
}

const useStorage = (
  storageType: StorageTypes = 'sessionStorage'
): IUseStorage => {
  const storage =
    storageType === 'sessionStorage'
      ? sessionStorage
      : storageType === 'localStorage'
      ? localStorage
      : sessionStorage;

  const getFromStorage = (key: string): string | null => {
    return storage.getItem(key);
  };

  const saveToStorage = (key: string, value: any): void => {
    let valueString: string = '';

    if (typeof value === 'object') {
      try {
        JSON.stringify(value);
      } catch {
        value.toString();
      }
    } else valueString = value;

    storage.setItem(key, valueString);
  };

  const removeFromStorage = (key: string): void => {
    storage.removeItem(key);
  };

  return { getFromStorage, saveToStorage, removeFromStorage };
};

export default useStorage;
