import './styles/general.scss';
import { icons } from './icons';

export const iconsKeys = Object.keys(icons);

export * from './components';
export * from './dtos';
export * from './hooks';
export * from './interfaces';
export * from './store';
export * from './utils';
export { default as styles } from './styles';
