import { SVGProps } from 'react';

const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M27.215 15.575q-5.04-.24-10.086-.327.05-5.178-.098-10.356c-.03-.963-1.53-.967-1.5 0q.155 5.162.098 10.326-5.422-.079-10.845.006a.75.75 0 0 0 0 1.5q5.414-.087 10.827-.006-.08 5.198-.36 10.39c-.053.965 1.447.962 1.5 0q.286-5.176.36-10.36 5.053.095 10.104.327c.965.046.963-1.454 0-1.5Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgAdd;
