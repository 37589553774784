import { SVGProps } from 'react';

const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M22.08 15.773c-3.594-3.968-6.894-8.236-11.251-11.42-.78-.57-1.53.73-.757 1.295 4.044 2.956 7.155 6.935 10.468 10.647A120.781 120.781 0 0 1 9.99 26.47c-.724.641.34 1.699 1.06 1.06A115.381 115.381 0 0 0 22.08 16.835a.77.77 0 0 0 0-1.06Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgArrowRight;
