import Button from 'components/Button/Button';
import { Flex } from 'components/Templates';
import IProps from 'interfaces/IProps';
import { FC, PropsWithChildren } from 'react';
import { useHistory } from 'react-router';

export interface IWhitePageHeaderProps extends PropsWithChildren<IProps> {
  title: string | JSX.Element;
  onBack?: () => void;
}

const WhitePageHeader: FC<IWhitePageHeaderProps> = (
  props: IWhitePageHeaderProps
): JSX.Element => {
  const { className, style, title, onBack } = props;
  const history = useHistory();

  return (
    <Flex
      className={className}
      style={{ ...style, marginBottom: '2rem' }}
      justifyContent='flex-start'
    >
      <Button
        dataTestId='whitePage-closeButton'
        style={{ marginRight: '1rem' }}
        type='secondary'
        icon='close'
        onClick={() => (onBack ? onBack() : history.goBack())}
      />
      {typeof title === 'string' ? <h3>{title}</h3> : title}
    </Flex>
  );
};

export default WhitePageHeader;
