import Tag from 'components/Tag/Tag';
import styles from 'styles';
import styled, { css } from 'styled-components';
import { rgba } from 'utils/colors';

export interface IOptionWrapperProps {}

export const OptionWrapper = styled.label<IOptionWrapperProps>`
  display: inline-block;
  position: relative;
  cursor: pointer;

  > input {
    position: absolute;
    opacity: 0;
  }
`;

export interface IOptionTagProps {
  active: boolean;
  isBig?: boolean;
  isJoined?: boolean;
}

export const OptionTag = styled(Tag)<IOptionTagProps>`
  &:hover {
    background-color: ${({ active }: IOptionTagProps) =>
      active
        ? rgba(styles.colors.brand.main, 0.75)
        : rgba(styles.colors.brand.main, 0.25)};
  }

  ${({ active }: IOptionTagProps) =>
    active
      ? css`
          > span {
            font-weight: 600;
          }
        `
      : css``}

  ${({ isBig, isJoined }: IOptionTagProps) =>
    isBig
      ? css`
          flex-direction: column;
          width: 100%;
          padding: 0.75rem 1.5rem;
          border-radius: 1rem;
          box-sizing: border-box;

          > .option-icon {
            width: ${styles.iconSize.small};
            height: ${styles.iconSize.small};

            + * {
              margin-top: 0.25rem;
            }
          }
        `
      : isJoined
      ? css``
      : css`
          min-width: 8.125rem;
        `}
`;
