import { SVGProps } from 'react';

const SvgFence = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name='Camada 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 19.72'
    {...props}
  >
    <path
      className='fence_svg__cls-1'
      d='M24 8.43v-2h-2.11V2.32a2.32 2.32 0 1 0-4.63 0v4.12h-2.94V2.32a2.32 2.32 0 0 0-4.64 0v4.12H6.74V2.32a2.32 2.32 0 1 0-4.63 0v4.12H0v2h2.11v5H0v2h2.11v4.35h4.63v-4.42h2.94v4.35h4.64v-4.35h2.94v4.35h4.63v-4.35H24v-2h-2.11v-5Zm-17.26 5v-5h2.94v5Zm10.52 0h-2.94v-5h2.94Z'
    />
  </svg>
);

export default SvgFence;
