import { SVGProps } from 'react';

const SvgClinicalDataFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M25.804 24.482c-.023-2.46-.018-4.92-.04-7.38l-.08-8.367c-.009-1.018.255-2.538-.804-3.14a3.75 3.75 0 0 0-1.515-.31 9.058 9.058 0 0 0-.951-.028c.127-.64.033-1.283-.78-1.445a10.612 10.612 0 0 0-1.842-.052q-.919-.017-1.837-.044c-1.277-.039-2.538-.013-3.813-.026a24.596 24.596 0 0 0-3.487-.008 1.25 1.25 0 0 0-1.087.806 1.529 1.529 0 0 0 .013.858 4.232 4.232 0 0 0-2.313.44c-.87.584-.779 1.989-.814 2.906q-.143 3.706-.18 7.416c-.024 2.277-.077 4.533-.037 6.803a27.036 27.036 0 0 0 .17 2.896 2.393 2.393 0 0 0 1.539 2.038 9.226 9.226 0 0 0 3.225.189l3.703-.046 7.405-.09a3.902 3.902 0 0 0 2.816-.717c.806-.757.718-1.686.709-2.699ZM14.38 13.44a.765.765 0 0 1-.75-.75.756.756 0 0 1 .75-.75l1.028.03c-.001-.323.003-.646-.004-.969a.75.75 0 0 1 1.5 0c.008.337.003.674.004 1.011l1.315.038a.765.765 0 0 1 .75.75.756.756 0 0 1-.75.75l-1.332-.038c-.011.424-.02.848-.043 1.272a.767.767 0 0 1-.75.75.755.755 0 0 1-.75-.75c.023-.438.033-.877.045-1.315Zm-2.865 3.692q4.846-.029 9.687-.247c.967-.044.961 1.457 0 1.5q-4.84.218-9.687.247a.75.75 0 0 1 0-1.5Zm.114 2.906q4.674.042 9.343-.227c.966-.056.96 1.444 0 1.5q-4.666.27-9.343.227a.75.75 0 0 1 0-1.5Zm9.574 4.419q-4.787-.088-9.574.074c-.967.033-.962-1.467 0-1.5q4.786-.162 9.574-.074a.75.75 0 0 1 0 1.5Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgClinicalDataFilled;
