import { icons } from 'icons';
import IProps from 'interfaces/IProps';
import { FC, PropsWithChildren } from 'react';
import styles from 'styles';
import { rgba } from 'utils/colors';
import { TagIcon, TagLabel, TagWrapper } from './Tag.styles';

export interface ITagProps extends PropsWithChildren<IProps> {
  iconClassName?: string;
  icon?: keyof typeof icons;
  label?: string;
  background?: string;
  color?: string;
  iconColor?: string;
  iconWithBackground?: boolean;
  textColor?: string;
}

const Tag: FC<ITagProps> = (props: ITagProps): JSX.Element => {
  const {
    className,
    style,
    iconClassName,
    icon,
    label,
    background,
    color,
    iconColor,
    iconWithBackground,
    textColor
  } = props;

  return (
    <TagWrapper
      className={className}
      style={style}
      isOnlyLabel={!icon}
      background={background}
    >
      {icon && (
        <TagIcon
          className={iconClassName}
          src={icon}
          size={styles.iconSize.text}
          backgroundColor={
            iconWithBackground
              ? rgba(iconColor || color || styles.colors.brand.dark, 0.1)
              : undefined
          }
          fill={iconColor || color || rgba(styles.colors.brand.dark, 0.7)}
        />
      )}
      {label && (
        <TagLabel
          color={textColor || color || rgba(styles.colors.brand.dark, 0.7)}
        >
          {label}
        </TagLabel>
      )}
    </TagWrapper>
  );
};

Tag.displayName = 'Tag';

export default Tag;
