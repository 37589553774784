import { SVGProps } from 'react';

const SvgMicro = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M10.99 16.527a5.325 5.325 0 0 0 1.59 4.078 5.47 5.47 0 0 0 7.776-1.238c1.028-1.5.734-3.57.743-5.292l.034-6.097a3.81 3.81 0 0 0-.446-2.134 5.13 5.13 0 0 0-1.642-1.764 5.45 5.45 0 0 0-1.842-.761 3.29 3.29 0 0 0-1.142-.014 7.316 7.316 0 0 0-1.357.193 6.057 6.057 0 0 0-3.268 2.963 4.808 4.808 0 0 0-.583 2.226c-.004.861.059 1.724.06 2.586.004 1.75.01 3.505.077 5.254Zm1.563-8.924a5.103 5.103 0 0 1 1.015-1.579 3.556 3.556 0 0 1 5.478.05 3.487 3.487 0 0 1 .495.823 2.566 2.566 0 0 1 .092 1.081c-.016 2.834-.008 5.67-.048 8.503a3.267 3.267 0 0 1-1.5 3.02 4.153 4.153 0 0 1-3.42.597c-2.679-.85-2.204-4.205-2.233-6.414-.018-1.431.018-2.867-.054-4.297a3.987 3.987 0 0 1 .175-1.784Z' />
      <path d='M22.358 22.163a7.614 7.614 0 0 0 1.925-5.654c-.05-.96-1.55-.966-1.5 0a6.254 6.254 0 0 1-1.193 4.256 6.578 6.578 0 0 1-3.42 2.188 7.591 7.591 0 0 1-7.565-1.815 6.01 6.01 0 0 1-1.339-4.705c.09-.96-1.411-.955-1.5 0a7.491 7.491 0 0 0 1.288 5.162 7.765 7.765 0 0 0 4.036 2.763 9.618 9.618 0 0 0 2.215.38 12.87 12.87 0 0 1-.06 1.705c-1.147.008-2.295.017-3.441.017a.75.75 0 0 0 0 1.5c2.878 0 5.76-.047 8.638-.028a.75.75 0 0 0 0-1.5 298.548 298.548 0 0 0-3.701.006 13.255 13.255 0 0 0 .063-1.71 8.597 8.597 0 0 0 5.554-2.565Z' />
    </g>
  </svg>
);

export default SvgMicro;
