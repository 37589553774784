import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth-slice';
import languageSlice from './language-slice';

const store = configureStore({
  reducer: {
    language: languageSlice.reducer,
    auth: authSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
});

export default store;
