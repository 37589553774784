import { RefObject, useEffect, useRef, useState } from 'react';

export interface IUseOnScreen {
  elementRef: RefObject<HTMLElement>;
  isIntersecting: boolean;
}

const useOnScreen = (
  disconnectAfterIntersecting: boolean = true
): IUseOnScreen => {
  const [isIntersecting, setIntersecting] = useState(false);
  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && disconnectAfterIntersecting)
        observer.disconnect();
      setIntersecting(entry.isIntersecting);
    });

    if (elementRef.current) observer.observe(elementRef.current);

    return () => observer.disconnect();
  }, [elementRef.current]);

  return { elementRef, isIntersecting };
};

export default useOnScreen;
