import { FC, PropsWithChildren } from 'react';
import MediaQuery from 'react-responsive';
import styles from 'styles';

const MediaScreenSmallDesktop: FC = (
  props: PropsWithChildren<{}>
): JSX.Element => {
  const { children } = props;

  return (
    <MediaQuery minWidth={styles.smallDesktop} maxWidth={styles.desktop}>
      {children}
    </MediaQuery>
  );
};

export default MediaScreenSmallDesktop;
