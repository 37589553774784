import Icon, { IIconProps } from 'components/Icon/Icon';
import Popup from 'components/Popup/Popup';
import { Flex } from 'components/Templates';
import styled from 'styled-components';
import styles from 'styles';

export interface ITagsInputPopupProps {}

export const TagsInputPopup = styled(Popup)<ITagsInputPopupProps>`
  width: 100%;
`;

export interface IOptionsListProps {}

export const OptionsList = styled.ul<IOptionsListProps>`
  overflow-y: auto;
  overflow-y: overlay;
  padding: 0 1rem;
`;

export interface IOptionsGroupProps {}

export const OptionsGroup = styled.li<IOptionsGroupProps>`
  :not(:first-child) {
    margin-top: 0.75rem;
  }

  > div {
    > b {
      font-size: ${styles.textSize.small};
    }
  }

  > ul {
    margin-left: calc(${styles.iconSize.text} + 0.1rem);
  }
`;

export interface IOptionsGroupTitleProps {}

export const OptionsGroupTitle = styled(Flex).attrs((_: IIconProps) => ({
  justifyContent: 'flex-start'
}))<IOptionsGroupTitleProps>``;

export interface IOptionsGroupIconProps {}

export const OptionsGroupIcon = styled(Icon).attrs((_: IIconProps) => ({
  size: styles.iconSize.text,
  fill: styles.colors.brand.dark
}))<IOptionsGroupIconProps>`
  margin-right: 0.1rem;
`;
