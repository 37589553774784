import { FC, PropsWithChildren } from 'react';
import MediaQuery from 'react-responsive';
import styles from 'styles';

const MediaScreenMobile: FC = (props: PropsWithChildren<{}>): JSX.Element => {
  const { children } = props;

  return <MediaQuery maxWidth={styles.tablet}>{children}</MediaQuery>;
};

export default MediaScreenMobile;
