import { SVGProps } from 'react';

const SvgMapMarker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id='map_marker_svg__Camada_1'
    data-name='Camada 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32.5 37.19'
    {...props}
  >
    <defs>
      <style>{'.map_marker_svg__cls-1{fill:#009eba}'}</style>
    </defs>
    <path
      className='map_marker_svg__cls-1'
      d='M22.5 41.56a1.21 1.21 0 0 1-.77-.27c-2.43-1.91-10.39-8.2-11.06-8.91a16.38 16.38 0 0 1-1.31-1.58v-.07a16.15 16.15 0 0 1-3.06-9.49v-.61a16.25 16.25 0 0 1 32.5 0v.61a16.16 16.16 0 0 1-3 9.36 1.51 1.51 0 0 1-.18.26 16.76 16.76 0 0 1-1.27 1.52c-1.29 1.37-9.57 8.18-11.26 9a1.28 1.28 0 0 1-.59.18ZM11.36 29.3a13.15 13.15 0 0 0 1.12 1.35c.51.52 6.19 5 10.09 8.13a126.79 126.79 0 0 0 9.93-8.13 10.69 10.69 0 0 0 1.13-1.37 13.69 13.69 0 0 0 2.59-8v-.61a13.75 13.75 0 1 0-27.5 0v.61a13.69 13.69 0 0 0 2.59 8Z'
      transform='translate(-6.25 -4.38)'
    />
    <path
      className='map_marker_svg__cls-1'
      d='M22.5 28a7.35 7.35 0 1 1 7.34-7.34A7.35 7.35 0 0 1 22.5 28Zm0-12.19a4.85 4.85 0 1 0 4.84 4.85 4.85 4.85 0 0 0-4.84-4.88Z'
      transform='translate(-6.25 -4.38)'
    />
  </svg>
);

export default SvgMapMarker;
