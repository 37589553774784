import useClickOutside from 'hooks/use-clickOutside';
import {
  ChangeEvent,
  FocusEvent,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { ActionTypes, FormContext } from 'store/form-context';
import { IFormInput, InputValue } from 'utils/form';
import { IInputProps } from './Input.types';

export interface IUseInput {
  input: IFormInput;
  value: InputValue;
  isFocused: boolean;
  showPopup: boolean;
  containerRef: RefObject<HTMLDivElement>;
  changeHandler: (value: InputValue, updateContextValue?: boolean) => void;
  inputChangeHandler: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  focusHandler: (
    event?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  blurHandler: (
    event?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const useInput = (
  props: IInputProps,
  hasPopup: boolean = false,
  isInForm: boolean = true
): IUseInput => {
  const { name } = props;
  const { inputs, dispatchInputs } = useContext(FormContext);
  const input = inputs[name];
  const [value, setValue] = useState<InputValue>(input.value);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  if (hasPopup) useClickOutside(containerRef, setShowPopup);

  if (!input && isInForm)
    throw new Error(`There is no input available by the name ${name}`);

  useEffect(() => {
    setValue(input.value);
  }, [input.value]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateValue(value);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [value]);

  const updateValue = (value: InputValue): void => {
    dispatchInputs({
      type: ActionTypes.UPDATE_VALUE,
      attribute: name,
      value
    });
  };

  const changeHandler = (
    value: InputValue,
    updateContextValue: boolean = true
  ): void => {
    setValue(value);

    if (updateContextValue) updateValue(value);
  };

  const inputChangeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    changeHandler(event.target.value);
  };

  const focusHandler = (
    event?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    event?.stopPropagation();

    setIsFocused(true);
    if (hasPopup) setShowPopup(true);
  };

  const blurHandler = (
    event?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    event?.stopPropagation();

    setIsFocused(false);
    if (hasPopup) setShowPopup(false);

    updateValue(value);

    if (input.autoVerification && !input.error)
      dispatchInputs({ type: ActionTypes.VERIFY, attribute: name });
  };

  return {
    input,
    value,
    isFocused,
    showPopup,
    containerRef,
    changeHandler,
    inputChangeHandler,
    focusHandler,
    blurHandler
  };
};

export default useInput;
