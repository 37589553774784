import { SVGProps } from 'react';

const SvgHistoric = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M12.935 23.604a9.439 9.439 0 0 1-1.056-.702l-.105-.084a.834.834 0 0 0-.53-.22.763.763 0 0 0-.53.22.755.755 0 0 0-.22.53.733.733 0 0 0 .22.53 10.5 10.5 0 0 0 1.465 1.02.78.78 0 0 0 .578.076.765.765 0 0 0 .448-.345.757.757 0 0 0 .075-.578.776.776 0 0 0-.345-.447ZM18.567 24.684a10.562 10.562 0 0 1-2.115-.025.748.748 0 0 0-.53.22.75.75 0 0 0 0 1.06.866.866 0 0 0 .53.22 10.562 10.562 0 0 0 2.115.025.805.805 0 0 0 .53-.22.75.75 0 0 0 0-1.06.735.735 0 0 0-.53-.22ZM25.996 10.355a10.578 10.578 0 0 0-7.131-4.478A10.651 10.651 0 0 0 10.84 8.15a9.949 9.949 0 0 0-3.822 7.233c-.177-.013-.355-.018-.532-.034-.725-.066-1.85-.419-2.307.374a1.37 1.37 0 0 0-.128.956 2.257 2.257 0 0 0 .385.897c.165.253.404.496.559.723a3.453 3.453 0 0 0 .29.43.13.13 0 0 0 .014.013l.008.013c.04.061.085.12.126.182.658.98 1.477 2.582 2.927 2.151.024-.007.036-.022.058-.03a.79.79 0 0 0 .098-.053.747.747 0 0 0 .148-.093c.008-.007.019-.01.027-.017a12.332 12.332 0 0 0 2.774-3.366 4.93 4.93 0 0 0 .404-1.01.987.987 0 0 0-.692-1.184 5.06 5.06 0 0 0-2.084.075c-.193.014-.386.006-.58.01.269-4.643 4.648-8.29 9.26-8.14a8.734 8.734 0 0 1 6.375 3.155 8.633 8.633 0 0 1 2.258 6.91 9.421 9.421 0 0 1-1.203 3.553 7.888 7.888 0 0 1-3.02 2.69c-.846.467-.09 1.763.756 1.295a9.878 9.878 0 0 0 3.2-2.691 9.743 9.743 0 0 0 1.564-3.642 9.954 9.954 0 0 0-1.707-8.194ZM9.878 16.82c.119-.017.204-.032.288-.044a9.965 9.965 0 0 1-2.32 2.858c-.03-.02-.088-.064-.102-.072a2.886 2.886 0 0 1-.253-.256l-.078-.096a6.91 6.91 0 0 1-.134-.188c-.179-.258-.34-.526-.514-.788a6.029 6.029 0 0 0-.215-.308c-.06-.08-.14-.158-.193-.244a1.867 1.867 0 0 0-.093-.178c-.178-.253-.406-.5-.554-.733l.069.008c.275.028.55.056.826.08.494.042.989.074 1.484.08a11.675 11.675 0 0 0 1.79-.119Z' />
      <path d='M19.73 17.413a13.158 13.158 0 0 1-1.339-1.165l-.008-.148c-.007-.17-.01-.34-.014-.51q-.013-.52-.014-1.04a81.1 81.1 0 0 1 .024-2.15.75.75 0 0 0-1.5 0 78.883 78.883 0 0 0-.023 2.358q.004.584.022 1.168a2.061 2.061 0 0 0 .217 1.135 7.605 7.605 0 0 0 1.522 1.37l1.848 1.499a.772.772 0 0 0 1.061 0 .755.755 0 0 0 0-1.06Zm-1.33-1.019.007.013c0 .06-.003.042-.007-.013Z' />
    </g>
  </svg>
);

export default SvgHistoric;
