import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthSlice } from 'interfaces/ISlices';
import { decodeToken } from 'utils/';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    id: null,
    type: null,
    token: null,
    expiresIn: null
  } as IAuthSlice,
  reducers: {
    login: (
      state: any,
      action: PayloadAction<{ token: string; expiresIn: number }>
    ): void => {
      const { token, expiresIn } = action.payload;
      const decodedToken = decodeToken(token);

      if (decodedToken) {
        const { id } = decodedToken;
        state.id = id;
        // state.type = type;
        state.token = token;
        state.expiresIn = expiresIn * 1000;
      }
    },
    clear: (state: any): void => {
      state.id = null;
      // state.type = null;
      state.token = null;
      state.expiresIn = null;
    }
  }
});

export const authActions = authSlice.actions;

export default authSlice;
