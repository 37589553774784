import IOption from 'interfaces/IOption';
import { CSSTransition } from 'react-transition-group';
import useSelect from './Select.logic';
import { FC, PropsWithChildren } from 'react';
import IProps from 'interfaces/IProps';
import {
  SelectIcon,
  SelectLabel,
  SelectOptions,
  SelectWrapper
} from './Select.styles';
import styles from 'styles';
import { InputIcon, InputWrapper } from 'components/Input/Input.styles';
import { icons } from 'icons';
import { Flex } from 'components/Templates';

export interface ISelectProps extends PropsWithChildren<IProps> {
  name: string;
  icon?: keyof typeof icons;
  placeholder?: string;
  options: IOption[];
  small?: boolean;
  disabled?: boolean;
}

const Select: FC<ISelectProps> = (props: ISelectProps): JSX.Element => {
  const {
    dataTestId,
    className,
    style,
    icon,
    placeholder,
    options,
    small,
    disabled
  } = props;
  const {
    isOpen,
    value,
    width,
    containerRef,
    selectRef,
    toggleOpenOptionsHandler,
    selectOptionHandler
  } = useSelect(props);
  const inputValue = value as string | undefined;
  const selectedOption = options.find(
    (option: IOption) => option.id === inputValue
  );

  return (
    <InputWrapper className={className} style={style} alignItems='center'>
      {icon && <InputIcon style={{ marginTop: '0' }} src={icon} />}
      <SelectWrapper
        data-testid={dataTestId}
        disabled={disabled || options.length === 0}
        isOpen={isOpen}
        isSmall={small}
        ref={containerRef}
      >
        <select ref={selectRef}>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <SelectLabel
          active={!!selectedOption}
          onClick={toggleOpenOptionsHandler}
        >
          <Flex justifyContent='space-between'>
            <span style={{ width }}>
              {selectedOption?.name || placeholder || '-'}
            </span>
            <SelectIcon
              isOpen={isOpen}
              small={small}
              src='arrowDown'
              size={styles.iconSize[small ? 'text' : 'textSmall']}
            />
          </Flex>
        </SelectLabel>
        <CSSTransition
          classNames='options'
          in={isOpen}
          timeout={styles.transition}
          mountOnEnter
          unmountOnExit
        >
          <SelectOptions direction='column'>
            <ul>
              {options.map((option) => (
                <li
                  data-testid={`${dataTestId}-${option.id}`}
                  key={option.id}
                  className={`${
                    option.id === selectedOption?.id && 'selected'
                  }`}
                  onClick={() => selectOptionHandler(option)}
                >
                  {option.name}
                </li>
              ))}
            </ul>
          </SelectOptions>
        </CSSTransition>
      </SelectWrapper>
    </InputWrapper>
  );
};

Select.displayName = 'Select';

export default Select;
