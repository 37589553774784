import { SVGProps } from 'react';

const SvgPhoneNumber = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M26.238 22.273a16.138 16.138 0 0 0-2.59-2.834c-.814-.812-1.705-1.606-2.938-1.242a5.866 5.866 0 0 0-1.659.954c-.23.166-.455.339-.676.517q-.17.138-.337.28a.969.969 0 0 1-.084.06 11.134 11.134 0 0 1-1.748-1.454 30.97 30.97 0 0 1-1.77-1.799 9.659 9.659 0 0 1-2.2-3.805c1.546-.95 3.922-2.75 2.473-4.637-.3-.392-.682-.709-.966-1.115-.32-.456-.57-.956-.896-1.408a3.28 3.28 0 0 0-2.44-1.534A4.08 4.08 0 0 0 7.633 5.49a7.288 7.288 0 0 0-2.016 2.507 6.043 6.043 0 0 0-.262 2.662 14.148 14.148 0 0 0 .658 3.366 11.4 11.4 0 0 0 1.313 3.021c.664.967 1.314 1.956 2.044 2.874a29.332 29.332 0 0 0 4.9 4.97 13.212 13.212 0 0 0 5.921 2.75 5.181 5.181 0 0 0 3.169-.361 9.306 9.306 0 0 0 2.479-1.912 2.38 2.38 0 0 0 .399-3.094Zm-1.962 2.502a8.932 8.932 0 0 1-1.229.962c-1.836 1.134-4.306.151-6.021-.805a20.479 20.479 0 0 1-5.113-4.35 26.638 26.638 0 0 1-2.18-2.69 15.68 15.68 0 0 1-1.761-2.821 11.716 11.716 0 0 1-1.099-6.023A4.967 4.967 0 0 1 8.85 6.416a2.295 2.295 0 0 1 1.556-.657 1.92 1.92 0 0 1 1.256.946c.321.461.574.967.906 1.422.293.401.862.786 1.049 1.241.202.494-.454.98-.776 1.27a10.076 10.076 0 0 1-1.636 1.182.717.717 0 0 0-.13.118.753.753 0 0 0-.446.844 10.795 10.795 0 0 0 2.813 5.109 32.388 32.388 0 0 0 2.138 2.135 13.456 13.456 0 0 0 1.249 1.002c.421.304.876.727 1.433.573a3.439 3.439 0 0 0 1.089-.803 9.865 9.865 0 0 1 1.469-1.01.855.855 0 0 1 1.104.074 15.666 15.666 0 0 1 1.35 1.327c.374.377.753.75 1.114 1.138.309.333.844.879.775 1.372-.063.442-.577.8-.887 1.076Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgPhoneNumber;
