import { SVGProps } from 'react';

const SvgPatientFemale = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    overflow='visible'
    viewBox='0 0 130 130'
    xmlSpace='preserve'
    y={0}
    x={0}
    id='patientFemale_svg__Layer_1_1632235826037'
    {...props}
  >
    <g transform='translate(1 1)'>
      <style>{'path{fill:#009ebb}'}</style>
      <path
        d='M1408 896c-35.3 0-64-28.7-64-64s28.7-64 64-64 64 28.7 64 64-28.7 64-64 64zm0-2c34.2 0 62-27.8 62-62s-27.8-62-62-62-62 27.8-62 62 27.8 62 62 62z'
        vectorEffect='non-scaling-stroke'
        transform='translate(-1344 -768)'
      />
      <path
        d='M1408.7 880.1c.5-.6.3-1.5-.5-1.7-8-1.9-14-7.1-17.5-14.8-.2-.5-.7-.7-1.2-.5-2.7.8-4.9 2.8-5.4 4.8-2.2 10.4 17 20.1 24.6 12.2zm-22.6-11.9c.2-1 1.5-2.2 3.2-3 3.5 7.2 9.1 12.2 16.5 14.5-7 4.3-21.4-3.5-19.7-11.5z'
        vectorEffect='non-scaling-stroke'
        transform='translate(-1344 -768)'
      />
      <path
        d='M1407.3 880.1c-.5-.6-.3-1.5.5-1.7 8-1.9 14-7.1 17.5-14.8.2-.5.7-.7 1.2-.5 2.7.8 4.9 2.8 5.4 4.8 2.2 10.4-17 20.1-24.6 12.2zm22.6-11.9c-.2-1-1.5-2.2-3.2-3-3.5 7.2-9.1 12.2-16.5 14.5 7 4.3 21.4-3.5 19.7-11.5z'
        vectorEffect='non-scaling-stroke'
        transform='translate(-1344 -768)'
      />
      <path
        d='M1408 891c-2.4 0-4-1.9-4-5 0-2.1 1.1-4.4 3.3-6.7.4-.4 1.1-.4 1.5 0 2.2 2.3 3.3 4.5 3.3 6.7-.1 3.1-1.7 5-4.1 5zm-2-5c0 2.1.8 3 2 3s2-.9 2-3c0-1.3-.7-2.8-2-4.5-1.3 1.7-2 3.2-2 4.5zM1440.3 887h-2l-.8-10c-.3-3.3-2.3-6.2-5.4-7.5l-2.1-.9v-2.2l2.9 1.3c3.7 1.6 6.2 5.1 6.6 9.2l.8 10.1zm-64.6 0 .8-10.1c.3-4 2.9-7.6 6.6-9.2l2.9-1.3v2.2l-2.1.9c-3 1.3-5.1 4.2-5.4 7.5l-.8 10h-2zm50-22.4-.2 2.1-7.9-3.4c-2.2-1-3.6-3.1-3.6-5.5V852h2v5.7c0 1.6.9 3 2.4 3.7l7.3 3.2zm-35.4 0 7.3-3.2c1.5-.6 2.4-2.1 2.4-3.7V852h2v5.7c0 2.4-1.4 4.5-3.6 5.5l-7.9 3.4-.2-2zM1387.4 798.3c-.3-1.4-.4-2.8-.4-4.3 0-11.6 9.4-21 21-21s21 9.4 21 21c0 1.5-.2 2.9-.4 4.3l-1.7-1.6c.1-.9.2-1.8.2-2.7 0-10.5-8.5-19-19-19s-19 8.5-19 19c0 .9.1 1.8.2 2.7l-1.9 1.6z'
        vectorEffect='non-scaling-stroke'
        transform='translate(-1344 -768)'
      />
      <path
        d='M1380.4 829.5c-1.6-3.5-2.4-7.4-2.4-11.5 0-16 13.4-29 30-29s30 13 30 29c0 4.1-.9 8-2.4 11.5l-1-2.9c.9-2.7 1.4-5.6 1.4-8.6 0-14.9-12.5-27-28-27s-28 12.1-28 27c0 3 .5 5.9 1.4 8.6l-1 2.9z'
        vectorEffect='non-scaling-stroke'
        transform='translate(-1344 -768)'
      />
      <path
        d='M1431.4 826.4c-.5.3-1.1.2-1.4-.3-.3-.5-.2-1.1.3-1.4.7-.4 1.4-.7 2.2-.7 2.8 0 4.5 2.5 4.5 6.5 0 4.2-3.4 9.5-6.5 9.5-1 0-1.8-.4-2.3-1.2-.3-.4-.2-1.1.2-1.4.4-.3 1.1-.2 1.4.2.2.3.4.4.7.4 1.8 0 4.5-4.2 4.5-7.5 0-3.1-1-4.5-2.5-4.5-.3 0-.7.1-1.1.4zm-45.2 11.2c.3-.4 1-.5 1.4-.2.4.3.5 1 .2 1.4-.6.7-1.4 1.2-2.3 1.2-3.1 0-6.5-5.3-6.5-9.5 0-4 1.7-6.5 4.5-6.5.7 0 1.5.2 2.2.7.5.3.6.9.3 1.4-.3.5-.9.6-1.4.3-.4-.2-.8-.4-1.1-.4-1.5 0-2.5 1.4-2.5 4.5 0 3.3 2.7 7.5 4.5 7.5.3 0 .5-.1.7-.4z'
        vectorEffect='non-scaling-stroke'
        transform='translate(-1344 -768)'
      />
      <path
        d='M1431 824.5c-16.6-2.3-26.6-7.6-30.1-15.9-.3-.8-1.5-.8-1.8 0-3.2 7.5-8 12.5-14.3 15.1-.3.1-.6.4-.6.8-.1 1.1-.2 2.3-.2 3.5 0 14.3 10.7 26 24 26s24-11.7 24-26c0-.9 0-1.7-.1-2.6-.1-.5-.4-.8-.9-.9zm-1 3.5c0 13.3-9.9 24-22 24s-22-10.7-22-24c0-.9 0-1.8.1-2.6 6-2.6 10.6-7.3 13.9-14 4.4 7.8 14.4 12.8 29.9 15.1.1.4.1 1 .1 1.5z'
        vectorEffect='non-scaling-stroke'
        transform='translate(-1344 -768)'
      />
      <path
        d='m1405.6 832.8-.8.6-1.1-1.7.8-.6c1.2-.8 2.3-1.2 3.6-1.2s2.4.4 3.6 1.2l.8.6-1.1 1.7-.8-.6c-.8-.6-1.7-.8-2.4-.8s-1.8.3-2.6.8zM1425 830.5l4.7-5.5h1.3l.8.7-6 7-.8-.7v-1.5zM1391 830.5v1.5l-.8.7-6-7 1.5-1.3 5.3 6.1z'
        vectorEffect='non-scaling-stroke'
        transform='translate(-1344 -768)'
      />
      <path
        d='M1419 842c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-2c3.3 0 6-2.7 6-6s-2.7-6-6-6-6 2.7-6 6 2.7 6 6 6zM1397 842c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-2c3.3 0 6-2.7 6-6s-2.7-6-6-6-6 2.7-6 6 2.7 6 6 6z'
        vectorEffect='non-scaling-stroke'
        transform='translate(-1344 -768)'
      />
    </g>
  </svg>
);

export default SvgPatientFemale;
