import IProps from 'interfaces/IProps';
import { FC, PropsWithChildren } from 'react';
import styles from 'styles';
import { rgba } from 'utils/colors';
import useInput from '../Input.logic';
import { InputWrapper } from '../Input.styles';
import { IInputProps } from '../Input.types';
import { SearchIcon, SearchInputContainer } from './SearchInput.styles';

export interface ISearchInputProps
  extends PropsWithChildren<
    Omit<
      IInputProps,
      'name' | 'icon' | 'success' | 'error' | 'prefix' | 'suffix' | 'help'
    > &
      IProps
  > {}

const SearchInput: FC<ISearchInputProps> = (
  props: ISearchInputProps
): JSX.Element => {
  const { dataTestId, className, style, disabled, placeholder } = props;
  const { value, isFocused, inputChangeHandler, focusHandler, blurHandler } =
    useInput({ ...props, name: '' }, false, false);

  return (
    <InputWrapper className={className} style={style}>
      <SearchInputContainer isFocused={isFocused}>
        <SearchIcon
          size={styles.iconSize.text}
          fill={rgba(styles.colors.brand.dark, 0.4)}
          src='search'
        />
        <input
          data-testid={dataTestId}
          type='search'
          placeholder={placeholder}
          value={value as string}
          disabled={disabled}
          onChange={inputChangeHandler}
          onFocus={focusHandler}
          onBlur={blurHandler}
        />
      </SearchInputContainer>
    </InputWrapper>
  );
};

SearchInput.displayName = 'SearchInput';

export default SearchInput;
