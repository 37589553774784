import { SVGProps } from 'react';

const SvgFile = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M25.294 17.917q-.001-.89-.005-1.779c-.001-.595-.098-1.174-.1-1.765a6.79 6.79 0 0 0-.037-1.716c-.163-.607-.766-1.014-1.204-1.423L21.149 8.62c-.45-.421-.891-.858-1.354-1.265a2.04 2.04 0 0 0-1.443-.535 13.061 13.061 0 0 0-1.37.044c-.137-.39-.324-.78-.48-1.143a3.148 3.148 0 0 0-1.011-1.448 3.998 3.998 0 0 0-3.8-.128 3.495 3.495 0 0 0-1.912 2.777 5.623 5.623 0 0 0-2.11.289c-1.16.458-.919 1.51-.862 2.515.146 2.582.09 5.188.101 7.772.011 2.602.033 5.206-.017 7.807-.02 1.042-.294 2.262.874 2.781a8.73 8.73 0 0 0 3.381.214l8.695-.014 2.214-.004a6.953 6.953 0 0 0 1.987-.09c1.246-.388 1.136-1.749 1.202-2.793.137-2.482.052-4.996.05-7.482ZM12.562 5.408c.717-.234 1.826-.323 2.276.342a4.741 4.741 0 0 1 .575 1.203c-.134.003-.267.012-.402.01-1.23-.02-2.461-.003-3.69-.004h-.023a1.94 1.94 0 0 1 1.264-1.55Zm11.102 21.248c-.165.264-.935.125-1.24.126l-3.322.005-6.642.011c-1.103.002-2.206.013-3.308.005a3.468 3.468 0 0 1-.552-.02c-.244-.042-.245-.05-.262-.282-.04-.546.055-1.14.06-1.69q.025-3.347.013-6.693t-.058-6.69c-.007-.496-.011-.991-.034-1.486a16.659 16.659 0 0 0-.05-.754c-.01-.12-.12-.494-.062-.596.104-.183 1.096-.13 1.317-.132.559-.008 1.118-.002 1.677-.001l3.232.002c.425 0 .85.017 1.274.02.078 1.009-.004 2.05-.01 3.071-.011 2.167-.066 4.334-.107 6.5a10.248 10.248 0 0 1-.04 1.546c-.075.423-.325.522-.72.584-.416.066-1.458.175-1.649-.36a4.912 4.912 0 0 1 .065-1.507q.04-.83.064-1.662c.035-1.227.035-2.454.035-3.68a.75.75 0 0 0-1.5 0c0 1.424-.003 2.85-.054 4.274a65.357 65.357 0 0 1-.089 1.914 2.27 2.27 0 0 0 .35 1.667c1.11 1.477 4.44 1.204 4.92-.715a8.75 8.75 0 0 0 .116-1.937l.042-2.224c.028-1.467.06-2.933.067-4.4.006-1.063.115-2.16.045-3.233l.024-.003a3.868 3.868 0 0 1 1.353.013c.202.076.411.362.567.508l.632.59 2.528 2.361c.38.356 1.107.817 1.316 1.285.212.474-.006 1.255.038 1.776.093 1.102.094 2.197.095 3.306.001 2.407.083 4.84-.05 7.244-.018.285-.035.57-.044.857a.93.93 0 0 1-.037.4Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgFile;
