import { SVGProps } from 'react';

const SvgFilters = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M26.268 4.678a.666.666 0 0 0-.076-.092c-.014-.013-.021-.03-.036-.043s-.03-.015-.046-.027a.696.696 0 0 0-.105-.09 4.224 4.224 0 0 0-2.343-.307q-1.013.02-2.026.028c-1.62.015-3.24.017-4.859.016-2.763-.002-5.526-.023-8.29-.028-1.003-.002-2.24-.146-3.022.59a2.548 2.548 0 0 0-.268 2.896A12.341 12.341 0 0 0 5.748 9c1.145 2.012 2.396 3.969 3.614 5.937.518.837 1.035 1.675 1.562 2.506.184.289.391.533.597.809a1.837 1.837 0 0 1 .407 1.382c.072 1.278.072 2.558.118 3.837a2.206 2.206 0 0 0 .768 1.908c.313.226.667.407 1 .6 1.097.638 2.2 1.265 3.32 1.86a3.316 3.316 0 0 0 1.147.482c.984.1 1.346-.798 1.36-1.627.026-1.507-.002-3.017-.014-4.525-.004-.505-.003-1.01-.016-1.516a4.12 4.12 0 0 1 .365-2.658q1.824-2.763 3.619-5.545l1.933-2.982a8.961 8.961 0 0 0 1.288-2.395 2.433 2.433 0 0 0-.548-2.395Zm-1.742 3.58q-.763 1.18-1.528 2.358c-1.28 1.975-2.558 3.951-3.85 5.918l-.467.704a4.039 4.039 0 0 0-.61 2.638c.065 1.183.058 2.368.065 3.552q.006 1.112.008 2.224l-.002.884c0 .027-.006.078-.012.132l-.035-.015c-.226-.118-.45-.24-.674-.362q-.842-.46-1.675-.933-.746-.423-1.487-.855c-.293-.173-.643-.3-.686-.563a26.13 26.13 0 0 1-.1-3.27c-.043-1.257.005-2.384-.832-3.407a14.702 14.702 0 0 1-1.044-1.57Q9.74 12.716 7.92 9.715a11.93 11.93 0 0 1-1.028-1.747c-.123-.32-.222-.682-.355-1.015-.155-.388-.356-1.024.134-1.235a3.314 3.314 0 0 1 1.216-.084c5.158-.02 10.316.081 15.474-.01a4.54 4.54 0 0 1 1.808.072c.677.747-.183 1.85-.643 2.562Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgFilters;
