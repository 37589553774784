import { SVGProps } from 'react';

const SvgHelpContent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M26.74 25.462c-.01-.702-.03-1.404-.035-2.107l-.063-9.136c-.01-1.358-.018-2.71.024-4.066a2.804 2.804 0 0 0-.255-1.364 6.371 6.371 0 0 0-1.264-1.426 8.507 8.507 0 0 1-1.163-1.44c-.335-.542-.525-1.14-1.18-1.367a7.057 7.057 0 0 0-1.942-.242 26.616 26.616 0 0 0-2.341.015c-3.105.131-6.203.154-9.31.17-.73.004-1.465-.014-2.195.011a1.519 1.519 0 0 0-1.471.788 4.703 4.703 0 0 0-.211 1.813q-.043 1.16-.064 2.32c-.055 3.053.013 6.105.183 9.154a37.478 37.478 0 0 1 .016 4.481 3.97 3.97 0 0 0 .956 3.311 5.43 5.43 0 0 0 3.951 1.109c1.728-.021 3.454-.089 5.182-.122 1.73-.034 3.462-.038 5.19.057a31.047 31.047 0 0 0 5.232.215c.026-.003.044-.017.069-.022a.669.669 0 0 0 .579-.35 3.902 3.902 0 0 0 .113-1.802ZM6.791 8.455q.026-.977.067-1.953a1.386 1.386 0 0 1 .021-.444c.01-.023.005-.025.006-.037a.109.109 0 0 0 .043-.008 3.733 3.733 0 0 1 1.064-.007c2.765-.015 5.542.045 8.305-.068 1.398-.057 2.796-.173 4.196-.137.328.008.657.025.984.052.216.018.672-.018.856.12a1.456 1.456 0 0 1 .237.45.655.655 0 0 0-.147.454q.372 8.01-.044 16.027l-15.414.222.018-.26c.05-.699.077-1.395.065-2.095-.048-2.764-.274-5.52-.295-8.287q-.015-2.015.038-4.03Zm15.066 17.54a52.813 52.813 0 0 0-4.197-.153c-2.713-.003-5.451.26-8.16.136a2.463 2.463 0 0 1-2.435-1.353l16.032-.231a.766.766 0 0 0 .75-.75q.426-7.68.123-15.373c.115.121.216.255.334.373.334.334.797.641.86 1.14a9.877 9.877 0 0 1-.043 1.313c-.008.645.008 1.292.012 1.937l.065 9.333q.01 1.293.033 2.585c.008.375.014.75.017 1.125v.102a29.194 29.194 0 0 1-3.39-.184Zm3.383.7c-.016.106-.046.116 0 0Z' />
      <path d='M12.887 19.22a3.98 3.98 0 0 1 1.4-.128c.532.14.896.49 1.492.45a5.03 5.03 0 0 0 2.786-1.555 4.348 4.348 0 0 0 1.154-2.958 3.722 3.722 0 0 0-.732-3.014 3.937 3.937 0 0 0-2.477-1.055 5.518 5.518 0 0 0 .465-2.03.753.753 0 0 0-.75-.75.822.822 0 0 0-.282.063.705.705 0 0 0-.227-.095 2.987 2.987 0 0 0-3.11 1.205 2.956 2.956 0 0 0-.497 1.657 2.741 2.741 0 0 0 .068.55 2.437 2.437 0 0 0-1.967.673 3.437 3.437 0 0 0-.894 2.776c.181 1.961 1.21 4.5 3.571 4.211Zm.86-8.85a1.429 1.429 0 0 1 1.57-.775.712.712 0 0 0 .078 0l-.003.017c-.017.088-.036.175-.059.262s-.048.17-.079.254l-.006.018c-.018.027-.048.1-.052.109-.037.07-.081.135-.123.202-.036.038-.07.076-.106.112a2.31 2.31 0 0 1-.097.088l-.023.019c-.076.051-.151.105-.229.154a9.012 9.012 0 0 1-.51.307c-.035.019-.208.085-.27.126.032-.014.063-.017-.016.013l-.013.01.01-.01-.009.004a2.06 2.06 0 0 1-.128.041l-.018.006a1.472 1.472 0 0 1 .083-.958Zm-2.858 3.575c.19-.614.809-1.181 1.452-.78a.657.657 0 0 0 .612.068.719.719 0 0 0 .866-.124 3.395 3.395 0 0 1 3.665-.36 1.61 1.61 0 0 1 .777 1.649 4.321 4.321 0 0 1-.398 2.106 4.558 4.558 0 0 1-1.606 1.334.859.859 0 0 1-.913.098 2.958 2.958 0 0 0-1.08-.356c-.675-.06-1.465.352-2.113.134-.52-.175-.791-.804-.961-1.28a4.053 4.053 0 0 1-.3-2.49Z' />
      <path d='M15.256 14.105a2.327 2.327 0 0 1 .214.369 2.3 2.3 0 0 1 .124.442.838.838 0 0 0 .345.449.75.75 0 0 0 1.026-.27l.076-.179a.756.756 0 0 0 0-.398 2.985 2.985 0 0 0-.791-1.55.75.75 0 0 0-1.06 0 .755.755 0 0 0-.22.53.798.798 0 0 0 .22.53c.023.025.043.052.066.077ZM13.822 11.274l.016-.012a.06.06 0 0 0-.019.013Z' />
    </g>
  </svg>
);

export default SvgHelpContent;
