import { SVGProps } from 'react';

const SvgClinicalData = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M25.804 24.482c-.023-2.46-.018-4.92-.04-7.38l-.08-8.367c-.009-1.018.255-2.538-.804-3.14a3.75 3.75 0 0 0-1.515-.31 9.058 9.058 0 0 0-.951-.028c.127-.64.033-1.283-.78-1.445a10.612 10.612 0 0 0-1.842-.052q-.919-.017-1.837-.044c-1.277-.039-2.538-.013-3.813-.026a24.596 24.596 0 0 0-3.487-.008 1.25 1.25 0 0 0-1.087.806 1.529 1.529 0 0 0 .013.858 4.232 4.232 0 0 0-2.313.44c-.87.584-.779 1.989-.814 2.906q-.143 3.706-.18 7.416c-.024 2.277-.077 4.533-.037 6.803a27.036 27.036 0 0 0 .17 2.896 2.393 2.393 0 0 0 1.539 2.038 9.226 9.226 0 0 0 3.225.189l3.703-.046 7.405-.09a3.902 3.902 0 0 0 2.816-.717c.806-.757.718-1.686.709-2.699ZM12.37 5.172l1.771.018c1.009.01 2.006-.036 3.014 0q1.64.058 3.282.08c.042 0 .239.015.434.024a11.395 11.395 0 0 1-.63 1.391 4.606 4.606 0 0 0-.316.522l-.021.034a1.48 1.48 0 0 1-.187.009c-.514.011-1.03-.01-1.544-.014l-3.3-.03-1.82-.017-.684-.006a1.993 1.993 0 0 1-.658-.012c.097.06-.077-.374-.1-.429-.061-.154-.128-.294-.182-.452-.096-.28-.175-.567-.263-.85l-.075-.246c.412.013 1.203-.022 1.28-.021ZM24.033 26.12a1.789 1.789 0 0 1-1.161.27c-4.146.085-8.295.102-12.442.153-.67.008-1.933.235-2.378-.414a1.919 1.919 0 0 1-.172-1.057c-.022-.406-.147-.775-.148-1.185-.005-.942-.045-1.882-.062-2.823-.007-.382.09-.75.097-1.133.011-.523-.007-1.046-.007-1.569q.001-3.465.103-6.93c.033-1.196.04-2.4.127-3.593a1.75 1.75 0 0 1 .123-.75c.144-.18.455-.151.671-.17.434-.035.87-.037 1.304-.04.06.162.122.325.172.488.132.43.178.79.597 1.059a2.214 2.214 0 0 0 1.206.254l1.9.017 3.642.033 1.611.015a3.007 3.007 0 0 0 1.465-.18 2.528 2.528 0 0 0 .803-1.033 6.745 6.745 0 0 0 .38-.75c.285-.022.572-.036.86-.03a3.45 3.45 0 0 1 1.268.11c.17.088.162.081.177.306.018.275.007.555.01.83l.014 1.598.058 6.15.059 6.29c.009 1.018-.029 2.037.007 3.055.013.397.05.744-.284 1.029Z' />
      <path d='M11.516 18.63q4.846-.028 9.687-.246c.961-.043.967-1.544 0-1.5q-4.84.218-9.687.247a.75.75 0 0 0 0 1.5ZM11.63 21.537q4.674.042 9.343-.227c.96-.056.966-1.556 0-1.5q-4.666.27-9.343.227a.75.75 0 0 0 0 1.5ZM21.204 22.956q-4.787-.088-9.574.074c-.962.033-.967 1.533 0 1.5q4.786-.162 9.574-.074a.75.75 0 0 0 0-1.5ZM14.381 13.439l1.013.029c-.012.438-.022.877-.045 1.315a.755.755 0 0 0 .75.75.767.767 0 0 0 .75-.75c.023-.424.032-.848.043-1.272l1.332.038a.756.756 0 0 0 .75-.75.765.765 0 0 0-.75-.75l-1.315-.038c0-.337.004-.674-.004-1.011a.75.75 0 0 0-1.5 0c.007.323.003.645.004.968l-1.028-.03a.756.756 0 0 0-.75.75.765.765 0 0 0 .75.75Z' />
    </g>
  </svg>
);

export default SvgClinicalData;
