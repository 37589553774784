import { SVGProps } from 'react';

const SvgVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M28.24 18.227c.128-2.68.02-5.39-.035-8.073-.014-.649-.028-1.298-.039-1.947a3.342 3.342 0 0 0-.388-1.764c-.638-1.013-2.088-1.001-3.144-.987q-4.326.059-8.653.064c-2.842.004-5.692-.094-8.533-.034a3.663 3.663 0 0 0-3.012 1.207c-.71.943-.576 2.094-.58 3.199-.006 1.302-.053 2.606-.062 3.909-.005.686 0 1.371.01 2.058.008.613-.072 1.206-.077 1.814a10.992 10.992 0 0 0 .18 3.62 2.73 2.73 0 0 0 2.666 1.585c3.046-.02 6.092.002 9.138.005l4.786.005c1.35 0 2.676-.075 4.021-.084 1.162-.007 2.547.053 3.315-.994.707-.963.354-2.464.407-3.583Zm-1.466-2.487c.005 1.248-.04 2.493-.04 3.74 0 .53.187 1.342-.36 1.645a4.09 4.09 0 0 1-1.856.18c-1.005.006-1.987.084-2.989.083l-3.55-.003-7.283-.007-3.52-.004a3.33 3.33 0 0 1-1.406-.167c-.531-.229-.54-.698-.545-1.219-.022-2.219.053-4.431.073-6.652.01-1.148.053-2.297.057-3.445.003-.799-.247-1.97.504-2.533.823-.616 2.248-.371 3.2-.363q1.854.016 3.708.022 3.77.012 7.541-.016 1.855-.013 3.709-.037a7.061 7.061 0 0 1 1.759.04 2.865 2.865 0 0 1 .619.128c.236.145.248.513.264.753.168 2.602.104 5.248.115 7.855Z' />
      <path d='M19.163 12.797c-.775-.57-1.56-1.124-2.34-1.687a10.504 10.504 0 0 0-2.23-1.438 2.138 2.138 0 0 0-2.293.484c-.627.687-.453 1.752-.453 2.597 0 1.106.008 2.213-.003 3.318-.01 1.012-.213 2.172.832 2.756a.713.713 0 0 0 .104.033c.027.013.048.032.076.043.938.353 1.998-.278 2.797-.731.83-.47 1.624-1.01 2.431-1.518a4.709 4.709 0 0 0 1.923-1.608c.462-.94-.102-1.704-.844-2.249Zm-.845 1.933c-.269.203-.575.367-.86.546-.73.46-1.455.932-2.191 1.382-.326.198-.66.386-1.004.553a1.64 1.64 0 0 1-.89.262c-.08-.101-.052-.254-.046-.437.011-.368.017-.736.019-1.105.005-.754.001-1.508.001-2.263v-1.067c0-.356-.055-.746-.023-1.098a.973.973 0 0 1 .078-.306c.01-.018.004-.015 0-.016a1.545 1.545 0 0 1 .159-.077.527.527 0 0 1 .526-.026 7.109 7.109 0 0 1 1.549 1.017l1.847 1.331.862.622c.09.064.334.208.337.326 0 .088-.301.31-.363.356ZM27.41 24.75q-11.08.346-22.167.295a.75.75 0 0 0 0 1.5q11.085.051 22.167-.295c.962-.03.967-1.53 0-1.5Z' />
    </g>
  </svg>
);

export default SvgVideo;
