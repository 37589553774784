import { KeyboardEvent, RefObject, useContext, useRef } from 'react';
import { FormContext } from 'store';
import { ActionTypes } from 'store/form-context';
import { InputTypes } from 'utils/form';
import useInput, { IUseInput } from '../Input.logic';
import { ITagsInputProps } from './TagsInput';

export interface IUseTagsInput
  extends Omit<
    IUseInput,
    'showPopup' | 'containerRef' | 'changeHandler' | 'inputChangeHandler'
  > {
  inputRef: RefObject<HTMLInputElement>;
  focusInputHandler: () => void;
  keyUpHandler: (event: KeyboardEvent<HTMLInputElement>) => void;
  addTagHandler: (tagId: string) => void;
  removeTagHandler: (tagId: string) => void;
}

const useTagsInput = (props: ITagsInputProps): IUseTagsInput => {
  const { name } = props;
  const { dispatchInputs } = useContext(FormContext);
  const { input, value, isFocused, focusHandler, blurHandler } =
    useInput(props);
  const inputRef = useRef<HTMLInputElement>(null);

  if (input?.type !== InputTypes.TAGS)
    throw new Error('Tags Input requires the type to be TAGS.');

  const focusInputHandler = (): void => {
    inputRef.current?.focus();
  };

  const keyUpHandler = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === ',') {
      const tags = event.currentTarget.value.split(',');
      addTagHandler(tags[0]);
      event.currentTarget.value = tags[1] || '';
    } else if (event.key === 'Enter') {
      addTagHandler(event.currentTarget.value);
      event.currentTarget.value = '';
    }
  };

  const addTagHandler = (tagId: string) => {
    const value = input.value as string[];
    value.push(tagId);

    dispatchInputs({
      type: ActionTypes.UPDATE_VALUE,
      attribute: name,
      value
    });
  };

  const removeTagHandler = (tagId: string): void => {
    const value = input.value as string[];
    value.splice(value.indexOf(tagId), 1);

    dispatchInputs({
      type: ActionTypes.UPDATE_VALUE,
      attribute: name,
      value
    });
  };

  return {
    input,
    value,
    isFocused,
    inputRef,
    focusInputHandler,
    keyUpHandler,
    addTagHandler,
    removeTagHandler,
    focusHandler,
    blurHandler
  };
};

export default useTagsInput;
