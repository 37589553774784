import IProps from 'interfaces/IProps';
import { forwardRef, PropsWithChildren, ForwardedRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from 'styles';
import useScrollView, { Scroll } from './ScrollView.logic';
import {
  ScrollViewBackToTopButton,
  ScrollViewGradient,
  ScrollViewWrapper
} from './ScrollView.styles';

export interface IScrollViewProps extends PropsWithChildren<IProps> {
  hideGradients?: boolean;
  showButtonScrollTop?: boolean;
}

const ScrollView = forwardRef<
  HTMLDivElement,
  PropsWithChildren<IScrollViewProps>
>((props: IScrollViewProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { className, style, hideGradients, showButtonScrollTop, children } =
    props;
  const { scroll, showButton, containerRef, scrollToTopHandler } =
    useScrollView(ref);

  return (
    <ScrollViewWrapper className={`${scroll} ${className}`} style={style}>
      <ScrollViewGradient
        hide={hideGradients || scroll === Scroll.NONE || scroll === Scroll.TOP}
      >
        <div /> <div />
      </ScrollViewGradient>
      <div ref={containerRef}>{children}</div>
      <ScrollViewGradient
        hide={
          hideGradients || scroll === Scroll.NONE || scroll === Scroll.BOTTOM
        }
      >
        <div /> <div />
      </ScrollViewGradient>
      {showButtonScrollTop && (
        <CSSTransition
          classNames='back-to-top_button'
          in={showButton}
          timeout={styles.transition}
          mountOnEnter
          unmountOnExit
        >
          <ScrollViewBackToTopButton
            icon='arrowTop'
            type='secondary'
            theme='white'
            onClick={scrollToTopHandler}
          />
        </CSSTransition>
      )}
    </ScrollViewWrapper>
  );
});

export default ScrollView;
