import { SVGProps } from 'react';

const SvgEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='m27.998 20.882-.01-2.33-.022-4.54a19.956 19.956 0 0 0-.176-4.12 3.542 3.542 0 0 0-.706-1.538 2.618 2.618 0 0 0-1.6-.674.73.73 0 0 0-.103-.001.72.72 0 0 0-.096-.026c-1.254-.08-2.542.008-3.8.015l-3.748.02c-2.743.015-5.486.016-8.229.03-2.056.012-5.033-.165-5.44 2.507a25.549 25.549 0 0 0-.049 3.758l-.007 4.169c-.002 1.252-.051 2.516-.008 3.767a2.795 2.795 0 0 0 1.629 2.33 5.486 5.486 0 0 0 1.716.109c.679-.002 1.358-.017 2.036-.017q1.993-.002 3.987 0l7.972.001a28.904 28.904 0 0 0 3.855-.077 3.198 3.198 0 0 0 2.532-1.487 4.06 4.06 0 0 0 .267-1.896Zm-4.335-2.053a29.073 29.073 0 0 1-2.78-3.05l.487-.511 4.922-5.177a4.568 4.568 0 0 1 .141.912c.07 1.121.03 2.255.035 3.378.01 2.322.05 4.645.032 6.967-.001.119-.006.233-.018.344q-1.424-1.417-2.819-2.863ZM10.008 9.217q3.925-.013 7.85-.03l3.749-.02c1.162-.005 2.35-.08 3.512-.02l-4.295 4.519-2.402 2.526c-.555.584-1.325 1.681-2.229 1.67a1.896 1.896 0 0 1-1.116-.603c-.416-.36-.818-.738-1.224-1.11q-1.259-1.15-2.501-2.32a273.886 273.886 0 0 1-4.616-4.466 18.293 18.293 0 0 1 3.272-.146ZM5.5 21.345c-.028-.98.01-1.971.01-2.947.005-2.292.017-4.583-.004-6.874A4.756 4.756 0 0 1 5.6 10.35a273.086 273.086 0 0 0 5.2 5.016l-.009.01a15.505 15.505 0 0 1-2.963 3.2A35.276 35.276 0 0 0 5.5 21.345Zm19.17 1.476c-1.102.073-2.222.022-3.326.022l-6.886-.001q-3.495 0-6.99.015a4.369 4.369 0 0 1-1.31-.026c.458-.501.878-1.036 1.29-1.576a14.325 14.325 0 0 1 1.52-1.694 17.95 17.95 0 0 0 2.941-3.171c.534.493 1.065.989 1.603 1.477a4.234 4.234 0 0 0 2.68 1.495 3.889 3.889 0 0 0 2.64-1.428c.342-.346.672-.704 1.006-1.058a35.746 35.746 0 0 0 2.673 2.92q1.422 1.477 2.876 2.923a4.422 4.422 0 0 1-.716.102Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgEmail;
