import { SVGProps } from 'react';

const SvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M27.494 8.616c-.016-1.26.054-2.664-.968-3.585-.997-.898-2.334-.673-3.563-.673h-9.407c-1.485 0-2.97.004-4.455-.01a9.813 9.813 0 0 0-1.962.126 3.492 3.492 0 0 0-1.605.892c-1.032.92-1.029 2.255-1.036 3.53-.008 1.55.014 3.1.04 4.65.05 3.006.042 6.009.029 9.013-.006 1.257-.148 2.754.655 3.818a4.297 4.297 0 0 0 3.45 1.37c1.606.044 3.23-.048 4.84-.048h5.04c1.592 0 3.145.145 4.742.15a3.912 3.912 0 0 0 3.604-1.677 7.138 7.138 0 0 0 .488-3.927c-.053-1.459.07-2.943.089-4.403.041-3.074.057-6.152.019-9.226ZM6.03 7.546a1.79 1.79 0 0 1 .814-1.3 2.607 2.607 0 0 1 1.589-.397c1.308-.033 2.623.01 3.931.01h8.015c1.306 0 2.61-.016 3.915-.032a1.59 1.59 0 0 1 1.351.445 2.605 2.605 0 0 1 .335 1.636c.075 2.854.04 5.72.01 8.575-.076-.087-.152-.175-.228-.26a18.032 18.032 0 0 0-1.732-1.68c-1.15-.978-2.435-1.605-3.87-.763a10.807 10.807 0 0 0-1.812 1.524q-.978.915-1.937 1.85c-.209.203-.411.413-.62.617a7.513 7.513 0 0 0-2.085-1.348c-.404-.185-.733-.471-1.143-.651a3.271 3.271 0 0 0-1.59-.34c-1.072.093-1.99 1.002-2.815 1.618-.709.53-1.398 1.086-2.089 1.64.008-1.063.018-2.126.001-3.189-.02-1.344-.047-2.69-.062-4.036a26.767 26.767 0 0 1 .022-3.918Zm16.55 18.8c-1.236-.005-2.431-.146-3.67-.146l-7.904-.001a12.808 12.808 0 0 1-3.607-.121c-1.33-.394-1.304-1.47-1.325-2.658-.018-.959-.009-1.918-.003-2.878a.976.976 0 0 0 .22-.132q1.179-.97 2.396-1.891.61-.46 1.229-.907a3.293 3.293 0 0 1 1.093-.678c.75-.151 1.469.565 2.094.856a6.294 6.294 0 0 1 1.627 1.039c-.696.692-1.398 1.378-2.086 2.078-.677.689.383 1.75 1.06 1.06 2.175-2.212 4.338-4.534 6.717-6.53a1.526 1.526 0 0 1 1.659-.46 6.339 6.339 0 0 1 1.544 1.21 10.497 10.497 0 0 1 1.523 1.62 5.109 5.109 0 0 0 .803 1.128c-.013.813-.022 1.626-.042 2.439-.016.638.009 1.268.01 1.905a4.034 4.034 0 0 1-.315 2.136c-.72 1.07-1.878.937-3.023.932Z' />
      <path d='M13.177 12.98a2.831 2.831 0 0 0 1.41-1.14 2.563 2.563 0 0 0 .252-1.939 2.511 2.511 0 0 0-2.46-1.792 3.17 3.17 0 0 0-2.657 1.355 2.386 2.386 0 0 0 .478 3.063 2.956 2.956 0 0 0 2.977.453Zm.163-2.83.012.025c.048.088.008.024-.012-.025Zm-2.424.252c.04-.07.076-.138.118-.206.023-.027.046-.054.071-.08.052-.055.112-.102.166-.154.038-.026.076-.052.116-.076.073-.045.15-.084.226-.123l.01-.005c.034-.012.067-.026.101-.037q.124-.042.252-.071c.04-.01.08-.018.12-.025l.01-.002c.097-.003.193-.015.29-.014.05.001.1.005.15.008a2.443 2.443 0 0 1 .267.062l.023.009c.006 0 0-.008.014 0l.037.018a.214.214 0 0 0 .051.018c-.02-.002-.021-.007-.036-.011l.022.011c.044.023.087.048.128.074l.007.005c.05.06.13.123.184.177l.004.008c.03.044.057.09.082.138l.01.02c.012.05.043.115.054.154.012.048.021.097.031.145v.002c.004.1 0 .196-.003.295-.008.037-.015.074-.026.11-.01.04-.025.077-.037.115l-.006.011c-.036.07-.08.132-.12.196-.032.034-.062.069-.095.102a1.86 1.86 0 0 1-.087.08l-.007.007c-.069.045-.135.092-.208.132q-.053.03-.108.056l-.01.005c-.079.02-.16.058-.238.08-.076.02-.153.032-.23.048h-.005q-.08.007-.16.009a2.966 2.966 0 0 1-.245-.009c-.038-.007-.076-.013-.113-.022-.084-.02-.164-.05-.246-.075-.042-.022-.084-.043-.125-.067a1.835 1.835 0 0 1-.104-.065l-.001-.001c-.064-.064-.13-.123-.192-.189-.023-.036-.049-.071-.071-.109q-.04-.067-.075-.138l-.001-.003v-.005c-.012-.05-.028-.1-.04-.152l-.001-.01c-.003-.026-.008-.068-.008-.085a1.577 1.577 0 0 1 .002-.158V10.6c.005-.027.01-.054.017-.08.01-.04.022-.079.035-.118Z' />
      <path d='m12.9 9.713-.014-.007-.051-.018c-.017 0 .002.01.065.025Z' />
    </g>
  </svg>
);

export default SvgImage;
