import { SVGProps } from 'react';

const SvgBelt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    xmlSpace='preserve'
    {...props}
  >
    <path d='M247.449 247.983h-.086c-4.428 0-7.974 3.588-7.974 8.017 0 4.428 3.631 8.017 8.059 8.017s8.017-3.588 8.017-8.017a8.015 8.015 0 0 0-8.016-8.017zM119.267 247.983h-.086c-4.428 0-7.974 3.588-7.974 8.017 0 4.428 3.631 8.017 8.059 8.017s8.017-3.588 8.017-8.017a8.014 8.014 0 0 0-8.016-8.017zM452.676 247.983h-.085c-4.428 0-7.974 3.588-7.974 8.017 0 4.428 3.631 8.017 8.059 8.017s8.017-3.588 8.017-8.017a8.016 8.016 0 0 0-8.017-8.017zM59.409 247.983h-.086c-4.428 0-7.974 3.588-7.974 8.017 0 4.428 3.631 8.017 8.059 8.017A8.016 8.016 0 0 0 67.425 256a8.014 8.014 0 0 0-8.016-8.017z' />
    <path d='M495.432 196.676h-150.28c-5.792-10.2-16.749-17.102-29.294-17.102H196.142c-12.546 0-23.502 6.902-29.294 17.102H16.568C7.432 196.676 0 204.108 0 213.244v85.511c0 9.136 7.432 16.568 16.568 16.568h150.28c5.792 10.2 16.749 17.102 29.294 17.102h119.716c12.546 0 23.502-6.902 29.294-17.102h150.28c9.136 0 16.568-7.432 16.568-16.568v-85.511c0-9.136-7.432-16.568-16.568-16.568zM162.486 299.29H16.568a.536.536 0 0 1-.534-.534v-85.511c0-.294.241-.534.534-.534h145.918c-.003.178-.014.355-.014.534v85.511c0 .179.01.356.014.534zm171.009-51.307h-18.171v-26.188a8.016 8.016 0 0 0-8.017-8.017H204.693a8.016 8.016 0 0 0-8.017 8.017v68.409a8.016 8.016 0 0 0 8.017 8.017h102.614a8.016 8.016 0 0 0 8.017-8.017v-26.188h18.171v34.739c0 9.725-7.912 17.637-17.637 17.637H196.142c-9.725 0-17.637-7.912-17.637-17.637v-85.511c0-9.725 7.912-17.637 17.637-17.637h119.716c9.725 0 17.637 7.912 17.637 17.637v34.739zm-43.29 16.034h9.086v18.171h-86.58v-52.376h86.58v18.171h-9.086a8.016 8.016 0 0 0-8.017 8.017 8.016 8.016 0 0 0 8.017 8.017zm120.25 9.085c0 14.441-11.747 26.188-26.188 26.188h-34.753c.003-.179.014-.355.014-.534v-85.511c0-.18-.011-.356-.014-.534h34.753c14.441 0 26.188 11.747 26.188 26.188v34.203zm85.512 25.654a.536.536 0 0 1-.534.534h-78.084c5.714-7.203 9.14-16.301 9.14-26.188v-34.205c0-9.887-3.426-18.985-9.14-26.188h78.084c.294 0 .534.24.534.534v85.513z' />
  </svg>
);

export default SvgBelt;
