import EntityImageDTO from 'dtos/EntityImageDTO';
import { IProps } from 'interfaces';
import { FC, PropsWithChildren } from 'react';
import { getImageBase64 } from 'utils/file';
import { UserImageIcon, UserImageWrapper } from './UserImage.styles';

export interface IUserImageProps extends PropsWithChildren<IProps> {
  src?: EntityImageDTO | string;
  size?: string;
  square?: boolean;
  onClick?: () => void;
}

const UserImage: FC<IUserImageProps> = (
  props: IUserImageProps
): JSX.Element => {
  const { dataTestId, className, style, src, size, square, onClick } = props;

  return (
    <UserImageWrapper
      data-testid={dataTestId}
      className={className}
      style={style}
      size={size}
      isSquare={square}
      isClickable={onClick !== undefined}
      onClick={() => (onClick ? onClick() : '')}
    >
      {src && (
        <img
          data-testid={`${dataTestId}-image`}
          src={typeof src === 'string' ? src : getImageBase64(src)}
          alt=''
        />
      )}
      {!src && (
        <UserImageIcon data-testid={`${dataTestId}-empty`} src='userData' />
      )}
    </UserImageWrapper>
  );
};

export default UserImage;
