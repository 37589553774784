import { Flex, IFlexProps } from 'components/Templates';
import styled from 'styled-components';
import { IInputContainerProps, InputContainer } from '../Input.styles';

export interface ITagsWithOptionsInputContainerProps {
  hasValue: boolean;
}

export const TagsWithOptionsInputContainer = styled(InputContainer).attrs(
  (_: IInputContainerProps) => ({ alignItems: 'flex-start' })
)<ITagsWithOptionsInputContainerProps>`
  padding: 0.25rem;
  padding-bottom: ${({ hasValue }: ITagsWithOptionsInputContainerProps) =>
    hasValue ? '0' : ''};
  border-radius: 1.5rem;
`;

export interface ITagsProps {}

export const Tags = styled(Flex).attrs((_: IFlexProps) => ({
  justifyContent: 'flex-start'
}))<ITagsProps>`
  flex-wrap: wrap;
`;
