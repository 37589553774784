import { SVGProps } from 'react';

const SvgConditions = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M26.832 21.358a2.226 2.226 0 0 0-1.893-.584h-.256c-.046-.573.066-1.155.052-1.73-.023-.888-.01-1.777-.008-2.665l.01-5.238c.003-1.746.013-3.492.009-5.239-.002-.605-.056-1.244-.672-1.547a6.216 6.216 0 0 0-2.45-.176l-10.33-.17c-.76-.012-1.522-.02-2.282.025-.632.038-1.44 0-1.782.647a3.554 3.554 0 0 0-.132 1.628l-.003 2.685-.01 10.251c-.002 1.643-.045 3.288-.042 4.93a3.451 3.451 0 0 0 1.901 3.412 3.355 3.355 0 0 0 2.501.223.743.743 0 0 0 .262.054l10.205.015c1.314.002 2.944.037 3.984-.91a5.16 5.16 0 0 0 1.3-3.764 2.652 2.652 0 0 0-.364-1.847Zm-16.92 4.986a1.692 1.692 0 0 1-1.311-1.385 10.435 10.435 0 0 1-.02-2.114c.01-.874.002-1.75.003-2.624l.005-4.881c.003-3.247.09-6.508-.003-9.753l-.001-.018c.113-.01.219-.02.201-.02.378-.028.757-.04 1.136-.046.862-.013 1.724.012 2.586.026l4.927.081 2.833.047 1.355.022a7.3 7.3 0 0 0 1.478.024 1.012 1.012 0 0 1 .174-.024c.019.233-.03.53-.03.723l-.002 1.247-.005 2.993-.01 5.738c-.002.887-.018 1.777.007 2.664.016.59-.054 1.153-.039 1.726l-3.345-.008q-2.483-.004-4.965-.003a6.004 6.004 0 0 0-2.282.116c-.77.35-.573 1.294-.555 1.977a4.564 4.564 0 0 1-.835 3.344c-.39.409-.794.293-1.302.148Zm15.79-3.356a4.984 4.984 0 0 1-.472 2.428 2.227 2.227 0 0 1-1.83.935 27.288 27.288 0 0 1-2.732.026l-2.987-.004-4.787-.007a5.891 5.891 0 0 0 .641-2.346c.028-.389.023-.779.013-1.168-.004-.157-.01-.313-.016-.47l-.003-.073c.334-.004.775-.05.86-.05h1.49q2.606 0 5.213.005l2.73.007c.496.002.995-.016 1.49.005.2.008.291-.034.388.125.07.116.004.468.001.587Z' />
      <path d='M19.876 16.968q-3.837.143-7.678.07a.75.75 0 0 0 0 1.5q3.84.074 7.678-.07c.962-.035.967-1.535 0-1.5ZM20.011 13.985h-7.95a.75.75 0 0 0 0 1.5h7.95a.75.75 0 0 0 0-1.5ZM12 12.499q3.938.1 7.876-.04c.962-.034.967-1.534 0-1.5Q15.939 11.1 12 11a.75.75 0 0 0 0 1.5ZM15 9.736h4.785a.75.75 0 0 0 0-1.5H15a.75.75 0 0 0 0 1.5Z' />
    </g>
  </svg>
);

export default SvgConditions;
