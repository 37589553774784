import { FC, PropsWithChildren } from 'react';
import { IIconProps } from './Icon';
import { LoadingIconWrapper } from './LoadingIcon.styles';

export interface ILoadingIconProps
  extends PropsWithChildren<Omit<IIconProps, 'src'>> {}

const LoadingIcon: FC<ILoadingIconProps> = (
  props: ILoadingIconProps
): JSX.Element => {
  const {} = props;

  return <LoadingIconWrapper {...props} src='loading' />;
};

LoadingIcon.displayName = 'LoadingIcon';

export default LoadingIcon;
