import { SVGProps } from 'react';

const SvgPatientsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M19.784 10.785a17.84 17.84 0 0 1-2.919-.39c-.341.176-.688.343-1.048.49a14.616 14.616 0 0 1-4.013.991 5.145 5.145 0 0 1-1.746-.207c-.341-.086-.665-.148-.987-.238a7.833 7.833 0 0 0 2.35 4.507 7.198 7.198 0 0 0 1.345.975 8.248 8.248 0 0 1-.863.492c.685.73 1.373 1.438 2.033 2.201l1.321 1.529c.209.241.416.484.626.724a2.25 2.25 0 0 0 .28.32.311.311 0 0 0 .077-.077 20.807 20.807 0 0 1 4.896-4.366c-.255-.143-.512-.283-.757-.443a4.651 4.651 0 0 1-.712-.488c.115-.064.23-.126.343-.194a6.835 6.835 0 0 0 2.925-6.012.969.969 0 0 1-.15.038 20.445 20.445 0 0 1-3 .148ZM18.914 9.04c-.061.053-.127.097-.19.147.39.046.782.087 1.177.103a19.894 19.894 0 0 0 2.772-.143 7.045 7.045 0 0 0-.228-.74 7.669 7.669 0 0 0-1.296-2.12 11.685 11.685 0 0 1-2.235 2.754ZM9.139 9.877a7.06 7.06 0 0 0 1.509.414 3.442 3.442 0 0 0 1.453.034 15.299 15.299 0 0 0 3.324-.89 9.433 9.433 0 0 0 2.642-1.64 11.32 11.32 0 0 0 1.918-2.467.705.705 0 0 1 .07-.072 6.505 6.505 0 0 0-4.798-1.38c-2.848.344-5.615 2.293-6.166 5.23a6.704 6.704 0 0 0-.085.734.722.722 0 0 1 .133.037Z' />
      <path d='M25.905 24.846c0-.44.018-.877-.014-1.317a6.16 6.16 0 0 0-2.318-4.475 4.39 4.39 0 0 0-.95-.578.753.753 0 0 1-.15.151 25.674 25.674 0 0 0-3.297 2.538c-.504.472-.983.97-1.439 1.488-.406.463-.814 1.067-1.496 1.094-.728.028-1.198-.572-1.627-1.068l-1.46-1.69c-.446-.516-.866-1.095-1.353-1.572-.42-.41-.837-.815-1.24-1.251a.72.72 0 0 1-.062-.1 12.886 12.886 0 0 0-1.22.6 5.661 5.661 0 0 0-2.592 3.221 7.628 7.628 0 0 0-.28 4.55c.588 1.61 2.096 1.603 3.553 1.612q2.745.018 5.49.012 1.277-.002 2.554-.01c.771-.004 1.534.074 2.299.057.817-.017 1.635-.04 2.453-.056a3.876 3.876 0 0 0 2.257-.415 2.328 2.328 0 0 0 .962-1.692c.028-.369-.069-.729-.07-1.099Z' />
    </g>
  </svg>
);

export default SvgPatientsFilled;
