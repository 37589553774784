import {
  ILanguage,
  IReduxState
} from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { useSelector } from 'react-redux';
import IProps from 'interfaces/IProps';
import {
  Children,
  cloneElement,
  createContext,
  Dispatch,
  FC,
  FocusEventHandler,
  FormEvent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useReducer,
  useState
} from 'react';
import {
  getDefaultInputValue,
  IFormInput,
  IInitFormInput,
  initFormValues,
  InputValue,
  verifyFormInput
} from 'utils/form';
import styles from 'styles';

export type InputsReducerType = { [key: string]: IFormInput };

export interface IFormContext {
  inputs: InputsReducerType;
  isValid: boolean;
  dispatchInputs: Dispatch<Action>;
}

export type FormSubmit = {
  inputs: InputsReducerType;
  dispatchInputs: Dispatch<Action>;
};

export const FormContext = createContext<IFormContext>({
  inputs: {},
  isValid: false,
  dispatchInputs: () => {}
} as IFormContext);

export enum ActionTypes {
  UPDATE_VALUE,
  VERIFY,
  SET_SUCCESS,
  SET_ERROR,
  CLEAR
}

export type Action = {
  type: ActionTypes;
  attribute?: string;
  value?: InputValue;
  error?: string;
};

const inputsReducer = (
  state: InputsReducerType,
  action: Action,
  language: ILanguage
): InputsReducerType => {
  const { type, attribute, value } = action;

  if (attribute === undefined || state[attribute] === undefined) return state;

  const input = state[attribute];

  switch (type) {
    case ActionTypes.UPDATE_VALUE:
      if (value !== undefined) input.value = value;
      input.error = undefined;
      break;
    case ActionTypes.VERIFY:
      const { success, error } = verifyFormInput(input, language);
      input.success = success;
      input.error = error;
      break;
    case ActionTypes.SET_ERROR:
      input.error = value as string;
      break;
    case ActionTypes.CLEAR:
      Object.keys(state).forEach((attribute: string) => {
        state[attribute].value = getDefaultInputValue(undefined, input.type);
        state[attribute].error = undefined;
      });
      break;
  }

  return { ...state };
};

export interface IFormContextProviderProps
  extends Omit<PropsWithChildren<IProps>, 'children'> {
  initialValues: IInitFormInput[];
  onLoad?: (dispatchInputs: Dispatch<Action>) => void;
  onFocus?: FocusEventHandler<HTMLFormElement>;
  onBlur?: FocusEventHandler<HTMLFormElement>;
  onSubmit: (formSubmit: FormSubmit) => void;
  children:
    | React.ReactNode
    | ((inputs: InputsReducerType, isValid: boolean) => ReactNode);
}

const FormContextProvider: FC<IFormContextProviderProps> = (
  props: IFormContextProviderProps
): JSX.Element => {
  const {
    dataTestId,
    className,
    style,
    initialValues,
    onLoad,
    onFocus,
    onBlur,
    onSubmit,
    children
  } = props;
  const language = useSelector((state: IReduxState) => state.language.values);
  const [inputs, dispatchInputs] = useReducer(
    (state: InputsReducerType, action: Action) =>
      inputsReducer(state, action, language),
    initFormValues(...initialValues)
  );
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (onLoad) onLoad(dispatchInputs);
  }, []);

  useEffect(() => {
    let isValid: boolean = true;

    Object.entries(inputs).forEach(([, input]: [string, IFormInput]) => {
      const inputIsValid =
        !(
          input.isRequired === true &&
          (input.value === '' ||
            (Array.isArray(input.value) && input.value.length === 0))
        ) && input.error === undefined;

      isValid = isValid && inputIsValid;
    });

    setIsValid(isValid);
  }, [inputs]);

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit({ inputs, dispatchInputs });
  };

  const renderChildren = (
    children: ReactNode | ReactNode[]
  ): ReactNode | ReactNode[] => {
    return Array.isArray(children)
      ? children.map((child: any, index: number) => renderChild(child, index))
      : renderChild(children);
  };

  const renderChild = (child: any, index?: number): ReactNode => {
    if (!child) return null;

    if (typeof child !== 'object') return child;

    const isSubmitButton =
      child.type?.displayName === 'Button' &&
      child.props.buttonType === 'submit';
    const isSelectOrMultiOption = ['Select', 'MultiOption'].includes(
      child.type?.displayName
    );

    const elementChildren = child.props?.children;

    return cloneElement(
      child,
      child.props
        ? {
            ...child.props,
            key: index !== undefined ? index : undefined,
            ...(elementChildren && {
              children: renderChildren(elementChildren)
            }),
            ...((isSubmitButton || isSelectOrMultiOption) && {
              style: {
                marginTop: isSubmitButton
                  ? '3rem'
                  : isSelectOrMultiOption
                  ? styles.inputMargin
                  : child.props.style?.marginTop,
                ...child.props.style
              }
            }),
            ...(isSubmitButton && {
              disabled: isSubmitButton
                ? child.props.disabled !== undefined
                  ? child.props.disabled
                  : !isValid
                : undefined
            })
          }
        : undefined
    );
  };

  return (
    <FormContext.Provider value={{ inputs, isValid, dispatchInputs }}>
      <form
        data-testid={dataTestId}
        className={className}
        style={style}
        onFocus={onFocus}
        onBlur={onBlur}
        onSubmit={submitHandler}
      >
        {renderChildren(
          Children.toArray(
            typeof children === 'function'
              ? children(inputs, isValid).props.children
              : children
          )
        )}
      </form>
    </FormContext.Provider>
  );
};

export default FormContextProvider;
