import IProps from 'interfaces/IProps';
import { FC, PropsWithChildren } from 'react';
import { StepProgressNumber, StepProgressWrapper } from './StepProgress.styles';
import { Flex } from '../Templates';

export interface IStepProgressDetailedProps extends PropsWithChildren<IProps> {
  stepsNames: string[];
  step: number;
}

const StepProgressDetailed: FC<IStepProgressDetailedProps> = (
  props: IStepProgressDetailedProps
): JSX.Element => {
  const { dataTestId, className, style, stepsNames, step: currentStep } = props;

  return (
    <StepProgressWrapper
      data-testid={dataTestId}
      className={className}
      style={style}
      childrenMargin='1rem'
    >
      {stepsNames.map((stepName: string, index: number) => (
        <Flex key={index} data-testid={dataTestId && `${dataTestId}-${index}`}>
          <StepProgressNumber as='span' isSelected={index === currentStep}>
            {index + 1}
          </StepProgressNumber>
          <b>{stepName}</b>
        </Flex>
      ))}
    </StepProgressWrapper>
  );
};

export default StepProgressDetailed;
