import { SVGProps } from 'react';

const SvgPrivacyPolicy = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M26.118 10.534c-.041-.998-.017-2.13-.943-2.748a6.435 6.435 0 0 0-1.416-.588 32.75 32.75 0 0 1-1.846-.726 34.846 34.846 0 0 1-3.385-1.683C17.7 4.32 16.72 3.545 15.7 3.794a11.578 11.578 0 0 0-1.343.636q-.943.37-1.876.767-1.732.733-3.426 1.552-.848.411-1.685.845a2.727 2.727 0 0 0-1.286 1.035 3.144 3.144 0 0 0-.24 1.474 96.152 96.152 0 0 0-.004 1.765 34.218 34.218 0 0 0 .193 3.634 15.532 15.532 0 0 0 2.078 6.492 16.58 16.58 0 0 0 4.399 4.602 6.909 6.909 0 0 0 3.188 1.649c1.242.073 2.352-.92 3.294-1.603a18.263 18.263 0 0 0 5.308-5.44 15.997 15.997 0 0 0 1.812-6.864 37.621 37.621 0 0 0 .006-3.804Zm-1.477 3.211a14.818 14.818 0 0 1-1.637 6.7 15.219 15.219 0 0 1-3.917 4.264 25.223 25.223 0 0 1-2.404 1.71 1.328 1.328 0 0 1-1.246.237 7.926 7.926 0 0 1-1.27-.738 13.909 13.909 0 0 1-6.388-8.89 29.808 29.808 0 0 1-.44-6.154 6.805 6.805 0 0 1 .02-1.351c.098-.45.817-.66 1.187-.849q1.47-.749 2.974-1.43 1.49-.675 3.012-1.279a8.568 8.568 0 0 0 1.3-.567c.37-.224.652-.06 1.014.145a43.953 43.953 0 0 0 5.656 2.78c.531.207 1.109.354 1.625.594.449.208.436.575.462 1.022.073 1.265.1 2.54.052 3.806Z' />
      <path d='M21.822 11.528a43.82 43.82 0 0 0-6.643 6.463l-2.44-2.966a.756.756 0 0 0-1.06 0 .766.766 0 0 0 0 1.06l2.96 3.6a.75.75 0 0 0 1.061 0 43.833 43.833 0 0 1 7.183-7.096.755.755 0 0 0 0-1.061.768.768 0 0 0-1.06 0Z' />
    </g>
  </svg>
);

export default SvgPrivacyPolicy;
