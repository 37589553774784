import { SVGProps } from 'react';

const SvgHigh = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M26.089 18.338a10.408 10.408 0 0 0-1.15-1.116c.189.164-.125-.135-.163-.171-.136-.13-.269-.262-.402-.395-.708-.706-1.368-1.453-2.045-2.189a68.616 68.616 0 0 0-5.58-5.497.732.732 0 0 0-.103-.058.684.684 0 0 0-.148-.084c-.016-.006-.032-.016-.05-.02a.71.71 0 0 0-.43-.03 2.925 2.925 0 0 0-.99.745c-.46.406-.915.817-1.37 1.228-1.314 1.184-2.664 2.324-3.925 3.566-.83.818-1.63 1.666-2.429 2.515q-.56.594-1.121 1.185a1.793 1.793 0 0 0-.69 1.227 1.557 1.557 0 0 0 .754 1.11c.447.364.888.733 1.335 1.095.742.601 1.63 1.613 2.612.914a10.332 10.332 0 0 0 1.127-1.077l1.365-1.334 2.457-2.4a11.7 11.7 0 0 1 1.043-1.012.693.693 0 0 1 .093-.048 2.337 2.337 0 0 0 .467.392 21.213 21.213 0 0 1 1.88 1.994l2.447 2.614c.574.614 1.12 1.457 2.088 1.204a2.572 2.572 0 0 0 1.097-.761c.418-.41.864-.79 1.286-1.194.75-.718 1.334-1.464.545-2.402Zm-1.691 1.423c-.396.375-.81.731-1.2 1.113-.106.103-.269.217-.363.33a.663.663 0 0 1-.059-.063 8.705 8.705 0 0 0-.969-1.058l-2.121-2.266c-.547-.584-1.072-1.188-1.626-1.762-.262-.272-.562-.5-.83-.767a1.093 1.093 0 0 0-1.044-.407c-.829.172-1.448.97-2.022 1.53l-2.197 2.147c-.74.722-1.482 1.442-2.218 2.167-.031.031-.264.248-.28.349a5.901 5.901 0 0 1-.467-.397c-.42-.334-.834-.678-1.249-1.019l-.533-.437-.024-.022c.298-.28.816-.931.93-1.052.692-.735 1.384-1.473 2.093-2.193 1.344-1.365 2.793-2.607 4.216-3.887.432-.388.862-.777 1.294-1.163l.464-.41q1.976 1.756 3.8 3.671c1.188 1.245 2.305 2.557 3.533 3.762.428.42.876.807 1.29 1.243.03.032.059.065.087.099h-.002c-.146.039-.388.383-.503.492Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgHigh;
