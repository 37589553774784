import { useMediaQuery } from 'react-responsive';
import styles from 'styles';

export interface IUseMediaScreen {
  isDesktopScreen: boolean;
  isSmallDesktopScreen: boolean;
  isMobileScreen: boolean;
  isTabletScreen: boolean;
  isSmartphoneScreen: boolean;
}

const useMediaScreen = (): IUseMediaScreen => {
  const isDesktopScreen = useMediaQuery({ minWidth: `${styles.desktop}px` });
  const isSmallDesktopScreen = useMediaQuery({
    minWidth: `${styles.tablet}px`,
    maxWidth: `${styles.smallDesktop}px`
  });
  const isMobileScreen = useMediaQuery({ maxWidth: `${styles.tablet}px` });
  const isTabletScreen = useMediaQuery({
    minWidth: `${styles.smartphone}px`,
    maxWidth: `${styles.tablet}px`
  });
  const isSmartphoneScreen = useMediaQuery({
    maxWidth: `${styles.smartphone}px`
  });

  return {
    isDesktopScreen,
    isSmallDesktopScreen,
    isMobileScreen,
    isTabletScreen,
    isSmartphoneScreen
  };
};

export default useMediaScreen;
