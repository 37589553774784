import Icon from 'components/Icon/Icon';
import { Flex } from 'components/Templates';
import styled, { css } from 'styled-components';
import styles from 'styles';
import { rgba } from 'utils/colors';
import { transition } from 'utils/styles';

export interface ISelectWrapperProps {
  disabled?: boolean;
  isOpen: boolean;
  isSmall?: boolean;
}

export const SelectWrapper = styled.div`
  flex: none !important;
  position: relative;
  display: inline-block;
  background-color: #fff;

  ${({ disabled }: ISelectWrapperProps) =>
    disabled
      ? css`
          opacity: 0.7;
        `
      : css`
          cursor: pointer;
        `}

  ${({ isOpen }: ISelectWrapperProps) =>
    isOpen
      ? css`
          z-index: 99;
        `
      : css``}

  ${({ isSmall }: ISelectWrapperProps) =>
    isSmall
      ? css`
          > div {
            &:first-of-type {
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;

              * {
                font-size: ${styles.textSize.small};
                font-weight: 600;
              }
            }
          }
        `
      : css``}

  > select {
    position: absolute;
    width: 100%;
    opacity: 0;
  }
`;

export interface ISelectLabelProps {
  active: boolean;
}

export const SelectLabel = styled.div<ISelectLabelProps>`
  position: relative;
  overflow: hidden;
  height: 2.6rem;
  border-radius: 999px;
  background-color: white;
  z-index: 10;

  > div {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    margin-bottom: 0;
    background-color: ${rgba(styles.colors.brand.main, 0.1)};

    > span {
      overflow-x: hidden;
      color: ${({ active }: ISelectLabelProps) =>
        active
          ? styles.colors.brand.dark
          : rgba(styles.colors.brand.dark, 0.15)};
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export interface ISelectIconProps {
  isOpen: boolean;
  small?: boolean;
}

export const SelectIcon = styled(Icon)<ISelectIconProps>`
  margin-left: ${({ small }: ISelectIconProps) =>
    small ? '0.25rem' : '0.75rem'};
  ${transition('all')}

  ${({ isOpen }: ISelectIconProps) =>
    isOpen
      ? css`
          transform: rotate(180deg);
        `
      : css``}
`;

export interface ISelectOptionsProps {}

export const SelectOptions = styled(Flex)<ISelectOptionsProps>`
  position: absolute;
  top: 50%;
  left: 0;
  overflow-y: hidden;
  width: 100%;
  max-height: 0;
  border: 1px solid ${rgba(styles.colors.brand.main, 0.15)};
  border-top: 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-top: 1.3rem;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
  background-color: ${styles.colors.brand.lightest};
  z-index: 9;
  transition: max-height ${styles.transition}ms ease-in-out;

  &.options-enter,
  &.options-exit,
  &.options-exit-done {
    max-height: 0;
  }

  &.options-enter-done {
    max-height: 15rem;
  }

  > ul {
    overflow-y: auto;
    overflow-y: overlay;
    width: 100%;

    > li {
      padding: 0.5rem 1rem;
      border-bottom: 1px solid ${rgba(styles.colors.brand.main, 0.15)};
      cursor: pointer;

      &:last-of-type {
        border: 0;
      }

      &:hover {
        background-color: ${rgba(styles.colors.brand.main, 0.15)} !important;
      }

      &.selected {
        background-color: ${rgba(styles.colors.brand.main, 0.05)};
      }
    }
  }
`;
