import { SVGProps } from 'react';

const SvgSos = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 397.436 397.436'
    xmlSpace='preserve'
    {...props}
  >
    <path d='M68.992 183.701c-21.662-6.594-31.867-10.978-31.867-22.65 0-12.712 19.478-13.7 25.45-13.7 10.62 0 22.418 3.082 32.364 8.457a11.644 11.644 0 0 0 9.247.79 11.644 11.644 0 0 0 6.881-6.222l2.324-5.128a11.633 11.633 0 0 0-4.904-14.951c-13.12-7.358-29.472-11.579-44.862-11.579-33.854 0-56.601 17.603-56.601 43.801 0 32.705 27.853 40.983 50.239 47.634 22.782 6.769 32.296 11.47 32.232 23.117 0 15.602-21.888 16.815-28.596 16.815-13.541 0-28.056-5.367-39.822-14.723a11.636 11.636 0 0 0-17.647 3.902l-2.199 4.398a11.634 11.634 0 0 0 2.924 14.115c15.465 12.98 36.597 20.729 56.533 20.729 35.234 0 58.909-18.53 58.909-46.063.131-33.296-28-41.861-50.605-48.742z' />
    <path d='M200.865 118.929c-46.449 0-82.833 35.001-82.833 79.686 0 44.917 36.384 80.104 82.833 80.104 46.33 0 82.623-35.187 82.623-80.104.001-44.685-36.293-79.686-82.623-79.686zm0 131.995c-29.886 0-54.2-23.466-54.2-52.31 0-28.73 24.313-52.102 54.2-52.102 29.654 0 53.781 23.373 53.781 52.102.001 28.844-24.127 52.31-53.781 52.31z' />
    <path d='M346.827 183.701c-21.66-6.596-31.865-10.98-31.865-22.65 0-12.712 19.481-13.7 25.452-13.7 10.622 0 22.419 3.082 32.365 8.457a11.632 11.632 0 0 0 16.127-5.432l2.325-5.127a11.636 11.636 0 0 0-4.904-14.952c-13.12-7.358-29.474-11.579-44.861-11.579-33.856 0-56.601 17.603-56.601 43.801 0 32.706 27.854 40.983 50.238 47.634 22.782 6.77 32.294 11.47 32.233 23.116 0 15.603-21.891 16.816-28.6 16.816-13.54 0-28.056-5.367-39.822-14.723a11.634 11.634 0 0 0-17.647 3.902l-2.199 4.398a11.634 11.634 0 0 0 2.926 14.116c15.466 12.979 36.6 20.729 56.532 20.729 35.234 0 58.91-18.53 58.91-46.063.129-33.297-28.002-41.862-50.609-48.743z' />
  </svg>
);

export default SvgSos;
