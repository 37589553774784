import Icon from 'components/Icon/Icon';
import { Flex } from 'components/Templates';
import styled, { css } from 'styled-components';
import styles from 'styles';
import { rgba } from 'utils/colors';
import { transition } from 'utils/styles';

export interface IUserImageWrapperProps {
  size?: string;
  isSquare?: boolean;
  isClickable?: boolean;
}

export const UserImageWrapper = styled(Flex)<IUserImageWrapperProps>`
  overflow: hidden;
  border-radius: ${({ isSquare }: IUserImageWrapperProps) =>
    isSquare ? '1.5rem' : '999px'};
  background-color: white;
  ${transition('width', 'height', 'border-radius')}

  ${({ size }: IUserImageWrapperProps) => css`
    width: ${size || '3rem'};
    height: ${size || '3rem'};
  `}

  ${({ isClickable }: IUserImageWrapperProps) =>
    isClickable
      ? css`
          cursor: pointer;
        `
      : css``}
  
  > img {
    height: 100%;
  }

  > div {
    width: 100%;
    height: 100%;
    background-color: ${rgba(styles.colors.feedback.offline, 0.1)};
  }
`;

export interface IUserImageIconProps {
  size?: string;
  isSquare?: boolean;
  isClickable?: boolean;
}

export const UserImageIcon = styled(Icon)<IUserImageIconProps>`
  width: 100%;
  height: 100%;
`;
