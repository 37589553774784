import styled from 'styled-components';
import styles from 'styles';
import { rgba } from 'utils/colors';
import { InputContainer } from '../Input.styles';

export interface ITextAreaInputContainerProps {}

export const TextAreaInputContainer = styled(
  InputContainer
)<ITextAreaInputContainerProps>`
  padding: 0.75rem 1rem;
  border-radius: 1.25rem;

  > textarea {
    width: 100%;
    background-color: transparent;
    resize: none;

    :focus {
      border-color: $main;
      outline: none !important;
    }

    ::placeholder {
      color: ${rgba(styles.colors.brand.dark, 0.4)};
    }
  }
`;
