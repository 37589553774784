import countries, { Country } from 'country-list-js';
import IOption from '../interfaces/IOption';

export const getCountriesOptions = (lowerCase: boolean = false): IOption[] => {
  return Object.values(countries.all)
    .map((country: Country) => ({
      id: (country as any).iso2,
      name: lowerCase ? country.name.toLowerCase() : country.name
    }))
    .sort((country1: IOption, country2: IOption) =>
      country1.name.localeCompare(country2.name)
    );
};

export * from './colors';
export * from './form';
export * from './grid';
export * from './styles';
