import {
  ILanguage,
  IReduxState
} from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { useState, ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InputValue, InputTypes } from 'utils/form';
import useInput, { IUseInput } from '../Input.logic';
import { IPhotoInputProps } from './PhotoInput';

type FileResult = string | null | undefined;

export interface IUsePhotoInput
  extends Omit<
    IUseInput,
    | 'input'
    | 'isFocused'
    | 'showPopup'
    | 'containerRef'
    | 'changeHandler'
    | 'inputChangeHandler'
    | 'focusHandler'
    | 'blurHandler'
  > {
  language: ILanguage;
  value: InputValue;
  imageSrc: FileResult;
  fileUploadChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
}

const usePhotoInput = (props: IPhotoInputProps): IUsePhotoInput => {
  const language = useSelector((state: IReduxState) => state.language.values);
  const { input, value, changeHandler } = useInput(props);
  const [imageSrc, setImageSrc] = useState<FileResult>();

  if (input?.type !== InputTypes.PHOTO)
    throw new Error('Photo Input requires the type to be PHOTO.');

  useEffect(() => {
    if (!value) return;
    console.log(value);

    const reader = new FileReader();
    reader.readAsDataURL(value as File);
    reader.onload = (event: ProgressEvent<FileReader>) => {
      setImageSrc(event.target?.result as string);
    };
  }, [value]);

  const fileUploadChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    if (event.target.files && event.target.files?.length > 0) {
      const imageFile = event.target.files![0];

      changeHandler(imageFile);

      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = (event: ProgressEvent<FileReader>) => {
        setImageSrc(event.target?.result as string);
      };
    }
  };

  return { language, value, imageSrc, fileUploadChangeHandler };
};

export default usePhotoInput;
