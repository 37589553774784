import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps
} from 'react-datepicker';
import './DatePicker.scss';
import DatePickerHeader from './DatePickerHeader';
import { FC, PropsWithChildren } from 'react';

export interface IDatePickerProps extends PropsWithChildren<{}> {
  value: Date;
  min?: Date;
  max?: Date;
  onChange: (date: Date) => void;
}

const DatePicker: FC<IDatePickerProps> = (props: IDatePickerProps) => {
  const { value, min, max, onChange } = props;

  return (
    <ReactDatePicker
      selected={value}
      minDate={min}
      maxDate={max}
      renderCustomHeader={(props: ReactDatePickerCustomHeaderProps) => (
        <DatePickerHeader {...props} />
      )}
      renderDayContents={(dayOfMonth: number) => (
        <span
          data-testid={`datePicker-day${dayOfMonth.toLocaleString('en-US', {
            minimumIntegerDigits: 2
          })}`}
        >
          {dayOfMonth}
        </span>
      )}
      formatWeekDay={(formattedDate: string) => formattedDate.charAt(0)}
      onChange={onChange}
      inline={true}
    />
  );
};

export default DatePicker;
