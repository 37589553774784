import { Flex } from 'components/Templates';
import styled, { css } from 'styled-components';
import styles from 'styles';
import { transition, rgba } from 'utils';

export interface IStepProgressWrapperProps {
  childrenMargin: string;
}

export const StepProgressWrapper = styled(Flex)<IStepProgressWrapperProps>`
  > * {
    &:not(:last-child) {
      margin-right: ${({ childrenMargin }: IStepProgressWrapperProps) =>
        childrenMargin};
    }
  }
`;

export interface IStepProgressCircleProps {
  isSelected: boolean;
  hasNext: boolean;
}

export const StepProgressCircle = styled.svg<IStepProgressCircleProps>`
  width: 0.5rem;
  ${transition('all')}

  ${({ isSelected }: IStepProgressCircleProps) =>
    isSelected
      ? css`
          transform: scale(1.5);
        `
      : css``}

  > circle {
    fill: ${rgba(styles.colors.brand.main, 0.6)};
    ${transition('fill')}

    ${({ hasNext }: IStepProgressCircleProps) =>
      hasNext
        ? css`
            fill: ${rgba(styles.colors.brand.main, 0.2)};
          `
        : css``}
  }
`;

export interface IStepProgressNumberProps {
  isSelected: boolean;
}

export const StepProgressNumber = styled(Flex)<IStepProgressNumberProps>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 999px;
  margin-right: 0.625rem;

  ${transition('color', 'background-color')}

  ${({ isSelected }: IStepProgressNumberProps) =>
    isSelected
      ? css`
          color: ${styles.colors.brand.lightest};
          background-color: ${styles.colors.brand.main};
        `
      : css`
          color: ${styles.colors.brand.dark};
          background-color: ${rgba(styles.colors.brand.dark, 0.1)};
        `}
`;
