import IProps from 'interfaces/IProps';
import { FC, PropsWithChildren } from 'react';
import useInput from '../Input.logic';
import {
  InputContainer,
  InputHelpMessage,
  InputIcon,
  InputPrefix,
  InputStateMessage,
  InputSuffix,
  InputWrapper
} from '../Input.styles';
import { IInputProps } from '../Input.types';
import { InputTypes } from 'utils/form';

export interface ITextInputProps
  extends PropsWithChildren<IInputProps & IProps> {}

const TextInput: FC<ITextInputProps> = (
  props: ITextInputProps
): JSX.Element => {
  const {
    dataTestId,
    className,
    style,
    icon,
    disabled,
    prefix,
    placeholder,
    suffix,
    help
  } = props;
  const {
    input,
    value,
    isFocused,
    inputChangeHandler,
    focusHandler,
    blurHandler
  } = useInput(props);
  const { success, error } = input;

  if (input?.type !== InputTypes.TEXT)
    throw new Error('Text Input requires the type to be TEXT.');

  return (
    <InputWrapper className={className} style={style}>
      {icon && <InputIcon src={icon} />}
      <div>
        {success && (
          <InputStateMessage
            data-testid={`${dataTestId}_success`}
            type='success'
          >
            {success}
          </InputStateMessage>
        )}
        {error && (
          <InputStateMessage data-testid={`${dataTestId}_error`} type='alert'>
            {error}
          </InputStateMessage>
        )}
        <InputContainer
          isFocused={isFocused}
          state={success ? 'success' : error ? 'alert' : undefined}
        >
          {prefix && <InputPrefix>{prefix}</InputPrefix>}
          <input
            data-testid={dataTestId}
            type='text'
            placeholder={placeholder}
            value={value as string}
            disabled={disabled}
            onChange={inputChangeHandler}
            onFocus={focusHandler}
            onBlur={blurHandler}
          />
          {suffix && <InputSuffix>{suffix}</InputSuffix>}
        </InputContainer>
        {help && (
          <InputHelpMessage data-testid={`${dataTestId}_help`}>
            {help}
          </InputHelpMessage>
        )}
      </div>
    </InputWrapper>
  );
};

TextInput.displayName = 'TextInput';

export default TextInput;
