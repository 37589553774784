import { Flex } from 'components/Templates';
import styled from 'styled-components';
import styles from 'styles';
import { getGutter, getMargin } from 'utils/grid';

export interface IWhitePageWrapperProps {}

export const WhitePageWrapper = styled(Flex)<IWhitePageWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  padding: ${getGutter()} ${getMargin()};
  box-sizing: border-box;
  background-color: ${styles.colors.brand.lightest};
  box-sizing: border-box;
`;

export interface IWhitePageSingleContainerProps {}

export const WhitePageSingleContainer = styled(
  Flex
)<IWhitePageSingleContainerProps>`
  height: 100%;
  margin: 0 auto;
`;
