import { SVGProps } from 'react';

const SvgVideoCallOff = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M8.388 15.54a.761.761 0 0 0 .75-.75l-.066-1.866a.784.784 0 0 0-.22-.53.75.75 0 0 0-1.28.53l.066 1.866a.785.785 0 0 0 .22.53.762.762 0 0 0 .53.22ZM25.848 9.611a7.44 7.44 0 0 0-2.334 1.863c-.421.4-.81.832-1.213 1.25.026-.456.04-.913.072-1.369a3.262 3.262 0 0 0-.4-2.484 1.437 1.437 0 0 0-1.006-.468c-.61-.041-1.221-.069-1.831-.112-.992-.051-1.957.085-2.947.07q-1.501-.024-3.003-.045.776.746 1.524 1.523l1.48.022c.99.015 1.955-.121 2.946-.07.44.031.879.082 1.32.1.402.017.368-.108.457.168a1.691 1.691 0 0 1 .013.563c-.025.57-.082 1.141-.117 1.711a115.78 115.78 0 0 0-.166 4.077l5.416 5.966a1.557 1.557 0 0 0 1.09-.666 4.254 4.254 0 0 0 .336-2.592 44.58 44.58 0 0 1 .326-5.879c.144-1.257.174-4.343-1.963-3.628Zm.487 3.416c-.095.901-.206 1.798-.267 2.703q-.091 1.364-.09 2.732c0 .266.197 2.44-.125 2.455.07-.004-.31-.415-.276-.377q-.22-.241-.437-.484l-1.019-1.128-1.891-2.095a.676.676 0 0 0-.088-.065c.013-.607.02-1.215.043-1.822a.71.71 0 0 0 .177-.13q.891-.992 1.843-1.925a14.662 14.662 0 0 1 1.928-1.75.783.783 0 0 1 .15-.083 1.892 1.892 0 0 1 .092.488 6.452 6.452 0 0 1-.04 1.481Z' />
      <path d='M13.937 22.229c-1.887-.024-3.774-.06-5.662-.094a6.724 6.724 0 0 1-2.251-.119c-.397-.163-.338-.495-.352-.883-.02-.53-.032-1.062-.043-1.593q-.059-2.838.012-5.677c.02-.832.027-1.668.078-2.498.013-.22.048-.443.058-.663a2.49 2.49 0 0 1-.009-.525.465.465 0 0 1 .185-.236q-.581-.476-1.154-.961a1.716 1.716 0 0 0-.514 1.024c-.112.954-.089 1.956-.117 2.92a131.581 131.581 0 0 0-.034 6.86c.021.93-.163 2.228.455 3.006a2.704 2.704 0 0 0 2.137.817c2.277.086 4.562.086 6.84.117 1.868.025 3.78.114 5.659.04q-.69-.761-1.383-1.522-1.952.01-3.905-.013ZM18.435 17.321c-2.799-3.082-5.474-6.388-8.67-9.073A48.4 48.4 0 0 1 4.91 3.94C4.258 3.224 3.2 4.287 3.849 5A39.746 39.746 0 0 0 8.12 8.824a41.785 41.785 0 0 1 4.284 4.085c2.953 3.227 5.881 6.477 8.822 9.716l5.024 5.534c.65.716 1.708-.347 1.06-1.06Z' />
    </g>
  </svg>
);

export default SvgVideoCallOff;
