import { SVGProps } from 'react';

const SvgTemperature = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M15.527 22.72a.28.28 0 0 1-.5-.182c.01-.221.16-.254.322-.345a1.386 1.386 0 0 0 .573-.515 3.965 3.965 0 0 0 .235-1.701q.058-1.104.086-2.209a81.804 81.804 0 0 0-.24-8.587c-.077-.956-1.578-.964-1.5 0a81.642 81.642 0 0 1 .14 11.04 2.183 2.183 0 0 1-.036.614c-.06.147.005.052-.23.171a1.62 1.62 0 0 0-.612.635 1.883 1.883 0 0 0-.05 1.745 1.834 1.834 0 0 0 3.108.091c.578-.773-.724-1.522-1.296-.757Z' />
      <path d='m21.201 15.393-2.238.051c.037-.863.055-1.728.07-2.592a11.284 11.284 0 0 1 2.288.07.745.745 0 0 0 .53-.22.75.75 0 0 0 0-1.061l-.151-.117a.748.748 0 0 0-.379-.103 11.305 11.305 0 0 0-2.261-.07c.015-.879.02-1.757-.003-2.636h2.03a.75.75 0 0 0 0-1.5h-2.095a3.925 3.925 0 0 0-.605-1.901 3.592 3.592 0 0 0-4.725-1.08c-2.257 1.281-2.049 4.014-1.997 6.253.066 2.772.026 5.54-.003 8.311a5.495 5.495 0 0 0 1.06 8.737 5.426 5.426 0 0 0 5.17.09 5.696 5.696 0 0 0 2.916-4.386 5.314 5.314 0 0 0-1.7-4.665 4.548 4.548 0 0 0-.116-.083.501.501 0 0 1-.034-.136c-.008-.266-.041-.53-.048-.797-.005-.204 0-.408.004-.612l2.287-.052a.75.75 0 0 0 0-1.5Zm-2.488 9.457a3.906 3.906 0 0 1-3.643 1.884 4.043 4.043 0 0 1-3.512-2.853 4.179 4.179 0 0 1 1.473-4.304.59.59 0 0 0 .132-.842c.022-1.968.069-3.94.053-5.908-.008-1.026-.05-2.051-.065-3.077a8.79 8.79 0 0 1 .204-2.936 2.196 2.196 0 0 1 3.24-1.214 2.52 2.52 0 0 1 .933 2.2 47.06 47.06 0 0 1 .043 3.022c-.024 1.976-.11 3.95-.158 5.925a4.765 4.765 0 0 0 .307 2.607c.184.257.486.38.707.601a2.599 2.599 0 0 1 .54.85 4.475 4.475 0 0 1-.254 4.045Z' />
    </g>
  </svg>
);

export default SvgTemperature;
