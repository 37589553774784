import { SVGProps } from 'react';

const SvgUserData = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M19.522 15.108a3.987 3.987 0 0 0 .055-4.426c-.02-.026-.046-.044-.068-.068-.018-.027-.029-.058-.05-.084a4.694 4.694 0 0 0-4.03-1.702 4.284 4.284 0 0 0-3.68 2.804 4.437 4.437 0 0 0 .595 3.973 7.54 7.54 0 0 0-1.703 4.16 5.946 5.946 0 0 0 .169 2.177 1.698 1.698 0 0 0 1.833 1.196c1.874.016 3.747.014 5.62.014.832 0 1.926.197 2.594-.416a3.286 3.286 0 0 0 .813-2.193c.128-1.98-.969-3.733-2.023-5.31a.738.738 0 0 0-.125-.125Zm-6.417-2.787a2.68 2.68 0 0 1 2.23-1.98l.052-.01.043-.003c.074-.007.148-.012.222-.015a4.384 4.384 0 0 1 .522.013l.047.003.013.002c.087.014.173.03.258.05a4.031 4.031 0 0 1 .506.149 3.528 3.528 0 0 1 .762.422 2.508 2.508 0 0 1 .267.229 3.91 3.91 0 0 1 .29.32 2.918 2.918 0 0 1-4.082 3.888 2.608 2.608 0 0 1-1.032-1.317 2.943 2.943 0 0 1-.098-1.75Zm6.746 9.307c-.097.06-.302.022-.41.023h-.724l-2.72-.002q-1.36-.002-2.719-.009c-.225 0-.862.102-1.01-.052-.152-.158-.133-.746-.143-.95a5.887 5.887 0 0 1 1.309-4.01 4.384 4.384 0 0 0 4.777-.046 4.33 4.33 0 0 0 .34-.294c.848 1.27 1.737 2.675 1.619 4.254-.019.25-.072.934-.319 1.086Z' />
      <path d='M24.229 6.457a11.904 11.904 0 0 0-6.45-2.809 13.406 13.406 0 0 0-4.24.187c-.558.114-1.134.217-1.686.35a6.678 6.678 0 0 0-1.726.823 13.74 13.74 0 0 0-5.313 5.41 11.429 11.429 0 0 0-1.176 3.663 16.198 16.198 0 0 0 .133 4.13 7.237 7.237 0 0 0 .345 1.878 19.823 19.823 0 0 0 .867 1.901 12.392 12.392 0 0 0 2.15 2.978 13.103 13.103 0 0 0 3.067 2.143 11.893 11.893 0 0 0 3.885 1.338 13.41 13.41 0 0 0 8.33-1.538 10.033 10.033 0 0 0 3.268-2.511 10.593 10.593 0 0 0 1.96-3.747c1.241-4.79.499-10.792-3.414-14.195ZM22.224 25.3a12.417 12.417 0 0 1-6.376 1.804 11.946 11.946 0 0 1-6.527-2.248 10.895 10.895 0 0 1-3.84-5.375 7.662 7.662 0 0 1-.26-1.756c-.058-.605-.156-1.209-.182-1.816a9.309 9.309 0 0 1 .491-3.364A11.263 11.263 0 0 1 9.552 7.18a7.349 7.349 0 0 1 2.967-1.59 29.059 29.059 0 0 1 3.472-.53 10.772 10.772 0 0 1 6.182 1.706c4.336 2.845 5.246 8.77 4.024 13.488a8.418 8.418 0 0 1-3.973 5.044Z' />
    </g>
  </svg>
);

export default SvgUserData;
