export const dateIsValid = (dateString?: string | null): boolean => {
  if (!dateString) return false;

  const timestamp = Date.parse(dateString);
  return !isNaN(timestamp);
};

export const getAge = (birthDate: Date): number => {
  const monthDifference = Date.now() - birthDate.getTime();
  const ageDateTime = new Date(monthDifference);
  const year = ageDateTime.getUTCFullYear();

  return Math.abs(year - 1970);
};

export const getDayRange = (date: Date): { startDate: Date; endDate: Date } => {
  const startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 1);

  return {
    startDate,
    endDate,
  };
};

export const timeToSeconds = (time: Date): number => {
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();

  return hours * 60 * 60 + minutes * 60 + seconds;
};

export const timeToMinutes = (time: Date): number => {
  const hours = time.getHours();
  const minutes = time.getMinutes();

  return hours * 60 + minutes;
};

export const milisecondsToMinutes = (miliseconds: number): number => {
  return Math.round(miliseconds / 1000 / 60);
};

export const datesAreEqual = (date1: Date | undefined, date2: Date | undefined): boolean => {
  return (
    date1?.getDate() === date2?.getDate() &&
    date1?.getMonth() === date2?.getMonth() &&
    date1?.getFullYear() === date2?.getFullYear()
  );
};

export const monthsAreEqual = (date1: Date | undefined, date2: Date | undefined): boolean => {
  return date1?.getMonth() === date2?.getMonth();
};

export const yearsAreEqual = (date1: Date | undefined, date2: Date | undefined): boolean => {
  return date1?.getFullYear() === date2?.getFullYear();
};

export const getOldestDate = (dates: Date[]): Date => {
  return dates.reduce((date1, date2) => (date1 < date2 ? date1 : date2));
};

export const getNewestDate = (dates: Date[]): Date => {
  return dates.reduce((date1, date2) => (date1 > date2 ? date1 : date2));
};

export const isToday = (date: Date): boolean => {
  return datesAreEqual(date, new Date());
};

export const dateIsPast = (date: Date): boolean => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date.getTime() < today.getTime();
};

export const timeIsPast = (date: Date): boolean => {
  return date.getTime() < new Date().getTime();
};

export const isPast = (d: Date, includeHours: boolean = false): boolean => {
  const date = new Date(d);
  const today = new Date();

  if (!includeHours) {
    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
  }

  return date.getTime() < today.getTime();
};

export const differenceInMinutes = (date1: Date, date2: Date): number => {
  return milisecondsToMinutes(Math.abs(date2.getTime() - date1.getTime()));
};

export const differenceInMinutesFromNow = (date: Date): number => {
  return differenceInMinutes(date, new Date());
};

export const millisecondsToTime = (milliseconds: number): string => {
  if (milliseconds <= 0) return '00:00';

  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const ss = (seconds % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  const MM = (minutes % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  const hh = hours.toLocaleString('en-US', { minimumIntegerDigits: 2 });

  return `${hh === '00' ? '' : `${hh}:`}${MM}:${ss}`;
};

export const secondsToTime = (seconds: number): string => {
  if (seconds <= 0) return '00:00';

  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const ss = (seconds % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  const MM = (minutes % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  const hh = hours.toLocaleString('en-US', { minimumIntegerDigits: 2 });

  return `${hh === '00' ? '' : `${hh}:`}${MM}:${ss}`;
};

export const getMinutesUntilNow = (date: Date): number => {
  const now = new Date().getTime();
  const time = date.getTime();

  return Math.floor((now - time) / 1000 / 60);
};

export const getHoursUntilNow = (date: Date): number => {
  const now = new Date().getTime();
  const time = date.getTime();

  return Math.floor((now - time) / 1000 / 60 / 60);
};

export const timeIsInsidePeriod = (time: Date, min: number, max: number): boolean => {
  const hours = time.getHours();
  return min <= hours && hours < max;
};
