import IOption from 'interfaces/IOption';
import Option from './Option';
import { FC, PropsWithChildren } from 'react';
import IProps from 'interfaces/IProps';
import useOptionList, { IUseOptionListProps } from 'hooks/use-optionList';
import { MultiOptionContainer } from './MultiOption.styles';
import { InputIcon, InputWrapper } from 'components/Input/Input.styles';
import { icons } from 'icons';

export interface IMultiOptionProps
  extends PropsWithChildren<IUseOptionListProps & IProps> {
  optionClassName?: string;
  icon?: keyof typeof icons;
  big?: boolean;
  join?: boolean;
}

const MultiOption: FC<IMultiOptionProps> = (
  props: IMultiOptionProps
): JSX.Element => {
  const {
    dataTestId,
    className,
    style,
    optionClassName,
    name,
    icon,
    options,
    multi,
    big,
    join
  } = props;
  const { value, optionsChangeHandler } = useOptionList(props);

  return (
    <InputWrapper
      data-testid={dataTestId}
      className={className}
      style={style}
      alignItems='center'
    >
      {icon && <InputIcon style={{ marginTop: '0' }} src={icon} />}
      <MultiOptionContainer justifyContent='flex-start' join={join}>
        {options.map((option: IOption, index: number) => (
          <Option
            dataTestId={`${dataTestId}-${index}`}
            className={optionClassName}
            key={option.id}
            type={multi ? 'checkbox' : 'radio'}
            identifier={name}
            option={option}
            checked={value.includes(option.id)}
            isBig={big}
            isJoined={join}
            onCheck={() => optionsChangeHandler(true, option)}
            onUncheck={() => optionsChangeHandler(false, option)}
          />
        ))}
      </MultiOptionContainer>
    </InputWrapper>
  );
};

MultiOption.displayName = 'MultiOption';

export default MultiOption;
