import { SVGProps } from 'react';

const SvgHealthFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M25.728 6.786a6.786 6.786 0 0 0-6.731-.71 7.522 7.522 0 0 0-1.594.846c-.28.2-.544.42-.817.629-.2.152-.604.564-.87.582-.312.023-.712-.522-.924-.711a7.507 7.507 0 0 0-1.63-1.08 6.494 6.494 0 0 0-6.858.296 6.253 6.253 0 0 0-1.912 6.264c.598 2.936 2.8 5.282 4.635 7.525l3.255 3.976c.891 1.09 1.877 2.845 3.453 1.738 3.244-2.379 6.829-4.502 9.389-7.67a11.172 11.172 0 0 0 2.526-5.295c.343-2.287.006-4.887-1.922-6.39Zm-.656 6.841a.75.75 0 1 1-1.446-.399c.02-.087.038-.174.054-.262a.192.192 0 0 1 .007-.025.285.285 0 0 1 0-.04c.007-.133.015-.266.01-.4q-.002-.1-.011-.2c-.002-.027-.006-.053-.009-.08l-.003-.019c-.035-.14-.057-.283-.099-.423q-.028-.096-.062-.19l-.044-.108a3.823 3.823 0 0 0-.18-.347q-.06-.1-.127-.197l-.067-.088a3.569 3.569 0 0 0-.248-.268c-.048-.046-.1-.089-.15-.134l-.037-.031-.049-.035a3.462 3.462 0 0 0-.37-.227 4.353 4.353 0 0 0-.18-.089c-.022-.01-.02-.01-.029-.015a.357.357 0 0 1-.038-.012 4.496 4.496 0 0 0-.416-.126q-.098-.024-.196-.042a2.885 2.885 0 0 0-.078-.014l-.02-.002c-.135-.01-.267-.019-.401-.017q-.1 0-.2.008a2.81 2.81 0 0 0-.1.01 3.567 3.567 0 0 0-.423.094.75.75 0 1 1-.399-1.447 4.247 4.247 0 0 1 5.312 5.127Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgHealthFilled;
