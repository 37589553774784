import { SVGProps } from 'react';

const SvgVeryHigh = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M6.248 15.105c.446.363.888.733 1.334 1.095.742.6 1.63 1.613 2.612.914a10.332 10.332 0 0 0 1.127-1.077l1.366-1.334 2.456-2.4a11.685 11.685 0 0 1 1.043-1.012.693.693 0 0 1 .093-.049 2.337 2.337 0 0 0 .467.392 21.213 21.213 0 0 1 1.88 1.995l2.447 2.614c.574.614 1.12 1.457 2.088 1.204a2.572 2.572 0 0 0 1.097-.761c.418-.41.864-.79 1.286-1.194.75-.718 1.334-1.464.545-2.402a10.408 10.408 0 0 0-1.15-1.117c.189.165-.125-.134-.163-.171-.136-.13-.269-.262-.402-.395-.708-.706-1.368-1.453-2.045-2.188a68.631 68.631 0 0 0-5.58-5.498.723.723 0 0 0-.103-.058.684.684 0 0 0-.148-.084c-.016-.006-.032-.015-.05-.02a.712.712 0 0 0-.43-.03 2.928 2.928 0 0 0-.99.746c-.46.406-.915.817-1.37 1.228-1.313 1.182-2.664 2.323-3.924 3.565-.83.818-1.63 1.666-2.43 2.515q-.559.594-1.12 1.185a1.793 1.793 0 0 0-.69 1.226 1.557 1.557 0 0 0 .754 1.111Zm1.877-2.207c.693-.736 1.385-1.473 2.094-2.193 1.344-1.365 2.793-2.607 4.216-3.887.432-.388.862-.777 1.294-1.163l.464-.41Q18.17 7 19.993 8.915c1.187 1.245 2.304 2.556 3.532 3.762.428.42.877.806 1.29 1.242.03.032.06.066.088.099h-.002c-.146.04-.388.384-.503.493-.396.375-.811.731-1.201 1.113-.106.103-.268.218-.363.33a.663.663 0 0 1-.058-.063 8.703 8.703 0 0 0-.969-1.058l-2.122-2.266c-.546-.583-1.071-1.188-1.625-1.762-.263-.272-.563-.5-.83-.767a1.093 1.093 0 0 0-1.044-.406c-.83.17-1.448.968-2.022 1.53l-2.197 2.146c-.74.722-1.482 1.442-2.218 2.168-.032.03-.265.247-.28.348a5.88 5.88 0 0 1-.467-.397c-.421-.334-.834-.678-1.249-1.019q-.266-.219-.534-.437l-.023-.022c.298-.28.816-.932.93-1.052Z' />
      <path d='M24.939 22.973c.189.165-.125-.134-.163-.171-.135-.13-.269-.262-.402-.395-.708-.706-1.368-1.453-2.045-2.188a68.631 68.631 0 0 0-5.58-5.498.723.723 0 0 0-.103-.058.684.684 0 0 0-.148-.084c-.016-.006-.032-.015-.05-.02a.712.712 0 0 0-.43-.03 2.928 2.928 0 0 0-.99.745c-.46.406-.914.817-1.37 1.228-1.313 1.184-2.664 2.324-3.924 3.566-.83.819-1.63 1.667-2.43 2.515q-.56.594-1.121 1.186a1.793 1.793 0 0 0-.69 1.226 1.557 1.557 0 0 0 .755 1.11c.446.364.887.733 1.334 1.095.742.601 1.63 1.614 2.612.915a10.332 10.332 0 0 0 1.127-1.078l1.365-1.334 2.457-2.4a11.685 11.685 0 0 1 1.042-1.012.693.693 0 0 1 .094-.048 2.337 2.337 0 0 0 .466.392 21.213 21.213 0 0 1 1.88 1.994l2.448 2.615c.574.613 1.119 1.457 2.088 1.203a2.572 2.572 0 0 0 1.096-.761c.418-.409.864-.79 1.287-1.194.75-.718 1.334-1.463.544-2.402a10.408 10.408 0 0 0-1.149-1.117Zm-.541 2.539c-.396.375-.81.731-1.2 1.113-.106.103-.269.218-.363.33a.663.663 0 0 1-.059-.063 8.703 8.703 0 0 0-.969-1.058l-2.121-2.266c-.547-.583-1.072-1.188-1.626-1.762-.262-.272-.562-.5-.83-.767a1.093 1.093 0 0 0-1.044-.406c-.829.17-1.448.968-2.022 1.53l-2.197 2.146c-.74.722-1.482 1.442-2.218 2.168-.031.03-.264.247-.28.348a5.88 5.88 0 0 1-.467-.397c-.42-.334-.834-.678-1.249-1.019l-.533-.437-.024-.022c.298-.28.816-.932.93-1.052.692-.736 1.383-1.473 2.093-2.193 1.344-1.365 2.793-2.607 4.216-3.887.432-.388.861-.777 1.294-1.163q.231-.207.464-.41 1.976 1.755 3.8 3.67c1.188 1.246 2.305 2.557 3.533 3.762.428.42.876.807 1.29 1.243.03.032.059.065.087.099h-.002c-.146.04-.388.384-.503.493Z' />
    </g>
  </svg>
);

export default SvgVeryHigh;
