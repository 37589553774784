import { SVGProps } from 'react';

const SvgEndCall = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='M28.333 16.24a4.268 4.268 0 0 0-1.633-2.612 12.612 12.612 0 0 0-6.335-2.884c-4.79-.512-10.44-.812-14.536 2.217a5.359 5.359 0 0 0-2.057 2.541 7.827 7.827 0 0 0-.222 3.376 2.243 2.243 0 0 0 2.159 2.02 17.975 17.975 0 0 0 3.92-.158 2.315 2.315 0 0 0 2.237-1.68 9.799 9.799 0 0 0 .265-2.182c.004-.127.009-.262.005-.392a3.933 3.933 0 0 0 .957-.215 19.81 19.81 0 0 1 4.035-.258 8.169 8.169 0 0 1 3.28.74 7.238 7.238 0 0 0 .007 3.141 2.085 2.085 0 0 0 2.394 1.414c1.144-.053 2.253.408 3.397.208a2.671 2.671 0 0 0 2.133-1.998 7.565 7.565 0 0 0-.006-3.277Zm-1.336 2.197c-.055 1.072-.556 1.694-1.676 1.629-.565-.033-1.12-.182-1.682-.241a7.198 7.198 0 0 1-1.499-.02c-.427-.152-.371-.882-.376-1.25a9.238 9.238 0 0 1 .154-1.794.735.735 0 0 0-.002-.153.69.69 0 0 0-.282-.924 10.177 10.177 0 0 0-5.45-1.173 27.95 27.95 0 0 0-2.95.23c-.707.093-2.148.17-2.492.908a4.054 4.054 0 0 0-.12 1.467 6.014 6.014 0 0 1-.223 1.628c-.252.721-1.488.55-2.087.59-.525.034-1.05.074-1.576.095-.443.018-1.155.095-1.51-.233-.252-.235-.219-.71-.225-1.028a9.16 9.16 0 0 1 .062-1.638c.308-1.92 2.42-3.045 4.088-3.652a18.868 18.868 0 0 1 6.239-.948 25.63 25.63 0 0 1 3.283.126 13.871 13.871 0 0 1 2.946.495 10.252 10.252 0 0 1 4.841 2.94 4.774 4.774 0 0 1 .537 2.946Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgEndCall;
