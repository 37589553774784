import { SVGProps } from 'react';

const SvgVerified = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 22 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M21.785 7.236c-.044-1.064-.017-2.272-1.005-2.93a6.858 6.858 0 0 0-1.509-.627 34.897 34.897 0 0 1-1.967-.773 37.134 37.134 0 0 1-3.608-1.795C12.813.612 11.768-.214 10.68.051c-.49.195-.969.422-1.43.679A79.986 79.986 0 0 0 3.6 3.2a76.88 76.88 0 0 0-1.797.9 2.907 2.907 0 0 0-1.37 1.104 3.351 3.351 0 0 0-.256 1.57C.171 7.402.167 8.03.173 8.656c0 1.294.068 2.587.205 3.874a16.555 16.555 0 0 0 2.215 6.92 17.67 17.67 0 0 0 4.688 4.905 7.364 7.364 0 0 0 3.398 1.757c1.324.078 2.507-.98 3.51-1.708a19.466 19.466 0 0 0 5.658-5.799 17.05 17.05 0 0 0 1.932-7.315c.07-1.35.072-2.704.006-4.054Z'
      fill='#3F9631'
    />
    <path
      d='M17.207 8.294a46.706 46.706 0 0 0-7.08 6.889l-2.601-3.161a.806.806 0 0 0-1.13 0 .816.816 0 0 0 0 1.13l3.155 3.837a.8.8 0 0 0 1.13 0 46.724 46.724 0 0 1 7.656-7.564.805.805 0 0 0 0-1.13.819.819 0 0 0-1.13 0Z'
      fill='#fff'
    />
  </svg>
);

export default SvgVerified;
