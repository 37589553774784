import IOption from 'interfaces/IOption';
import IProps from 'interfaces/IProps';
import { ChangeEvent, FC, MouseEvent, PropsWithChildren } from 'react';
import { TagOptionWrapper, TagOptionCheckIcon } from './TagOption.styles';

export interface ITagOptionProps extends PropsWithChildren<IProps> {
  identifier: string;
  tag: IOption;
  isSelected: boolean;
  onAddTag: (tagId: string) => void;
  onRemoveTag: (tagId: string) => void;
}

const TagOption: FC<ITagOptionProps> = (
  props: ITagOptionProps
): JSX.Element => {
  const { dataTestId, identifier, tag, isSelected, onAddTag, onRemoveTag } =
    props;
  const { id, name } = tag;

  const changeHandler = (
    event: MouseEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.currentTarget.checked) onAddTag(id);
    else onRemoveTag(id);
  };

  return (
    <TagOptionWrapper
      data-testid={dataTestId}
      key={id}
      as='label'
      selected={isSelected}
    >
      <input
        name={identifier}
        type='checkbox'
        checked={isSelected}
        value={id}
        onChange={changeHandler}
      />
      <span>{name}</span>
      {isSelected && <TagOptionCheckIcon />}
    </TagOptionWrapper>
  );
};

export default TagOption;
