import ICheckbox from 'interfaces/IOption';
import IProps from 'interfaces/IProps';
import { FC, FormEvent, PropsWithChildren } from 'react';
import styles from 'styles';
import { CheckboxWrapper } from './Checkbox.styles';

export interface CheckboxProps extends PropsWithChildren<IProps> {
  type: 'radio' | 'checkbox';
  identifier?: string;
  option: ICheckbox;
  checked: boolean;
  onCheck: () => void;
  onUncheck: () => void;
}

const Checkbox: FC<CheckboxProps> = (props: CheckboxProps): JSX.Element => {
  const {
    dataTestId,
    className,
    type,
    identifier,
    option,
    checked,
    onCheck,
    onUncheck
  } = props;
  const { id, name } = option;

  const changeHandler = (event: FormEvent<HTMLInputElement>): void => {
    if (event.currentTarget.checked) onCheck();
    else onUncheck();
  };

  return (
    <CheckboxWrapper as='label' className={className} checked={checked}>
      <input
        data-testid={dataTestId}
        type={type}
        name={type === 'radio' ? identifier : id}
        checked={checked}
        value={id}
        onChange={changeHandler}
      />
      {type === 'radio' && (
        <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
          <circle
            cx='10'
            cy='10'
            r='8'
            stroke={styles.colors.brand.main}
            strokeWidth={2}
            fill='transparent'
          />
          <circle
            cx='10'
            cy='10'
            r='4'
            fill={checked ? styles.colors.brand.main : 'transparent'}
          />
        </svg>
      )}
      {type === 'checkbox' && (
        <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
          <rect
            x='2'
            y='2'
            width='16'
            height='16'
            rx='4'
            stroke={styles.colors.brand.main}
            strokeWidth={2}
            fill={checked ? styles.colors.brand.main : 'transparent'}
          />
        </svg>
      )}
      <span>{name}</span>
    </CheckboxWrapper>
  );
};

export default Checkbox;
