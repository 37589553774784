import { SVGProps } from 'react';

const SvgFemale = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    overflow='visible'
    viewBox='0 0 11 17'
    {...props}
  >
    <path
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter'
      }}
      d='M11 5.5C11 2.46 8.54 0 5.5 0S0 2.46 0 5.5c0 2.7 1.94 4.93 4.5 5.4V13h-2v2h2v2h2v-2h2v-2h-2v-2.1c2.56-.47 4.5-2.7 4.5-5.4zm-9 0C2 3.57 3.57 2 5.5 2S9 3.57 9 5.5 7.43 9 5.5 9 2 7.43 2 5.5z'
      vectorEffect='non-scaling-stroke'
    />
  </svg>
);

export default SvgFemale;
