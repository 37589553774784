import { SVGProps } from 'react';

const SvgHour = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M22.404 6.103a12.092 12.092 0 0 0-7.844-1.755 11.568 11.568 0 0 0-7.02 3.59 12.091 12.091 0 0 0-3.607 8.172 11.56 11.56 0 0 0 2.778 7.804 12.853 12.853 0 0 0 11.374 3.632 12.337 12.337 0 0 0 9.384-8.311 11.269 11.269 0 0 0-5.065-13.132Zm3.94 11.605a10.942 10.942 0 0 1-7.622 8.161 11.304 11.304 0 0 1-10.95-3.015 10.107 10.107 0 0 1-.13-12.749 10.663 10.663 0 0 1 5.715-4.009 10.509 10.509 0 0 1 7.244.723c4.034 1.975 6.766 6.352 5.743 10.89Z' />
      <path d='M18.201 17.877a16.197 16.197 0 0 1-1.77-1.53 7.124 7.124 0 0 1-.043-.965q-.015-.669-.018-1.338c-.003-.921.01-1.843.03-2.764a.75.75 0 0 0-1.5 0 101.12 101.12 0 0 0-.028 3.032q.005.75.027 1.501a2.493 2.493 0 0 0 .234 1.33 9.505 9.505 0 0 0 1.94 1.74l2.377 1.927a.772.772 0 0 0 1.06 0 .755.755 0 0 0 0-1.06Z' />
    </g>
  </svg>
);

export default SvgHour;
