import { useDate } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import {
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  useEffect,
  useReducer,
  useState
} from 'react';
import { IInputFormat, updateDateValue } from 'utils/form';
import useInput, { IUseInput } from '../Input.logic';
import { IInputProps } from '../Input.types';

export interface IUseDateTimeInputs
  extends Omit<IUseInput, 'inputChangeHandler' | 'blurHandler'> {
  values: DateTimeValuesState;
  dispatchValues: React.Dispatch<DateTimeAction>;
  containerClickHandler: () => void;
  inputClickHandler: (event: MouseEvent<HTMLInputElement>) => void;
  inputChangeHandler: (
    event: ChangeEvent<HTMLInputElement>,
    format: IInputFormat
  ) => void;
  blurHandler: (
    format?: IInputFormat,
    event?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export type DateTimeValuesState = {
  [key: string]: {
    format: IInputFormat;
    value: number | undefined;
    verified: boolean;
  };
};

export type DateTimeAction = {
  type: string;
  value?: number;
  verify?: boolean;
};

const getInitialValues = (inputFormat: IInputFormat[]): DateTimeValuesState => {
  let initialValues: DateTimeValuesState = {};
  inputFormat.forEach((format: IInputFormat) => {
    initialValues = {
      ...initialValues,
      [format.text]: { format, value: undefined, verified: true }
    };
  });

  return initialValues;
};

const inputsReducer = (
  state: DateTimeValuesState,
  action: DateTimeAction
): DateTimeValuesState => {
  const { type, value, verify } = action;

  const {
    format: { min, max, step, nrDigits },
    value: stateValue
  } = state[type];

  let newValue: number | undefined;

  if (verify) {
    newValue = stateValue;

    if (newValue === undefined) newValue = min || max;
    else if (min && newValue < min) newValue = min;
    else if (max && newValue > max) newValue = max;
    else if (step) {
      const modulus = newValue % step;

      if (modulus > 0) {
        newValue =
          modulus < step / 2 ? newValue - modulus : newValue + (step - modulus);
      }
    }
  } else {
    newValue = value;

    if (
      newValue !== undefined &&
      nrDigits &&
      newValue.toString().length > nrDigits
    )
      return { ...state };
  }

  return {
    ...state,
    [type]: {
      ...state[type],
      value: newValue,
      verified: verify !== undefined ? verify : state[type].verified
    }
  };
};

const useDateTimeInputs = (
  props: IInputProps,
  inputFormat: IInputFormat[]
): IUseDateTimeInputs => {
  const { formatDate } = useDate();
  const {
    input,
    value,
    isFocused,
    showPopup,
    containerRef,
    changeHandler,
    focusHandler,
    blurHandler: inputBlurHandler
  } = useInput(props, true);
  const [values, dispatchValues] = useReducer(
    inputsReducer,
    getInitialValues(inputFormat)
  );
  const [updateValue, setUpdateValue] = useState<boolean>(false);

  useEffect(() => {
    inputFormat.forEach(({ text }: IInputFormat) =>
      dispatchValues({ type: text, value: +formatDate(value as Date, text!) })
    );
  }, [value]);

  useEffect(() => {
    if (!updateValue) return;

    const newTime = new Date(value as Date);
    Object.values(values).forEach(
      ({
        format: { text },
        value
      }: {
        format: IInputFormat;
        value: number | undefined;
      }) => {
        if (value) updateDateValue(text, value, newTime);
      }
    );

    changeHandler(newTime);
    setUpdateValue(false);
  }, [updateValue]);

  const containerClickHandler = (): void => {
    containerRef.current?.querySelector('input')?.focus();
  };

  const inputClickHandler = (event: MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  const inputChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    { text }: IInputFormat
  ): void => {
    event.stopPropagation();

    const inputValue = event.target.value;

    if (!inputValue) {
      dispatchValues({ type: text, value: undefined, verify: false });
      return;
    }

    dispatchValues({ type: text, value: +inputValue, verify: false });
  };

  const blurHandler = (
    format?: IInputFormat,
    event?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    if (format) dispatchValues({ type: format.text, verify: true });
    inputBlurHandler(event);
    setUpdateValue(true);
  };

  return {
    input,
    value,
    isFocused,
    showPopup,
    containerRef,
    values,
    dispatchValues,
    containerClickHandler,
    inputClickHandler,
    inputChangeHandler,
    changeHandler,
    focusHandler,
    blurHandler
  };
};

export default useDateTimeInputs;
