import { SVGProps } from 'react';

const SvgFlipCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M27.96 22.511a11.232 11.232 0 0 1-.061-2.033v-4.602a35.44 35.44 0 0 0 .009-4.109 3.301 3.301 0 0 0-2.06-2.93 9.697 9.697 0 0 0-1.96-.216c-.308-.016-.616-.024-.923-.041a4.191 4.191 0 0 1-1.198-.086c-.073-.047-.146-.49-.18-.606a9.74 9.74 0 0 0-.314-.907 3.868 3.868 0 0 0-.759-1.426c-.448-.409-.962-.262-1.504-.219-.722.057-1.453.061-2.177.074q-1.056.019-2.113.004a17.317 17.317 0 0 0-2.096-.034 1.455 1.455 0 0 0-1.26 1.012c-.295.668-.575 1.343-.862 2.015a2.691 2.691 0 0 1-.157.287c-.119.07-.442.009-.569.009-.349-.001-.698-.01-1.048 0a6.637 6.637 0 0 0-2.162.441 3.272 3.272 0 0 0-2.318 2.933c-.188 1.637-.014 3.352-.036 5-.023 1.735-.133 3.467-.166 5.201a5.385 5.385 0 0 0 .734 2.856 3.56 3.56 0 0 0 2.83 1.546 37.567 37.567 0 0 0 4.201.07q2.295-.004 4.589-.043 2.35-.04 4.7-.117c.672-.022 1.354-.002 2.023-.067a17.262 17.262 0 0 0 1.9-.13 3.69 3.69 0 0 0 2.66-2.14 3.067 3.067 0 0 0 .278-1.742ZM19.54 6.745c-.045-.064-.06-.177 0 0Zm6.354 17.529c-.76.84-1.856.667-2.877.757-1.285.112-2.602.083-3.892.117q-4.547.12-9.096.1c-1.063-.006-2.686.24-3.585-.455a3.216 3.216 0 0 1-.898-2.515c.05-2.692.198-5.375.17-8.07-.01-.994-.276-2.55.583-3.286a3.694 3.694 0 0 1 1.39-.59 7.067 7.067 0 0 1 1.88-.131 3.438 3.438 0 0 0 1.479-.168c.522-.242.67-.687.885-1.187.241-.565.468-1.14.725-1.697.152-.33.186-.285.54-.274.363.01.725.024 1.087.031q1.842.039 3.684-.026c.523-.019 1.063-.1 1.583-.1l.003.01a2.944 2.944 0 0 0 .145.303 8.381 8.381 0 0 1 .339.869 6.924 6.924 0 0 0 .421 1.316 1.616 1.616 0 0 0 1.318.708c.625.076 1.257.097 1.886.125a5.535 5.535 0 0 1 1.867.206c1.14.516.867 2.17.884 3.142.021 1.21-.017 2.427-.017 3.642v3.957c0 1.134.326 2.298-.504 3.216Z' />
      <path d='M23.463 15.798a6.345 6.345 0 0 1-1.34-1.919.756.756 0 0 0-1.027-.269.767.767 0 0 0-.27 1.026 7.594 7.594 0 0 0 1.217 1.846c.084.095.184.175.274.265H9.55c.06-.068.115-.138.175-.205a18.876 18.876 0 0 1 1.464-1.453.75.75 0 0 0-1.06-1.06 18.75 18.75 0 0 0-1.841 1.884c-.313.37-.614.75-.898 1.142a1.061 1.061 0 0 0-.01 1.344 5.796 5.796 0 0 0 1.952 1.268 5.415 5.415 0 0 1 1.575 1.687.77.77 0 0 0 1.027.27.756.756 0 0 0 .269-1.027 8.091 8.091 0 0 0-1.85-2.055 3.868 3.868 0 0 0-.501-.295h12.456a19.745 19.745 0 0 0-2.273 2.683.757.757 0 0 0 .27 1.026.765.765 0 0 0 1.026-.269 17.064 17.064 0 0 1 1.57-1.96q.43-.46.89-.89a2.727 2.727 0 0 0 .889-1.036c.31-.959-.646-1.451-1.216-2.003Z' />
    </g>
  </svg>
);

export default SvgFlipCamera;
