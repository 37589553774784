import { SVGProps } from 'react';

const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <g data-name='finais c/expand' fill='#009eba'>
      <path d='M25.453 5.032c-1.87.335-3.737.685-5.606 1.03a3.357 3.357 0 0 0-2.192-1.846 7.51 7.51 0 0 0-2.739.025 4.82 4.82 0 0 0-2.344 1.04 2.977 2.977 0 0 0-.826 2.303c-1.867.358-3.734.71-5.598 1.077-.947.187-.546 1.633.398 1.447q9.638-1.897 19.306-3.63c.949-.17.547-1.616-.399-1.446Zm-11.78 1.274a3.918 3.918 0 0 1 1.642-.619 5.88 5.88 0 0 1 1.831-.044 1.304 1.304 0 0 1 .783.312 3.075 3.075 0 0 1 .323.4q-2.502.464-5.002.94a1.325 1.325 0 0 1 .423-.989ZM15.39 14.685l-.212 8.687a.75.75 0 0 0 1.5 0l.212-8.687a.75.75 0 0 0-1.5 0ZM19.609 14.493l-.749 8.713c-.083.962 1.418.956 1.5 0l.75-8.713c.082-.961-1.419-.956-1.501 0ZM13.135 20.882c-.063-.737-.123-1.474-.19-2.21a69.556 69.556 0 0 0-.52-4.44.755.755 0 0 0-.922-.524.769.769 0 0 0-.523.923c.224 1.453.368 2.916.495 4.38.065.737.132 1.473.189 2.21a14.155 14.155 0 0 0 .206 2.087c.214.94 1.661.543 1.447-.399a14.78 14.78 0 0 1-.182-2.027Z' />
      <path d='M24.24 10.306q-8.241-.065-16.48.14a.773.773 0 0 0-.539 1.282v.007A54.35 54.35 0 0 1 8.31 21.09c.05.77.107 1.542.18 2.311.074.783.251 1.56.275 2.344a1.883 1.883 0 0 0 .466 1.39 2.873 2.873 0 0 0 1.759.674 45.28 45.28 0 0 0 4.62.087q2.39.015 4.78-.05c1.15-.03 2.45-.221 2.996-1.384a5.68 5.68 0 0 0 .245-1.997c.069-.888.148-1.774.222-2.662l.471-5.652c.128-1.53.318-3.05.459-4.576a.765.765 0 0 0-.542-1.27Zm-1.692 9.164-.38 4.546c-.028.337-.058.674-.075 1.011a2.071 2.071 0 0 1-.077.835c-.23.444-.938.455-1.362.476-2.828.139-5.659 0-8.486.013a3.738 3.738 0 0 1-1.699-.17c-.362-.203-.212-.795-.26-1.177a66.935 66.935 0 0 1-.428-4.361 57.017 57.017 0 0 0-.995-8.715q7.234-.165 14.47-.122c-.104 1.04-.242 2.075-.33 3.118l-.378 4.546Z' />
    </g>
  </svg>
);

export default SvgDelete;
