import { SVGProps } from 'react';

const SvgNotificationsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <path
      d='m27.656 22.38-1.954-.01c.037-1.77.198-3.53.142-5.307a23.159 23.159 0 0 0-.703-5.29 9.897 9.897 0 0 0-5.071-6.678 10.701 10.701 0 0 0-8.063-.367 8.557 8.557 0 0 0-5.282 6.188 22.352 22.352 0 0 0-.599 5.846c.012 1.837.073 3.68.162 5.516l-1.944-.009a.75.75 0 0 0 0 1.5l6.617.031c.362 2.403 2.915 4.056 5.25 4.089a4.641 4.641 0 0 0 4.692-4.041l6.754.032a.75.75 0 0 0 0-1.5Zm-5.362-6.848a.75.75 0 0 1-1.5 0 7.977 7.977 0 0 0-1.669-5.396.767.767 0 0 1 0-1.06.755.755 0 0 1 1.061 0 9.53 9.53 0 0 1 2.108 6.455Z'
      fill='#009eba'
      data-name='finais c/expand'
    />
  </svg>
);

export default SvgNotificationsFilled;
